import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import * as Yup from "yup";
import WUpdateEmail from "./Web/WUpdateEmail";
import { axiosPatch, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { errorCode } from "src/constants/common";
import { me } from "src/redux/reducers/common/Common.slice";
import MUpdateEmail from "./Mobile/MUpdateEmail";
import { IUpdateEmailField, IUpdateEmailFieldKey, IUpdateEmailPayload, IUpdateEmailVerifyPayload, IEmailVerifyStep } from "./Profile.interface";

const UpdateEmail = ({ handleModalClose, setIsSuccess }: { handleModalClose: () => void; setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpLoading, setIsOtpLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [verifyStep, setVerifyStep] = useState<IEmailVerifyStep>({ email: true, otp: false, success: false });

    const schema = Yup.object({
        email: Yup.string().email(t("A valid Email address is required")).required(t("This field is required")),
    }).required();

    const methods = useForm<IUpdateEmailField>({
        resolver: yupResolver(schema),
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        defaultValues: {
            email: "",
            otp: "",
        },
    });
    const { handleSubmit, setError, resetField } = methods;

    const handleChange = async (data: IUpdateEmailField) => {
        setIsLoading(true);
        setEmail(data.email);
        if (verifyStep.otp) {
            const payload: IUpdateEmailVerifyPayload = {
                ...data,
                otp: data.otp ?? "",
            };
            await axiosPost(API.USER.EMAIL_VERIFY_OTP, payload)
                .then(async (response) => {
                    dispatch(me());
                    if (setIsSuccess) {
                        setIsSuccess(true);
                    }
                    setVerifyStep((prevState) => ({
                        ...prevState,
                        otp: false,
                        success: true,
                    }));
                })
                .catch((error: any) => {
                    const response = error.response.data;
                    if (response.status === errorCode.unprocessable) {
                        if (response.data) {
                            let message = "";
                            Object.keys(response.data).forEach((field) => {
                                message = response.data[field][0];
                                setError(field as IUpdateEmailFieldKey, {
                                    type: "manual",
                                    message: message,
                                });
                                return;
                            });
                        }
                        return;
                    }
                })
                .finally(() => setIsLoading(false));
        } else {
            const asyncFunction = handlerSendCode(data.email);
            asyncFunction();
        }
    };

    const handlerSendCode = (emailData: string) => async () => {
        resetField("otp");
        setIsOtpLoading(true);
        const payload: IUpdateEmailPayload = {
            email: emailData,
        };
        await axiosPatch(API.USER.EMAIL_UPDATE, payload)
            .then(async (response) => {
                setVerifyStep((prevState) => ({
                    ...prevState,
                    otp: true,
                    email: false,
                }));
            })
            .catch((error: any) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        let message = "";
                        Object.keys(response.data).forEach((field) => {
                            message = response.data[field][0];
                            setError(field as IUpdateEmailFieldKey, {
                                type: "backend",
                                message: message,
                            });
                            return;
                        });
                    }
                    return;
                }
            })
            .finally(() => {
                setIsOtpLoading(false);
                setIsLoading(false);
            });
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleChange)}>
                {uiState.isTablet ? (
                    <WUpdateEmail email={email} isLoading={isLoading} verifyStep={verifyStep} handleModalClose={handleModalClose} handlerSendCode={handlerSendCode} isOtpLoading={isOtpLoading} />
                ) : (
                    <MUpdateEmail email={email} isLoading={isLoading} handleModalClose={handleModalClose} verifyStep={verifyStep} handlerSendCode={handlerSendCode} isOtpLoading={isOtpLoading} />
                )}
            </form>
        </FormProvider>
    );
};

export default UpdateEmail;
