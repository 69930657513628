import React, { useLayoutEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import { useAppSelector } from "../../redux/hooks";
import { logout } from "../../utils/global-functions";
import Landing from "./Landing";
import Notification from "./Notification";
import AccountSetting from "./AccountSetting";
import PaymentMethod from "./PaymentMethod";
import NotificationSetting from "./NotificationSetting";
import BookingList from "./Booking/BookingList";
import { initAction } from "./Profile.interface";
import AddNewCardTabComponent from "../BookAppointment/FinalizeBooking/AddNewCardTabComponent";
import Header from "./Header";
import { axiosGet, axiosPatch } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { getBookingInfo } from "../BookAppointment/Booking.slice";

const Profile = ({ isOpen, handleClose }: any) => {
    const [action, setAction] = useState(initAction);
    const uiState = useAppSelector((data) => data.UiStates);
    const [totalRecords, setTotalRecords] = useState({ totalRecords: 0, unread_count: 0 });
    const BookingInfo = useAppSelector(getBookingInfo);

    useLayoutEffect(() => {
        if (isOpen) {
            setAction(initAction);
        }
    }, [isOpen]);

    // const handleAction = (type: string) => () => {
    //     const types = {
    //         [type]: true,
    //         landing: type !== "landing" && type !== "logout" ? false : true,
    //     };
    //     setAction((old) => ({ ...old, ...types }));
    // };

    const checkNotificationStatus = async () => {
        if (totalRecords.unread_count === 0) return;

        const payload: any = {
            type: "all",
        };

        await axiosPatch(API.NOTIFICATION.READ, payload).then(async (response) => {
            getNotificationCount();
            return;
        });
    };

    const getNotificationCount = () => {
        axiosGet(API.NOTIFICATION.LIST, {}).then((response) => {
            setTotalRecords({ totalRecords: response.data.data.totalRecords, unread_count: response.data.data.unread_count });
        });
    };

    const handleAction = (type: string) => () => {
        if (type === "logout") {
            return logout();
        }
        setAction((old) => {
            const updatedAction: typeof old = Object.keys(old).reduce((acc, key) => {
                acc[key as keyof typeof old] = key === type;
                return acc;
            }, {} as typeof old);

            return updatedAction;
        });
    };

    const handleSidebarClose = (isNotification = false) => {
        handleClose();
        if (isNotification) {
            checkNotificationStatus();
        }
    };

    const handleReturnToLanding = () => {
        setAction(initAction);
    };

    const handleBack = () => {
        setAction(initAction);
    };

    const getHeader = () => {
        if ((uiState.isMobile && action.landing) || (uiState.isMobile && action.notification) || !uiState.isMobile) {
            return (
                <Header
                    handleAction={handleAction}
                    handleClose={(!uiState.isMobile && action.landing) || (uiState.isMobile && action.landing) ? handleSidebarClose : handleReturnToLanding}
                    isAnyUnreadMsg={totalRecords.unread_count > 0}
                    action={action}
                    checkNotificationStatus={checkNotificationStatus}
                    getNotificationCount={getNotificationCount}
                />
            );
        }
        return null;
    };

    return (
        <>
            <Sidebar
                isOpen={isOpen}
                handleClose={() => handleSidebarClose(action.notification)}
                size={`w-[460px] ${BookingInfo.isFullScreen ? "max-lg:!pb-[24px]" : ""} `}
                customClass={`${
                    uiState.isMobile
                        ? action.landing || action.notification
                            ? "!h-[100%] w-full profile-sidebar"
                            : "w-full !h-auto"
                        : action.landing || action.notification
                        ? "profile-sidebar only-close-icon "
                        : "w-[460px] profile-sidebar"
                } profile-sidebar-pages top-line-remove `}
                position={uiState.isMobile ? "bottom" : "right"}
                isBack={!action.landing}
                isBottomView={uiState.isMobile}
                handleBack={handleBack}
                showCloseIcon={uiState.isMobile ? (action.landing || action.notification ? false : true) : false}
                dismissable
            >
                {getHeader()}
                {isOpen && (
                    <>
                        {action.landing && <Landing handleAction={handleAction} handleClose={handleSidebarClose} />}
                        {action.notification && <Notification handleSidebarClose={handleSidebarClose} setTotalRecords={setTotalRecords} totalRecords={totalRecords} />}
                        {action.notification_setting && <NotificationSetting handleClose={handleReturnToLanding} />}
                        {action.account_setting && <AccountSetting handleClose={handleReturnToLanding} />}
                        {action.payment_method && <PaymentMethod />}
                        {action.appointment_history && <BookingList handleClose={handleSidebarClose} />}
                        {action.create_payment_method && <AddNewCardTabComponent closeAddNewCardTab={handleReturnToLanding} />}
                    </>
                )}
            </Sidebar>
        </>
    );
};

export default Profile;
