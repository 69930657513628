import { Minus, Plus } from "@untitled-ui/icons-react/build/cjs";
import React, { FC, useEffect, useState } from "react";
import { DefaultImageJPG } from "src/theme/Images";
import { IIndividualProductDetail } from "./Product.interface";
import { IImages } from "../../Template2/Product/Product.interface";
import { formatAmount } from "src/utils/global-functions";

const IndividualProductDetail: FC<IIndividualProductDetail> = ({ productData }) => {
    const productDetails = productData[0];
    const imageUrl = productDetails.images && productDetails.images.length > 0 ? productDetails.images.find((image: IImages) => image.is_main)?.image_url || DefaultImageJPG : DefaultImageJPG;
    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 1, isShow: false });
    const [selctedVariant, setSelctedVariant] = useState<{
        variant: number;
        price: number;
    }>({ price: 0, variant: 0 });

    useEffect(() => {
        if (productData.length && productData[0].variants.length) {
            setSelctedVariant({
                variant: productData[0]?.variants[0].id,
                price: productData[0]?.variants[0].price,
            });
        }
    }, []);

    const handlePrice = (variantDetail: any) => {
        setSelctedVariant({
            variant: variantDetail.id,
            price: variantDetail.price,
        });
    };
    return (
        <>
            <div className="flex  flex-row gap-6 w-full">
                {" "}
                <div className="profile-images max-lg:w-[40%] lg:w-[50%] lg:max-w-[668px] lg:min-w-[512px] 2xl:min-w-[668px]">
                    <img src={imageUrl} alt="" className="w-full max-h-[438px] lg:min-h-[672px] lg:max-h-[672px] aspect-[1/1] profile-img object-cover cursor-pointer " />
                </div>
                <div className="flex flex-col w-[60%] 2xl:w-full">
                    <h3 className="text-[24px] leading-[36px] lg:text-[32px] lg:leading-[48px] uppercase text-TextColor font-medium">{productDetails?.name}</h3>

                    <div className="flex justify-between mt-[12px] ">
                        <div className="flex gap-2">
                            {productDetails.variants.map((variant, index) => (
                                <button
                                    key={index}
                                    onClick={() => handlePrice(variant)}
                                    className={`border border-borderColorSecondary px-1.5 hover:border-primary hover:text-primary text-textGrey py-[5px] rounded-[6px] text-[12px] leading-[18px] font-medium
                                 ${variant.id === selctedVariant.variant ? "border-primary !text-primary" : ""}
                            `}
                                >
                                    {`${variant.size} ${productDetails?.unit_of_measure === "units" ? "" : productDetails.unit_of_measure}`}
                                </button>
                            ))}
                        </div>
                        <div className="text-[24px] leading-[36px] lg:text-[32px] lg:leading-[48px] uppercase text-TextColor font-medium">{formatAmount(selctedVariant.price)}</div>
                    </div>
                    <hr className=" border-TextColor my-4 lg:mt-5 lg:mb-8"></hr>
                    <p className="text-[18px] leading-[22px] lg:text-[20px] lg:leading-[24.2px] text-TextColor font-medium mb-3">Product Description</p>
                    <div className="text-sm lg:text-[16px] lg:leading-[19.6px] text-TextColor font-normal pb-3 border-b border-TextColor">{productDetails?.description}</div>
                    {productDetails.details.map((detail, index) => (
                        <div key={index} className="py-4 border-b border-TextColor ">
                            <div className="flex justify-between items-center">
                                <p className="text-[18px] leading-[22px] lg:text-[20px] lg:leading-[24.2px] text-TextColor font-medium">{detail.name}</p>
                                <button
                                    onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
                                    className="text-right text-TextColor text-[14px] font-normal"
                                >
                                    {isCollapsed.isShow && isCollapsed.id === index ? <Minus /> : <Plus />}
                                </button>
                            </div>
                            {isCollapsed.isShow && isCollapsed.id === index && (
                                <p className="pt-2 text-sm lg:text-[16px] lg:leading-[19.6px] text-TextColor font-normal pb-3 "> {detail.description}</p>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default IndividualProductDetail;
