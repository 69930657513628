import React, { FC, useState } from "react";
import { CountryCode } from "libphonenumber-js";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FloatingInputbox from "src/components/FloatingInputbox";
import Number from "src/components/Number";
import { WInquireFormProps } from "./WInquireForm";

const MInquireForm: FC<WInquireFormProps> = ({ title }) => {
    const { t } = useTranslation();
    const { control, setValue, clearErrors } = useFormContext();
    const [isLabel, setIsLabel] = useState(true);
    const [isFocus, setIsFocus] = useState(false);
    const [localNumber, setLocalNumber] = useState("");
    const onFocus = () => {
        setIsFocus(true);
        setIsLabel(true);
    };

    const onBlur = () => {
        if (!localNumber) {
            setIsLabel(true);
        } else {
            setIsLabel(false);
        }
        setIsFocus(false);
    };

    const handleNumberChange = (country: CountryCode, phone: string, code: string, isValid: boolean) => {
        setValue(`phone`, `+${code}${phone}`);
        setValue(`phone_country_code`, country);
        if (isValid) {
            clearErrors("phone");
        }
        setLocalNumber(phone);
    };
    return (
        <div className="mobile-model-inner flex flex-col mt-5">
            {title !== "" && <span className="login-model-title-mobile">{title}</span>}
            <div className="mt-[12px]">
                <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full">
                            <FloatingInputbox label={t("Name")} type="text" required handleChange={onChange} value={value} name="name" error={!!error} />
                            {error && <p className="text-error !ml-0">{error.message}</p>}
                        </div>
                    )}
                />
            </div>
            <div className="mt-[16px]">
                <Controller
                    name="phone"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-full">
                            <div className="flex flex-col relative">
                                {isLabel ? (
                                    <span className="text-textGrey text-[12px] font-normal -tracking-[0.01rem] leading-[14.52px] flex absolute left-[14px] top-2">{t("Phone Number")}</span>
                                ) : (
                                    ""
                                )}
                                <Number
                                    getNumber={onChange}
                                    errors={error}
                                    parentClassName={`${isFocus || isLabel ? "!pt-[22px]" : ""}`}
                                    onNumberChange={handleNumberChange}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                />
                            </div>
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
            </div>
            <div className="mt-[16px]">
                <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full">
                            <FloatingInputbox label={t("Message")} type="text" required handleChange={onChange} value={value} name="description" error={!!error} />
                            {error && <p className="text-error !ml-0">{error.message}</p>}
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default MInquireForm;
