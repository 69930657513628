export interface IIsLoading {
    deafultCard: boolean;
    cardList: boolean;
    id: number | null;
}

export const initLoading = {
    deafultCard: false,
    cardList: false,
    id: null,
};

export interface IFormData {
    carddetails: string;
    holdername: string;
}
export interface ICardTabComponent {
    loadStripeCardList: () => Promise<void>;
    isButtonShow: boolean;
    setIsShowAddNewCardTab?: React.Dispatch<React.SetStateAction<boolean>>;
}
