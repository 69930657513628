import React, { FC, useEffect, useState } from "react";
import BookingView from "src/app/Profile/Booking/BookingView";
import { axiosGet, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import Sidebar from "src/components/Sidebar";
import { IInitAction, initAction } from "./Location.interface";
import { currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import BookingDetails from "./BookingDetails";
import SliderCarousel from "src/components/SliderCarousel";
import { firebaseEmitter } from "src/services/API/firebase";
import { getBookingInfo, setBookingIsUpadteCard } from "../BookAppointment/Booking.slice";

interface IBooking {
    isCarouselView?: boolean;
    setBookingCount?: React.Dispatch<React.SetStateAction<number>>;
}

const Booking: FC<IBooking> = ({ isCarouselView = false, setBookingCount }) => {
    const dispatch = useAppDispatch();
    const bookingInfo = useAppSelector(getBookingInfo);
    const shop = useAppSelector(currentShop);
    const user = useAppSelector(currentUser);
    const [isRefresh, setIsRefresh] = useState(true);
    const uiState = useAppSelector((data) => data.UiStates);
    const [bookings, setBookings] = useState<any[]>([]);
    const [booking, setBooking] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState<IInitAction>(initAction);
    const [isCardAdded, setIsCardAdded] = useState(false);
    const [isCardLoaded, setIsCardLoaded] = useState(false);
    const [removeBookingId, setRemoveBookingId] = useState(null);
    const [success, setSuccess] = useState({ id: 0, status: false });

    useEffect(() => {
        const fetchApi = async () => {
            if (isRefresh && user) {
                await listStripeCards();
                await listBookings();
            }
        };
        fetchApi();
    }, [isRefresh]);

    useEffect(() => {
        if (removeBookingId && bookings.length) {
            const filteredArray = bookings.filter((item: any) => item.id !== removeBookingId);
            setBookings(filteredArray);
            setRemoveBookingId(null);
        }
    }, [removeBookingId]);

    const listStripeCards = async () => {
        setIsLoading(true);
        setIsCardLoaded(false);
        axiosGet(API.STRIPE.CARD_LIST)
            .then((response) => {
                if (response.data.data.cards.length) {
                    setIsCardAdded(true);
                } else {
                    setIsCardAdded(false);
                }
            })
            .catch((error: any) => {})
            .finally(() => {
                setIsLoading(false);
                setIsCardLoaded(true);
                dispatch(setBookingIsUpadteCard(false));
            });
    };

    useEffect(() => {
        if (bookingInfo.isUpadteCard) {
            listStripeCards();
        }
    }, [bookingInfo.isUpadteCard]);

    useEffect(() => {
        if (user) {
            firebaseEmitter.on("firebaseNotification", handleNewNotification);
            return () => {
                firebaseEmitter.removeListener("firebaseNotification", handleNewNotification);
            };
        }
    }, []);

    const handleNewNotification = (payload: any) => {
        listBookings();
    };

    const listBookings = async () => {
        setIsLoading(true);
        const payload = {
            type: "upcoming",
        };
        const params = { shop_id: shop.id };
        axiosPost(API.BOOKING.UPCOMING_LIST, payload, params)
            .then((response) => {
                if (setBookingCount) {
                    setBookingCount(response.data.data.length);
                }
                setBookings(response.data.data);
            })
            .finally(() => {
                setIsLoading(false);
                setIsRefresh(false);
                setSuccess({ id: 0, status: false });
            });
    };

    const handleAction = (type: keyof IInitAction, data?: any) => () => {
        if (type === "booking") {
            setBooking(data);
        }
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    const settings = {
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        centerMode: false,
    };

    const handlePaymentEnable = (bookingItem: IAppointmentBooking) => {
        if (bookingItem && bookingItem.staff?.pay_structure && bookingItem.staff?.pay_structure.pay_structure_type && bookingItem.staff.pay_structure.pay_structure_type === "rent") {
            return bookingItem.staff.is_verified_stripe_account;
        } else {
            if (shop) {
                return shop.admin.is_verified_stripe_account;
            }
        }
    };

    // return isLoading ? (
    //     <></>
    // ) : (
    return (
        <div
            className={` ${
                uiState.isMobile ? "pt-0 mb-6 !mt-3 absolute overflow-y-auto booking-container" : "top-0 bottom-0 left-0 right-0 lg:pt-5 px-5 lg:pb-5 absolute overflow-y-auto booking-container"
            } ${bookings.length === 0 && "!mb-0 !pb-0 !mt-0 !pt-0"} ${bookings.length === 1 ? "!mb-5" : ""}`}
        >
            {bookings.length > 0 && isCardLoaded && (
                <>
                    <div className={` ${uiState.isMobile ? "flex flex-col w-full rounded-[12px] bg-transparent" : "ml-auto flex flex-col w-full max-w-[370px] gap-4 relative"} z-10 `}>
                        {isCarouselView && bookings.length !== 1 ? (
                            <SliderCarousel settings={settings}>
                                {bookings.map((item: IAppointmentBooking) =>
                                    item.status === "completed" ? (
                                        isCardAdded && !item.is_tip_paid && !item.is_tip_unpaid && handlePaymentEnable(item) ? (
                                            <BookingDetails
                                                bookingDetails={item}
                                                handleAction={handleAction}
                                                key={item.id}
                                                isCardAdded={isCardAdded}
                                                listBookings={listBookings}
                                                isTipShow={true}
                                                setRemoveBookingId={setRemoveBookingId}
                                                success={success}
                                                setSuccess={setSuccess}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    ) : (
                                        <BookingDetails
                                            bookingDetails={item}
                                            handleAction={handleAction}
                                            key={item.id}
                                            isCardAdded={isCardAdded}
                                            listBookings={listBookings}
                                            isTipShow={false}
                                            setRemoveBookingId={setRemoveBookingId}
                                            success={success}
                                            setSuccess={setSuccess}
                                        />
                                    ),
                                )}
                            </SliderCarousel>
                        ) : (
                            bookings.map((item: IAppointmentBooking) =>
                                item.status === "completed" ? (
                                    isCardAdded && !item.is_tip_paid && !item.is_tip_unpaid && handlePaymentEnable(item) ? (
                                        <BookingDetails
                                            CustomClassName="!lg:shadow-[0px_4px_10px_0px_var(--header-shadow)] hover:!shadow-[0px_4px_25px_0px_var(--header-shadow)] !shadow-[0px_4px_10px_0px_var(--header-shadow)]"
                                            bookingDetails={item}
                                            handleAction={handleAction}
                                            key={item.id}
                                            isCardAdded={isCardAdded}
                                            listBookings={listBookings}
                                            isTipShow={true}
                                            setRemoveBookingId={setRemoveBookingId}
                                            success={success}
                                            setSuccess={setSuccess}
                                        />
                                    ) : (
                                        <></>
                                    )
                                ) : (
                                    <BookingDetails
                                        CustomClassName="!lg:shadow-[0px_4px_10px_0px_var(--header-shadow)] hover:!shadow-[0px_4px_25px_0px_var(--header-shadow)] !shadow-[0px_4px_10px_0px_var(--header-shadow)]"
                                        bookingDetails={item}
                                        handleAction={handleAction}
                                        key={item.id}
                                        isCardAdded={isCardAdded}
                                        listBookings={listBookings}
                                        isTipShow={false}
                                        setRemoveBookingId={setRemoveBookingId}
                                        success={success}
                                        setSuccess={setSuccess}
                                    />
                                ),
                            )
                        )}
                    </div>
                    <Sidebar
                        isOpen={action.booking}
                        handleClose={handleClose}
                        size="w-[460px]"
                        position={uiState.isMobile ? "bottom" : "right"}
                        isBottomView={uiState.isMobile}
                        customClass="profile-sidebar !h-full "
                        dismissable
                    >
                        {action.booking && booking && <BookingView booking={booking} handleClose={handleClose} setIsRefresh={setIsRefresh} />}
                    </Sidebar>
                </>
            )}
        </div>
    );
    // );
};

export default Booking;
