import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import { currentShop } from "src/redux/reducers/common/Common.slice";

const Step6 = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const businessName = shop && shop.business_name ? shop.business_name : "FLAIR Studios";
    return (
        <div className="flex flex-col w-full">
            <span className="text-[14px] leading-[19.6px] font-normal text-TextColor tracking-[-0.01em] h-[44px]">{t("Enable push notifications for appointment updates.")}</span>
            <div className="phone-front-body-bg">
                <div className="phone-body">
                    <div className="w-[231px] min-h-[174px] bg-mainBackgroundColor rounded-3xl flex flex-col items-center justify-center">
                        <div className="flex flex-col gap-1.5 p-3 text-center">
                            <div className="text-[14px] leading-[19.6px] font-bold text-txtAppointmentColor tracking-[-0.01rem]">
                                <span>“{businessName}”</span> <span>{t("We’d like to send you notifications.")}</span>
                            </div>

                            <div className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor tracking-[-0.01rem]">
                                {t("Notifications may include alerts, sounds, and badges. Configure them in settings.")}
                            </div>
                        </div>
                        <div className="flex items-center h-[44px] border-t border-borderColorSecondary w-full">
                            <span className="text-primary py-3 leading-[19.6px] text-[14px] border-r border-borderColorSecondary w-[50%] justify-center items-center flex">{t("Don’t Allow")}</span>
                            <span className="text-primary leading-[19.6px] text-[14px] justify-center items-center flex w-[50%]">{t("Allow")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step6;
