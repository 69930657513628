export const API = {
    USER: {
        ME: "/user/shop/{shop_id}/me",
        LOGIN: "/user/login",
        VERIFY_OTP: "/user/verify-otp",
        PHONE_EMAIL: "/user/phone-email",
        PHONE_EMAIL_VERIFY_OTP: "/user/phone-email/verify",
        FORGOT_PASSWORD: "/user/forgot-password",
        FORGET_PASSWORD_VERIFY_OTP: "/user/forgot-password/verify",
        NEW_PASSWORD: "/user/new-password",
        ACCOUNT: "/user/shop/{shop_id}/account",
        SOCIAL_ACCOUNT: "/user/shop/{shop_id}/social/account",
        SOCIAL_ACCOUNT_VERIFY: "/user/social/account/verify",
        LOGOUT: "/user/logout",
        USER_PROFILE_IMAGE_UPDATE: "/user/image",
        PROFILE: "/user/profile",
        EMAIL_UPDATE: "/user/email",
        EMAIL_VERIFY_OTP: "/user/email/verify-otp",
        PHONE_UPDATE: "/user/phone",
        PHONE_VERIFY_OTP: "/user/phone/verify-otp",
        CHANGE_PASSWORD: "/user/change-password",
        VERIFY_PASSWORD: "/user/verify-password",
        USER_DETAILS: "/user/shop/{shop_id}/client",
    },
    LOCATION: {
        LIST: "/user/shop/{shop_id}/location",
    },
    STAFF: {
        LIST: "/user/shop/{shop_id}/location/{location_id}/staff",
    },
    STAFF_ROLE: {
        LIST: "/user/shop/{shop_id}/staff/role",
    },
    CATEGORY: {
        LIST: "/user/shop/{shop_id}/location/{location_id}/category",
    },
    SERVICE: {
        LIST: "/user/shop/{shop_id}/location/{location_id}/service",
    },
    PRODUCT: {
        LIST: "/user/shop/{shop_id}/location/{location_id}/product",
        CATEGORY: "/user/shop/{shop_id}/product/category",
    },
    BOOKING: {
        SLOTS: "/user/shop/{shop_id}/location/{location_id}/slot",
        CREATE: "/user/shop/{shop_id}/location/{location_id}/booking",
        UPDATE_PAY_NOW: "/user/shop/{shop_id}/location/{location_id}/booking/{id}/pay-now",
        UPDATE_PAY_LATER: "/user/shop/{shop_id}/location/{location_id}/booking/{id}/pay-later",
        LIST: "/user/shop/{shop_id}/booking",
        UPCOMING_LIST: "/user/shop/{shop_id}/booking/upcoming",
        UPDATE_STATUS: "/user/shop/{shop_id}/booking/{id}/status",
        TIP: "/user/shop/{shop_id}/booking/{id}/tip",
        RECEIPT: "/user/shop/{shop_id}/booking/{id}/receipt",
        REVIEW: "/user/shop/{shop_id}/booking/{id}/review",
        GET: "/user/shop/{shop_id}/booking/{id}",
        SHOW: "/user/shop/{shop_id}/booking/{id}/show",
    },
    STRIPE: {
        CARD_LIST: "/user/stripe/card/list",
        CARD_ADD: "/user/stripe/card/add",
        CARD_DEFAULT: "/user/stripe/card/default",
        CARD_REMOVE: "/user/stripe/card/remove",
    },
    NOTIFICATION: {
        LIST: "/user/notification",
        CREATE_UPDATE: "/user/notification",
        READ: "/user/notification/mark-read",
    },
    PROMOTION: {
        LIST: "/user/shop/{shop_id}/promotion",
    },
    USER_SHOP: {
        GET: "/user/shop/slug/{slug}",
    },
    SETTING: {
        GET: "/user/shop/{shop_id}/setting",
    },
    COURSE: {
        CREATE: "/user/shop/{shop_id}/course/contact",
    },
};
