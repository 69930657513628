import React, { useCallback, useEffect, useState } from "react";
import Info from "./Info";
import { useAppSelector } from "src/redux/hooks";
import { Swiper as SwiperType } from "swiper/types";
import { SwiperSlide } from "swiper/react";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import ProfessionalList from "./ProfessionalList";
import { IProfessional } from "./Professional.interface";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
import AnimatedTitle from "src/components/AnimatedTitle/AnimatedTitle";

const Professional: React.FC<IProfessional> = ({ data, locationSetting, sectionIndex, hasTeamAnimated }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const uiState = useAppSelector((uiStateData) => uiStateData.UiStates);
    const [staffList, setStaffList] = useState([]);
    const [staffData, setstaffData] = useState<any[]>([]);
    const [swiperRef, setSwiperRef] = useState<SwiperType>();

    useEffect(() => {
        if (staffList.length) {
            const mainData = staffInPairs(staffList);
            setstaffData(mainData || []);
        } else {
            setstaffData([]);
        }
    }, [staffList]);

    useEffect(() => {
        setStaffList(data?.staff);
    }, []);

    const staffInPairs = (array: any) => {
        const result = [];
        if (array && array.length) {
            for (let i = 0; i < array.length; i += 4) {
                result.push(array.slice(i, i + 4));
            }
            return result;
        }
    };

    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);
    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const title = getLocalizedSetting(data, "title");

    return (
        <>
            {uiState.isTablet ? (
                staffData.length > 0 ? (
                    <div className={`flex flex-col `}>
                        <div className="w-full flex flex-col lg:flex-row mb-4 lg:justify-between gap-3 md:gap-2 items-center professionals_section">
                            <div className="w-full flex items-center flex-1">
                                <div className="slice-label">
                                    <span className="num">{sectionIndex}</span>
                                    {data?.is_title && (
                                        <span className="slice-text">
                                            <AnimatedTitle title={title} isActive={hasTeamAnimated} />
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="flex relative justify-end">
                                <button
                                    disabled={staffData.length <= 1}
                                    className={`rounded-primary flex flex-col justify-center items-center border border-TextColor h-[24px] w-[24px] min-w-[24px] mr-3.5 bg-transparent  ${
                                        staffData.length <= 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:bg-primaryHover hover:border-primaryBorderHover"
                                    }`}
                                    onClick={handlePrevious}
                                >
                                    <ChevronLeft className="text-TextColor w-4 h-4" />
                                </button>
                                <button
                                    disabled={staffData.length <= 1}
                                    className={`rounded-primary flex flex-col justify-center items-center border border-TextColor h-[24px] w-[24px] min-w-[24px] bg-transparent  ${
                                        staffData.length <= 1 ? "opacity-50 cursor-not-allowed" : "cursor-pointer hover:bg-primaryHover hover:border-primaryBorderHover"
                                    }`}
                                    onClick={handleNext}
                                >
                                    <ChevronRight className="text-TextColor w-4 h-4" />
                                </button>
                            </div>
                        </div>
                        <CustomSwiper className="mySwiper w-full" setSwiperRef={setSwiperRef} loop={staffData.length > 1}>
                            {staffData.map((staff: any, index: React.Key | null | undefined) => (
                                <SwiperSlide key={index}>
                                    <ProfessionalList key={index} prop={staff} />
                                </SwiperSlide>
                            ))}
                        </CustomSwiper>
                    </div>
                ) : null
            ) : staffList.length > 0 ? (
                <div className="flex flex-col items-center">
                    <CustomSwiper setSwiperRef={setSwiperRef} className="mySwiper w-full mt-[32px]">
                        {staffList.map((staff: any, index: React.Key | null | undefined) => (
                            <SwiperSlide key={index}>
                                <Info key={index} data={staff} />
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                    {staffList && staffList.length > 1 && (
                        <div className="flex relative mt-4">
                            <button
                                className="rounded-primary flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] mr-3.5 bg-transparent"
                                onClick={handlePrevious}
                            >
                                <ChevronLeft className="text-TextColor w-4 h-4" />
                            </button>
                            <button
                                className="rounded-primary flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] bg-transparent"
                                onClick={handleNext}
                            >
                                <ChevronRight className="text-TextColor w-4 h-4" />
                            </button>
                        </div>
                    )}
                </div>
            ) : null}
        </>
    );
};

export default Professional;
