import React, { FC, useEffect } from "react";
import { ArrowLeft, Bell03, DotsVertical } from "@untitled-ui/icons-react/build/cjs";
import { Dropdown } from "flowbite-react";
import { currentUser } from "src/redux/reducers/common/Common.slice";
import { useAppSelector } from "src/redux/hooks";
import { firebaseEmitter } from "src/services/API/firebase";
import { GoDotFill } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { IAction } from "./Profile.interface";

interface IHeader {
    handleAction: (type: string) => () => void;
    handleClose: () => void;
    isAnyUnreadMsg: boolean;
    action: IAction;
    checkNotificationStatus: () => Promise<void>;
    getNotificationCount: () => void;
}

const Header: FC<IHeader> = ({ handleAction, handleClose, isAnyUnreadMsg, action, checkNotificationStatus, getNotificationCount }) => {
    const { t } = useTranslation();
    const user = useAppSelector(currentUser);

    useEffect(() => {
        if (user) {
            getNotificationCount();
            firebaseEmitter.on("firebaseNotification", handleNewNotification);
            return () => {
                firebaseEmitter.removeListener("firebaseNotification", handleNewNotification);
            };
        }
    }, [user]);

    const handleNewNotification = (payload: any) => {
        getNotificationCount();
    };

    const handleBack = () => {
        handleClose();
        if (action.notification) {
            checkNotificationStatus();
        }
    };

    return (
        <div className="flex justify-between items-center border-b min-h-[52px] h-[52px] lg:h-[60px] lg:min-h-[60px] px-4 lg:px-5 border-borderColorPrimary">
            <div className="flex items-center flex-1">
                <div className="flex items-center cursor-pointer w-[32px] h-[32px] justify-center rounded-[8px] hover:bg-mainBackgroundColor" onClick={handleBack}>
                    <ArrowLeft className="w-[20px] h-[20px] cursor-pointer text-txtAppointmentColor" />
                </div>
            </div>
            <div className="flex space-x-[8px] ">
                {!action.notification && (
                    <div className="flex items-center justify-center relative">
                        <button type="button" className=" flex items-center justify-center" onClick={handleAction("notification")}>
                            <Bell03 className="w-[20px] h-[20px] text-textGrey hover:text-primary" />
                        </button>
                        {isAnyUnreadMsg && (
                            <div className="absolute top-[-1px] right-[-3px]">
                                <GoDotFill size={9} color="#17B26A" className="rounded-full" />
                            </div>
                        )}
                    </div>
                )}
                <Dropdown
                    className="z-[99] bg-dropdownBackgroundColor !left-[-6px] border border-borderColorPrimary rounded-minimal flex items-center justify-center"
                    theme={{
                        content: "py-1.5 px-1 focus:outline-none min-w-[180px] space-y-[3px]",
                    }}
                    label=""
                    placement="bottom-start"
                    dismissOnClick={true}
                    renderTrigger={() => (
                        <button type="button" className="flex items-center w-[32px] h-[32px] justify-center rounded-[8px] hover:bg-mainBackgroundColor ">
                            <DotsVertical className="w-[20px] h-[20px] text-textMoreGrey" />
                        </button>
                    )}
                >
                    <Dropdown.Item
                        onClick={handleAction("account_setting")}
                        className="dropdown-item-wrapper"
                        theme={{
                            container: "right-0",
                            base: "flex items-center item-text justify-start py-2 pl-2 pr-2 text-[12px] text-textMoreGrey hover:text-TextColor font-medium cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-minimal focus:bg-mainBackgroundColor focus:outline-none focus:ring-0 min-w-max w-full",
                        }}
                    >
                        {t("Account Settings")}
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={handleAction("appointment_history")}
                        className="dropdown-item-wrapper"
                        theme={{
                            container: "right-0",
                            base: "flex items-center item-text justify-start py-2 pl-2 pr-2 text-[12px] text-textMoreGrey hover:text-TextColor font-medium cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-minimal focus:bg-mainBackgroundColor focus:outline-none focus:ring-0 min-w-max w-full",
                        }}
                    >
                        {t("Appointment History")}
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={handleAction("notification_setting")}
                        className="dropdown-item-wrapper"
                        theme={{
                            container: "right-0",
                            base: "flex items-center item-text justify-start py-2 pl-2 pr-2 text-[12px] text-textMoreGrey hover:text-TextColor font-medium cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-minimal focus:bg-mainBackgroundColor focus:outline-none focus:ring-0 min-w-max w-full",
                        }}
                    >
                        {t("Notification Settings")}
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={handleAction("payment_method")}
                        className="dropdown-item-wrapper"
                        theme={{
                            container: "right-0",
                            base: "flex items-center item-text justify-start py-2 pl-2 pr-2 text-[12px] text-textMoreGrey hover:text-TextColor font-medium cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-minimal focus:bg-mainBackgroundColor focus:outline-none focus:ring-0 min-w-max w-full",
                        }}
                    >
                        {t("Payment Methods")}
                    </Dropdown.Item>
                    <Dropdown.Item
                        className="dropdown-item-wrapper"
                        onClick={handleAction("logout")}
                        theme={{
                            container: "right-0",
                            base: "flex items-center item-text justify-start py-2 pl-2 pr-2 text-[12px] text-textMoreGrey hover:text-TextColor font-medium cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-minimal focus:bg-mainBackgroundColor focus:outline-none focus:ring-0 min-w-max w-full",
                        }}
                    >
                        {t("Log out")}
                    </Dropdown.Item>
                </Dropdown>
            </div>
        </div>
    );
};

export default Header;
