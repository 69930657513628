import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import MPhoneOrEmail from "src/components/PhoneOrEmail/MPhoneOrEmail";
import { IForgotPwd } from "../Password.interface";
import { useAppSelector } from "src/redux/hooks";

const MForgotPassword: FC<IForgotPwd> = ({ handleAuthAction, isLoading, inputType }) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const { control } = useFormContext();
    return (
        <div className="mobile-model-inner-wrapper font-primary">
            <div className="mobile-model-inner flex flex-col">
                <span className="login-model-title-mobile">{t("Forgot your password?")}</span>
                <p className="mt-2.5 leading-[15.6px] text-textGrey text-[12px] tracking-[-0.01em]">{t("forgot_password_description")}</p>
                <div className="mt-[12px]">
                    <Controller
                        name="emailPhone"
                        control={control}
                        render={({ field: { onChange }, fieldState: { error } }: any) => (
                            <div className="flex flex-col">
                                <MPhoneOrEmail onNumberChange={onChange} errors={error} inputType={inputType} />
                            </div>
                        )}
                    />
                    <div className="mt-[20px] w-full bottom-0 flex flex-col">
                        <CustomButton
                            type="button"
                            className={` ${
                                uiState.isTablet
                                    ? "!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px] hover:!bg-arrowBackgroundColor !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                                    : "!text-primary leading-[19.6px] font-semibold !text-[14px] flex justify-center items-center cursor-pointer -tracking-[0.01rem] mb-5"
                            }`}
                            shadow={false}
                            onClick={() => handleAuthAction("login")}
                        >
                            {t("Back to log in")}
                        </CustomButton>
                        <CustomButton primary type="submit" className="w-full !h-[48px] mb-4" isLoading={isLoading} disabled={isLoading}>
                            <span>{t("Continue")}</span>
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MForgotPassword;
