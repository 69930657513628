import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { ChevronLeft } from "@untitled-ui/icons-react/build/cjs";
import WInputPasswordWithLabel from "src/components/InputPasswordWithLabel/WInputPasswordWithLabel";
import { IVerifyPassword } from "../Password.interface";
import { exceptValidation } from "src/utils/global-functions";

const WVerifyPassword: FC<IVerifyPassword> = ({ loading, authData, handleAction, resetAuthAction }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="content font-primary model-inner-wrapper">
            <h1 className="login-model-title gap-1">{t("Welcome back with name", { name: authData.name })}</h1>
            <div className="mx-auto mt-[42px]">
                <div className="flex flex-wrap">
                    <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full">
                                <WInputPasswordWithLabel
                                    placeholder={t("Enter your password")}
                                    onChange={onChange}
                                    inputPasswordClass="!h-[44px]"
                                    value={value.password}
                                    name="password"
                                    isFocus={true}
                                    error={!!error}
                                />
                                {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                </div>

                <div className="mt-[24px] flex justify-center">
                    <span
                        className="text-primary leading-[24.6px] relative font-semibold text-[14px] overflow-visible border-b-animation-hover flex justify-center items-center cursor-pointer -tracking-[0.03rem]"
                        onClick={handleAction("forgotPassword")}
                    >
                        {t("Forgot your password?")}
                    </span>
                </div>

                <div className="mt-[52px] w-full bottom-0 flex flex-col gap-2">
                    <CustomButton
                        type="button"
                        icon={<ChevronLeft width={20} />}
                        className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px]  hover:!bg-arrowBackgroundColor !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                        shadow={false}
                        onClick={() => resetAuthAction && resetAuthAction()}
                    ></CustomButton>
                    <CustomButton type="submit" isLoading={loading} disabled={loading} primary className={`fl-btn !h-[48px] font-bold w-full rounded-secondary transition-all ease-in-out delay-75`}>
                        {"Continue"}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default WVerifyPassword;
