import React, { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { DefaultImageJPG } from "src/theme/Images";
import "mapbox-gl/dist/mapbox-gl.css";
import { IShopLocation } from "src/Interface";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { getBookingInfo, resetTempStore, setBookingInitialState, setSelectedShopLocation } from "../BookAppointment/Booking.slice";
import { useNavigate } from "react-router-dom";
import { usePhone } from "src/hooks/usePhone";
import { CountryCode } from "libphonenumber-js";
import { currentUser } from "src/redux/reducers/common/Common.slice";
import Booking from "./Booking";
import { PATH } from "src/constants/path";
import moment from "moment";
import { useTranslation } from "react-i18next";

const MapBox = ({ locations, selectedShopData }: any) => {
    const { t } = useTranslation();
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESSTOKEN || "";
    const uiState = useAppSelector((data) => data.UiStates);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const booking = useAppSelector(getBookingInfo);
    const { getFormatPhone } = usePhone();
    const mapContainerRef = useRef<HTMLDivElement | null>(null);
    const mapRef = useRef<mapboxgl.Map | null>(null);
    const markersRef = useRef<mapboxgl.Marker[]>([]);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [popup, setPopup] = useState<any>();
    const [isLoading, setIsLoading] = useState(true);

    const getAddress = (location: any) =>
        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${location.longitude},${location.latitude}.json?access_token=${mapboxgl.accessToken}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.features && data.features.length > 0) {
                    const address = data.features[0].place_name;
                    return address;
                } else {
                    return null;
                }
            })
            .catch((error) => null);

    const availableDate = (inputDate: string) => {
        const date = moment(inputDate);
        const today = moment();

        if (date.isSame(today, "day")) {
            return t("Today");
        } else if (date.isSame(today.add(1, "days"), "day")) {
            return t("Tomorrow");
        } else {
            return date.format("DD-MM-YYYY");
        }
    };

    const locationPopUp = (locationData: any) => `
            <div class="w-full p-0 map-box-wrapper">
                <div className="overflow-hidden">
                    <img src="${locationData.profile_image_url}" alt="" class="w-full object-cover max-h-[130px]" />
                </div>
                <div class="p-3 w-full">
                    <p class="text-[14px] font-normal text-TextColor leading-[18.2px] mb-1 -tracking-[0.01rem]">${locationData.name}</p>
                    <p class="line-clamp-2 text-[12px] font-normal text-textGrey leading-[15.6px]">
                        ${locationData.address}
                    </p>
                    <div class="mt-2 flex items-center text-txtcolor text-[12px] leading-[15.6px] gap-[6px]">
                        <p class="text-[12px] leading-[15.6px] -tracking-[0.01rem] font-normal">${getFormatPhone(locationData?.phone as string, locationData?.phone_country_code as CountryCode)}</p>
                        
                    </div>
                    ${
                        locationData.available_at
                            ? `<div class="text-[12px] mt-1.5 text-TextColor font-normal leading-[15.6px] -tracking-[0.01rem]"> <span> ${t(
                                  "Next Availability",
                              )}: </span><span class="text-[12px] font-normal">${availableDate(locationData.available_at)}</span></div>`
                            : ""
                    }
                </div>
                
            </div>
        `;

    const initializeMap = useCallback(() => {
        if (mapContainerRef.current && !mapRef.current) {
            setPopup(
                new mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false,
                }),
            );

            mapRef.current = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: "mapbox://styles/mapbox/light-v11",
                center: [-95, 55],
                zoom: 4,
                pitch: 0,
                bearing: 0,
                boxZoom: true,
                antialias: true,
                attributionControl: false,
                pitchWithRotate: false,
                touchZoomRotate: false,
            });

            mapRef.current.addControl(new mapboxgl.NavigationControl());

            mapRef.current.on("load", () => {
                setIsMapLoaded(true);
                setIsLoading(false);
                const layers = mapRef.current?.getStyle()?.layers;
                const labelLayer = layers?.find((layer: any) => layer.type === "symbol" && layer.layout["text-field"]);

                if (labelLayer && mapRef.current) {
                    mapRef.current.addLayer(
                        {
                            id: "add-3d-buildings",
                            source: "composite",
                            "source-layer": "building",
                            filter: ["==", "extrude", "true"],
                            type: "fill-extrusion",
                            minzoom: 15,
                            paint: {
                                "fill-extrusion-color": "#aaa",
                                "fill-extrusion-height": ["interpolate", ["linear"], ["zoom"], 15, 0, 15.05, ["get", "height"]],
                                "fill-extrusion-base": ["interpolate", ["linear"], ["zoom"], 15, 0, 15.05, ["get", "min_height"]],
                                "fill-extrusion-opacity": 0.6,
                            },
                        },
                        labelLayer.id,
                    );
                }

                if (mapRef.current) {
                    mapRef.current.addSource("points", {
                        type: "geojson",
                        data: {
                            type: "FeatureCollection",
                            features: [
                                {
                                    type: "Feature",
                                    geometry: {
                                        type: "Point",
                                        coordinates: [-77.03238901390978, 38.913188059745586],
                                    },
                                    properties: {
                                        title: "Mapbox DC",
                                    },
                                },
                                {
                                    type: "Feature",
                                    geometry: {
                                        type: "Point",
                                        coordinates: [-122.414, 37.776],
                                    },
                                    properties: {
                                        title: "Mapbox SF",
                                    },
                                },
                            ],
                        },
                    });
                }
            });
        }
    }, []);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.remove();
            mapRef.current = null;
        }
        initializeMap();
    }, []);

    useEffect(() => {
        if (isMapLoaded) {
            if (locations && locations.length) {
                const newBounds = new mapboxgl.LngLatBounds();

                locations.forEach(async (location: any) => {
                    const el = document.createElement("div");
                    el.className = "marker";
                    const parentDiv = document.createElement("div");
                    parentDiv.className = "parent-marker"; // Assign a class for additional styling
                    const csutomerImageDiv = document.createElement("div");
                    csutomerImageDiv.className = selectedShopData?.id === location.id ? "location-marker-image" : "location-marker-secondary-image"; // Assign a class for additional styling
                    // Set marker image based on customer data
                    if (location.profile_image_url) {
                        el.style.backgroundImage = `url(${location.profile_image_url})`;
                    } else {
                        el.style.backgroundImage = `url(${DefaultImageJPG})`;
                    }

                    let parentElementLenght = document.querySelectorAll(".parent-marker").length;

                    if (locations.length > parentElementLenght) {
                        csutomerImageDiv.appendChild(el); // Append the marker to the parent div
                        parentDiv.appendChild(csutomerImageDiv); // Append the marker to the parent div
                    }
                    // Create marker
                    const marker = new mapboxgl.Marker(parentDiv).setLngLat([location.longitude, location.latitude]).addTo(mapRef.current!);
                    markersRef.current.push(marker);
                    if (location.longitude !== undefined && location.latitude !== undefined && !isNaN(location.longitude) && !isNaN(location.latitude)) {
                        newBounds.extend([location.longitude, location.latitude]);
                    }
                    const address = await getAddress(location);
                    const popupContent = locationPopUp({ ...location, address });
                    marker.getElement().addEventListener("mouseenter", () => {
                        popup.setHTML(popupContent);
                        popup.setLngLat([location.longitude, location.latitude]).addTo(mapRef.current!);
                    });
                    marker.getElement().addEventListener("mouseleave", () => {
                        popup.remove();
                    });
                    if (selectedShopData?.id === location.id) {
                        popup.setHTML(popupContent);
                        popup.setLngLat([location.longitude, location.latitude]).addTo(mapRef.current!);
                    }
                    if (!selectedShopData) {
                        popup.remove();
                    }
                    marker.getElement().addEventListener("click", (event: any) => {
                        event.preventDefault();
                        handleStartBookingWithShopLocation(location);
                    });
                });
                if (mapRef.current && !newBounds.isEmpty()) {
                    mapRef.current.fitBounds(newBounds, {
                        padding: 50,
                        animate: false,
                    });
                }
                setIsLoading(false);
            } else {
                markersRef.current.forEach((marker) => marker.remove());
                markersRef.current = [];
            }
        }
    }, [locations, isMapLoaded, selectedShopData]);

    const handleStartBookingWithShopLocation = (location: IShopLocation) => {
        if (location.id !== booking.selectedShopLocation?.id || booking.isModifyingAppointment) {
            dispatch(setBookingInitialState());
        }
        dispatch(setSelectedShopLocation(location));
        dispatch(resetTempStore());
        navigate(PATH.APPOINTEMENT);
    };

    return (
        <div className="flex-1 lg:flex flex-col relative h-full">
            {user && !uiState.isMobile && <Booking />}
            <div className="relative h-full w-full geography-map">
                <div id="map" ref={mapContainerRef} style={{ width: "100%", height: "100%", opacity: isLoading ? 0 : 1 }}></div>
            </div>
        </div>
    );
};

export default MapBox;
