import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { INotification } from "./Profile.interface";
import { axiosPatch } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { currentUser, me } from "src/redux/reducers/common/Common.slice";
import CustomButton from "src/components/CustomButton";
import { getBookingInfo } from "../BookAppointment/Booking.slice";

const NotificationSetting = ({ handleClose }: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState<INotification>({
        promotions: "all",
        booking_confirm: "all",
        booking_reschedule: "all",
        booking_canceled: "all",
        whats_new: "all",
        referral: "all",
    });
    const bookingInfo = useAppSelector(getBookingInfo);

    useEffect(() => {
        if (user) {
            setNotification(user.notification);
        }
    }, [user]);

    const handleSave = () => {
        setIsLoading(true);
        axiosPatch(API.NOTIFICATION.CREATE_UPDATE, notification)
            .then(async () => {
                await dispatch(me());
                handleClose();
            })
            .finally(() => setIsLoading(false));
    };

    const handleChange = (type: string, value: string) => () => {
        setNotification((old) => ({ ...old, [type]: value }));
    };

    const settings = useMemo(
        () => [
            { name: t("Promotions"), type: "promotions", value: notification.promotions },
            { name: t("Confirmed Appointments"), type: "booking_confirm", value: notification.booking_confirm },
            { name: t("Rescheduled Appointments"), type: "booking_reschedule", value: notification.booking_reschedule },
            { name: t("Canceled Appointments"), type: "booking_canceled", value: notification.booking_canceled },
            { name: t("What’s New"), type: "whats_new", value: notification.whats_new },
            { name: t("Referral Program"), type: "referral", value: notification.referral },
        ],
        [t, notification],
    );

    const options = useMemo(
        () => [
            { name: t("None"), value: "none" },
            { name: t("Both"), value: "all" },
            { name: t("Email"), value: "email" },
            { name: t("SMS"), value: "sms" },
        ],
        [],
    );

    return (
        <div className="relative flex flex-col  font-primary overflow-hidden">
            <div className="flex flex-col mb-4 mt-1 lg:mt-[20px] px-4 lg:px-5">
                <div className="text-[16px] lg:text-[20px] mb-1 leading-[22.4px] lg:leading-[28.4px] font-bold lg:font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">
                    {t("Notification Settings")}
                </div>
                <div className="font-normal text-[12px] lg:text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">{t("Manage when you’ll receive notifications")}</div>
            </div>

            <div className="flex flex-col max-h-[calc(100vh-220px)] overflow-y-auto mb-4 gap-2.5 lg:gap-3.5 px-4 lg:px-5 pb-1.5">
                {settings.map((setting: any, index) => (
                    <div key={index} className="border border-borderColorPrimary p-3 rounded-lg w-full">
                        <div className="flex flex-col">
                            <h1 className="text-[14px] leading-[19.6px] tracking-[-0.01rem] font-medium text-txtAppointmentColor">{setting.name}</h1>
                            {/* <span className="text-textGrey text-[12px] leading-[15.6px] font-normal tracking-[-0.01rem]">{t("I’m mentioned in a message or comment")}</span> */}
                        </div>
                        <div className="flex border rounded-lg w-full border-borderColorPrimary overflow-hidden mt-2">
                            {options.map((option, key) => (
                                <button
                                    key={key}
                                    className={`flex justify-center items-center h-[38px]  border-borderColorPrimary w-full transition-all ease-in-out delay-100 text-[14px] leading-[18px] tracking-[-0.01rem] bg-transparent hover:bg-mainBackgroundColor hover:text-TextColor font-medium text-textMoreGrey ${
                                        setting.value === option.value ? "!bg-primaryHover !text-TextColor" : ""
                                    } ${key === 1 ? "border-l border-r" : key === 2 ? "border-r" : ""}`}
                                    onClick={handleChange(setting.type, option.value)}
                                >
                                    <span className="text-[14px] leading-[18px]">{option.name}</span>
                                </button>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className={`mt-auto w-full flex gap-3 bg-contentBackground px-4 lg:px-5 ${bookingInfo.isFullScreen ? "!pb-[24px]" : "mb-4"}`}>
                <CustomButton outlineSecondary size="w-1/2" disabled={isLoading} onClick={handleClose}>
                    {t("Back")}
                </CustomButton>
                <CustomButton primary size="w-1/2" disabled={isLoading} isLoading={isLoading} onClick={handleSave}>
                    {t("Save changes")}
                </CustomButton>
            </div>
        </div>
    );
};

export default NotificationSetting;
