/* eslint-disable complexity */
import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import en from "react-phone-number-input/locale/en.json";
import { usePhone } from "src/hooks/usePhone";
import { INumberList, ISelectedNumber } from "./PhoneOrEmail.interface";
import parsePhoneNumberFromString, { CountryCode, formatIncompletePhoneNumber, parseIncompletePhoneNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { Check, ChevronDown, Plus } from "@untitled-ui/icons-react/build/cjs";
import countryData from "src/utils/countryData.json";
import { exceptValidation } from "src/utils/global-functions";

const WPhoneOrEmail = ({ onNumberChange, disabled, errors, name, defaultValues, isFocus = false, singleType, isButtonClicked, inputType, defaultEmailValues }: any) => {
    const { setFormatPhone, isPhoneValid, phoneInfo } = usePhone();
    const { t } = useTranslation();

    const getphoneinfo = phoneInfo(defaultValues?.phone, defaultValues?.phone_country_code);
    const [isPhone, setIsPhone] = useState(singleType && singleType === "phone" ? true : false);

    const initialSelectedNumber: ISelectedNumber = {
        code: getphoneinfo && getphoneinfo.countryCallingCode ? getphoneinfo.countryCallingCode : "1",
        number: getphoneinfo && getphoneinfo.nationalNumber ? getphoneinfo.nationalNumber : "",
        image: defaultValues && defaultValues.phone_country_code ? countryData.find((country) => country.code === defaultValues.phone_country_code)?.flag_emoji : "🇨🇦",
        name: en[defaultValues && defaultValues.phone_country_code ? (defaultValues.phone_country_code as CountryCode) : "CA"],
        country: defaultValues && defaultValues.phone_country_code ? defaultValues.phone_country_code : "CA",
    };

    const [Switch, setSwitch] = React.useState(false);
    const [selectedNumber, setSelectedNumber] = useState<ISelectedNumber>(initialSelectedNumber);

    useEffect(() => {
        const handler = () => setSwitch(false);
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler);
        };
    }, []);

    useEffect(() => {
        if (!isPhone) {
            setSelectedNumber(initialSelectedNumber);
        }
    }, [isPhone]);

    const handlerInputClik = (e: any) => {
        e.stopPropagation();
        setSwitch(!Switch);
    };

    const handleCountryCode = (option: ISelectedNumber) => {
        setSelectedNumber({ ...selectedNumber, ...option });
    };

    const handleInputNumberChange = (e: any) => {
        let value = e.target.value;
        const numberPattern = /^[+]?\d+$/;
        let tempIsPhone = false;
        if (!singleType) {
            if (value && value.length >= 2 && numberPattern.test(value)) {
                setIsPhone(true);
                tempIsPhone = true;
            } else if (!value) {
                setIsPhone(false);
                tempIsPhone = false;
            }
        }

        let payload = {};
        // setSelectedNumber({ ...selectedNumber, number: value });
        if (isPhone || tempIsPhone) {
            let number = parseIncompletePhoneNumber(value);
            const newValueFormatted = formatIncompletePhoneNumber(number, selectedNumber.country);
            if (number === selectedNumber.number) {
                if (newValueFormatted.indexOf(e.target.value) === 0) {
                    number = number.slice(0, -1);
                }
            }
            let code = selectedNumber.code;
            let country: any = selectedNumber.country;
            let image: any = selectedNumber.image;
            const parsedNumber = parsePhoneNumberFromString(number, selectedNumber.country);
            if (parsedNumber) {
                if (number.startsWith("+")) {
                    code = parsedNumber.countryCallingCode;
                    country = parsedNumber.country;
                }
                number = parsedNumber.nationalNumber;
            }
            setSelectedNumber({ ...selectedNumber, number, code, country, image });
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            payload = {
                isEmail: true,
                data: value,
                error: !emailPattern.test(value) ? t("Please enter a valid email") : "",
            };
            onNumberChange(payload);
        }
    };
    useEffect(() => {
        if (isPhone) {
            const isValid = isPhoneValid(selectedNumber.number, selectedNumber.country);
            const payload = {
                isEmail: false,
                data: { country: selectedNumber.country, number: selectedNumber.number, isValid: isValid, code: selectedNumber.code },
                error: !isValid ? "Please enter a valid number" : "",
            };
            onNumberChange(payload);
        }
    }, [selectedNumber.code, selectedNumber.number, selectedNumber.country]); // TODO
    const [filterList, setFilterList] = React.useState<INumberList[]>([]);
    useLayoutEffect(() => {
        const fetchData = async () => {
            let list = countryData.map((country) => ({
                image: country.flag_emoji,
                code: country.dial_code,
                name: country.name,
                country: country.code as CountryCode,
            }));
            const shortedList = list.sort((a, b) => a.name.localeCompare(b.name));
            setFilterList(shortedList);
        };

        fetchData();
    }, []);

    const handleSearchTermClick = (e: { stopPropagation(): unknown; preventDefault: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const defaultCountry = () => {
        const matchingItem = filterList.find((item) => item.name === selectedNumber.name);
        if (matchingItem) {
            const index = filterList.indexOf(matchingItem);
            const listElement = document.getElementById(`country-item-${index}`);
            listElement?.scrollIntoView({ block: "center" });
        }
    };

    useEffect(() => {
        if (Switch) {
            defaultCountry();
        }
    }, [Switch]);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key.toLowerCase();
        const matchingItem = filterList.find((item) => item.name.toLowerCase().startsWith(key));
        if (matchingItem) {
            const index = filterList.indexOf(matchingItem);
            const listElement = document.getElementById(`country-item-${index}`);
            listElement?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    return (
        <div className="relative">
            <div
                className={`fl-input-field fl-phone-field !h-[44px] rounded-secondary bg-transparent form_input_control shadow-InputAndButton ${Switch && ""} ${
                    errors ? "!is-invalid !border-danger focus:!border-danger focus-within:!border-danger focus-visible:!border-danger" : "border border-borderColorPrimary"
                }`}
            >
                {isPhone && (
                    <div className="flex items-center pl-3.5">
                        <span
                            className={`focus:ring-0 ring-0 focus:outline-none !focus:border-0 text-txtAppointmentColor flex items-center ${disabled ? "!cursor-default" : ""}`}
                            onClick={handlerInputClik}
                        >
                            {/* <img src={selectedNumber.image} alt="" className="w-7 rounded" /> */}
                            <div className="text-[22px] mr-2">{selectedNumber.image}</div>
                            {/* <span className="text-txtAppointmentColor text-[16px] !font-normal leading-[24px]">{selectedNumber.country}</span> */}
                            <ChevronDown className="text-[#98A2B3] h-[20px] w-[19px] mr-2" />
                            <span className="text-txtAppointmentColor text-[16px] font-normal leading-[24px]">+{selectedNumber.code}</span>
                        </span>
                    </div>
                )}

                <input
                    className="font-primary rounded-secondary w-full py-0 pt-[0px] border-0 text-[16px] leading-[24px] focus:ring-0 outline-none font-normal placeholder:text-placeholderTxt placeholder:text-[16px] placeholder:leading-[24px] h-[-webkit-fill-available] bg-transparent placeholder:font-normal text-txtAppointmentColor pl-3.5"
                    type="text"
                    name={name}
                    disabled={disabled}
                    value={
                        isPhone ? setFormatPhone(selectedNumber.code, selectedNumber.number, selectedNumber.country) : defaultEmailValues?.data ?? defaultValues // Assuming `defaultValues.email` holds the email value.
                    }
                    onChange={handleInputNumberChange}
                    placeholder={
                        inputType === "phone"
                            ? setFormatPhone(selectedNumber.code, "8708708866", selectedNumber.country) // Format for phone
                            : inputType === "email"
                            ? t("Enter your email address") // Placeholder for email
                            : t("Enter your phone number or email address") // General placeholder
                    }
                    autoFocus={isFocus}
                    maxLength={isPhone ? 20 : 255}
                />
            </div>
            {errors && exceptValidation(errors) && <p className="text-error">{errors.message}</p>}
            {isPhone && (
                <>
                    {!disabled && Switch && (
                        <div
                            id="dropdownDelay"
                            className="z-[999] absolute w-full focus:outline-none px-2 pt-2 pb-2 mt-1
                            shadow-[0px_5.33px_16px_0px_var(--promotion-overlay-shadow)] bg-dropdownBackgroundColor border border-borderColorPrimary rounded-minimal flex items-center justify-center"
                            tabIndex={0}
                        >
                            <div className="relative opacity-0 w-0 h-0">
                                <input
                                    type="text"
                                    placeholder={t("Search for a country")}
                                    value={""}
                                    onChange={() => {}}
                                    onClick={handleSearchTermClick}
                                    autoFocus
                                    onKeyDown={handleKeyPress}
                                    className="opacity-0 w-0 h-0"
                                />
                            </div>
                            <ul className="country-code-dropdown-list" aria-labelledby="dropdownDelayButton">
                                {filterList.map((country: any, indexNumberCode: number) => (
                                    <li
                                        onClick={() => handleCountryCode(country)}
                                        className={`country-code-dropdown-li ${selectedNumber.name === country.name && "active"}`}
                                        key={indexNumberCode}
                                        value={country.code}
                                        id={`country-item-${indexNumberCode}`}
                                        tabIndex={indexNumberCode}
                                    >
                                        <div className="flex ">
                                            <span className="text-[20px] h-auto"> {country.image}</span>
                                            <span className="text-sm min-w-[42px] ml-3 mr-2 flex items-center">
                                                <Plus className="w-[12px] min-w-[12px] h-[15px] mr-0.5" /> <span className="leading-[15px]">{country.code}</span>
                                            </span>
                                            <span className="">{country.name}</span>
                                        </div>
                                        {selectedNumber.country === country.country && <Check className="h-[17px] w-[17px] text-primary" />}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default memo(WPhoneOrEmail);
