export const APP_ROOT = "";

export const ROUTES = {
    HOME: `${APP_ROOT}/`,
    LOCATION: `${APP_ROOT}/location`,
    MOBILE_LANDING: `${APP_ROOT}/mobile-landing`,
    PAGE_NOT_FOUND: `${APP_ROOT}/not-found`,
    ENV: `${APP_ROOT}env/:type`,
    BOOK_APPOINTMENT: `${APP_ROOT}/appointment/:tab?/:id?`,
};
