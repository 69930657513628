import React, { useState } from "react";
import { ISpecialistService } from "../../../../Interface";
import { useTranslation } from "react-i18next";
import { getBookingInfo, setTempStore } from "../../Booking.slice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import Sidebar from "src/components/Sidebar";
import ServiceInfo from "src/app/BookAppointment/SelectServices/ServiceInfo";
import moment from "moment";
import { X } from "@untitled-ui/icons-react/build/cjs";
import { formatAmount } from "src/utils/global-functions";
import ShowMoreText from "react-show-more-text";
interface IProps {
    serviceData: ISpecialistService;
    removeable?: boolean;
    isSelected?: boolean;
    handleRemoveService?: () => void;
}

const ServiceCardComponent = ({ serviceData, removeable = false, handleRemoveService, isSelected = false }: IProps) => {
    const { t } = useTranslation();
    const bookingInfo = useAppSelector(getBookingInfo);
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const [isShowServiceInfoPopup, setIsShowServiceInfoPopup] = useState(false);

    const handleServiceSelect = async (e: any) => {
        e.stopPropagation();
        if (e.target.id === "alert_circle_svg") {
            return;
        }
        if (e.target.id === "more") {
            return;
        }
        if (e.target.id === "less") {
            return;
        }
        if (removeable) {
            handleRemoveService && handleRemoveService();
        } else {
            await dispatch(
                setTempStore({
                    selectedServices: [...bookingInfo.tempStore?.selectedServices, { ...serviceData, quantity: 1 }],
                }),
            );
        }
    };

    const getDurationPriceRange = (type: string) => {
        if (serviceData?.staff_service?.length) {
            let matchingService: ISpecialistService | undefined = undefined;
            if (bookingInfo.selectedSpecialist) {
                matchingService = bookingInfo.selectedSpecialist.services.find((specialistServiceData: any) => specialistServiceData.id === serviceData.id);
            }
            switch (type) {
                case "duration":
                    if (matchingService) {
                        const particularServiceDuration = moment.duration(matchingService.shop_staff_service.duration).asMinutes();
                        return (
                            <div className="text-textGrey lg:font-medium font-medium text-[14px] max-lg:leading-[18px] lg:text-sm -tracking-[0.01em] mt-1  xl:mt-0">{`${particularServiceDuration} minutes`}</div>
                        );
                    } else {
                        const durations = serviceData.staff_service.map((service: any) => moment.duration(service.duration).asMinutes());
                        const minDuration = Math.min(...durations);
                        const maxDuration = Math.max(...durations);
                        return (
                            <div className="text-textGrey lg:font-medium font-normal text-[14px] max-lg:leading-[14px] lg:text-sm -tracking-[0.02rem]  xl:mt-0 mt-1">
                                {minDuration === maxDuration ? `${durations[0]} minutes` : `${minDuration} minutes - ${maxDuration} minutes`}
                            </div>
                        );
                    }
                case "price":
                    if (matchingService) {
                        const particularServicePrice = matchingService.shop_staff_service.price;
                        return (
                            <div className={`w-full flex flex-col`}>
                                <p className=" w-full text-textGrey font-normal text-[14px] lg:text-[14px] leading-[18px] text-nowrap -tracking-[0.02rem] justify-end flex">
                                    {serviceData.price?.starting_price ? t("Starting Price") : t("Price")}
                                </p>
                                <div className="justify-end flex text-[14px] font-medium lg:text-[16px] leading-[18px] lg:font-semibold text-txtAppointmentColor rounded-secondary -tracking-[0.01em] min-w-max  mt-1">
                                    {`${formatAmount(particularServicePrice)}`}
                                </div>
                            </div>
                        );
                    } else {
                        const prices = serviceData.staff_service.map((service: any) => service.price);
                        const minPrice = Math.min(...prices);
                        const maxPrice = Math.max(...prices);
                        return (
                            <div className={`w-full flex flex-col`}>
                                <p className="w-full text-textGrey font-normal text-[14px] lg:text-[14px] leading-[18px] text-nowrap -tracking-[0.02rem] justify-end flex">
                                    {serviceData.price?.starting_price ? t("Starting Price") : t("Price")}
                                </p>
                                <div className=" justify-end flex text-[14px] font-medium lg:text-[16px] leading-[18px] lg:font-semibold text-txtAppointmentColor rounded-secondary -tracking-[0.03rem] min-w-max  mt-1">
                                    {minPrice === maxPrice ? `${formatAmount(minPrice)}` : `${formatAmount(minPrice)} - ${formatAmount(maxPrice)}`}
                                </div>
                            </div>
                        );
                    }
            }
            return null;
        }
    };

    const [isExpanded, setIsExpanded] = useState(false);

    const handleclicknew = (expand: boolean) => (event: any) => {
        event.stopPropagation();
        setIsExpanded(expand);
    };

    return (
        <div className="w-full flex relative">
            <div
                className={`inline-flex items-center  w-full lg:min-h-[78px] rounded-secondary p-3  lg:p-4 pr-6 text-gray-500 card-shadow-hover border cursor-pointer group relative ${
                    isSelected ? "bg-primaryHover border-primaryBorderHover" : "border-borderColorPrimary"
                } `}
                onClick={removeable ? undefined : handleServiceSelect}
            >
                <div className="flex flex-row w-full h-full">
                    <div className="w-full flex-1 flex flex-col multiline-truncate-wrapper">
                        <div className="flex xl:gap-[10px] flex-col xl:flex-row">
                            <h2 className="text-txtcolor font-medium lg:font-semibold text-[16px] lg:text-[16px] leading-[19.6px] -tracking-[0.02rem]">{serviceData.name}</h2>
                            {getDurationPriceRange("duration")}
                        </div>
                        <div className="text-[14px] mt-1 !leading-[18px] font-medium hidden lg:flex">
                            <ShowMoreText
                                lines={2}
                                more={<span id="more">Show more</span>}
                                less={<span id="less">Show less</span>}
                                className={`content-css ${isExpanded ? "isExpandadtext" : "isExpandadtextnot"}`}
                                anchorClass="show-more-less-clickable"
                                expanded={isExpanded}
                                onClick={handleclicknew}
                                truncatedEndingComponent={<span>... </span>}
                            >
                                <span>{serviceData.description}</span>
                            </ShowMoreText>
                        </div>
                    </div>
                    <div className="flex flex-row gap-10 ">
                        <div className="flex flex-row gap-10"></div>
                        {getDurationPriceRange("price")}
                    </div>
                </div>
                {isSelected && (
                    <button
                        onClick={handleServiceSelect}
                        className="absolute -right-[6px] -top-[6px] z-10 flex justify-center items-center w-6 h-6 bg-dropdownBackgroundColor border-borderColorPrimary text-txtcolor rounded-full border CloseShadow"
                    >
                        <X width="18" />
                    </button>
                )}
            </div>
            <Sidebar
                isOpen={isShowServiceInfoPopup}
                position={uiState.isMobile ? "bottom" : "right"}
                handleClose={() => setIsShowServiceInfoPopup(false)}
                size={""}
                customClass={` ${uiState.isMobile ? "w-full rounded-t-[24px] h-[80%] " : "w-[660px]"}`}
            >
                <ServiceInfo data={serviceData} />
            </Sidebar>
        </div>
    );
};

export default ServiceCardComponent;
