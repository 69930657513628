import React from "react";

const SBarberDetailCard = () => (
    <div>
        <label className={`bg-cardColor group flex shadow-card relative rounded-secondary  cursor-pointer outline-none ring-0 p-3 min-h-[95px]  border-borderColorPrimary border`}>
            <div className="lg:w-[100px] max-w-[100px] lg:h-[71px] object-cover min-w-[100px] min-h-[70px] h-[70px] mr-[12px] lg:mr-5 custom-loading"></div>
            <div className="p-0 flex-1 w-full flex flex-col justify-center gap-[3px] lg:gap-[4px]">
                <span className="text-[16px] font-normal text-txtcolor !leading-[22.4px] -tracking-[0.02rem] custom-loading">Gentlemen barber shop</span>
                <div className="flex flex-col gap-1">
                    <span className="text-[14px] text-textGrey font-normal !leading-[18.2px] -tracking-[0.02em] custom-loading">Canada Place 999 Canada Pl, Vancouver, Canada V6C 3E1</span>
                </div>
            </div>
        </label>
    </div>
);

export default SBarberDetailCard;
