import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EBookingTabState } from "src/app/BookAppointment/bookAppointment.interface";
import { IStateType, setTempStore } from "src/app/BookAppointment/Booking.slice";
import { LOCAL_STORAGE_KEYS } from "src/constants/common";
import { PATH } from "src/constants/path";
import { ROUTES } from "src/constants/routes";
import { useAppSelector } from "src/redux/hooks";
import { allShopLocations } from "src/redux/reducers/common/Common.slice";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import { getSlug } from "src/utils/global-functions";

export const useShopNavigation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const shopLocations = useAppSelector(allShopLocations);
    const settings: any = useAppSelector((data) => data.Common.settings)?.find((setting: any) => setting.type === (getSlug().isPreview ? "template_preview" : "template"))?.value;

    const navigateBasedOnShopLocations = useCallback(() => {
        dispatch(setIsShowLoginModal(!!localStorage.getItem(LOCAL_STORAGE_KEYS.isLoginPopup)));
        if (settings && settings.is_active_template) {
            if (shopLocations && shopLocations.length > 1) {
                navigate(ROUTES.LOCATION);
            } else {
                navigate(PATH.APPOINTEMENT);
            }
        } else {
            navigate(ROUTES.HOME);
        }
    }, [settings, shopLocations, navigate]);

    const syncTempStoreOnClose = useCallback(
        (bookingDetails: IStateType) => {
            if (!bookingDetails) return;

            switch (bookingDetails.step) {
                case EBookingTabState.SELECT_SERVICE:
                    dispatch(setTempStore({ selectedServices: bookingDetails.selectedServices }));
                    break;
                case EBookingTabState.SELECT_SPECIALIST:
                    dispatch(setTempStore({ selectedSpecialist: bookingDetails.selectedSpecialist }));
                    break;
                case EBookingTabState.SELECT_DATE_TIME:
                    dispatch(
                        setTempStore({
                            selectedBookingDate: bookingDetails.selectedBookingDate,
                            selectedBookingTime: bookingDetails.selectedBookingTime,
                        }),
                    );
                    break;
                case EBookingTabState.TAB_MENU:
                    dispatch(
                        setTempStore({
                            selectedServices: bookingDetails.selectedServices,
                            selectedSpecialist: bookingDetails.selectedSpecialist,
                            selectedBookingDate: bookingDetails.selectedBookingDate,
                            selectedBookingTime: bookingDetails.selectedBookingTime,
                        }),
                    );
                    break;
                default:
                    break;
            }
        },
        [dispatch],
    );

    return { navigateBasedOnShopLocations, syncTempStoreOnClose };
};
