import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputWithLabel from "src/components/InputWithLabel";
import { CardElement } from "@stripe/react-stripe-js";
import { useAppSelector } from "src/redux/hooks";
import { currentUser } from "src/redux/reducers/common/Common.slice";

const WCardTabComponent = ({ setCardError, cardError, isButtonShow }: any) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const user = useAppSelector(currentUser);
    return (
        <div className={`${user && user.total_loyalty_points > 0 ? "flex flex-col relative mb-6 lg:mb-0 max-lg:mx-4 lg:px-2 shrink-0" : ""} ${isButtonShow ? "lg:px-2" : ""}`}>
            <Controller
                name="holdername"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className="w-full mb-5">
                        <label htmlFor="cardnumber" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.01rem] mb-1.5 block">
                            {t("Cardholder Name")}
                        </label>
                        <InputWithLabel
                            placeholder={t("Enter the cardholder's name")}
                            handleChange={(e) => onChange(e.target.value)}
                            value={value}
                            name="holdername"
                            borderClass={`!h-[44px] !text-sm ${error && "!is-invalid border-solid"}`}
                        />
                        {error && <p className="text-error">{error && error.message}</p>}
                    </div>
                )}
            />

            <div className="grid grid-cols-1 gap-4 w-full">
                <Controller
                    name="carddetails"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full mb-5">
                            <label htmlFor="carddetails" className="text-txtAppointmentColor text-[14px] font-normal leading-[20px] -tracking-[0.01rem] mb-1.5 block">
                                {t("Card Details")}
                            </label>
                            <CardElement
                                id="number-123"
                                className={`card-element !h-[44px] !pt-[14px] !text-base placeholder:!text-base ${(error || cardError) && "!is-invalid"}`}
                                options={{
                                    style: {
                                        base: {
                                            color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                        },
                                    },
                                }}
                                onChange={(e) => {
                                    onChange(e);
                                    setCardError(e.complete ? "" : e.error?.message);
                                }}
                            />
                            {(error || cardError) && <p className="text-error">{(error || cardError) && (error?.message || cardError)}</p>}
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default WCardTabComponent;
