import React from "react";
import { useTranslation } from "react-i18next";
import { convertToHumanReadable, formatAmount, getShortName } from "src/utils/global-functions";
import { InfoCircle } from "@untitled-ui/icons-react/build/cjs";

interface ILanding {
    data: any;
}

const ServiceInfo = ({ data }: ILanding) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col font-primary -tracking-[0.03rem]">
            <div className="flex flex-col">
                <div className="text-base font-medium text-txtAppointmentColor">{data?.name}</div>
                {data?.price && <div className="text-sm font-normal text-textGrey">{convertToHumanReadable(data?.price?.duration)}</div>}
            </div>
            <div className="flex flex-col mt-4">
                <div className="font-medium text-xs text-txtAppointmentColor -tracking-[0.01rem]">Description</div>
                <div className="font-normal text-xs text-txtAppointmentColor -tracking-[0.01rem]">{data?.description}</div>
            </div>
            {data?.price && (
                <div className="flex flex-row mt-[18px] justify-between items-center w-full">
                    <div className="font-normal text-sm text-textGrey">{t("Starting Price")}</div>
                    <div className="bg-contentBackground border rounded-secondary border-borderColorPrimary max-w-max h-[24px] px-[6px] flex justify-center text-sm text-txtAppointmentColor font-bold items-center gap-0.5 ">
                        <span className="">$</span> <span className="">{formatAmount(data?.price?.price)}</span>
                    </div>
                </div>
            )}
            <div className="border-b border-borderColorSecondary py-4"></div>
            <div className="flex flex-col mt-4">
                <div className="text-base font-medium text-txtAppointmentColor">Specialist offering {data?.name}</div>
                <div className="mt-4 grid grid-cols-2 sm:grid-cols-4 gap-3">
                    {data?.staff?.map((staff: any) => (
                        <div className="flex " key={staff.id}>
                            <div className="border p-[9px] rounded-secondary h-full bg-contentBackground  border-borderColorSecondary">
                                <div className="mt-0">
                                    {staff?.profile_image_url ? (
                                        <img key={staff.id} src={staff.profile_image_url} alt="" className="w-full h-auto rounded-[9px] object-cover" />
                                    ) : (
                                        <span
                                            key={staff.id}
                                            className=" border-2 border-white flex justify-center items-center bg-gray-100 text-textGrey text-xs leading-[18px] font-semibold w-[161px] h-[118px] sm:w-[114px] rounded-[6px] sm:h-[114px]   object-cover"
                                        >
                                            {getShortName(staff?.full_name)}
                                        </span>
                                    )}
                                </div>
                                <div className="flex items-end flex-row mt-4 ">
                                    <div className="flex flex-col justify-between flex-1">
                                        <div className="  text-sm font-bold text-txtcolor leading-[140%] -tracking-[0.01rem]">{staff?.full_name}</div>
                                        <div className="text-textGrey font-normal pt-1 text-xs -tracking-[0.01rem]">{staff?.staff_role?.name}</div>
                                    </div>
                                    <InfoCircle width={16} className="text-primary" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServiceInfo;
