import React, { useCallback, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { onError } from "src/utils/global-functions";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
import AnimatedTitle from "src/components/AnimatedTitle/AnimatedTitle";
interface Props {
    data: any;
    sectionIndex: number;
    hasTestimonialAnimated: boolean;
}
const Testimonial: React.FC<Props> = ({ data, sectionIndex, hasTestimonialAnimated }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const [testimonialData, setTestimonialData] = useState<any[]>([]);
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);
    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    useEffect(() => {
        const mainData = testimonialInPairs(data?.testimonials);
        setTestimonialData(mainData || []);
    }, []);

    const testimonialInPairs = (array: any) => {
        const result = [];
        if (array && array.length) {
            for (let i = 0; i < array.length; i += 2) {
                result.push(array.slice(i, i + 2));
            }
            return result;
        }
    };
    interface TestimonialsProps {
        prop: any[];
    }

    const Testimonials: React.FC<TestimonialsProps> = ({ prop }) => (
        <div className="flex flex-col w-full flex-1 h-[100%]">
            {prop.map((propData, index) => (
                <div
                    key={index}
                    className={`flex flex-col justify-between bg-templateCardBg p-4 min-h-[258px] smd:min-h-[240px] sm:min-h-[190px] md:min-h-[280px] lg:min-h-[260px] xls:min-h-[220px] xl:min-h-[190px]  3xl:min-h-[170px] ${
                        index > 0 ? "mt-4" : ""
                    }`}
                >
                    {propData.is_description && <p className="landing-[25.6px] text-[16px] font-light text-TextColor mb-4">{getLocalizedSetting(propData, "description")}</p>}
                    <div className="flex items-center flex-row">
                        <img src={propData.image_url} className="w-[42px] min-w-[42px] h-[42px] mr-4" alt="" onError={onError("Professional")} />
                        <p className="font-light landing-[19.36px] text-[16px] tracking-[-0.01rem] text-TextColor">{propData.name}</p>
                    </div>
                </div>
            ))}
        </div>
    );

    const title = getLocalizedSetting(data, "title");

    return (
        <>
            <div className="flex flex-row justify-between">
                <div className="slice-label mb-[18px]">
                    <span className="num">{sectionIndex}</span>
                    {data?.is_title && (
                        <span className="slice-text">
                            <AnimatedTitle title={title} isActive={hasTestimonialAnimated} />
                        </span>
                    )}
                </div>
                {testimonialData && testimonialData.length > 1 && (
                    <div className="flex relative">
                        <button
                            className="rounded-primary flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[26px] w-[26px] min-w-[26px] mr-3.5 bg-transparent"
                            onClick={handlePrevious}
                        >
                            <ChevronLeft className="text-TextColor w-4 h-4" />
                        </button>
                        <button
                            className="rounded-primary flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[26px] w-[26px] min-w-[26px] bg-transparent"
                            onClick={handleNext}
                        >
                            <ChevronRight className="text-TextColor w-4 h-4" />
                        </button>
                    </div>
                )}
            </div>
            <CustomSwiper className="mySwiper w-full testimonialSlider flex flex-col flex-1" setSwiperRef={setSwiperRef}>
                {testimonialData.length > 0 &&
                    testimonialData?.map((testimonial: any[], indexKey: React.Key | null | undefined) => (
                        <SwiperSlide style={{ width: "100%" }} key={indexKey}>
                            <Testimonials key={indexKey} prop={testimonial} />
                        </SwiperSlide>
                    ))}
            </CustomSwiper>
        </>
    );
};

export default Testimonial;
