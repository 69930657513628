import React, { useMemo, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { InstagramSVG } from "src/theme/Images";
import { convertToHumanReadable, onError } from "../../utils/global-functions";
import { ISpecialistImageGallary } from "../../Interface";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton";
import { INSTAGRAM_URL } from "src/constants/common";
import PopupModal from "../PopupModal";
import GalleryCarousel from "src/app/BookAppointment/SelectSpecialists/SpecialistCardComponent/GalleryCarousel";

const SpecialistDetail = () => {
    const { t } = useTranslation();
    const uiState = useAppSelector((data) => data.UiStates);
    const [activeTab, setActiveTab] = useState<any>(uiState.showSpecialistProfileModalData?.galleries?.length ? "Gallery" : "Services");
    const [clickedImageId, setClickedImageId] = useState<null | number>(null);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const tabs = useMemo(
        () => [
            { name: t("Gallery"), count: uiState.showSpecialistProfileModalData?.galleries?.length || 0 },
            { name: t("Services"), count: uiState.showSpecialistProfileModalData?.services?.length || 0 },
        ],
        [uiState.showSpecialistProfileModalData],
    );

    const handleClose = () => {
        setClickedImageId(null);
    };

    const handleImage = (imageId: number, index: number) => () => {
        setClickedImageId(imageId);
        setActiveSlideIndex(index);
    };

    return (
        <>
            <div className="flex flex-col xl:rounded-3xl lg:p-5 w-full relative overflow-hidden h-hull">
                <div className="flex gap-1 justify-between items-center shrink-0">
                    <div className="flex flex-row items-center gap-2 md:gap-3 rounded-primary w-full flex-1">
                        <img
                            className="rounded-minimal min-w-[40px] w-[40px] h-[40px] lg:min-w-[48px] lg:w-[48px] lg:h-[48px] object-cover"
                            src={uiState.showSpecialistProfileModalData?.profile_image_url}
                            alt=""
                            onError={onError("Professional")}
                        />
                        <div className="content">
                            <h2 className="font-semibold text-[16px] text-txtAppointmentColor leading-[19.2px] -tracking-[0.01em] mb-1 pt-0.5">
                                {`${uiState.showSpecialistProfileModalData?.first_name} ${uiState.showSpecialistProfileModalData?.last_name}`}
                            </h2>
                            <p className="font-normal text-[14px] leading-[14.52px] text-textGrey -tracking-[0.01rem]">{uiState.showSpecialistProfileModalData?.staff_role?.name}</p>
                        </div>
                    </div>
                    <CustomButton outlineSecondary className="!h-[40px] rounded-minimal !py-1.5 shadow-InputAndButton cursor-pointer hover:shadow-md shadow:[0px_1px_2px_0px_#1018280D]">
                        {uiState.showSpecialistProfileModalData?.instagram_link && (
                            <a
                                className="flex gap-3 w-full items-center bg-inputbg  rounded-xl cursor-pointer justify-between max-lg:justify-center max-lg:mx-auto"
                                href={`${INSTAGRAM_URL}${uiState.showSpecialistProfileModalData?.instagram_link}`}
                                target="_blank"
                            >
                                <div className="flex gap-1.5 items-center ">
                                    <img src={InstagramSVG} className="w-[20px]" alt="" />
                                    <p className="text-xs sm:text-sm font-semibold text-txtAppointmentColor flex justify-between items-center leading-[18.2px] -tracking-[0.01rem]">
                                        @{uiState.showSpecialistProfileModalData?.instagram_link}
                                    </p>
                                </div>
                            </a>
                        )}
                    </CustomButton>
                </div>
                <div className="flex flex-row gap-2 mt-4 justify-between items-center mb-5 shrink-0">
                    <div className="font-normal flex-1 text-[16px] leading-[21px] text-textGrey text-justify -tracking-[0.01rem] line-clamp-3">{uiState.showSpecialistProfileModalData?.about}</div>
                </div>
                <div className="flex flex-row gap-2 transition duration-200 ease-in-out shrink-0">
                    {tabs.map(
                        (tab, index) =>
                            tab.count !== 0 && (
                                <div className="group" key={index}>
                                    <button
                                        key={index}
                                        type="button"
                                        className={` group max-w-[182px] flex flex-row gap-1.5 text-sm font-medium  items-center h-[28px] px-3 py-1 border rounded-minimal transition duration-200 ease-in-out group-hover:text-text-gray-700 group-hover:border-borderColorSecondary group-hover:bg-primaryHover ${
                                            activeTab === tab.name ? "bg-primaryHover rounded-minimal border-primaryBorderHover text-primary text-gray-700" : "text-gray-700 border-borderColorPrimary"
                                        }`}
                                        onClick={() => setActiveTab(tab.name)}
                                    >
                                        {tab.name}
                                        {/* <div
                                    className={`w-[21px] h-5 rounded-[3px] text-sm group-hover:bg-borderColorPrimary group-hover:text-textCounterColor transition duration-200 ease-in-out ${
                                        activeTab === tab.name ? "bg-borderColorPrimary bg-opacity-100" : " bg-bgContent  text-textCounterColor "
                                    }`}
                                >
                                    {tab.count}
                                </div> */}
                                    </button>
                                </div>
                            ),
                    )}
                </div>
                {activeTab === "Services" ? (
                    <div className="overflow-y-auto lg:max-h-[248px] lg:min-h-[248px] pb-2 scrollbar-hide mt-4  grid grid-cols-2 sm:grid-cols-3 gap-2.5 -tracking-[0.02rem] mb-1.5">
                        {uiState.showSpecialistProfileModalData?.services?.map((item: any, index: number) => (
                            <div key={index} className="flex flex-col border border-borderColorPrimary rounded-secondary justify-between w-full h-[118px] card-shadow-service hover:cursor-pointer">
                                <div className="flex flex-col  h-[68px] p-3.5 border-b border-borderColorPrimary">
                                    <div className="text-xs sm:text-sm font-bold text-txtAppointmentColor -tracking-[0.02rem] leading-[18.2px] truncate max-w-full mb-0.5">{item?.name}</div>
                                    <div className="text-xs font-normal text-textGrey leading-[18px] -tracking-[0.02rem]">
                                        {item?.shop_staff_service?.duration && convertToHumanReadable(item?.shop_staff_service?.duration)}
                                    </div>
                                </div>
                                <div className="flex flex-row px-4 py-3 justify-end items-center w-full ">
                                    {/* <div className="text-xs font-normal text-textGrey -tracking-[0.01rem] leading-[16.8px]">
                                        {item.shop_staff_service.starting_price ? t("Starting Price") : t("Price")}
                                    </div> */}
                                    <div className="bg-borderColorPrimary border rounded-[6px] border-borderColorSecondary max-w-max h-[24px] px-[6px] flex justify-center ">
                                        <div
                                            className="text-txtAppointmentColor flex items-center gap-[2px] text-xs sm:text-sm -tracking-[0.02rem] font-semibold 
                                        leading-[22.4px]"
                                        >
                                            <span className="">$</span>
                                            <span className="">{item.shop_staff_service.price}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    uiState.showSpecialistProfileModalData?.galleries &&
                    uiState.showSpecialistProfileModalData?.galleries?.length >= 0 && (
                        <div className="mt-4 grid flex-wrap grid-cols-2 sm:grid-cols-3 gap-2.5 scrollbar-hide overflow-y-auto lg:min-h-[248px] lg:max-h-[282px] w-full mb-3 lg:mb-1.5">
                            {uiState.showSpecialistProfileModalData?.galleries?.map((item: ISpecialistImageGallary, index: number) => (
                                <div key={index} className="w-full h-[118px] rounded-lg overflow-hidden">
                                    <img
                                        onClick={handleImage(item.id, index)}
                                        src={item?.image_url}
                                        alt=""
                                        className="object-cover w-[200px] h-[118px] max-h-[118px] rounded-lg cursor-pointer overflow-hidden transition-transform duration-300 hover:scale-110 ease-in-out"
                                        onError={onError()}
                                    />
                                </div>
                            ))}
                        </div>
                    )
                )}
            </div>
            {clickedImageId && (
                <PopupModal
                    position="center"
                    onClose={handleClose}
                    size="max-w-[95%] modelGallery max-sms:max-w-[310px] mx-auto overflow-hidden max-md:max-w-[380px] md:w-[660px] font-primary outline-custom "
                    className="!pt-0 !pb-0"
                    backgroundClassName={` ${uiState.isMobile ? "modal_backdrop" : "custom-toast-background"}`}
                    dismissible
                >
                    <GalleryCarousel activeSlideIndex={activeSlideIndex} />
                </PopupModal>
            )}
        </>
    );
};

export default SpecialistDetail;
