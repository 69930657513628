import React, { FC, useCallback, useEffect, useState } from "react";
import { IProps, ProductProps } from "./Product.interface";
import { formatAmount, onError } from "src/utils/global-functions";
import { ArrowLeft, ArrowRight, Minus, Plus } from "@untitled-ui/icons-react/build/cjs";
import { useTranslation } from "react-i18next";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { Swiper as SwiperType } from "swiper/types";
import { SwiperSlide } from "swiper/react";

const ProductDetail: FC<IProps> = ({ data, productVariant, setProductVariant }) => {
    const { t } = useTranslation();
    const [swiperRef, setSwiperRef] = useState<SwiperType>();

    useEffect(() => {
        setProductVariant(Object.keys(productVariant).length ? productVariant : data.variants[0]);
    }, [productVariant]);

    const ProductInfo: React.FC<ProductProps> = ({ index, title, description }) => {
        const [isCollapsed, setIsCollapsed] = useState<number[]>([]); // State to keep track of open sections

        const toggleCollapse = (indexNum: number) => {
            setIsCollapsed((prevState) => (prevState.includes(indexNum) ? prevState.filter((i) => i !== indexNum) : [...prevState, indexNum]));
        };

        return (
            <div className="border-b border-borderColorPrimary py-4 text-txtAppointmentColor last:border-b-0 border-b-animation-hover relative">
                <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleCollapse(index)}>
                    <div className="flex-1">
                        <h3 className="text-sm leading-[18.2px] font-semibold gap-3 lg:gap-4 -tracking-[0.01em]">{title}</h3>
                    </div>
                    <div className="text-right -tracking-[0.01em]">
                        <button>{isCollapsed.includes(index) ? <Minus className="w-[18px] h-[18px]" /> : <Plus className="w-[18px] h-[18px]" />}</button>
                    </div>
                </div>
                {isCollapsed.includes(index) && (
                    <div className="mt-3">
                        <div className="flex justify-between items-center">
                            <div className="w-full">
                                <div className="flex font-normal text-sm leading-[18.2px] text-txtAppointmentColor">{description}</div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const [currentIndex, setCurrentIndex] = useState<number>(1);
    const handleSlideChange = (swiper: { realIndex: React.SetStateAction<number> }) => {
        const currentSlideIndex = Number(swiper.realIndex) + 1;
        setCurrentIndex(currentSlideIndex);
    };

    return (
        <div className="flex flex-col lg:p-5 !pb-0 w-full overflow-hidden h-full">
            <div className="flex flex-col sm:flex-row sm:gap-8">
                <div className="flex bg-contentBackground gap-4 w-full min-w-[220px] max-sm:mx-auto max-w-[220px] mb-5 flex-1 relative">
                    <CustomSwiper spaceBetween={0} loop={data.images.length > 1} className="mySwiper w-full relative" setSwiperRef={setSwiperRef} onSlideChange={handleSlideChange}>
                        {data.images.map((img: any, index: React.Key | null | undefined) => (
                            <SwiperSlide key={index}>
                                <div className="relative w-full h-full cursor-pointer flex items-center justify-center">
                                    <img
                                        src={`${img.image_url}`}
                                        className="w-full rounded-secondary min-w-[220px] max-sm:mx-auto max-w-[220px] h-[220px] object-cover"
                                        alt=""
                                        onError={onError("Product")}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                        {data.images.length > 1 && (
                            <div className="flex-grow relative">
                                <div className="absolute bottom-[12px] left-[14px] justify-between flex right-[14px] items-center z-[99]">
                                    <div className="flex justify-center items-center w-[41px]  min-w-[41px] h-[27px] shadow-[0px_0px_4px_2px_var(--booking-card-shadow)] rounded-[4px] bg-tooltipTextColor leading-[27px] font-medium text-[14px] tracking-[-0.01em] text-TextColor">
                                        {currentIndex}/{data.images.length}
                                    </div>

                                    <div className="flex gap-2 flex-row">
                                        <span
                                            onClick={handlePrevious}
                                            className="border-[0.80px] border-borderColorSecondary hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] w-[32px] bg-tooltipTextColor h-[32px] flex justify-center items-center text-TextColor hover:text-primary rounded-[4.79px] cursor-pointer"
                                        >
                                            <ArrowLeft className="w-[18px] h-[18px]" />
                                        </span>
                                        <span
                                            onClick={handleNext}
                                            className="border-[0.80px] border-borderColorSecondary w-[32px] h-[32px] bg-tooltipTextColor flex justify-center items-center rounded-[4.79px] cursor-pointer text-TextColor hover:text-primary hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)]"
                                        >
                                            <ArrowRight className="w-[18px] h-[18px]" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </CustomSwiper>
                </div>

                <div className="flex-col hidden sm:flex">
                    <div className="flex flex-col mb-4">
                        <span className="mt-2.5 leading-[31.2px] font-semibold text-[26px] tracking-[-0.01em] text-TextColor mb-1">{data.name}</span>
                        <span className="text-[12px] font-normal text-textGrey leading-[14.52px]">{data.category.name}</span>
                    </div>

                    <div className="font-semibold mb-4 text-[32px] text-txtAppointmentColor leading-[38.4px] -tracking-[0.01em]">{formatAmount(productVariant?.inventory?.price)}</div>

                    <div className="flex w-full flex-col">
                        <span className="leading-[15.6px] text-[12px] font-medium text-textGrey mb-1.5">{t("Size")}:</span>
                        <div className="flex fl-tab-btn-view flex-wrap flex-row gap-2 items-center">
                            {data.variants?.map((variant: any) => (
                                <div
                                    key={variant.id}
                                    className={`!px-2 !text-[12px] leading-[18px] !font-medium !h-[28px] flex items-center justify-center border rounded-[6px] border-borderColorPrimary text-textGrey cursor-pointer hover:border-primary hover:bg-primaryHover hover:!text-primary ${
                                        productVariant.id === variant.id ? "border-primary !text-primary" : ""
                                    }`}
                                    onClick={() => {
                                        setProductVariant(variant);
                                    }}
                                >
                                    {`${variant.size} ${data.unit_of_measure === "units" ? "" : data.unit_of_measure}`}
                                </div>
                            ))}
                        </div>
                        {productVariant?.color && (
                            <div className="flex gap-2.5  border-borderColorPrimary mt-4 -tracking-[0.01rem] mb-[12px] items-center">
                                <div className="text-sm font-semibold uppercase text-txtAppointmentColor leading-[13px] pt-[1px]">{t("Color")}</div>
                                <div className="flex flex-row gap-2 items-center">
                                    <span className={`h-[14px] w-[14px] rounded-full cursor-pointer bg-[#${productVariant.color}]`}></span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col max-lg:overflow-y-auto md:max-h-[258px]  overflow-y-auto max-lg:h-full scrollbar-hide max-lg:mb-[60px]">
                <div className="flex-col flex sm:hidden mb-4">
                    <div className="flex flex-col mb-4">
                        <span className="mt-2.5 leading-[31.2px] font-semibold text-[26px] tracking-[-0.01em] text-TextColor mb-1">{data.name}</span>
                        <span className="text-[12px] font-normal text-textGrey leading-[14.52px]">{data.category.name}</span>
                    </div>

                    <div className="font-semibold mb-4 text-[32px] text-txtAppointmentColor leading-[38.4px] -tracking-[0.01em]"> ${productVariant?.inventory?.price}</div>

                    <div className="flex w-full flex-col">
                        <span className="leading-[15.6px] text-[12px] font-medium text-textGrey mb-1.5">{t("Size")}:</span>
                        <div className="flex fl-tab-btn-view flex-wrap flex-row gap-2 items-center">
                            {data.variants?.map((variant: any) => (
                                <div
                                    key={variant.id}
                                    className={`!px-2 !text-[12px] leading-[18px] !font-medium !h-[28px] flex items-center justify-center border rounded-[6px] border-borderColorPrimary text-textGrey cursor-pointer hover:border-primary hover:bg-primaryHover hover:!text-primary ${
                                        productVariant.id === variant.id ? "border-primary !text-primary" : ""
                                    }`}
                                    onClick={() => {
                                        setProductVariant(variant);
                                    }}
                                >
                                    {`${variant.size} ${data.unit_of_measure === "units" ? "" : data.unit_of_measure}`}
                                </div>
                            ))}
                        </div>
                        {productVariant?.color && (
                            <div className="flex gap-2.5 border-b pb-4 border-borderColorPrimary mt-4 -tracking-[0.01rem] items-center">
                                <div className="text-sm font-semibold uppercase text-txtAppointmentColor leading-[13px] pt-[1px]">{t("Color")}</div>
                                <div className="flex flex-row gap-2 items-center">
                                    <span className={`h-[14px] w-[14px] rounded-full cursor-pointer bg-[#${productVariant.color}]`}></span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col w-full">
                    {data.description && (
                        <div className="flex flex-col">
                            <span className="text-[14px] leading-[18.2px] font-semibold text-textGrey -tracking-[0.01em] mb-3">Product Description:</span>
                            <span className="text-[14px] leading-[18.2px] font-normal text-TextColor -tracking-[0.01em] mb-5">{data.description}</span>
                        </div>
                    )}
                    <div className="border-t border-borderColorPrimary flex flex-col w-full mb-0.5">
                        {data.details?.map((product: any, index: number) => (
                            <ProductInfo key={index} index={index} title={product.name} description={product.description} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ProductDetail;
