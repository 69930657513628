import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import MPhoneOrEmail from "src/components/PhoneOrEmail/MPhoneOrEmail";
import { AppleSVG, Facebook1SVG, GoogleSVG } from "src/theme/Images";
import { ILogin } from "../Login.interface";
import { LOCAL_STORAGE_KEYS } from "src/constants/common";

const MLogin: FC<ILogin> = ({ onInputChange, isLoading, isButtonDisabled, handleLogin }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="mobile-model-inner-wrapper font-primary">
            <div className="mobile-model-inner flex flex-col">
                <span className="login-model-title-mobile">{t("Get Started")}</span>
                <div className="mt-[12px]">
                    <Controller
                        name="emailPhone"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full">
                                <MPhoneOrEmail
                                    errors={error}
                                    onNumberChange={(data: any) => {
                                        onChange(data);
                                        onInputChange(data);
                                    }}
                                    defaultEmailValues={
                                        localStorage.getItem(LOCAL_STORAGE_KEYS.expressUserEmail) && value.isEmail
                                            ? {
                                                  data: value.data,
                                                  isEmail: true,
                                                  error: "",
                                              }
                                            : undefined
                                    }
                                />
                            </div>
                        )}
                    />
                    <CustomButton primary isLoading={isLoading} disabled={isButtonDisabled || isLoading} className={`w-full mt-5 !h-[48px]`} type="submit">
                        <span>{t("Continue")}</span>
                    </CustomButton>
                </div>
                <div className="flex items-center my-[16px] max-w-[200px] justify-between w-[200px] mx-auto">
                    <span className="bg-textGrey opacity-20 h-[1px] w-[80px] my-0" />
                    <span className="w-[43px] h-[23px] flex justify-center font-normal text-[18px] -tracking-[0.01rem] leading-[23.4px] text-textGrey ">{t("or")}</span>
                    <span className="bg-textGrey opacity-20 w-[80px] h-[1px] my-0" />
                </div>
                <div className="text-center flex justify-center gap-3 flex-row items-center">
                    <CustomButton
                        icon={<GoogleSVG className="w-5 min-w-5" />}
                        size="w-full"
                        className="h-[44px] w-[44px] min-w-[44px] max-w-[44px] border border-borderCounter rounded-lg bg-containerBgColor  "
                        onClick={handleLogin("google")}
                    ></CustomButton>
                    <CustomButton
                        icon={<AppleSVG className="w-5 min-w-5" />}
                        size="w-full"
                        className="h-[44px] w-[44px] min-w-[44px] max-w-[44px] border border-borderCounter rounded-lg bg-containerBgColor  "
                        onClick={handleLogin("apple")}
                    ></CustomButton>
                    <CustomButton
                        icon={<Facebook1SVG className="w-5 min-w-5" />}
                        size="w-full"
                        className="h-[44px] w-[44px] min-w-[44px] max-w-[44px] border border-borderCounter rounded-lg bg-containerBgColor "
                        onClick={handleLogin("facebook")}
                    ></CustomButton>
                </div>
            </div>
            <div className={`login-footer mt-5`}>
                <span className="font-medium text-[12px] leading-[14.52px] text-txtAppointmentColor mx-auto">
                    <span className="!text-[12px] !leading-[14.52px]">{t("By continuing, you agree to the ")}</span>
                    <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="text-primary !text-[12px] !leading-[14.52px]">
                        {t("terms")}
                    </a>
                    <span className="!text-[12px] !leading-[14.52px]">{t("and acknowledge the ")}</span>
                    <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="text-primary !text-[12px] !leading-[14.52px]">
                        {t("policies")}
                    </a>
                    <span className="!text-[12px] !leading-[14.52px]">.</span>
                </span>
            </div>
        </div>
    );
};

export default MLogin;
