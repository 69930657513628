import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import { axiosGet, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import Sidebar from "src/components/Sidebar";
import BookingView from "./BookingView";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { initAction } from "../Profile.interface";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { Plus } from "@untitled-ui/icons-react/build/cjs";
import BookingDetails from "src/app/Location/BookingDetails";

interface IProps {
    handleClose: () => void;
}

const BookingList = ({ handleClose }: IProps) => {
    const { t } = useTranslation();
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((data) => data.UiStates);
    const [action, setAction] = useState(initAction);
    const [isRefresh, setIsRefresh] = useState(true);
    const [booking, setBooking] = useState<IAppointmentBooking | null>(null);
    const [isLoading, setIsLoading] = useState({
        upcoming: true,
        past: true,
    });
    const [bookings, setBookings] = useState({
        upcoming: [],
        past: [],
    });
    const [isCardAdded, setIsCardAdded] = useState(false);
    const [isCardLoaded, setIsCardLoaded] = useState(false);

    useEffect(() => {
        const fetchApi = async () => {
            if (isRefresh) {
                await listStripeCards("upcoming");
                await listBookings("past");
                await listBookings("upcoming");
            }
        };
        fetchApi();
    }, [isRefresh]);

    const listStripeCards = async (type: string) => {
        setIsLoading((old) => ({ ...old, [type]: true }));
        setIsCardLoaded(false);
        axiosGet(API.STRIPE.CARD_LIST)
            .then((response) => {
                if (response.data.data.cards.length) {
                    setIsCardAdded(true);
                } else {
                    setIsCardAdded(false);
                }
            })
            .catch((error: any) => {})
            .finally(() => {
                setIsLoading((old) => ({ ...old, [type]: false }));
                setIsCardLoaded(true);
            });
    };

    const listBookings = async (type: string) => {
        setIsLoading((old) => ({ ...old, [type]: true }));
        const payloadData = {
            skip: 0,
            type,
        };
        const params = { shop_id: shop.id };
        axiosPost(API.BOOKING.LIST, payloadData, params)
            .then((response) => {
                setBookings((old) => ({ ...old, [type]: response.data.data }));
            })
            .finally(() => {
                setIsLoading((old) => ({ ...old, [type]: false }));
                setIsRefresh(false);
            });
    };

    const navigateLocation = () => {
        handleClose();
        navigateBasedOnShopLocations();
    };

    const handleAction = (type: string, data: IAppointmentBooking) => (event: any) => {
        event.stopPropagation();
        setBooking(data);
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleActionClose = () => {
        setAction(initAction);
    };

    useEffect(() => {}, []);
    const tabs = useMemo(
        () => [
            {
                name: t("Upcoming"),
                type: "upcoming",
            },
            {
                name: t("Past"),
                type: "past",
            },
        ],
        [],
    );
    const [activeTab, setActiveTab] = useState(tabs[0]);

    const handleTabClick = (tab: any) => async (event: { preventDefault: () => void }) => {
        event.preventDefault();
        setActiveTab(tab);
    };
    return (
        <>
            <div className="flex flex-col mb-4 mt-1 lg:mt-[20px] px-4 lg:px-5">
                <h3 className="text-[16px] lg:text-[20px] mb-1 leading-[22.4px] lg:leading-[28.4px] font-bold lg:font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">
                    {t("Appointment History")}
                </h3>
                <span className="font-normal text-[12px] lg:text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">{t("Manage your appointment notifications.")}</span>
            </div>

            <div className="flex flex-col side-spaching">
                <div className="px-4 lg:px-5">
                    <div className="fl-tab-btn-view4 w-full mb-4">
                        {tabs.map((tab, index) => (
                            <button key={index} type="button" className={`fl-tab-link4 w-full ${activeTab.name === tab.name ? "active" : ""}`} onClick={handleTabClick(tab)}>
                                {tab.name}
                            </button>
                        ))}
                    </div>
                </div>
                {activeTab.type === "upcoming" && (
                    <div
                        className={`flex flex-col gap-2.5 lg:gap-3.5 px-4 lg:px-5  mb-4 ${
                            bookings.upcoming.length > 0 ? "overflow-y-scroll max-h-[calc(100vh-220px)] lg:max-h-[calc(100vh-225px)] pb-2" : ""
                        }`}
                    >
                        {bookings.upcoming.length > 0 ? (
                            bookings.upcoming.map((upcomingBooking: any) => (
                                <div
                                    key={`upcoming - ${upcomingBooking.id}`}
                                    className="cursor-pointer flex flex-col hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] rounded-secondary"
                                    onClick={handleAction("booking", upcomingBooking)}
                                >
                                    <BookingDetails bookingDetails={upcomingBooking} handleAction={handleAction} isCardAdded={isCardAdded} listBookings={listBookings} isTipShow={false} />
                                </div>
                            ))
                        ) : (
                            <div
                                className="w-full border border-dashed border-borderColorPrimary px-[12px] py-[12px] flex flex-col items-center h-[92px] rounded-secondary justify-center bg-mainBackgroundColor cursor-pointer hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)]"
                                onClick={navigateLocation}
                            >
                                {isLoading.upcoming ? (
                                    <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1">{t("Loading")}</p>
                                ) : (
                                    <>
                                        <span className="flex items-center justify-center h-[26px] w-[26px] mb-1">
                                            <Plus className="w-[16px] h-[16px]" />
                                        </span>
                                        <p className="text-sm text-textGrey font-medium leading-[18px] tracking-[-0.01rem]">{t("Click here to book a new appointment.")}</p>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {activeTab.type === "past" && (
                    <div
                        className={`flex flex-col gap-2.5 lg:gap-3.5 px-4 lg:px-5 mb-4 ${
                            bookings.past.length > 0 ? "max-h-[calc(100vh-220px)] overflow-y-scroll lg:max-h-[calc(100vh-225px)] pb-1.5" : ""
                        }`}
                    >
                        {bookings.past.length > 0 && isCardLoaded ? (
                            bookings.past.map((pastBooking: any) => (
                                <div
                                    key={`latest - ${pastBooking.id}`}
                                    className="cursor-pointer rounded-secondary hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)]"
                                    onClick={handleAction("booking", pastBooking)}
                                >
                                    <BookingDetails bookingDetails={pastBooking} handleAction={handleAction} isCardAdded={isCardAdded} listBookings={listBookings} isTipShow={false} />
                                </div>
                            ))
                        ) : (
                            <div className="w-full border border-dashed border-borderColorPrimary px-[12px] py-[12px] flex flex-col items-center h-[92px] rounded-secondary justify-center bg-mainBackgroundColor cursor-pointer">
                                {isLoading.past ? (
                                    <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1">{t("Loading")}</p>
                                ) : (
                                    <>
                                        <p className="text-sm text-primary font-medium leading-[18px] -tracking-[0.01em]">{t("You have no past appointments.")}</p>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <Sidebar
                isBack
                isOpen={action.booking}
                isBottomView={uiState.isMobile}
                size="w-[460px]"
                position={uiState.isMobile ? "bottom" : "right"}
                customClass="profile-sidebar !h-full"
                handleClose={handleClose}
                handleBack={handleActionClose}
                dismissable
            >
                {action.booking && booking && <BookingView booking={booking} handleClose={handleActionClose} handleOnModify={handleClose} setIsRefresh={setIsRefresh} />}
            </Sidebar>
        </>
    );
};

export default BookingList;
