import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import { getBookingInfo } from "../../Booking.slice";
import { formatAmount } from "src/utils/global-functions";

interface IBasketCalculation {
    staticInfo: IAppointmentBooking;
}

const BookedBasketCalculation = ({ staticInfo }: IBasketCalculation) => {
    const { t } = useTranslation();
    const booking = useAppSelector(getBookingInfo);

    return (
        <div className="flex flex-col mt-auto">
            {booking?.selectedPromocode?.name && (
                <div className="flex justify-between mt-3">
                    <h1 className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Promo code")}</h1>
                    <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor -tracking-[0.01rem]">{booking?.selectedPromocode?.name}</span>
                </div>
            )}
            <div className="flex justify-between mt-3 mb-3">
                <h1 className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Subtotal")}</h1>
                <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor -tracking-[0.01rem]">{formatAmount(staticInfo.subtotal)}</span>
            </div>
            {Number(staticInfo.discount) > 0 && (
                <div className="flex justify-between mb-3">
                    <h1 className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Discount")}</h1>
                    <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor -tracking-[0.01rem]">- {formatAmount(staticInfo.discount)}</span>
                </div>
            )}
            {staticInfo?.is_tip_paid && staticInfo?.tips && (
                <div className="flex justify-between pb-3">
                    <h1 className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Tip")}</h1>
                    <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor -tracking-[0.01rem]">{formatAmount(staticInfo?.tips)}</span>
                </div>
            )}
            <div className="flex justify-between mb-4 border-b pb-3 border-borderColorPrimary">
                <h1 className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Taxes")}</h1>
                <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor -tracking-[0.01rem]">{formatAmount(staticInfo.taxes_and_fees)}</span>
            </div>
            <div className="flex justify-between mb-5">
                <h1 className="text-[16px] leading-[19.6px] font-semibold text-txtAppointmentColor -tracking-[0.01rem]">{t("Total")}</h1>
                <span className="text-[16px] leading-[19.6px] font-semibold text-txtAppointmentColor -tracking-[0.01rem]">
                    {formatAmount(parseFloat(staticInfo.total) + parseFloat(staticInfo?.tips))}
                </span>
            </div>
        </div>
    );
};

export default BookedBasketCalculation;
