import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import InputWithLabel from "../../../components/InputWithLabel";
import { useTranslation } from "react-i18next";
import { getBookingInfo, setSelectedPromocode, shopSettings } from "src/app/BookAppointment/Booking.slice";
import { Check, SearchLg, X } from "@untitled-ui/icons-react/build/cjs";

interface IProps {
    handleOverlayPanelClose: () => void;
    isLoading: boolean;
    promotions: never[];
}

interface IPromo {
    name: string;
    value?: number;
    type?: string;
    [key: string]: any;
}

const Promotion: FC<IProps> = ({ handleOverlayPanelClose, promotions, isLoading }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const booking = useAppSelector(getBookingInfo);
    const [search, setSearch] = useState("");
    const [filterPromotions, setFilterPromotions] = useState<IPromo[]>([]);
    const bookingShopSettings = useAppSelector(shopSettings);
    const [promotionsByRentOrShop, setPromotionsByRentOrShop] = useState<IPromo[]>([]);

    useEffect(() => {
        if (search) {
            const filteredPromotions: IPromo[] = promotionsByRentOrShop.filter((promotion: any) => promotion.name.toLowerCase().includes(search.toLowerCase()));
            setFilterPromotions(filteredPromotions);
        } else {
            setFilterPromotions(promotionsByRentOrShop);
        }
    }, [search, promotionsByRentOrShop]);

    useEffect(() => {
        if (bookingShopSettings.refer.status) {
            const updatedPromotions: IPromo[] = promotions.map((promo: IPromo) => {
                if (promo.name === "INVITEDISCOUNT") {
                    return {
                        ...promo,
                        value: bookingShopSettings.refer.refferer,
                        type: bookingShopSettings.refer.refferer_type,
                    };
                } else if (promo.name === "REFERDISCOUNT") {
                    return {
                        ...promo,
                        value: bookingShopSettings.refer.referee,
                        type: bookingShopSettings.refer.referee_type,
                    };
                }
                return promo;
            });
            setPromotionsByRentOrShop(updatedPromotions);
        } else {
            setPromotionsByRentOrShop([]);
        }
    }, [promotions]);

    const handleApply = (id: any) => () => {
        dispatch(setSelectedPromocode(id));
        handleOverlayPanelClose();
    };
    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearch(value);
    };

    return (
        <div className="flex flex-col font-primary flex-1 p-1.5 border border-borderColorPrimary rounded-minimal promotionOverlay  bg-dropdownBackgroundColor">
            <InputWithLabel
                name="search"
                placeholder={t("Search")}
                inputClass="pl-[41px] pt-0.5"
                value={search}
                borderClass="border border-borderCounter !h-[44px] mb-[10px]"
                buttonIcon={<SearchLg className="w-[18px] text-textGrey h-[18px]" />}
                buttonIconRight={search ? <X className="text-textGrey w-5 absolute top-1/2 -translate-y-1/2 right-4" /> : null}
                handlerIconClick={() => setSearch("")}
                handleChange={handleSearch}
            />
            {filterPromotions.length > 0 ? (
                filterPromotions.map((promotion: any) => (
                    <div className="flex items-center mt-0.5" key={promotion.code}>
                        <div
                            className=" flex items-center item-text justify-start py-2 pl-2 pr-2 text-[12px] text-textMoreGrey hover:text-TextColor font-medium cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-minimal focus:bg-mainBackgroundColor focus:outline-none focus:ring-0 min-w-max"
                            onClick={handleApply(promotion)}
                        >
                            <div className=" flex flex-1 flex-row items-center ">
                                <div className="flex flex-col">
                                    <span className="text-sm !leading-[22.4px] text-txtAppointmentColor font-medium align-middle">
                                        {promotion.name} - {promotion.type === "percentage" ? `${promotion.value}%` : `$${promotion.value}`}
                                    </span>
                                </div>
                            </div>
                            <div className="mr-0 float-right">
                                <button
                                    className="font-semibold text-sm bg-transparent text-primaryAppointment"
                                    type="button"
                                    onClick={handleApply(promotion)}
                                    disabled={booking?.selectedPromocode?.id === promotion.id}
                                >
                                    {booking?.selectedPromocode?.id === promotion.id && <Check className="text-primary h-4 w-4 mr-1.5" />}
                                </button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className="h-full w-full flex-1 text-[14px] flex justify-center items-center font-medium text-textGrey tracking-[0.03rem] min-h-[80px]">
                    {isLoading ? t("Loading") : t("No promotions available")}
                </div>
            )}
        </div>
    );
};

export default Promotion;
