import React from "react";
import { getBookingInfo } from "./Booking.slice";
import { useAppSelector } from "src/redux/hooks";
import Stepper from "./Stepper";
import { EBookingTabState } from "./bookAppointment.interface";
import { onError } from "src/utils/global-functions";
import { Link } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import AppointmentPreview from "./AppointmentPreview";
import { useTranslation } from "react-i18next";

const AppointmentControlPanel = ({ activeTab }: any) => {
    const { t } = useTranslation();
    const booking = useAppSelector(getBookingInfo);
    const previewAnimationProps = useSpring<React.CSSProperties>({
        from: { opacity: 0, transform: "translateX(20%)" },
        to: { opacity: 1, transform: "translateX(0%)" },
        config: { tension: 180, friction: 40, duration: 450 },
        immediate: false,
    });
    return (
        <div className={`p-2 max-lg:py-2.5 lg:p-8 bg-mainBackgroundColor h-full flex-1 overflow-hidden flex flex-col ${booking.isFullScreen ? "!pb-[28px] max-lg:pt-3" : ""} `}>
            <div
                className={`rounded-[16px] lg:rounded-containerRadius bg-containerBgColor shadow-[0px_5.33px_16px_0px_#928C971A] p-4 max-lg:pb-3 lg:px-8 lg:py-8 text-secondary-appointment font-primary flex flex-col lg:flex-row flex-1 scrollbar-hide md:overflow-hidden overflow-hidden h-full`}
            >
                <div className="lg:w-[52%] xl:w-[60%] 2xl:flex-1 flex flex-col relative h-full pr-0 lg:pr-[40px] max-lg:-mx-4 -mx-2  lg:pt-6 overflow-hidden">
                    <div className="flex flex-col flex-1 relative  overflow-hidden max-lg:pt-[16px] h-full">
                        {booking.step !== EBookingTabState.SELECT_DATE_TIME && booking.step !== EBookingTabState.SELECT_SERVICE && booking.step !== EBookingTabState.SELECT_SPECIALIST && (
                            <div className="flex flex-col relative mb-6 lg:mb-0 max-lg:mx-4 lg:px-2 shrink-0">
                                <Stepper />
                            </div>
                        )}
                        {booking.step === EBookingTabState.TAB_MENU && (
                            <div className="p-3 bg-appointmentBgColor border border-borderColorPrimary flex items-center rounded-secondary lg:hidden max-lg:mb-4 mx-4 shrink-0">
                                <img
                                    className="rounded-[6px] aspect-square w-[60px] h-[60px] min-w-[60px] mr-3 object-cover"
                                    src={booking.selectedShopLocation?.location_image_url}
                                    alt=""
                                    onError={onError("Location")}
                                />
                                <div className="flex flex-col">
                                    <h1 className="leading-[22.4px] text-base font-normal mb-[3px] text-txtcolor">{booking.selectedShopLocation?.name}</h1>
                                    <p className="leading-[18.2px] text-sm text-textGrey font-normal max-w-full">
                                        {`${booking.selectedShopLocation?.street}, ${booking.selectedShopLocation?.city}, ${booking.selectedShopLocation?.country} ${booking.selectedShopLocation?.postal_code}`}
                                    </p>
                                </div>
                            </div>
                        )}
                        {activeTab}
                    </div>
                    <div className="w-full pt-2 px-2 pl-2.5 hidden lg:block bg-containerBgColor z-10 pb-[1px]">
                        <p className="text-sm leading-[19.6px] relative font-normal -tracking-[0.02em] text-textGrey flex items-center gap-[4px]">
                            <span>{t("powered by")}</span>{" "}
                            <Link className="text-textGrey border-b-animation-hover relative overflow-visible" target="_blank" to={"https://www.getflair.ca/"}>
                                {t("getflair.ca")}
                            </Link>
                        </p>
                    </div>
                </div>
                <animated.div
                    className="hidden relative lg:block h-full lg:flex-1 2xl:flex-none overflow-hidden px-6 md:px-[30px] lg:px-0 lg:w-[400px] xl:w-[480px] 2xl:w-[480px] 3xl:w-[550px] rounded-secondary sidebar_shadow"
                    style={previewAnimationProps}
                >
                    <AppointmentPreview />
                </animated.div>
            </div>
        </div>
    );
};

export default AppointmentControlPanel;
