import React, { FC, useEffect, useState } from "react";
import { DefaultImageJPG } from "src/theme/Images";
import { IProductInfo, IVariant } from "./Product.interface";
import { Minus, Plus, XClose } from "@untitled-ui/icons-react/build/cjs";
import { formatAmount, onError } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";

const ProductInfo: FC<IProductInfo> = ({ productData, handleProduct }) => {
    const { t } = useTranslation();
    const [productDetailsById, setProductDetailsById] = useState<{
        [key: number]: { price: number; imageUrl: string; isOtherDetailsShow: boolean; description: string; details: any[]; variant: IVariant };
    }>({});
    const [hoveredProduct, setHoveredProduct] = useState<number | null>(null);
    const [isCollapsed, setIsCollapsed] = useState<{ [key: number]: { id: number; isShow: boolean } }>({});

    useEffect(() => {
        if (productData.length) {
            const initialDetails = productData.reduce((acc, product) => {
                const price = product.variants && product.variants.length > 0 ? product.variants[0].price : 0;
                const variant = product.variants && product.variants.length > 0 ? product.variants[0] : { price: 0, size: "0", id: 0 };
                const imageUrl = product.images && product.images.length > 0 ? product.images.find((image) => image.is_main)?.image_url || "" : "";
                const description = product.description;
                const details = product.details;

                acc[product.id] = { price, imageUrl, isOtherDetailsShow: false, description, details, variant };

                return acc;
            }, {} as { [key: number]: { price: number; imageUrl: string; isOtherDetailsShow: boolean; description: string; details: any[]; variant: IVariant } });

            const initialStates: { [key: number]: { id: number; isShow: boolean } } = {};
            productData.forEach((product) => {
                initialStates[product.id] = { id: 0, isShow: false };
            });
            setIsCollapsed(initialStates);
            setProductDetailsById(initialDetails);
        }
    }, [productData]);

    const handlePrice = (productDetails: any, productVariantDetail: any) => () => {
        setProductDetailsById((prevDetails) => ({
            ...prevDetails,
            [productDetails.id]: {
                ...prevDetails[productDetails.id],
                variant: productVariantDetail,
                price: productVariantDetail.price,
            },
        }));
    };
    const handleDetails = (productDetails: any, isDetailShow: boolean) => () => {
        setProductDetailsById((prevDetails) => ({
            ...prevDetails,
            [productDetails.id]: {
                ...prevDetails[productDetails.id],
                isOtherDetailsShow: isDetailShow,
            },
        }));
    };

    const handleToggleDetails = (productId: number, index: number) => () => {
        setIsCollapsed((prev) => ({
            ...prev,
            [productId]: {
                id: index,
                isShow: prev[productId]?.id === index ? !prev[productId].isShow : true,
            },
        }));
    };

    const ProductCard = ({ product }: any) => {
        const productDetails = productDetailsById[product.id] || {
            price: 0,
            imageUrl: DefaultImageJPG,
            isOtherDetailsShow: false,
            description: "description",
            details: [{ name: "Usage", description: "Liquid Foundation" }],
            variant: { price: 0, size: "0", id: 0 },
        };
        const isHovered = hoveredProduct === product.id;
        const detailsState = isCollapsed[product.id] || { id: 0, isShow: false };

        return (
            <div className="relative flex flex-col items-center justify-center">
                {/* {!productDetails.isOtherDetailsShow && ( */}
                <div className="flex flex-col w-full">
                    <div className="relative">
                        <img
                            src={productDetails.imageUrl}
                            alt=""
                            className="w-full aspect-1/1"
                            onMouseEnter={() => setHoveredProduct(product.id)}
                            onMouseLeave={() => setHoveredProduct(null)}
                            onError={onError("Product")}
                        />
                        {isHovered && (
                            <div className="absolute inset-0 flex items-center justify-center bg-[#000000B2]" onMouseLeave={() => setHoveredProduct(null)}>
                                <button
                                    onClick={handleDetails(product, true)}
                                    className="bg-transparent border w-full max-w-[254px] border-white h-[46px] uppercase text-white px-4 flex items-center justify-center text-[14px] leading-[18.2px] tracking-[-0.001em] font-medium "
                                >
                                    {t(" View Product Details")}
                                </button>
                            </div>
                        )}
                        <span className="absolute bottom-3 right-3 text-[20px] leading-[24.2px] font-normal p-3 text-white h-[48px] bg-[#000000]">{formatAmount(productDetails.price)}</span>
                    </div>
                    <div className="flex flex-row pt-4">
                        <div className="flex-1">
                            <h2 className="text-[20px] leading-[24.2px] font-normal text-TextColor uppercase mb-1.5">{product.name}</h2>
                            <div className="flex gap-2 flex-wrap">
                                {product.variants.map(
                                    (
                                        variant: {
                                            size: string;
                                            id: number;
                                        },
                                        index: React.Key | null | undefined,
                                    ) => (
                                        <button
                                            key={index}
                                            onClick={handlePrice(product, variant)}
                                            className={`border border-borderColorSecondary px-1.5 hover:border-primary hover:text-primary text-textGrey py-[5px] rounded-[6px] text-[12px] leading-[18px] font-medium ${
                                                variant.id === productDetails.variant.id ? "border-primary !text-primary" : ""
                                            }`}
                                        >
                                            {`${variant.size} ${product.unit_of_measure === "units" ? "" : product.unit_of_measure}`}
                                        </button>
                                    ),
                                )}
                            </div>
                        </div>
                        <button
                            onClick={handleProduct && handleProduct(product, productDetails.variant)}
                            className="border border-ProductBorderColor h-[40px] min-w-[40px] w-[40px] flex items-center justify-center md:hover:bg-primaryHover md:hover:border-primaryBorderHover"
                        >
                            <Plus className="w-[22px] h-[22px] text-ProductBorderColor" />
                        </button>
                    </div>
                </div>
                {/* )} */}

                {productDetails.isOtherDetailsShow && (
                    <div className="bg-primary w-full overflow-hidden flex flex-col h-full  absolute top-0 left-0 z-50">
                        <div className="relative px-6 pt-[24px] pb-5 w-full flex-col h-full overflow-y-auto flex-grow">
                            <h2 className="mb-[10px] text-[20px] leading-[30px] font-medium tracking-[-0.01em] text-white">{t("Product Description")}</h2>
                            <p className={`text-[14px] leading-[19.6px] font-normal text-white tracking-[-0.001em] ${productDetails.details ? "border-b pb-[20px]" : ""} `}>
                                {productDetails.description}
                            </p>
                            {productDetails.details.map((item, index) => (
                                <div key={index} className="py-4 border-b border-white ">
                                    <div className="flex justify-between items-center">
                                        <p className="text-left text-[14px] leading-[18.2px] text-white font-semibold tracking-[-0.001em]">{item.name}</p>
                                        <button onClick={handleToggleDetails(product.id, index)} className="text-right text-TextColor  text-[14px] font-normal">
                                            {detailsState.isShow && detailsState.id === index ? <Minus className="w-[18px] h-[18px] text-white" /> : <Plus className="w-[18px] h-[18px] text-white" />}
                                        </button>
                                    </div>
                                    {detailsState.isShow && detailsState.id === index && (
                                        <p className="pt-2 text-[14px] leading-[19.6px] font-normal text-white tracking-[-0.001em]">{item.description}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                        <button onClick={handleDetails(product, false)} className="absolute top-[16px] right-[16px] text-white rounded">
                            <XClose className="text-white w-[18px] h-[18px]" />
                        </button>
                    </div>
                )}
            </div>
        );
    };
    return (
        <>
            <div className="grid grid-cols-2 gap-[32px]">
                {productData.map((product) => (
                    <ProductCard key={product.id} product={product} />
                ))}
            </div>
        </>
    );
};

export default ProductInfo;
