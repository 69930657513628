import React, { useEffect } from "react";
import ButtonCmp from "../../components/ButtonCmp";
import { useAppDispatch } from "../../redux/hooks";
import { setIsMobilePageVisitedOnce } from "../../redux/reducers/Ui.slice";
import { useTranslation } from "react-i18next";
import { MobileHomePNG } from "src/theme/Images";

const MobileLanding = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setIsMobilePageVisitedOnce(true));
    }, []);

    return (
        <div className="h-full">
            <div className="bg-[url('src/assets/img/mobile-home.png')] h-[54%] w-full bg-no-repeat bg-center"></div>
            <h2 className="text-[28px] font-bold leading-8 text-txtcolor -tracking-[0.312px] text-center">{t("We have our own app!")}</h2>
            <p className="text-sm leading-[19.6px] text-center px-[35px] text-textGrey mt-4">{t("Download our app for a better experience and access to exclusive mobile features.")}</p>
            <div className="px-5">
                <ButtonCmp onClick={() => {}} className="btn_primary fl-btn w-full mt-6">
                    {t("Open")}
                </ButtonCmp>
                <ButtonCmp
                    onClick={() => {}}
                    className="flex items-center justify-center text-txtcolor border-black border-2 hover:text-white hover:bg-black transition-all ease-in-out fl-btn w-full mt-3"
                >
                    {t("Continue on mobile")}
                </ButtonCmp>
            </div>
        </div>
    );
};

export default MobileLanding;
