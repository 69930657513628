import { ArrowRight } from "@untitled-ui/icons-react/build/cjs";
import React from "react";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
import { useSpring, animated } from "@react-spring/web";

type Props = {
    service: any;
    isCollapsed: any;
    setIsCollapsed: any;
    index: number;
};

const Info: React.FC<Props> = ({ service, setIsCollapsed, isCollapsed, index }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const name = getLocalizedSetting(service, "name");
    const description = getLocalizedSetting(service, "description");

    const [styles, api] = useSpring(() => ({
        width: "0%",
        opacity: 0,
    }));

    const onMouseEnter = async () => {
        api.start({
            width: "50%",
            opacity: 1,
            config: { tension: 300, friction: 20 },
        });
        api.start({
            width: "100%",
            config: { tension: 200, friction: 30 },
        });
    };

    const onMouseLeave = () => {
        api.start({
            width: "90%",
            opacity: 0,
            config: { tension: 250, friction: 25 },
        });
    };

    return (
        <div>
            {/* Line before the first element */}
            {/* {index === 0 && <div style={{ height: "1px", background: "#D3D3D3", width: "100%" }} />} */}

            <div className="border-b border-borderColorPrimary flex flex-col border-b-animation relative pb-6 pt-6 max-md:py-4">
                <div className="w-full cursor-pointer flex items-start md:items-center gap-2">
                    <div className="flex w-full flex-1 flex-col max-md:gap-1.5">
                        <h3 className="text-[20px] leading-[30px] md:text-[24px] md:leading-[36px] font-medium uppercase text-TextColor tracking-[-0.02em]">{name}</h3>
                        <h3 className="text-[16px] leading-[22.4px] font-normal tracking-[-0.01em] text-ProductBorderColor md:hidden">${Number(service.price).toFixed(2)}</h3>
                        <p className="font-light text-[16px] leading-[24px] tracking-[-0.01rem] text-TextColor">{description}</p>
                    </div>
                    <div className="flex justify-end items-start md:items-center">
                        <h3 className="text-[20px] leading-[28px] font-normal tracking-[-0.01em] text-ProductBorderColor pr-[48px] hidden md:block">${Number(service.price).toFixed(2)}</h3>
                        <ArrowRight className="text-TextColor h-5 w-5 max-md:mt-[5px]" />
                    </div>
                </div>

                {/* <animated.div
                    style={{
                        height: "2px",
                        background: "black",
                        width: styles.width,
                        opacity: styles.opacity,
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                    }}
                /> */}
            </div>
        </div>
    );
};

export default Info;
