import React, { useEffect, useState } from "react";
import { axiosGet, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { capitalizeFirstLetter, getCardTypeImage } from "src/utils/global-functions";
import AddNewCardTabComponent from "../BookAppointment/FinalizeBooking/AddNewCardTabComponent";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { Plus, Trash01 } from "@untitled-ui/icons-react/build/cjs";
import { ProgressSpinner } from "primereact/progressspinner";
import { errorCode } from "src/constants/common";
import { setBookingIsUpadteCard, setIsCardAdded } from "../BookAppointment/Booking.slice";
import { useAppDispatch } from "src/redux/hooks";

interface IisCardActionLoading {
    [key: string]: boolean;
}

const PaymentMethod = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [stripeCards, setStripeCards] = useState([]);
    const [isStripeCardLoading, setIsStripeCardLoading] = useState(false);
    const [isCardActionLoading, setIsCardActionLoading] = useState<IisCardActionLoading>({});
    const [defaultCardId, setDefalutCardId] = useState<string | undefined>(undefined);
    const [isShowAddNewCardTab, setIsShowAddNewCardTab] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (errorMessage) {
            setTimeout(() => {
                setErrorMessage("");
            }, 2000);
        }
    }, [errorMessage]);

    useEffect(() => {
        listStripeCards();
    }, []);

    const listStripeCards = () => {
        setIsStripeCardLoading(true);
        axiosGet(API.STRIPE.CARD_LIST)
            .then((response) => {
                if (response.data.data.cards.length > 0) {
                    dispatch(setIsCardAdded(true));
                } else {
                    dispatch(setIsCardAdded(false));
                }
                setStripeCards(response.data.data.cards);
                setDefalutCardId(response.data.data.default_card_id);
            })
            .catch((error: any) => {})
            .finally(() => {
                setIsStripeCardLoading(false);
                dispatch(setBookingIsUpadteCard(false));
            });
    };

    const handleDefault = (paymentMethodId: string) => () => {
        if (defaultCardId === paymentMethodId) {
            return;
        }
        setIsCardActionLoading((prev) => ({ ...prev, [paymentMethodId]: true }));
        axiosPost(API.STRIPE.CARD_DEFAULT, { payment_method_id: paymentMethodId })
            .then(() => {
                setDefalutCardId(paymentMethodId);
            })
            .catch((error: any) => {})
            .finally(() => {
                setIsCardActionLoading((prev) => ({ ...prev, [paymentMethodId]: false }));
            });
    };

    const handleCardRemove = (paymentMethodId: string) => () => {
        setIsCardActionLoading((prev) => ({ ...prev, [paymentMethodId]: true }));
        axiosPost(API.STRIPE.CARD_REMOVE, { payment_method_id: paymentMethodId })
            .then(() => {
                dispatch(setBookingIsUpadteCard(true));
                listStripeCards();
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    setErrorMessage(response.message);
                }
            })
            .finally(() => {
                setIsCardActionLoading((prev) => ({ ...prev, [paymentMethodId]: false }));
            });
    };

    return (
        <div className="flex flex-col lg:flex-1">
            {isShowAddNewCardTab ? (
                <AddNewCardTabComponent
                    closeAddNewCardTab={() => {
                        setIsShowAddNewCardTab(false);
                    }}
                    listStripeCards={listStripeCards}
                />
            ) : (
                <>
                    <div className="flex flex-col mb-4 mt-1 lg:mt-[20px] px-4 lg:px-5">
                        <div className="text-[16px] lg:text-[20px] mb-1 leading-[22.4px] lg:leading-[28.4px] font-bold lg:font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">
                            {t("Payment Methods")}
                        </div>
                        <div className="font-normal text-[12px] lg:text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">{t("Update your plan payment details")}</div>
                    </div>

                    <div className="flex flex-col gap-4 px-4 lg:px-5 flex-1">
                        <div className="flex flex-col flex-1 gap-2.5 lg:gap-3.5">
                            {isStripeCardLoading ? (
                                <div className="custom_loading_wrapper mb-5 !ml-0.5">
                                    <div className="custom_loading"></div>
                                </div>
                            ) : stripeCards.length > 0 ? (
                                stripeCards.map((stripeCard: any) => (
                                    <div
                                        className="p-3 rounded-minimal border border-borderColorPrimary flex items-center cursor-pointer"
                                        // onClick={(e) => {
                                        //     e.stopPropagation();
                                        //     handlePaymentMethodChange("card", stripeCard.id);
                                        // }}
                                        key={stripeCard.id}
                                    >
                                        <div className="flex-1 gap-3 lg:gap-3.5 flex flex-row items-center">
                                            <div className="rounded-lg min-w-[42px] w-[42px] h-[42px] object-contain flex justify-center items-center">
                                                <img src={getCardTypeImage(stripeCard.card.brand)} alt="card" className="w-full" />
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="leading-[19.6px] font-semibold text-[14px] text-TextColor tracking-[-0.01em] mb-0.5">
                                                    {capitalizeFirstLetter(stripeCard.card.brand)} {t("ending in")} {stripeCard.card.last4}
                                                </span>
                                                {stripeCard.id === defaultCardId ? (
                                                    <span className="text-[12px] text-textGrey leading-[18px] font-normal">{t("Default")}</span>
                                                ) : (
                                                    <button
                                                        className="text-[12px] text-textGrey leading-[18px] font-normal cursor-pointer text-left hover:text-TextColor"
                                                        disabled={Object.values(isCardActionLoading).includes(true)}
                                                        onClick={handleDefault(stripeCard.id)}
                                                    >
                                                        {t("Set as default")}
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        {isCardActionLoading[stripeCard.id] ? (
                                            <ProgressSpinner style={{ width: "25px", height: "25px" }} className="radio-loader" strokeWidth="8" animationDuration="1.5s" />
                                        ) : (
                                            <button
                                                onClick={handleCardRemove(stripeCard.id)}
                                                disabled={Object.values(isCardActionLoading).includes(true)}
                                                className="border border-borderColorPrimary h-[36px] flex justify-center items-center w-[36px] min-w-[36px] rounded-minimal CustomButtonShadow hover:bg-dangerBgLight"
                                            >
                                                <Trash01 className="w-[20px] text-[#F04438] h-[20px] min-w-[20px]" />
                                            </button>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div
                                    className="w-full border border-borderColorPrimary px-[12px] py-[12px] flex flex-col items-center h-[72px] bg-contentBackground rounded-secondary justify-center bg-cardBackgroundColor cursor-pointer hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] mb-5"
                                    onClick={() => setIsShowAddNewCardTab(true)}
                                >
                                    <span className="flex items-center justify-center h-[26px] w-[26px] mb-1">
                                        <Plus className="w-[16px] h-[16px]" />
                                    </span>
                                    <p className="text-sm text-textGrey font-medium leading-[18px] tracking-[-0.01rem]">{t("Add Payment Method")}</p>
                                </div>
                            )}
                            {errorMessage && <p className="text-error !mt-[-8px]">{errorMessage}</p>}
                        </div>

                        <div className={`w-full mb-4 mt-auto ${stripeCards.length > 0 ? "flex" : "hidden"}`}>
                            <CustomButton
                                primary
                                className="!h-[40px] w-full"
                                onClick={() => setIsShowAddNewCardTab(true)}
                                disabled={isStripeCardLoading || Object.values(isCardActionLoading).includes(true)}
                            >
                                {t("Add Payment Method")}
                            </CustomButton>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PaymentMethod;
