import React, { FC, useCallback, useEffect, useState } from "react";
import SelectBox from "src/components/SelectBox";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { allShopLocations, currentShop, globalLanguage } from "src/redux/reducers/common/Common.slice";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { Swiper as SwiperType } from "swiper/types";
import WProduct from "./WProduct";
import { IProductInfo } from "./Product.interface";
import MProduct from "./MProduct";
import { addProduct, getBookingInfo, setBookingInitialState, setSelectedShopLocation } from "src/app/BookAppointment/Booking.slice";
import { IVariant } from "../../Template2/Product/Product.interface";
import { useNavigate } from "react-router-dom";
import { PATH } from "src/constants/path";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
interface IProduct {
    data: any;
}

const Product: FC<IProduct> = ({ data }) => {
    const shop = useAppSelector(currentShop);
    const currentLanguage = useAppSelector(globalLanguage);
    const { getLocalizedSetting } = useLocalizedSetting();
    const bookingState = useAppSelector(getBookingInfo);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((uiStateData) => uiStateData.UiStates);
    const shopLocations: any[] = useAppSelector(allShopLocations);
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<number | null>(null);
    const [productList, setProductList] = useState<IProductInfo[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const locationOptionsData: any = shopLocations.map((location: any) => ({
            value: location.id,
            label: location.name,
            id: location.id,
        }));

        setLocationOptions(locationOptionsData);
        if (!selectedLocation) {
            setSelectedLocation(locationOptionsData[0].id);
        }
    }, [shopLocations]);

    useEffect(() => {
        if (selectedLocation) {
            getProducts();
        }
    }, [selectedLocation, currentLanguage]);

    const getProducts = () => {
        setProductList([]);
        setIsLoading(true);
        const payload = {
            params: {
                shop_id: shop.id,
                location_id: selectedLocation,
            },
            data: {
                booking_id: null,
            },
        };
        axiosGet(API.PRODUCT.LIST, payload.data, payload.params)
            .then((response) => {
                const responseData = response.data.data;
                setProductList(responseData);
            })
            .finally(() => setIsLoading(false));
    };

    const handleLocation = (e: any) => {
        setSelectedLocation(Number(e.value));
    };

    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);
    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const handleProduct = (selectedProductData: IProductInfo, variant: IVariant) => () => {
        if (selectedLocation !== bookingState.selectedShopLocation?.id || bookingState.isModifyingAppointment) {
            dispatch(setBookingInitialState());
        }
        dispatch(addProduct({ ...selectedProductData, selectedVariant: variant }));
        if (selectedLocation) {
            dispatch(setSelectedShopLocation(shopLocations.find((locationData: any) => locationData.id === selectedLocation)));
            navigate(PATH.APPOINTEMENT);
        }
    };

    const title = getLocalizedSetting(data, "title");

    return (
        <section>
            <div className="pt-9 md:spacing-top">
                <div className="flex flex-col md:flex-row md:justify-between md:items-center">
                    {data?.is_title && <h2 className="custom-heading uppercase text-txtcolor">{title}</h2>}
                    <SelectBox
                        placeholder={"All Locations"}
                        name="location"
                        allowClear={false}
                        options={locationOptions}
                        inputClass="w-full mt-4 mb-4 md:mb-0 md:mt-0 md:min-w-[190px] productDropDown"
                        searchable={false}
                        onChange={handleLocation}
                        value={locationOptions.filter((item: any) => item.value === selectedLocation)}
                        disabled={false}
                    />
                </div>
                {/* <div className="mt-8 border-b border-borderColorPrimary relative">
                    {data?.products?.map((product: any, index: React.Key | null | undefined) => (
                        <Products key={index} info={product} index={index} />
                    ))}
                </div> */}
                {uiState.isTablet ? (
                    <WProduct
                        isLoading={isLoading}
                        productData={productList}
                        handlePrevious={handlePrevious}
                        handleNext={handleNext}
                        setSwiperRef={setSwiperRef}
                        selectedLocation={selectedLocation}
                        handleProduct={handleProduct}
                    />
                ) : (
                    <MProduct isLoading={isLoading} productList={productList} handlePrevious={handlePrevious} handleNext={handleNext} setSwiperRef={setSwiperRef} handleProduct={handleProduct} />
                )}
            </div>
        </section>
    );
};

export default Product;
