import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { FlairLogo } from "src/theme/Images";
const Step4 = () => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const slugValue = shop ? `https://${shop.slug}.getflair.ca` : "https://web.getflair.ca";
    const logoImageUrl = shop && shop.admin_logo_image_url ? shop.admin_logo_image_url : FlairLogo;
    const businessName = shop && shop.business_name ? shop.business_name : "FLAIR Studios";
    return (
        <div className="flex flex-col w-full">
            <span className="text-[14px] leading-[19.6px] font-normal text-TextColor tracking-[-0.01em] h-[44px]">
                <span className="text-[14px] leading-[19.6px]">Name the application and press</span> <span className="text-primary text-[14px] leading-[19.6px] font-normal">{t("Add a name")}</span>
            </span>
            <div className="phone-revarse-body-bg">
                <div className="phone-body ">
                    <div className="flex w-full justify-between  items-center h-[40px] px-3 py-2.5">
                        <span className="text-primary text-[14px] leading-[19.6px] tracking-[-0.01rem]">{t("Cancel")}</span>
                        <span className="text-[14px] leading-[19.6px] font-bold text-txtAppointmentColor tracking-[-0.01rem] truncate-custom">{t("Add to Home Screen")}</span>
                        <span className="text-primary text-[14px] leading-[19.6px] tracking-[-0.01rem]">{t("Add")}</span>
                    </div>
                    <div className="h-[78px] bg-containerBgColor border-t-[0.67px] border-b-[0.67px] border-borderColorPrimary flex  flex-row gap-3 items-center justify-center w-full pl-3 pt-3 pb-3">
                        <div className="flex w-[19%]">
                            <img src={logoImageUrl} className=" rounded-lg w-[42px] h-[42px]" alt="" />
                        </div>
                        <div className="flex flex-col w-[81%]">
                            <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor tracking-[-0.01rem] border-b border-borderColorPrimary pb-1.5">{businessName}</span>
                            <span className="text-[14px] leading-[19.6px] font-normal text-textGrey tracking-[-0.01rem] pt-1.5">{slugValue}</span>
                        </div>
                    </div>
                    <span className="px-3 text-[12px] leading-[18px] font-normal text-textGrey tracking-[-0.01rem]">{t("An icon will be added to your home screen for quick access.")}</span>
                </div>
            </div>
        </div>
    );
};

export default Step4;
