import { Check, Loading02 } from "@untitled-ui/icons-react/build/cjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CustomButton from "src/components/CustomButton";
import { API } from "src/constants/api";
import { CancelationIcon, LoadingRound } from "src/theme/Images";
import { validateAmount } from "src/utils/global-functions";
import { axiosPost } from "src/utils/requestClient";

const Tip = ({ bookingDetails, setIsTip, listBookings, setSuccess }: any) => {
    const { t } = useTranslation();
    const [selectedTip, setSelectedTip] = useState<any>(null);
    const [customTip, setCustomTip] = useState("");
    const [isCustomEnabled, setIsCustomEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isInputShow, setIsInputShow] = useState(false);

    const handleTipChange = (value: any) => (e: any) => {
        e.stopPropagation();
        setSelectedTip((prevSelected: any) => (prevSelected === value ? null : value));
    };

    const handleSubmit = () => {
        setIsLoading(true);

        const params = {
            id: bookingDetails.id,
            shop_id: bookingDetails.shop.id,
        };
        const payload = {
            is_tip: true,
            tip: selectedTip && selectedTip === "custom" ? Number(customTip) : selectedTip ? Number(selectedTip) : "",
        };

        const handleBookingList = async () => {
            await listBookings("past");
        };

        axiosPost(API.BOOKING.TIP, payload, params)
            .then((response) => {
                setSuccess({ id: bookingDetails.id, status: true });
                setIsTip(false);
                setTimeout(() => {
                    if (listBookings) {
                        handleBookingList();
                    }
                }, 2000);
            })
            .catch((error) => {
                const response = error?.response?.data;
                toast.error(
                    <span className="text-toastTextColor font-medium text-[14px] leading-[20px] line-truncate pr-2 py-1 my-1 font-primary">
                        {response?.message ?? "Something went wrong, Please try again"}
                    </span>,
                    {
                        icon: <img src={CancelationIcon} className="h-[38px] w-[38px] min-w-[38px] ml-[2px]" alt="" />,
                    },
                );
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div onClick={(e: any) => e.stopPropagation()} className="flex items-center justify-between w-full">
            <div className="flex gap-1.5 items-center flex-row">
                {bookingDetails.location.tips.map((tip: string, index: number) => (
                    <button
                        key={tip}
                        className={`flex items-center justify-center h-[28px] min-w-[40px] border border-uploadProfileBorder max-w-max text-textMoreGrey text-[12px] font-semibold leading-[20px] w-full lg:hover:text-primary lg:hover:border-primary rounded-[6px] cursor-pointer ${
                            selectedTip === Number(tip) ? "!text-primary !border-primary" : ""
                        }`}
                        onClick={handleTipChange(Number(tip))}
                    >
                        {bookingDetails.location.tips_type === "value" && "$"}
                        {tip}
                        {bookingDetails.location.tips_type === "percentage" && "%"}
                    </button>
                ))}
                {selectedTip === "custom" || isInputShow ? (
                    <div
                        className={`relative flex items-center justify-center h-[28px] min-w-[40px] border max-w-max text-[12px] font-semibold leading-[20px] rounded-[6px] cursor-pointer pr-1 pl-2 focus:!text-primary focus:border focus:!border-primary hover:!text-primary hover:border hover:!border-primary active:!text-primary active:border active:!border-primary focus-within:!text-primary focus-within:border focus-within:!border-primary ${
                            selectedTip === "custom" ? "!text-primary !border-primary" : "text-textMoreGrey border-uploadProfileBorder"
                        } `}
                    >
                        <span className={`cursor-pointer text-[12px] font-semibold leading-[21px]`}>{bookingDetails.location.tips_type === "value" ? "$" : "%"}</span>
                        <input
                            className={`py-0 !border-0 focus:ring-0 !focus:outline-none outline-none placeholder:text-textMoreGrey placeholder:text-[12px] bg-transparent placeholder:font-semibold -tracking-[0.01em] placeholder:-tracking-[0.01em] h-full px-0 pl-1 text-primary text-[12px] font-semibold leading-[20px] min-w-[40px] w-[30px] pr-[2px] [max-width:50px!important]`}
                            type="number"
                            name="tip"
                            value={customTip}
                            // onChange={(e) => {
                            //     setIsCustomEnabled(false);
                            //     let value = e.target.value;
                            //     const numberPattern = /^[+]?\d+$/;
                            //     if (!value) {
                            //         setCustomTip("");
                            //     }
                            //     if (numberPattern.test(value)) {
                            //         setCustomTip(e.target.value);
                            //     }
                            // }}
                            onChange={(e: any) => {
                                setIsCustomEnabled(false);
                                let amount = e.target.value;
                                amount = validateAmount(amount, bookingDetails.location.tips_type === "percentage");
                                if (!amount) {
                                    setCustomTip("");
                                }
                                if (amount) {
                                    setCustomTip(amount);
                                }
                            }}
                            placeholder="00.00"
                            autoFocus={true}
                        />
                        {!isCustomEnabled && (
                            <span
                                className="cursor-pointer"
                                onClick={() => {
                                    if (!!customTip) {
                                        setIsCustomEnabled(true);
                                        setIsInputShow(true);
                                        setSelectedTip("custom");
                                    }
                                }}
                            >
                                <label className="flex items-center cursor-pointer">
                                    <div className={`h-[20px] w-[20px] flex justify-center items-center min-w-[20px] rounded-[3.33px] bg-primary`}>
                                        <Check className="text-primaryBrandText w-[16px]" />
                                    </div>
                                </label>
                            </span>
                        )}
                    </div>
                ) : (
                    <button
                        key="custom"
                        className={`flex items-center justify-center h-[28px] min-w-[40px] border border-uploadProfileBorder max-w-max text-textMoreGrey text-[12px] font-semibold leading-[20px] w-full lg:hover:text-primary lg:hover:border-primary rounded-minimal px-2 cursor-pointer ${
                            selectedTip === "custom" ? "!text-primary !border-primary" : ""
                        }`}
                        onClick={handleTipChange("custom")}
                    >
                        {t("Custom")}
                    </button>
                )}
            </div>
            {/* <CustomButton
                primary
                className="w-full !h-[28px] !rounded-minimal !px-2 !max-w-max !text-[12px] !font-semibold flex-1 justify-end ml-auto"
                onClick={handleSubmit}
                isLoading={isLoading }
                disabled={isLoading || (selectedTip === "custom" ? !isCustomEnabled || !!!customTip : !selectedTip)}
            >
                {t("Add Tip")}
            </CustomButton> */}

            <button
                className={`flex  !transition-all -tracking-[0.01rem] md:hover:bg-brandColorLightDark text-primaryBrandText CustomButtonShadow bg-primary w-full !h-[28px] !rounded-minimal !px-2 !max-w-max !text-[12px] !font-semibold flex-1 items-center justify-center ml-auto min-w-[62px] ${
                    (selectedTip === "custom" ? !isCustomEnabled || !!!customTip : !selectedTip)
                        ? "border border-borderColorPrimary !bg-disabledButtonColor text-textGrey !transition-all font-bold text-[16px] leading-[24px] CustomButtonShadow"
                        : ""
                }`}
                onClick={handleSubmit}
                disabled={isLoading || (selectedTip === "custom" ? !isCustomEnabled || !!!customTip : !selectedTip)}
            >
                {isLoading ? <Loading02 className="w-[18px] h-[18px] loading_02 text-primaryBrandText" /> : t("Add Tip")}
            </button>
        </div>
    );
};

export default Tip;
