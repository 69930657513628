import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import WInputPasswordWithLabel from "src/components/InputPasswordWithLabel/WInputPasswordWithLabel";
import { IUpdatePassword } from "../Profile.interface";
import { CheckCircle } from "@untitled-ui/icons-react/build/cjs";
import { exceptValidation } from "src/utils/global-functions";

const WUpdatePassword: FC<IUpdatePassword> = ({ handleModalClose, passwordChange, handleValidation, isLoading, validationsPassed }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return passwordChange ? (
        <div className="model-inner-wrapper font-primary py-[16px]">
            <div className="flex flex-col w-full justify-center ">
                <div className="flex flex-col w-full justify-center text-center max-w-[280px] mx-auto">
                    <span className="bg-[#DCFAE6] mb-4 text-[14px] mx-auto leading-[20px] h-[48px] min-w-[48px] w-[48px] rounded-full flex items-center justify-center">
                        <CheckCircle className="text-[#079455] w-5 h-5" />
                    </span>
                    <span className="text-TextColor font-semibold text-[18px] leading-[28px] mb-1">{t("New Password Updated")}</span>
                    <span className="text-textMoreGrey font-normal text-[14px] leading-[20px]">{t("Your password has been updated successfully.")}</span>
                </div>
                <CustomButton primary onClick={() => handleModalClose()} className={`w-full mt-5 !h-[48px]`}>
                    <span>{t("Continue")}</span>
                </CustomButton>
            </div>
        </div>
    ) : (
        <div className="content font-primary model-inner-wrapper">
            <h5 className="login-model-title">{t("Change Password")}</h5>
            <p className="login-model-subtitle flex flex-col">{t("password_description")}</p>
            <div className="flex flex-wrap mt-[42px]">
                <Controller
                    name="old_password"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-full mb-4">
                            <WInputPasswordWithLabel
                                required
                                name="old_password"
                                label={t("Current Password")}
                                placeholder={t("Enter your current password")}
                                value={value}
                                onChange={onChange}
                                inputPasswordClass="!h-[44px]"
                                error={!!error}
                            />
                            {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-full mb-4">
                            <WInputPasswordWithLabel
                                name="password"
                                required
                                label={t("New Password")}
                                placeholder={t("Enter your new password")}
                                value={value}
                                onChange={(e: { target: { value: string } }) => {
                                    onChange(e);
                                    handleValidation(e.target.value);
                                }}
                                inputPasswordClass="!h-[44px]"
                                error={!!error}
                            />
                            {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-full">
                            <WInputPasswordWithLabel
                                name="password_confirmation"
                                label={t("Confirm Password")}
                                required
                                placeholder={t("Enter your confirm password")}
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                inputPasswordClass="!h-[44px]"
                            />
                            {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
            </div>
            <div className="mt-[52px]">
                <CustomButton className={`w-full !h-[48px]`} primary isLoading={isLoading} disabled={isLoading || validationsPassed === true ? false : true} type="submit">
                    {t("Password Updated")}
                </CustomButton>
            </div>
        </div>
    );
};

export default WUpdatePassword;
