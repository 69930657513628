import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { ConfigationsImg } from "src/theme/Images";

const NotConfigured = () => {
    const { t } = useTranslation();
    const { navigateBasedOnShopLocations } = useShopNavigation();
    return (
        <div className="w-full h-full flex-1 flex justify-center items-center flex-col bg-mainBackgroundColor">
            {/* <div className="text-3xl ">Page Not Found</div>
        <div className="text-lg">The requested page could not be found. Please check the URL and try again.</div> */}
            <div className="w-[90%] max-auto md:w-[500px] md:h-[500px] flex rounded-[32px] p-[32px] items-center justify-center bg-containerBgColor shadow-[0px_5.33px_16px_0px_#928C971A]">
                <div className="flex flex-col">
                    <div className="w-full mb-[32px] flex justify-center">
                        <img src={ConfigationsImg} alt="" className="" />
                    </div>
                    <div className="flex flex-col mb-[32px] text-center">
                        <h1 className="text-txtAppointmentColor leading-[28.8px] font-semibold text-[24px] tracking-[-0.04rem] mb-2">{t("Settings are incomplete.")}</h1>
                        <span className="text-textGrey font-normal text-[16px] leading-[22.4px] tracking-[-0.01rem]">{t("Set up the landing page.")}</span>
                    </div>
                    <CustomButton primary className="w-[235px] mx-auto" onClick={() => navigateBasedOnShopLocations()}>
                        {t("Book Now")}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default NotConfigured;
