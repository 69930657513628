import React, { FC, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, Resolver, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IChangePasswordProps, IFieldKey, IPasswordField } from "./Password.interface";
import { errorCode } from "src/constants/common";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { useAppSelector } from "src/redux/hooks";
import WNewPassword from "./Web/WNewPassword";
import MNewPassword from "./Mobile/MNewPassword";

const NewPassword: FC<IChangePasswordProps> = ({ authData, handleAuthAction }) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [loading, setLoading] = useState(false);
    const schema = Yup.object({
        password: Yup.string()
            .required(t("This field is required"))
            .min(8, t("Password must be at least 8 characters long."))
            .matches(/(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/, t("Password must contain at least one special character."))
            .matches(/(?=.*\d)/, t("Password must contain at least one number."))
            .matches(/(?=.*[A-Z])/, t("Password must contain at least one uppercase letter."))
            .matches(/(?=.*[a-z])/, t("Password must contain at least one lowercase letter.")),
        password_confirmation: Yup.string()
            .required(t("This field is required"))
            .oneOf([Yup.ref("password")], t("Passwords do not match")),
    }).required();

    const methods = useForm<IPasswordField>({
        resolver: yupResolver(schema) as Resolver<IPasswordField>,
        defaultValues: {
            old_password: "",
            password: "",
            password_confirmation: "",
        },
    });

    const { handleSubmit, setError, reset } = methods;

    const [validations, setValidations] = useState({
        minLength: false,
        hasDigit: false,
        hasNonDigit: false,
    });

    const handleValidation = (value: string) => {
        setValidations({
            minLength: value.length >= 8,
            hasDigit: /\d/.test(value),
            hasNonDigit: /\D/.test(value),
        });
    };
    const validationsPassed = validations.minLength && validations.hasDigit && validations.hasNonDigit;

    const handleSave = (data: IPasswordField) => {
        setLoading(true);
        const payload = {
            ...(authData.isEmail ? { email: authData.email } : { phone: `+${authData.phone.code}${authData.phone.number}` }),
            password: data.password,
            password_confirmation: data.password_confirmation,
            token: authData.token,
        };
        axiosPost(API.USER.NEW_PASSWORD, payload)
            .then(() => {
                reset();
                handleAuthAction("login");
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as IFieldKey, {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        return;
                    }
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSave)}>
                {uiState.isTablet ? (
                    <WNewPassword loading={loading} forgotPasswordStep={true} handleValidation={handleValidation} validationsPassed={validationsPassed} />
                ) : (
                    <MNewPassword loading={loading} forgotPasswordStep={true} handleValidation={handleValidation} validationsPassed={validationsPassed} />
                )}
            </form>
        </FormProvider>
    );
};

export default NewPassword;
