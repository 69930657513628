import React, { useRef, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import StepProgressBar from "./StepProgressBar";
import CustomButton from "src/components/CustomButton";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/redux/hooks";
import { getBookingInfo } from "src/app/BookAppointment/Booking.slice";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
interface IDrawerProps {
    handleClose: () => void;
}

const DownloadApp = ({ handleClose }: IDrawerProps) => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState<number>(1);
    const bookingInfo = useAppSelector(getBookingInfo);
    const totalSteps = 6;
    const swiperRef: any = useRef(null);

    const handleSwiper = (swiper: any) => {
        swiperRef.current = swiper;
    };

    const onSlideChange = () => {
        if (swiperRef.current) {
            if (swiperRef.current.realIndex !== undefined) {
                setActiveStep(swiperRef.current.realIndex + 1);
            }
        }
    };

    const handleActiveStep = () => {
        if (activeStep < totalSteps) {
            swiperRef.current?.slideNext();
        } else {
            handleClose();
        }
    };

    const handleAppClose = () => {
        if (activeStep > 1) {
            swiperRef.current?.slidePrev();
        } else {
            handleClose();
        }
    };

    return (
        <div className={`w-full font-primary px-4 pt-[8px] pb-[24px] flex flex-col sms:max-w-[375px] mx-auto bg-cardBackgroundColor overflow-y-auto scrollbar-hide appSwiper `}>
            <div className="flex flex-col">
                <h3 className="text-txtAppointmentColor font-bold text-[16px] leading-[22.4px] tracking-[-0.01em] mb-2">Get the app</h3>
                <div className="pt-3 pb-6">
                    <StepProgressBar steps={Array.from({ length: totalSteps }, (_, i) => i + 1)} currentStep={activeStep} />
                </div>
            </div>
            <Swiper ref={swiperRef} onSwiper={handleSwiper} loop className="mySwiper" onSlideChange={onSlideChange}>
                <SwiperSlide>
                    {" "}
                    <Step1 />
                </SwiperSlide>
                <SwiperSlide>
                    {" "}
                    <Step2 />
                </SwiperSlide>
                <SwiperSlide>
                    {" "}
                    <Step3 />
                </SwiperSlide>
                <SwiperSlide>
                    {" "}
                    <Step4 />
                </SwiperSlide>
                <SwiperSlide>
                    {" "}
                    <Step5 />
                </SwiperSlide>
                <SwiperSlide>
                    {" "}
                    <Step6 />
                </SwiperSlide>
            </Swiper>

            <div className={`items-center gap-4 flex flex-row w-full mt-[24px] ${bookingInfo.isFullScreen ? "pb-[24px]" : ""}`}>
                <CustomButton secondary onClick={handleAppClose} className={`w-full`} type="submit">
                    {activeStep === 1 ? t("Close") : t("Back")}
                </CustomButton>
                <CustomButton primary onClick={handleActiveStep} className={`w-full`} type="submit">
                    {totalSteps === activeStep ? t("Close") : t("Next")}
                </CustomButton>
            </div>
        </div>
    );
};

export default DownloadApp;
