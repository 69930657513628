import React, { FC } from "react";
import { IOTPField } from "./OTPField.interface";
import { InputOtp } from "primereact/inputotp";

const OTPField: FC<IOTPField> = ({ otp, setOtp, errors }): JSX.Element => {
    const customInput = ({ events, props }: any) => (
        <input
            key={props.id}
            {...events}
            {...props}
            type="text"
            className={`flex items-center justify-center w-[70px] h-[70px] sm:w-[80px] sm:h-[80px] rounded-secondary bg-transparent transition text-txtAppointmentColor font-medium text-center !p-0 !focus:outline-0 !focus:ring-0 CustomButtonShadow text-[32px] leading-[60px] ${
                errors
                    ? "!is-invalid border !border-danger focus:border-danger !focus:outline-0 !focus:ring-0"
                    : "!focus:outline-0 !focus:ring-0  focus:border-primary bg-transparent border border-borderColorSecondary"
            }`}
            autoFocus={props.id === 0}
        />
    );

    return (
        <InputOtp
            value={otp}
            onChange={(e) => setOtp(e.value)}
            integerOnly
            className=""
            unstyled
            inputTemplate={customInput}
            pt={{
                root: { className: "flex justify-center items-center gap-4 w-full max-md:justify-between" },
            }}
        />
    );
};

export default OTPField;
