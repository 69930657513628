import React, { useEffect, useState } from "react";
import DateSelectButton from "../../../../components/DateSelectButton";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import RangeCalnedarComponent from "./RangeCalnedarComponent";
import { format } from "date-fns";
import { axiosPost } from "../../../../utils/requestClient";
import { API } from "../../../../constants/api";
import { capitalizeFirstLetter, onError } from "../../../../utils/global-functions";
import { getBookingInfo, setSelectedBookingDate, setTempStore, shopSettings } from "../../Booking.slice";
import { ISpecialist, ISpecialistService, IWorkingHour } from "../../../../Interface";
import "./datepicker_mobile.scss";
import { useFirstRender } from "../../../../hooks/useFirstRender";
import { useTranslation } from "react-i18next";
import { BiSun } from "react-icons/bi";
import { BsFillSunsetFill, BsMoonFill } from "react-icons/bs";
import moment from "moment";
import ManageSubmit from "../../AppointmentPreview/ManageSubmit";
import { animated, config, useSpring, useTransition } from "react-spring";
import { Spinner } from "flowbite-react";
import { Calendar, User01 } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";
import { PATH } from "src/constants/path";
import { BookingTABS } from "../../bookAppointment.interface";
import { useNavigate } from "react-router-dom";
import { WeekDays } from "src/utils/global-variables";

interface IProps {
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
    selectedTimeSlot: string | undefined;
    setSelectedTimeSlot: React.Dispatch<React.SetStateAction<string | undefined>>;
    closeCurrentTab: () => void;
}
interface IPeriods {
    name: "all" | "morning" | "afternoon" | "evening";
    label: string;
    start: string;
    end: string;
    icon?: React.ReactNode;
}

const DateTimePick = ({ selectedDate, setSelectedDate, selectedTimeSlot, setSelectedTimeSlot }: IProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const initPeriods: IPeriods[] = [
        { name: "all", label: t("All"), start: "00:00", end: "23:59" },
        { name: "morning", label: t("Morning"), start: "06:00", end: "11:59", icon: <BiSun className="w-4 h-4 mr-1.5" /> },
        { name: "afternoon", label: t("Afternoon"), start: "12:00", end: "16:59", icon: <BsFillSunsetFill className="w-4 h-4 mr-1.5" /> },
        { name: "evening", label: t("Evening"), start: "17:00", end: "23:59", icon: <BsMoonFill className="w-3 h-3 mr-1.5" /> },
    ];
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [slots, setSlots] = useState([]);
    const [selectedPeriod, setSelectedPeriod] = useState<IPeriods>(initPeriods[0]);
    const [workingDays, setWorkingDays] = useState<string[]>([]);
    const seletedDateFirstRender = useFirstRender({ selectedDate });
    const [periods, setPeriods] = useState<IPeriods[]>(initPeriods);
    const [filteredSlots, setFilteredSlots] = useState<string[]>([]);
    const [animationCompletedCount, setAnimationCompletedCount] = useState(0);
    const [isTimeSlotLoading, setIsTimeSlotLoading] = useState(false);
    const [isShopWorkingHoursLoading, setIsShopWorkingHoursLoading] = useState(false);
    const [slotsAvailability, setSlotsAvailability] = useState("");
    const [nextAvailableDate, setnextAvailabletDate] = useState<Date | string>("");
    const bookingShopSettings = useAppSelector(shopSettings);
    const [maxDate, setMaxDate] = useState<Date | null>(null);

    useEffect(() => {
        setAnimationCompletedCount(0);
    }, [filteredSlots]);

    useEffect(() => {
        if (bookingShopSettings.calendar.advance_weeks) {
            const maxdate = new Date();
            maxdate.setDate(maxdate.getDate() + bookingShopSettings.calendar.advance_weeks * 7);
            setMaxDate(maxdate);
        }
    }, [bookingShopSettings.calendar]);

    const transitions = useTransition(filteredSlots, {
        keys: (item) => item,
        from: { opacity: 0, transform: "translateY(20px)" },
        enter: (item, index) => ({
            opacity: 1,
            transform: "translateY(0)",
            delay: uiState.isMobile ? index * 20 : index * 50,
        }),
        config: { tension: 220, friction: 20 },
        onStart: () => {
            setAnimationCompletedCount(0);
        },
        onRest: (item, phase) => {
            setAnimationCompletedCount((prevCount: any) => prevCount + 1);

            if (animationCompletedCount + 1 === filteredSlots.length) {
                setFilteredSlots([]);
            }
        },
    });

    useEffect(() => {
        if (!bookingState.selectedSpecialist) {
            listStaff();
        }
    }, []);

    useEffect(() => {
        manageWorkingHours();
    }, [bookingState.selectedSpecialist]);

    const manageWorkingHours = async () => {
        setIsShopWorkingHoursLoading(true);
        const workingDaysArray = bookingState.selectedSpecialist?.working_hours
            ? bookingState.selectedSpecialist?.working_hours.map((workingHour: IWorkingHour) => (workingHour.status ? workingHour.day : false))
            : [];
        setWorkingDays(workingDaysArray.filter((f) => f) as string[]);
        setIsShopWorkingHoursLoading(false);
    };

    useEffect(() => {
        setSlots([]);
        setFilteredSlots([]);
        listSlots();
        if (seletedDateFirstRender === false) {
            setSelectedTimeSlot(undefined);
            dispatch(setTempStore({ selectedTimeSlot: undefined, selectedBookingDateTime: undefined }));
        }

        if (format(selectedDate, "yyyy-MM-dd") === bookingState.selectedBookingDate) {
            setSelectedTimeSlot(bookingState.selectedBookingTime ?? "");
            dispatch(setTempStore({ selectedBookingTime: bookingState.selectedBookingTime ?? undefined }));

            if (bookingState.selectedBookingTime) {
                dispatch(setTempStore({ selectedBookingDateTime: `${format(selectedDate, "Y-MM-dd")} ${bookingState.selectedBookingTime}` }));
            }
        }
        dispatch(setTempStore({ selectedBookingDate: selectedDate ? format(selectedDate, "Y-MM-dd") : "un" }));
    }, [selectedDate]);

    useEffect(() => {
        if (slots.length) {
            const activePeriods = new Set();
            slots.forEach((time) => {
                const momentTime = moment(time, "HH:mm");
                initPeriods.forEach(({ name, start, end }) => {
                    if (momentTime.isBetween(moment(start, "HH:mm"), moment(end, "HH:mm"))) {
                        activePeriods.add(name);
                    }
                });
            });
            const filteredPeriods = initPeriods.filter((period) => activePeriods.has(period.name));
            setPeriods(filteredPeriods);
        }
    }, [slots]);

    useEffect(() => {
        if (slots.length) {
            const filterNew: string[] = slots.filter((time: moment.MomentInput) =>
                moment(time, "HH:mm").isBetween(moment(selectedPeriod.start, "HH:mm"), moment(selectedPeriod.end, "HH:mm"), undefined, "[]"),
            );
            setFilteredSlots(filterNew);
        }
    }, [slots, selectedPeriod]);

    const handleOnclickTimeSlot = (slot: string | undefined) => () => {
        dispatch(setTempStore({ selectedBookingTime: slot }));
        dispatch(setTempStore({ selectedBookingDateTime: bookingState.tempStore.selectedBookingDate && slot ? `${bookingState.tempStore.selectedBookingDate} ${slot}` : undefined }));
        setSelectedTimeSlot(slot);
    };

    const listStaff = () => {
        setIsShopWorkingHoursLoading(true);
        const serviceIds = bookingState.selectedServices?.map((service: ISpecialistService) => service.id);
        const payload = {
            service_id: serviceIds.length ? serviceIds : undefined,
            booking_date: bookingState.selectedBookingDate,
            booking_time: bookingState.selectedBookingTime ? `${bookingState.selectedBookingTime}:00` : undefined,
        };
        const params = {
            shop_id: bookingState.selectedShopLocation?.shop_id,
            location_id: bookingState.selectedShopLocation?.id,
        };
        axiosPost(API.STAFF.LIST, payload, params)
            .then((response) => {
                const allSpecialists = response.data.data;
                const workingDaysArray: string[] = [];
                allSpecialists.forEach((specialistData: ISpecialist) => {
                    if (specialistData?.working_hours) {
                        specialistData?.working_hours.forEach((workingHour: IWorkingHour) => (workingHour.status ? workingDaysArray.push(workingHour.day) : false));
                    }
                });
                setWorkingDays(workingDaysArray.filter((item, index) => workingDaysArray.indexOf(item) === index));
            })
            .finally(() => {
                setIsShopWorkingHoursLoading(false);
            });
    };

    const listSlots = () => {
        setIsTimeSlotLoading(true);
        const payload = {
            booking_date: format(selectedDate, "yyyy-MM-dd"),
            booking_id: bookingState.modifyingAppointmentId ? bookingState.modifyingAppointmentId : null,
            staff_id: bookingState.selectedSpecialist?.id,
            services: bookingState.selectedServices.length
                ? bookingState.selectedServices.map((service) => {
                      const servicePayloadElm = {
                          id: service.id,
                          quantity: service.quantity,
                      };
                      return servicePayloadElm;
                  })
                : undefined,
        };
        const params = {
            shop_id: bookingState.selectedShopLocation?.shop_id,
            location_id: bookingState.selectedShopLocation?.id,
        };
        axiosPost(API.BOOKING.SLOTS, payload, params)
            .then((response) => {
                const responseSlot = response.data.data;
                const workingDaysArray = bookingState.selectedSpecialist?.working_hours
                    ? bookingState.selectedSpecialist?.working_hours.map((workingHour: IWorkingHour) => (workingHour.status ? workingHour.day : false))
                    : [];
                const isStaffWork = workingDaysArray.includes(format(selectedDate, "EEEE").toLocaleLowerCase());
                const isBeforeToday = moment(selectedDate).isBefore(moment().startOf("day"));

                if (isBeforeToday) {
                    setSlotsAvailability("past");
                } else if (responseSlot.length === 0) {
                    setSlotsAvailability("not working");
                } else if (isStaffWork && responseSlot.length === 0) {
                    setSlotsAvailability("booked");
                }
                setSlots(response.data.data);
            })
            .finally(() => {
                setTimeout(() => {
                    setIsTimeSlotLoading(false);
                }, 500);
            });
    };

    const handleNextWorkingDate = (isSetDate: boolean) => {
        const isBeforeToday = moment(selectedDate).isBefore(moment().startOf("day"));
        if (isBeforeToday) {
            if (isSetDate) {
                setSelectedDate(new Date());
                return;
            }
        }
        const currentDayName = moment(selectedDate).format("dddd").toLowerCase();
        const currentIndex = WeekDays.indexOf(currentDayName);

        if (currentIndex === -1) {
            return false;
        }

        const yIndices = workingDays.map((day) => WeekDays.indexOf(day.toLowerCase())).filter((index) => index !== -1);

        let nextDayIndex = yIndices.find((index) => index > currentIndex);

        if (nextDayIndex === undefined) {
            nextDayIndex = yIndices[0];
        }
        const nextDayName = WeekDays[nextDayIndex];
        let nextWorkingDayDate = moment(selectedDate).add(1, "days");
        let loopCounter = 0;

        while (nextWorkingDayDate.format("dddd").toLowerCase() !== nextDayName && loopCounter < 7) {
            nextWorkingDayDate.add(1, "days");
            loopCounter++;
        }
        if (isSetDate) {
            setSelectedDate(nextWorkingDayDate.toDate());
        } else {
            setnextAvailabletDate(nextWorkingDayDate.toDate());
        }
    };

    useEffect(() => {
        const isBeforeToday = moment(selectedDate).isBefore(moment().startOf("day"));
        if (isBeforeToday) {
            setnextAvailabletDate(new Date());
        } else if (!isShopWorkingHoursLoading && workingDays.length > 0) {
            handleNextWorkingDate(false);
        }
    }, [isShopWorkingHoursLoading, workingDays, selectedDate]);

    const formatTimeTo12Hour = (time24: any) => {
        const [hours, minutes] = time24.split(":").map(Number);
        const period = hours >= 12 ? "PM" : "AM";
        const adjustedHours = hours % 12 || 12;
        return `${adjustedHours}:${minutes.toString().padStart(2, "0")} ${period}`;
    };

    const PeriodStyle = useSpring({
        from: { opacity: 0, transform: "translateY(-20px)" },
        to: { opacity: 1, transform: "translateY(0)" },
        config: config.stiff,
    });

    const renderAvailabilityMessage = () => {
        let text = "";
        if (slotsAvailability === "booked") {
            text = t("Fully booked on this date.");
        } else if (slotsAvailability === "not working" || slotsAvailability === "past") {
            if (bookingState.selectedSpecialist) {
                text = `${t("specialistName is not working on this date.", { specialistName: capitalizeFirstLetter(bookingState.selectedSpecialist.first_name) })}`;
            } else {
                text = t("Staff is not working on this date.");
            }
        } else {
            return null;
        }
        return text;
    };

    return (
        <div className={`flex flex-col w-full overflow-hidden h-full`}>
            <div className="flex-shrink-0">
                {isShopWorkingHoursLoading ? (
                    <Spinner size="xl" className="" />
                ) : (
                    <>
                        <div className="relative flex justify-between max-lg:px-4 z-20 items-center">
                            <RangeCalnedarComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} workingDays={workingDays} maxDate={maxDate} />
                            <div className={`flex-col items-center pt-1 pb-1.5 hidden lg:flex ${uiState.isMobile ? "absolute top-[60px] right-[20px]" : "relative pr-2 pl-1"} `}>
                                <DateSelectButton
                                    className={` ${
                                        uiState.isMobile
                                            ? " "
                                            : " w-[68px] h-[68px] lg:h-[42px] lg:w-[42px] hover:border-borderColorPrimary hover:bg-primaryHover xl:h-[68px] xl:w-[68px] border cursor-pointer border-borderColorPrimary hover: rounded-primary"
                                    } flex justify-center items-center`}
                                    dateFormat="dd/MM/yyyy"
                                    selectedDate={selectedDate}
                                    onChangeFunc={(changedDate: Date) => {
                                        setSelectedDate(new Date(changedDate));
                                        dispatch(setTempStore({ selectedBookingDate: format(new Date(changedDate), "Y-MM-dd") }));
                                        dispatch(setSelectedBookingDate(format(new Date(changedDate), "Y-MM-dd")));
                                    }}
                                    minDate={new Date()}
                                    maxDate={maxDate}
                                    closeOnOutsideClick={true}
                                >
                                    <Calendar className="w-[20px] h-[20px] !text-primaryAppointment" />
                                </DateSelectButton>
                            </div>
                        </div>

                        <animated.div style={PeriodStyle} className="fl-tab-btn-view max-smd:gap-1 w-full lg:mt-[20px] xl:mt-[44px] mt-[18px] px-4 lg:px-2">
                            {periods.map((period) => (
                                <button
                                    key={period.name}
                                    type="button"
                                    onClick={() => setSelectedPeriod(period)}
                                    className={`fl-tab-link  text-txtAppointmentColor border-borderColorPrimary -tracking-[0.03rem] card-shadow-hover  ${
                                        selectedPeriod.name === period.name ? "active" : ""
                                    }`}
                                >
                                    {period.icon && period.icon}

                                    <span className="mt-0 -tracking-[0.04rem]">{period.label}</span>
                                </button>
                            ))}
                        </animated.div>
                    </>
                )}
            </div>
            <div
                className={`${
                    filteredSlots.length === 0 && !isShopWorkingHoursLoading
                        ? "grid grid-cols-1 mt-3 lg:mt-3 max-lg:flex-1 h-full  overflow-auto scrollbar-hide max-md:pb-[50px]"
                        : `grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-4 xl:grid-col-6 relative overflow-auto scrollbar-hide max-lg:px-2  ${
                              isTimeSlotLoading ? "!grid-cols-1 sm:!grid-cols-1 md:!grid-cols-1 lg:!grid-cols-1 w-full mt-3 lg:mt-3" : " mt-5 lg:mt-5 xl:mt-7 pb-[58px]"
                          }`
                }`}
            >
                <>
                    {isTimeSlotLoading ? (
                        <div className="custom_loading_wrapper">
                            <div className="custom_loading"></div>
                        </div>
                    ) : !!filteredSlots.length && !isShopWorkingHoursLoading ? (
                        transitions((style, item) => (
                            <animated.div style={style} key={item} className={`p-2`}>
                                <div
                                    onClick={handleOnclickTimeSlot(item === selectedTimeSlot ? undefined : item)}
                                    className={`cursor-pointer ${
                                        selectedTimeSlot === item
                                            ? "border border-primaryBorderHover lg:hover:!border-primaryBorderHover max-lg:shadow-[0px_2px_12px_0px_#00000014] bg-primaryHover text-primaryAppointment font-normal max-lghover:!text-primary"
                                            : "border border-borderColorPrimary text-textGrey"
                                    } font-normal px-1 text-center py-3 transition-all ease-in-out lg:hover:text-txtAppointmentColor -tracking-[0.02rem] date-and-time-shadow md:hover:border-borderColorPrimary rounded-secondary text-sm xl:text-base`}
                                >
                                    {formatTimeTo12Hour(item)}
                                </div>
                            </animated.div>
                        ))
                    ) : (
                        !isShopWorkingHoursLoading && (
                            <div className={`flex justify-center items-center w-full h-full min-h-[280px] flex-col`}>
                                <div className="flex flex-col items-center m-auto gap-4">
                                    {bookingState.selectedSpecialist ? (
                                        <span className="bg-uploadProfileBg flex justify-center items-center hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] text-primaryAppointment font-medium h-[40px] w-[40px] rounded-minimal">
                                            <img src={bookingState.selectedSpecialist.profile_image_url} className="rounded-minimal w-full h-full " alt="" onError={onError("Professional")} />
                                        </span>
                                    ) : (
                                        <span className="bg-uploadProfileBg flex justify-center items-center text-primaryAppointment font-medium h-[40px] w-[40px] rounded-secondary">
                                            <User01 className="text-textGrey w-[20px] h-[20px]" />
                                        </span>
                                    )}
                                    <div className="flex flex-col gap-1 text-center justify-center">
                                        <span className="text-[18px] text-TextColor font-semibold leading-[24px] tracking-[-0.01em]">{renderAvailabilityMessage()}</span>
                                        <span className="text-textGrey leading-[18px] font-normal text-[14px] tracking-[-0.01em] mt-0.5 text-center">{`${t("Next availability on")} ${moment(
                                            nextAvailableDate,
                                        ).format("ddd,MMM DD")}`}</span>
                                    </div>
                                    <div className="mt-2 flex flex-col gap-3">
                                        <CustomButton secondary className="!h-[36px] !font-medium !px-4" onClick={() => handleNextWorkingDate(true)}>
                                            {t("Go to next available date")}
                                        </CustomButton>
                                        <CustomButton secondary className="!h-[36px] !font-medium !px-4" onClick={() => navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SPECIALIST}`)}>
                                            {t("Select another specialist")}
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </>
            </div>
            {uiState.isMobile && <ManageSubmit />}
        </div>
    );
};

export default DateTimePick;
