import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { calculateTotalTaxes, formatAmount } from "../../../../utils/global-functions";
import { useTranslation } from "react-i18next";
import { getBookingInfo, setSelectedPromocode, shopSettings } from "../../Booking.slice";
import { EBookingTabState } from "../../bookAppointment.interface";
import { allShopProducts, currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { IoClose } from "react-icons/io5";
import { OverlayPanel } from "primereact/overlaypanel";
import Promotion from "../Promotion";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { Minus, Plus } from "@untitled-ui/icons-react/build/cjs";

const BasketCalculation = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const bookingState = useAppSelector(getBookingInfo);
    const [servicesSubTotalPrice, setServicesSubTotalPrice] = useState("");
    const [servicesSubTotalTax, setServicesSubTotalTax] = useState("0.00");
    const [productSubTotalPrice, setProductSubTotalPrice] = useState("0.00");
    const [discountedServicesSubTotal, setDiscountedServicesSubTotal] = useState<number>(0.0);
    const [isPromotionShow, setIsPromotionShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [promotions, setPromotions] = useState([]);
    const bookingShopSettings = useAppSelector(shopSettings);
    const productList = useAppSelector(allShopProducts);
    const op = useRef<any>(null);
    const user = useAppSelector(currentUser);

    useEffect(() => {
        if (isPromotionShow && user) {
            listPromotions();
        } else {
            setPromotions([]);
        }
    }, [isPromotionShow, user]);

    useEffect(() => {
        if (
            (bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) ||
            (bookingShopSettings.loyalty.totalAmount >= bookingState.bookingTotal && bookingShopSettings.loyalty.status)
        ) {
            setIsPromotionShow(bookingState.step === EBookingTabState.FINALIZE_BOOKING);
        } else if (productList.length) {
            setIsPromotionShow(bookingState.step === EBookingTabState.PRODUCT_BOOKING);
        } else if (!!bookingState.selectedSpecialist && bookingState.selectedServices?.length > 0) {
            setIsPromotionShow(bookingState.step === EBookingTabState.SELECT_DATE_TIME);
        } else if (!!bookingState.selectedSpecialist && !!bookingState.selectedBookingDate && !!bookingState.selectedBookingTime) {
            setIsPromotionShow(bookingState.step === EBookingTabState.SELECT_SERVICE);
        } else if (bookingState.selectedServices?.length > 0 && !!bookingState.selectedBookingDate && !!bookingState.selectedBookingTime) {
            setIsPromotionShow(bookingState.step === EBookingTabState.SELECT_SPECIALIST);
        } else if (!!bookingState.selectedSpecialist && bookingState.selectedServices?.length > 0 && !!bookingState.selectedBookingDate && !!bookingState.selectedBookingTime) {
            setIsPromotionShow(bookingState.step === EBookingTabState.TAB_MENU);
        }
    }, [bookingState, bookingShopSettings, bookingState.step]);

    useEffect(() => {
        const totalServicesPrices = bookingState.selectedServices.reduce((prevPrice, serviceRecord) => {
            if (serviceRecord.price && serviceRecord.quantity) {
                return prevPrice + Number(serviceRecord.price?.price) * serviceRecord.quantity;
            }
            return prevPrice;
        }, 0);
        let finalPrice = totalServicesPrices;
        if (bookingState?.selectedPromocode) {
            if (bookingState?.selectedPromocode.type === "value") {
                finalPrice -= bookingState?.selectedPromocode.value;
            } else if (bookingState?.selectedPromocode.type === "percentage") {
                finalPrice -= (totalServicesPrices * bookingState?.selectedPromocode.value) / 100;
            }
            setDiscountedServicesSubTotal(finalPrice);
        } else {
            setDiscountedServicesSubTotal(finalPrice);
        }
        const totalProductsPrices = bookingState.selectedProducts.reduce((prevPrice, ProductRecord) => {
            if (ProductRecord?.selectedVariant?.inventory.price) {
                return prevPrice + Number(ProductRecord?.selectedVariant?.inventory.price);
            }
            return prevPrice;
        }, 0);
        setProductSubTotalPrice(totalProductsPrices);
        setServicesSubTotalPrice(totalServicesPrices.toFixed(2));
        if (totalServicesPrices && bookingState.selectedShopLocation?.sales_tax) {
            const subTotalTax = calculateTotalTaxes(
                finalPrice.toFixed(2),
                Number(bookingState.selectedShopLocation?.sales_tax?.gst),
                Number(bookingState.selectedShopLocation?.sales_tax?.hst),
                Number(bookingState.selectedShopLocation?.sales_tax?.pst),
            );
            setServicesSubTotalTax(subTotalTax);
        } else {
            setServicesSubTotalTax("0.00");
        }
    }, [bookingState.selectedServices, bookingState.selectedProducts, bookingState?.selectedPromocode]);

    const handleOverlayPanelClose = () => {
        if (op.current) {
            op.current.hide();
        }
    };

    const handlePromotionRemove = (e: any) => {
        e.stopPropagation();
        dispatch(setSelectedPromocode(undefined));
    };

    const listPromotions = () => {
        setIsLoading(true);
        const params = {
            shop_id: shop.id,
        };
        axiosGet(API.PROMOTION.LIST, {}, params)
            .then((response) => {
                const data = response.data.data;
                setPromotions(data);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div className="mt-auto flex flex-col">
            <div className="flex justify-between mt-3.5 mb-2 border-t border-borderColorPrimary pt-3">
                <h1 className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Subtotal")}</h1>
                <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor -tracking-[0.01rem]">
                    {formatAmount(parseFloat(servicesSubTotalPrice) + parseFloat(productSubTotalPrice))}
                </span>
            </div>
            <div className={`flex justify-between pb-2`}>
                <h1 className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Taxes")}</h1>
                <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor -tracking-[0.01rem]">{formatAmount(servicesSubTotalTax)}</span>
            </div>
            {bookingState.isModifyingAppointment && bookingState?.selectedPromocode ? (
                <div className="flex justify-between mb-3 items-center">
                    <span className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">
                        {bookingState?.selectedPromocode?.name} -{" "}
                        {bookingState?.selectedPromocode.type === "percentage" ? `${bookingState?.selectedPromocode?.value}%` : `$${bookingState?.selectedPromocode?.value}`}
                    </span>
                    <div className="flex justify-between">
                        <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor -tracking-[0.01rem]">
                            - {formatAmount(parseFloat(servicesSubTotalPrice) - discountedServicesSubTotal)}
                        </span>
                    </div>
                </div>
            ) : (
                isPromotionShow &&
                promotions.length > 0 &&
                !bookingState.isModifyingAppointment &&
                bookingShopSettings.refer.status && (
                    <>
                        <div className="flex justify-between pb-3 items-center">
                            {bookingState?.selectedPromocode ? (
                                <div className="flex text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem] items-center">
                                    <span className="">
                                        {bookingState?.selectedPromocode?.name} -{" "}
                                        {bookingState?.selectedPromocode.type === "percentage" ? `${bookingState?.selectedPromocode?.value}%` : `$${bookingState?.selectedPromocode?.value}`}
                                    </span>
                                    <IoClose className="w-4 h-4 ml-2 text-primary cursor-pointer" onClick={handlePromotionRemove} />
                                </div>
                            ) : (
                                <span className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Referral Discount")}</span>
                            )}

                            {bookingState?.selectedPromocode ? (
                                <span className="text-[14px] leading-[19.6px] font-normal text-txtAppointmentColor -tracking-[0.01rem] items-center flex">
                                    <Minus className="w-[13px] h-[16px] mr-[1px] text-txtAppointmentColor hover:text-brandColorLightDark" />
                                    <span className="leading-[15px]">{formatAmount(parseFloat(servicesSubTotalPrice) - discountedServicesSubTotal)}</span>
                                </span>
                            ) : (
                                <span
                                    className="text-sm font-medium leading-[16px] tracking-[-0.01rem] flex text-primaryAppointment lg:hover:text-brandColorLightDark items-center cursor-pointer"
                                    onClick={(e) => {
                                        op.current?.toggle(e);
                                    }}
                                >
                                    <Plus className="w-[16px] h-[15px] mr-0.5" /> <span className="leading-[15px]">{t("Add Promo Code")}</span>
                                </span>
                            )}
                        </div>

                        <OverlayPanel ref={op} showCloseIcon={false} closeOnEscape dismissable className="customOverlayPanel">
                            <Promotion handleOverlayPanelClose={handleOverlayPanelClose} promotions={promotions} isLoading={isLoading} />
                        </OverlayPanel>
                    </>
                )
            )}

            <div className="font-semibold flex justify-between mb-4 lg:mb-2 border-t pt-4 border-borderColorPrimary">
                <h1 className="text-[14px] leading-[19.6px] font-medium text-txtAppointmentColor -tracking-[0.01rem]">{t("Total")}</h1>
                <span className="text-[14px] leading-[19.6px] font-medium text-txtAppointmentColor -tracking-[0.01rem]">
                    {formatAmount(discountedServicesSubTotal + parseFloat(productSubTotalPrice) + parseFloat(servicesSubTotalTax))}
                </span>
            </div>
        </div>
    );
};

export default BasketCalculation;
