import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import MInputPasswordWithLabel from "src/components/InputPasswordWithLabel/MInputPasswordWithLabel";
import { IVerifyPassword } from "../Password.interface";
import { exceptValidation } from "src/utils/global-functions";

const MVerifyPassword: FC<IVerifyPassword> = ({ authData, handleAction, loading }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="mobile-model-inner-wrapper font-primary">
            <div className="mobile-model-inner flex flex-col">
                <span className="login-model-title-mobile">{t("Welcome back with name", { name: authData.name })}</span>
                <div className="w-full mt-[12px]">
                    <div className="flex flex-wrap">
                        <Controller
                            name="password"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="flex flex-col w-full">
                                    <MInputPasswordWithLabel required label={t("Password")} onChange={onChange} value={value.password} name="password" isFocus={true} error={error} />
                                    {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                </div>
                            )}
                        />
                    </div>

                    <div className="mt-[20px] flex justify-center">
                        <span
                            className="text-primary leading-[19.6px] font-semibold text-[14px] flex justify-center items-center cursor-pointer -tracking-[0.01rem]"
                            onClick={handleAction("forgotPassword")}
                        >
                            {t("Forgot your password?")}
                        </span>
                    </div>

                    <div className="mt-[20px] w-full bottom-0 flex flex-col gap-2 mb-4">
                        <CustomButton type="submit" isLoading={loading} disabled={loading} primary className={`!h-[48px] font-bold w-full rounded-secondary transition-all ease-in-out delay-75`}>
                            {"Continue"}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MVerifyPassword;
