import React, { FC, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Stripe, StripeElements, TokenResult } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { errorCode } from "src/constants/common";
import { getBookingInfo, setCardFormVisible, setIsCardLoading, setSubmitTriggered } from "../Booking.slice";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import WCardTabComponent from "./WCardTabComponent";
import MCardTabComponent from "./MCardTabComponent";
import CustomButton from "src/components/CustomButton";
import { ICardTabComponent, IFormData } from "./FinalizeBooking.interface";

const CardTabComponent: FC<ICardTabComponent> = ({ loadStripeCardList, isButtonShow, setIsShowAddNewCardTab }) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const dispatch = useAppDispatch();
    const bookingInfo = useAppSelector(getBookingInfo);
    const stripe: Stripe | null = useStripe();
    const elements: StripeElements | null = useElements();
    const [isCreateCardLoading, setIsCreateCardLoading] = useState(false);

    useEffect(() => {
        if (bookingInfo.submitTriggered && !bookingInfo.isCardAdd) {
            handleSubmit(onSubmit)();
            dispatch(setSubmitTriggered(false));
        }
    }, [bookingInfo.submitTriggered, bookingInfo.isCardAdd]);

    const [cardError, setCardError] = useState("");

    const methods = useForm<IFormData>({
        defaultValues: {
            carddetails: "",
            holdername: "",
        },
    });

    const {
        handleSubmit,
        setError,
        formState: { isValid },
    } = methods;

    const onSubmit = async (data: IFormData) => {
        dispatch(setIsCardLoading(true));
        setIsCreateCardLoading(true);
        const cardElement = elements?.getElement(CardElement);
        let formValid = true;

        if (stripe && cardElement) {
            const { error, token }: TokenResult = await stripe.createToken(cardElement);
            if (error) {
                if (error && error.message) {
                    formValid = false;
                    setCardError(error.message);
                }
            }

            if (!data.holdername || data.holdername === "") {
                setError("holdername", {
                    type: "manual",
                    message: t("This field is required"),
                });
                formValid = false;
            }

            if (isValid && !error && formValid) {
                handleCreateCard(token?.id);
            } else {
                dispatch(setIsCardLoading(false));
                setIsCreateCardLoading(false);
            }
        } else {
            dispatch(setIsCardLoading(false));
            setIsCreateCardLoading(false);
        }
    };

    const handleCreateCard = async (tokenId: string) => {
        setIsCreateCardLoading(true);
        dispatch(setIsCardLoading(true));

        axiosPost(API.STRIPE.CARD_ADD, {
            token: tokenId,
        })
            .then(async (response: any) => {
                if (setIsShowAddNewCardTab) {
                    setIsShowAddNewCardTab(false);
                }
                dispatch(setCardFormVisible(false));
                loadStripeCardList();
                return;
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable || response.status === 402) {
                    setCardError(response.message);
                }
            })
            .finally(() => {
                dispatch(setIsCardLoading(false));
                setIsCreateCardLoading(false);
            });
    };

    const handleCancel = () => {
        if (setIsShowAddNewCardTab) {
            setIsShowAddNewCardTab(false);
            dispatch(setCardFormVisible(false));
        }
    };

    return (
        <FormProvider {...methods}>
            {isButtonShow && (
                <div className="title mb-4 text-txtcolor font-medium justify-between text-2xl lg:mt-7 flex gap-2.5 items-center px-2 max-lg:flex-col shrink-0">
                    <div className="flex flex-col max-lg:px-2 multiline-truncate-wrapper max-lg:w-full flex-1">
                        <h5 className="text-txtcolor font-semibold text-[18px] leading-[21px] md:text-[22px] md:leading-[32px] mb-0.5">{t("Add Payment Method")}</h5>
                        <span className="text-textGrey leading-[18px] font-normal text-[14px] maltiline-truncate line-clamp-1">
                            {t("We require clients to secure bookings with a card on file. Your card will not be charged until service.")}
                        </span>
                    </div>
                    <div className="flex gap-3 max-lg:px-2 justify-end max-lg:w-full">
                        <CustomButton
                            secondary
                            disabled={isCreateCardLoading}
                            className={`lg:!max-w-max max-md:w-full max-lg:!h-[40px] lg:!h-[36px] px-4 text-primary font-semibold capitalize cursor-pointer max-lg:w-full flex items-center text-sm leading-[18.2px]`}
                            onClick={handleCancel}
                        >
                            {t("Cancel")}
                        </CustomButton>
                        <CustomButton
                            primary
                            isLoading={isCreateCardLoading}
                            disabled={isCreateCardLoading}
                            className={`lg:!max-w-max px-4 text-primary max-lg:!h-[40px] max-md:w-full font-semibold cursor-pointer max-lg:w-full flex items-center text-sm leading-[18.2px] lg:!h-[36px]`}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {t("Add Card")}
                        </CustomButton>
                    </div>
                </div>
            )}
            {uiState.isTablet ? (
                <WCardTabComponent setCardError={setCardError} cardError={cardError} isButtonShow={isButtonShow} />
            ) : (
                <MCardTabComponent setCardError={setCardError} cardError={cardError} isButtonShow={isButtonShow} />
            )}
        </FormProvider>
    );
};

export default CardTabComponent;
