import React, { useEffect, useState } from "react";
import moment from "moment";
import { capitalizeFirstLetterAndUnderscore, onError } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";
import { professionalDefaultImg } from "src/theme/Images";
import { GoDotFill } from "react-icons/go";
import { useAppSelector } from "src/redux/hooks";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import Tip from "./Tip";
import { XClose } from "@untitled-ui/icons-react/build/cjs";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { ProgressSpinner } from "primereact/progressspinner";

const BookingDetails = ({
    handleAction,
    bookingDetails,
    CustomClassName,
    key,
    isCardAdded,
    listBookings,
    isTipShow = false,
    setRemoveBookingId,
    success = { id: 0, status: false },
    setSuccess = () => {},
}: any) => {
    const { t } = useTranslation();
    const [isPaymentEnable, setIsPaymentEnable] = useState(false);
    const shop = useAppSelector(currentShop);
    const [isTip, setIsTip] = useState(false);
    const [isTipCancelLoading, setIsTipCancelLoading] = useState(false);

    useEffect(() => {
        if (bookingDetails && bookingDetails.staff?.pay_structure && bookingDetails.staff?.pay_structure.pay_structure_type && bookingDetails.staff.pay_structure.pay_structure_type === "rent") {
            setIsPaymentEnable(bookingDetails.staff.is_verified_stripe_account);
        } else {
            if (shop) {
                setIsPaymentEnable(shop.admin.is_verified_stripe_account);
            }
        }
    }, [bookingDetails]);

    const getServiceProductDes = () => {
        let description = "";

        if (bookingDetails.booking_services && bookingDetails.booking_services.length > 0) {
            if (bookingDetails.booking_services.length > 1) {
                description += `${bookingDetails.booking_services[0].service.name} +${bookingDetails.booking_services.length - 1}, `;
            } else {
                description += `${bookingDetails.booking_services[0].service.name}, `;
            }
        }

        if (bookingDetails.booking_products && bookingDetails.booking_products.length > 0) {
            if (bookingDetails.booking_products.length > 1) {
                description += `${bookingDetails.booking_products[0].product.name} +${bookingDetails.booking_products.length - 1}, `;
            } else {
                if (bookingDetails.booking_products[0].product) {
                    description += `${bookingDetails.booking_products[0].product.name}, `;
                }
            }
        }

        if (description.endsWith(", ")) {
            description = description.slice(0, -2);
        }

        return description;
    };
    const textColorClass =
        bookingDetails.status === "completed"
            ? "text-[#2dce89]"
            : bookingDetails.status === "canceled"
            ? "text-[#E33B3B]"
            : bookingDetails.status === "confirmed"
            ? "text-[#6172f3]"
            : "text-[#FDB022]";

    const formatDate = (date: string, time: string, currentDate = moment()) => {
        const dateTime = moment(`${date} ${time}`);
        const day = dateTime.format("Do");
        const dayNumber = dateTime.format("D");
        const suffix = day.slice(dayNumber.length);
        const inputDateTime = moment(`${date}`, "YYYY-MM-DD");
        const bookingStartTime = moment(time, "h:mm A").format("[at] h:mmA");

        const today = moment(currentDate).startOf("day");
        const tomorrow = moment(today).add(1, "days");

        const isToday = inputDateTime.isSame(today, "day");
        const isTomorrow = inputDateTime.isSame(tomorrow, "day");

        if (isToday) {
            return `${t("Today")} ${bookingStartTime}`;
        } else if (isTomorrow) {
            return `${t("Tomorrow")} - ${dateTime.format("MMM")} ${dayNumber}${suffix} at ${dateTime.format("h:mm A")}`;
        } else if (inputDateTime.isAfter(today)) {
            const daysDiff = inputDateTime.diff(today, "days");
            return `${t("In dayvalue Days", { value: daysDiff })} - ${dateTime.format("MMM")} ${dayNumber}${suffix} at ${dateTime.format("h:mm A")}`;
        } else {
            const daysAgo = today.diff(inputDateTime, "days");

            if (daysAgo === 1) {
                return `${t("Yesterday")} ${bookingStartTime}`;
            }

            const daysAgoNum = Number(daysAgo);
            return `${t("dayvalue Days ago", { value: daysAgoNum })} - ${dateTime.format("MMM")} ${dayNumber}${suffix} at ${dateTime.format("h:mm A")}`;
        }
    };

    const handleTip = (e: any) => {
        e.stopPropagation();
        setIsTip(true);
    };

    const handleClose = (bookingId: any) => (e: any) => {
        e.stopPropagation();
        setIsTipCancelLoading(true);
        const params = {
            id: bookingDetails.id,
            shop_id: bookingDetails.shop.id,
        };
        const payload = {
            is_tip: false,
            tip: 0,
        };

        axiosPost(API.BOOKING.TIP, payload, params)
            .then((response) => {
                if (setRemoveBookingId) {
                    setRemoveBookingId(bookingId);
                }
            })
            .finally(() => setIsTipCancelLoading(false));
    };

    return (
        <div
            key={key}
            className={`bg-cardBackground rounded-minimal flex flex-wrap items-center justify-between cursor-pointer font-primary transition border border-borderColorPrimary hover:shadow-[0px_4px_18px_0px_var(--header-shadow)] z-10 bg-navbarBgColor relative w-full ${CustomClassName}`}
            onClick={handleAction("booking", bookingDetails)}
        >
            {isTipShow &&
                bookingDetails.status === "completed" &&
                !bookingDetails.is_tip_paid &&
                !bookingDetails.is_tip_unpaid &&
                isCardAdded &&
                isPaymentEnable &&
                !bookingDetails.is_tip_unpaid &&
                (isTipCancelLoading ? (
                    <ProgressSpinner style={{ width: "20px", height: "20px" }} className="radio-loader absolute top-[14px] right-[14px]" strokeWidth="5" animationDuration="1.5s" />
                ) : (
                    <button
                        onClick={handleClose(bookingDetails.id)}
                        className="absolute top-[8px] right-[8px] flex items-center w-[32px] h-[32px] justify-center rounded-minimal hover:bg-mainBackgroundColor text-textMoreDark hover:text-TextColor cursor-pointer"
                    >
                        <XClose className="w-4 h-4" />
                    </button>
                ))}
            <div className="flex item-center gap-3 p-3 w-full">
                <div className="w-[42px] min-w-[42px] relative">
                    <img
                        src={bookingDetails.staff ? bookingDetails?.staff?.profile_image_url : professionalDefaultImg}
                        alt=""
                        onError={onError("Professional")}
                        className="h-[42px] min-w-[42px] w-[42px] object-cover relative rounded-minimal"
                    />
                </div>
                <div className="flex-1 flex flex-col justify-center">
                    <div className="text-[16px] font-medium flex-1 text-TextColor tracking-[-0.01rem] leading-[22.4px] truncate">
                        {t("Appointment with")} {bookingDetails?.staff?.first_name}
                    </div>
                    <p className="text-xs text-textGrey font-normal leading-[18px] tracking-[-0.01rem]">{getServiceProductDes()}</p>
                </div>
            </div>
            <div
                className={`flex gap-2  flex-row border-t border-borderColorPrimary w-full justify-between min-h-[46px] items-center ${
                    bookingDetails.status === "completed" && !bookingDetails.is_tip_paid && isCardAdded && isPaymentEnable ? "py-2 px-3" : "p-3"
                }`}
            >
                <span className="text-[12px] text-TextColor font-normal flex items-center leading-[18px] flex-1 truncate ">
                    {isTipShow && bookingDetails.status === "completed" && !bookingDetails.is_tip_paid && isCardAdded && isPaymentEnable && !bookingDetails.is_tip_unpaid ? (
                        <>
                            {success.status && success.id === bookingDetails.id ? (
                                <span className="text-txtAppointmentColor font-bold text-[12px] leading-[18px]">{t("Thank you for the tip!")}</span>
                            ) : isTip ? (
                                <Tip bookingDetails={bookingDetails} setSuccess={setSuccess} setIsTip={setIsTip} listBookings={listBookings} />
                            ) : (
                                <span className="flex items-center gap-2 absolute">
                                    <span className="text-textGrey font-normal text-[12px] leading-[18px] ">{t("Would you like to add a tip?")}</span>{" "}
                                    <button
                                        className="text-primary font-medium text-[12px] leading-[18px] cursor-pointer relative border-b-animation-hover"
                                        onClick={handleTip}
                                        disabled={isTipCancelLoading}
                                    >
                                        {t("Add Tip")}
                                    </button>
                                </span>
                            )}
                        </>
                    ) : (
                        formatDate(bookingDetails.booking_date, bookingDetails.booking_start_time)
                    )}
                </span>

                {!isTip && (
                    <div className="flex items-center justify-center rounded-[6px] border border-borderCounter pr-1.5 pl-1 h-[24px] CustomButtonShadow">
                        <span className="flex items-center">
                            <GoDotFill size={12} className={`w-3 h-3 mr-0.5 ${textColorClass} `} />
                            <span className="text-[12px] font-medium text-textCounterColor leading-[18px] capitalize">{capitalizeFirstLetterAndUnderscore(bookingDetails.status)}</span>
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BookingDetails;
