import React, { FC, useState } from "react";
import { formatAmount, onError } from "src/utils/global-functions";
import { Minus, Plus, XClose } from "@untitled-ui/icons-react/build/cjs";
import { IMProductInfo } from "./Product.interface";
import { IDetails, IVariant } from "../../Template2/Product/Product.interface";
import { useTranslation } from "react-i18next";

const MProductInfo: FC<IMProductInfo> = ({ productData, handleProduct }) => {
    const { t } = useTranslation();
    const productImageURL = productData.images && productData.images.length > 0 ? productData.images.find((image: any) => image.is_main)?.image_url || "" : "";
    const [selectedVariant, setselectedVariant] = useState<IVariant>(
        productData.variants.length > 0
            ? productData.variants[0]
            : {
                  price: 0,
                  size: "",
                  id: 0,
              },
    );
    const [details, setDetails] = useState<{ details: IDetails[]; isShow: boolean; description: string }>({
        description: productData.description ? productData.description : "",
        details: productData.details.length ? productData.details : [],
        isShow: false,
    });
    const [isCollapsed, setIsCollapsed] = useState<any>({ id: 0, isShow: false });
    const handlePrice = (productVariantDetail: any) => () => {
        setselectedVariant(productVariantDetail);
    };

    const handleDetails = (isDetailShow: boolean) => () => {
        setDetails((prevDetails) => ({
            ...prevDetails,
            isShow: isDetailShow,
        }));
    };

    return (
        <>
            <div className="relative flex flex-col items-center justify-center">
                <div className="flex flex-col w-full bg-templateCardBg">
                    <div className="relative">
                        <img src={productImageURL} alt="" onError={onError("Product")} className="w-full aspect-1/1" />
                        <button className="absolute bottom-3 right-3 text-[20px] leading-[24.2px] font-normal text-white h-[40px] w-[40px] bg-[#000000] flex justify-center items-center">
                            <Plus className="text-white w-[20px] h-[20px]" onClick={handleProduct(productData, selectedVariant)} />
                        </button>
                    </div>
                    <div className="flex flex-row p-4">
                        <div className="flex-1">
                            <div className="flex flex-row w-full items-center justify-between mb-3">
                                <h2 className="text-[20px] leading-[30px] font-medium truncate max-w-max tracking-[-0.01em] text-TextColor uppercase">{productData.name}</h2>
                                <span className="text-[20px] leading-[30px] font-normal truncate max-w-max tracking-[-0.01em] text-TextColor uppercase">{formatAmount(selectedVariant.price)}</span>
                            </div>
                            <div className="flex gap-2 flex-wrap">
                                {productData.variants.map(
                                    (
                                        variant: {
                                            size: string;
                                            id: number;
                                        },
                                        index: React.Key | null | undefined,
                                    ) => (
                                        <button
                                            key={index}
                                            onClick={handlePrice(variant)}
                                            className={`border border-borderColorSecondary px-1.5 hover:border-primary hover:text-primary text-textGrey py-[5px] rounded-[6px] text-[12px] leading-[18px] font-medium ${
                                                variant.id === selectedVariant.id ? "border-primary !text-primary" : ""
                                            }`}
                                        >
                                            {`${variant.size} ${productData.unit_of_measure === "units" ? "" : productData.unit_of_measure}`}
                                        </button>
                                    ),
                                )}
                            </div>
                            <div className="flex items-center justify-center w-full mt-8">
                                <button
                                    onClick={handleDetails(true)}
                                    className="bg-transparent border w-full border-ProductBorderColor h-[46px] uppercase text-ProductBorderColor px-4 flex items-center justify-center text-[14px] leading-[18.2px] tracking-[-0.001em] font-medium "
                                >
                                    {t("View Product Details")}
                                </button>
                            </div>
                        </div>
                        {/* <button className="border border-ProductBorderColor h-[40px] min-w-[40px] w-[40px] flex items-center justify-center">
                            <Plus className="w-[22px] h-[22px] text-ProductBorderColor" />
                        </button> */}
                    </div>
                </div>
                {details.isShow && (
                    <div className="bg-primary w-full overflow-hidden flex flex-col h-full  absolute top-0 left-0 z-50">
                        <div className="relative px-6 pt-[24px] pb-5 w-full flex-col h-full overflow-y-auto flex-grow">
                            <h2 className="mb-[10px] text-[20px] leading-[30px] font-medium tracking-[-0.01em] text-white">{t("Product Description")}</h2>
                            <p className={`text-[14px] leading-[19.6px] font-normal text-white tracking-[-0.001em] ${details.details.length ? "border-b pb-[20px]" : ""} `}>{details.description}</p>
                            {details.details.map((item, index) => (
                                <div key={index} className="py-4 border-b border-white ">
                                    <div className="flex justify-between items-center">
                                        <p className="text-left text-[14px] leading-[18.2px] text-white font-semibold tracking-[-0.001em]">{item.name}</p>
                                        <button
                                            onClick={() => setIsCollapsed({ isShow: isCollapsed.id === index ? !isCollapsed.isShow : true, id: index })}
                                            className="text-right text-TextColor  text-[14px] font-normal"
                                        >
                                            {isCollapsed.isShow && isCollapsed.id === index ? <Minus className="w-[18px] h-[18px] text-white" /> : <Plus className="w-[18px] h-[18px] text-white" />}
                                        </button>
                                    </div>
                                    {isCollapsed.isShow && isCollapsed.id === index && (
                                        <p className="pt-2 text-[14px] leading-[19.6px] font-normal text-white tracking-[-0.001em]">{item.description}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                        <button onClick={handleDetails(false)} className="absolute top-[16px] right-[16px] text-white rounded">
                            <XClose className="text-white w-[18px] h-[18px]" />
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default MProductInfo;
