import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorType, IInitialState } from "./Common.interface";
import { axiosGet } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { errorCode, LANGUAGE, LOCAL_STORAGE_KEYS } from "../../../constants/common";
import { getSlug } from "../../../utils/global-functions";
import { RootState } from "src/redux/store";
import { bookingGetAllShopSettings, getClientDetails, setSelectedShopLocation } from "src/app/BookAppointment/Booking.slice";

const initialState: IInitialState = {
    settings: [],
    products: [],
    locations: [],
    shop: null,
    user: null,
    loading: false,
    error: null,
    template: null,
    globalLanguage: LANGUAGE,
    theme: true,
    isCrossClickTime: null,
};

export const me = createAsyncThunk("user/me", async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
        const { Common }: any = getState();
        const response = await axiosGet(API.USER.ME, {}, { shop_id: Common.shop.id });
        if (response.data.status === errorCode.success) {
            dispatch(bookingGetAllShopSettings({ shop_id: Common.shop.id }));
            const payloadDetails = {
                params: {
                    shop_id: Common.shop.id,
                },
                data: {},
            };
            dispatch(getClientDetails(payloadDetails));
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopSettings = createAsyncThunk("setting/all", async (payload: any, { rejectWithValue, getState }) => {
    try {
        const response = await axiosGet(API.SETTING.GET, {}, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopLocations = createAsyncThunk("location/all", async (payload: any, { dispatch, rejectWithValue, getState }) => {
    try {
        const response = await axiosGet(API.LOCATION.LIST, {}, payload);
        if (response.data.status === errorCode.success) {
            if (response.data.data.length === 1) {
                dispatch(setSelectedShopLocation(response.data.data[0]));
            }
            return response.data.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getShop = createAsyncThunk("user/shop/get", async (payload, { dispatch, rejectWithValue }) => {
    try {
        const response = await axiosGet(API.USER_SHOP.GET, {}, { slug: getSlug().slug });
        if (response.data.status === errorCode.success) {
            await dispatch(getAllShopSettings({ shop_id: response.data.data.id }));
            await dispatch(getAllShopLocations({ shop_id: response.data.data.id }));
            return response.data.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopProducts = createAsyncThunk("product/all", async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.PRODUCT.LIST, payload.data, payload.params);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setGlobalLanguage: (state, { payload }) => {
            state.globalLanguage = payload;
            localStorage.setItem(LOCAL_STORAGE_KEYS.currentLanguage, payload);
        },
        setUser: (state, { payload }) => {
            state.user = payload;
        },
        setIsCrossClickTime: (state, { payload }) => {
            state.isCrossClickTime = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(me.pending, (state) => {
                state.loading = true;
            })
            .addCase(me.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.user = payload.data;
            })
            .addCase(me.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopSettings.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                const isPreview = getSlug().isPreview;
                // console.log("isPreview", isPreview);
                state.settings = payload.data;
                const settings = payload.data;

                if (settings && settings.length > 0) {
                    const templateSetting = settings.find((setting: { type: string }) => setting.type === (isPreview ? "template_preview" : "template"));
                    // console.log("templateSetting", templateSetting);
                    if (templateSetting) {
                        const template = templateSetting.value.template;
                        state.template = template;
                    }
                    const appearance = settings.find((setting: { type: string }) => setting.type === "appearance");
                    if (appearance) {
                        const {
                            base_theme: baseTheme,
                            brand_color: brandColor,
                            brand_text_color: brandTextColor,
                            font_family: fontFamily,
                            logo_font_family: logoFontFamily,
                            font_weight: fontWeight,
                            element_style: elementStyle,
                            brand_navigation_color: brandNavigationColor,
                            font_size: logotextSize,
                        } = appearance.value;

                        state.theme = baseTheme === "light" ? true : false;
                        const contentBackgroundColor = baseTheme === "dark" ? "#0C111D" : "#FFFFFF";
                        const cardBackgroundColor = baseTheme === "dark" ? "#161B26" : "#FFFFFF";
                        const mainTextColor = baseTheme === "dark" ? "#F5F5F6" : "#171F2E";
                        const borderColorPrimary = baseTheme === "dark" ? "#2F3746" : "#E3E7EF";
                        const borderColorSecondary = baseTheme === "dark" ? "#2F3746" : "#CACFDC";
                        const borderCounter = baseTheme === "dark" ? "#2F3746" : "#D0D5DD";
                        const textCounterColor = baseTheme === "dark" ? "#FAF8F7" : "#344054";
                        const dropdownBackgroundColor = baseTheme === "dark" ? "#161B26" : "#FFFFFF";
                        const productBackgroundColor = baseTheme === "dark" ? "#141414" : "#FFFFFF";
                        const bgContent = baseTheme === "dark" ? "#1E1E1E" : "#F7F7F8";
                        const tooltipBg = baseTheme === "dark" ? "#ffffff" : "#000000";
                        const tooltipTextColor = baseTheme === "dark" ? "#000000" : "#ffffff";
                        const mainBackgroundColor = baseTheme === "dark" ? "#1A2030" : "#F8F8F8";
                        const containerBgColor = baseTheme === "dark" ? "#0C111D" : "#FFFFFF";
                        const dateHoverBackground = baseTheme === "dark" ? "#0C111D" : "#FFFFFF";
                        const sidebarBgColor = baseTheme === "dark" ? "#0C111D" : "#FEFEFE";
                        const navbarBgColor = baseTheme === "dark" ? "#0C111D" : "#FEFEFE";
                        const appointmentBgColor = baseTheme === "dark" ? "#2A2F3C" : "#F6F8FE";
                        const mapPinBgColor = baseTheme === "dark" ? "#000000" : "#BDBDBD";
                        const mapPinBorderColor = baseTheme === "dark" ? "#FFFFFF" : "#D1D2D4";
                        const steperBackgroundColor = baseTheme === "dark" ? "#2A2F3C" : "#F7F7F8";
                        const stepcontentBackgroundColor = baseTheme === "dark" ? "#2F3746" : "#F7F7F8";
                        const footerPolicyBackgroundColor = baseTheme === "dark" ? "#161B26" : "#F3F4F6";
                        const successMessagesBorder = baseTheme === "dark" ? "#364F44" : "#079455";
                        const successMessagesBg = baseTheme === "dark" ? "#30493E" : "#EEFBF5";
                        const boxShadowColor = baseTheme === "dark" ? "#30493E" : "#e0eaff";
                        const uploadProfileBg = baseTheme === "dark" ? "#2A2F3C" : "#F2F4F7";
                        const uploadProfileBorder = baseTheme === "dark" ? "#2A2F3C" : "#EAECF0";
                        const uploadInputBg = baseTheme === "dark" ? "#2A2F3C" : "#FFFFFF";
                        const templateBg = baseTheme === "dark" ? "#161B26" : "#faf8f7";
                        const templateTextColor = baseTheme === "dark" ? "#FFFFFF" : "#060606";
                        const templateCardBg = baseTheme === "dark" ? "#000000" : "#ffffff";
                        const templateCardBorderColor = baseTheme === "dark" ? "#2F3746" : "#E0E0E0";
                        const ProductBorderColor = baseTheme === "dark" ? "#D6D7E2" : "#31376C";
                        const templateReview = baseTheme === "dark" ? "#3f4759" : "#CBD3E3";
                        const disabledButtonColor = baseTheme === "dark" ? "#3A3F47" : "#F2F4F7";
                        const shadowBgColor = baseTheme === "dark" ? "#ffffff1a" : "#0000001A";
                        const customLoadingBackgroundColor = baseTheme === "dark" ? "#2A2F3C" : "#eaecf0";
                        const arrowBackgroundColor = baseTheme === "dark" ? "#3A3F47" : "#F3F4F6";
                        const textGrey = baseTheme === "dark" ? "#94969C" : "#8C9CBB";
                        const textWhiteBlack = baseTheme === "dark" ? "#F5F5F6" : "#000000";
                        const textBlackWhite = baseTheme === "dark" ? "#000000" : "#F5F5F6";
                        const textMoreDark = baseTheme === "dark" ? "#F5F5F6" : "#101828";
                        const textMoreGrey = baseTheme === "dark" ? "#94969C" : "#475467";
                        const modalBackdropOpacity = baseTheme === "dark" ? "0.8" : "0.5";
                        const sidebarShadow = baseTheme === "dark" ? "0px 4px 24px 10px #18131380" : "0 4px 20px 10px #b4b4b41a";
                        const dateTimeShadow = baseTheme === "dark" ? "0px 4px 14px rgba(0, 0, 0, 0.6), 0px 2px 4px rgba(0, 0, 0, 0.4)" : "0px 2px 12px 0px #00000014";
                        const CustomButtonShadow = baseTheme === "dark" ? "#F5F5F60d" : "#1018280d";
                        const promotionOverlayShadow = baseTheme === "dark" ? "rgba(255, 255, 255, 0.08)" : "#10182814";
                        const inputShadow = baseTheme === "dark" ? "rgba(255, 255, 255, 0.08)" : "#1018280f";
                        const bookingCardShadow = baseTheme === "dark" ? "rgba(255, 255, 255, 0.08)" : "#AAAAAA26";
                        const headerShadow = baseTheme === "dark" ? "rgba(255, 255, 255, 0.05)" : "#0000000D";

                        const toastTextColor = baseTheme === "dark" ? "#344054" : "#ffffff";
                        const toastBorderColor = baseTheme === "dark" ? "#D0D5DD" : "#333741";
                        const toastBackgroundColor = baseTheme === "dark" ? "#FFFFFF" : "#161b26";

                        let brandBorderRadius = "8px";
                        let brandBorderRadiusSecoundary = "18px";
                        let brandBorderRadiusMinimal = "8px";
                        let containerRadius = "24px";
                        let modelRadius = "12px";
                        switch (elementStyle) {
                            case "minimal":
                                brandBorderRadius = "8px";
                                brandBorderRadiusSecoundary = "8px";
                                brandBorderRadiusMinimal = "8px";
                                containerRadius = "24px";
                                modelRadius = "12px";
                                break;
                            case "sharp":
                                brandBorderRadius = "0px";
                                brandBorderRadiusSecoundary = "0px";
                                brandBorderRadiusMinimal = "0px";
                                containerRadius = "0px";
                                modelRadius = "0px";
                                break;
                            case "curved":
                                brandBorderRadius = "100%";
                                brandBorderRadiusSecoundary = "18px";
                                brandBorderRadiusMinimal = "8px";
                                containerRadius = "24px";
                                modelRadius = "12px";
                                break;
                            default:
                                break;
                        }
                        const r = parseInt(brandColor.slice(1, 3), 16);
                        const g = parseInt(brandColor.slice(3, 5), 16);
                        const b = parseInt(brandColor.slice(5, 7), 16);

                        const navR = parseInt(brandNavigationColor.slice(1, 3), 16);
                        const navG = parseInt(brandNavigationColor.slice(3, 5), 16);
                        const navB = parseInt(brandNavigationColor.slice(5, 7), 16);

                        const darkFactor = 0.8; // Darken by 20%

                        // Darker versions
                        const darkerR = Math.floor(r * darkFactor);
                        const darkerG = Math.floor(g * darkFactor);
                        const darkerB = Math.floor(b * darkFactor);

                        const darkerNavR = Math.floor(navR * darkFactor);
                        const darkerNavG = Math.floor(navG * darkFactor);
                        const darkerNavB = Math.floor(navB * darkFactor);

                        // Ensure RGB values remain in a valid range and create the RGBA strings
                        const brandColorDark = `rgba(${Math.max(0, darkerR)}, ${Math.max(0, darkerG)}, ${Math.max(0, darkerB)}, 1)`;
                        const brandNavigationColorDark = `rgba(${Math.max(0, darkerNavR)}, ${Math.max(0, darkerNavG)}, ${Math.max(0, darkerNavB)}, 1)`;

                        const brandColorHoverDark = `rgba(${Math.max(0, darkerR)}, ${Math.max(0, darkerG)}, ${Math.max(0, darkerB)}, 1)`;

                        const brandColorLight = `rgba(${r}, ${g}, ${b}, 0.5)`;
                        const brandNavigationColorLight = `rgba(${navR}, ${navG}, ${navB}, 0.5)`;

                        // Function to calculate luminance
                        const getLuminance = function (r1: number, g1: number, b1: number): number {
                            const a = [r1, g1, b1].map(function (v) {
                                v /= 255;
                                return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
                            });
                            return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
                        };

                        // Determine if the colors are dark or light based on luminance
                        const isBrandColorDark = getLuminance(r, g, b) < 0.5;
                        const isBrandNavigationColorDark = getLuminance(navR, navG, navB) < 0.5;

                        const brandColorLightDark = isBrandColorDark ? brandColorLight : brandColorDark;
                        const brandNavigationColorLightDark = isBrandNavigationColorDark ? brandNavigationColorLight : brandNavigationColorDark;
                        const brandNavigationColorHover = isBrandNavigationColorDark ? "#FFFFFF" : "#060606";
                        const brandNavigationBorderColorHover = isBrandNavigationColorDark ? "255,255,255" : "6,6,6";
                        const brandNavigationBgColorHover = isBrandNavigationColorDark ? "255,255,255" : "156,145,145";

                        // Set CSS variables
                        document.documentElement.style.setProperty("--brand-color-light", brandColorLight);
                        document.documentElement.style.setProperty("--brand-color-dark", brandColorDark);
                        document.documentElement.style.setProperty("--brand-color-LightDark", brandColorLightDark);

                        document.documentElement.style.setProperty("--brand-navigation-color", brandNavigationColor);
                        document.documentElement.style.setProperty("--brand-navigation-color-light", brandNavigationColorLight);
                        document.documentElement.style.setProperty("--brand-navigation-color-dark", brandNavigationColorDark);
                        document.documentElement.style.setProperty("--brand-navigation-color-LightDark", brandNavigationColorLightDark);
                        document.documentElement.style.setProperty("--brand-navigation-color-hover", brandNavigationColorHover);
                        document.documentElement.style.setProperty("--brand-navigation-border-color-hover", brandNavigationBorderColorHover);
                        document.documentElement.style.setProperty("--brand-navigation-bg-color-hover", brandNavigationBgColorHover);

                        const brandColorHover = `rgba(${r}, ${g}, ${b}, 0.1)`;
                        const borderColorHover = `rgba(${r}, ${g}, ${b}, 0.2)`;
                        document.documentElement.style.setProperty("--brand-color-button", brandColorHoverDark);
                        document.documentElement.style.setProperty("--brand-color", brandColor);
                        document.documentElement.style.setProperty("--brand-color-hover", brandColorHover);
                        document.documentElement.style.setProperty("--border-color-hover", borderColorHover);
                        document.documentElement.style.setProperty("--brand-text-color", brandTextColor);
                        document.documentElement.style.setProperty("--content-background-color", contentBackgroundColor);
                        document.documentElement.style.setProperty("--card-background-color", cardBackgroundColor);
                        document.documentElement.style.setProperty("--main-text-color", mainTextColor);
                        document.documentElement.style.setProperty("--brand-font-family", fontFamily);
                        document.documentElement.style.setProperty("--logo-font-family", logoFontFamily);
                        document.documentElement.style.setProperty("--logo-font-weight", fontWeight);
                        document.documentElement.style.setProperty("--brand-border-radius", brandBorderRadius);
                        document.documentElement.style.setProperty("--brand-border-radius-secoundary", brandBorderRadiusSecoundary);
                        document.documentElement.style.setProperty("--brand-border-radius-minimal", brandBorderRadiusMinimal);
                        document.documentElement.style.setProperty("--container-radius", containerRadius);
                        document.documentElement.style.setProperty("--model-radius", modelRadius);
                        document.documentElement.style.setProperty("--border-color-primary", borderColorPrimary);
                        document.documentElement.style.setProperty("--border-color-secondary", borderColorSecondary);
                        document.documentElement.style.setProperty("--dropdown-background-color", dropdownBackgroundColor);
                        document.documentElement.style.setProperty("--product-background-color", productBackgroundColor);
                        document.documentElement.style.setProperty("--bg-content", bgContent);
                        document.documentElement.style.setProperty("--tooltip-bg", tooltipBg);
                        document.documentElement.style.setProperty("--tooltip-text-color", tooltipTextColor);
                        document.documentElement.style.setProperty("--main-background-color", mainBackgroundColor);
                        document.documentElement.style.setProperty("--container-background-color", containerBgColor);
                        document.documentElement.style.setProperty("--date-background-color", dateHoverBackground);
                        document.documentElement.style.setProperty("--navbar-background-color", navbarBgColor);
                        document.documentElement.style.setProperty("--appointment-background-color", appointmentBgColor);
                        document.documentElement.style.setProperty("--border-counter", borderCounter);
                        document.documentElement.style.setProperty("--sidebar-background-color", sidebarBgColor);
                        document.documentElement.style.setProperty("--text-counter-color", textCounterColor);
                        document.documentElement.style.setProperty("--map-pin-background-color", mapPinBgColor);
                        document.documentElement.style.setProperty("--map-pin-border-color", mapPinBorderColor);
                        document.documentElement.style.setProperty("--steper-background-color", steperBackgroundColor);
                        document.documentElement.style.setProperty("--step-content-background-color", stepcontentBackgroundColor);
                        document.documentElement.style.setProperty("--foter-policy-background-color", footerPolicyBackgroundColor);
                        document.documentElement.style.setProperty("--success-messages-border-color", successMessagesBorder);
                        document.documentElement.style.setProperty("--success-messages-background-color", successMessagesBg);
                        document.documentElement.style.setProperty("--box-shadow-color", boxShadowColor);
                        document.documentElement.style.setProperty("--upload-profile-border-color", uploadProfileBorder);
                        document.documentElement.style.setProperty("--upload-profile-background-color", uploadProfileBg);
                        document.documentElement.style.setProperty("--upload-input-background-color", uploadInputBg);
                        document.documentElement.style.setProperty("--template-background-color", templateBg);
                        document.documentElement.style.setProperty("--template-text-color", templateTextColor);
                        document.documentElement.style.setProperty("--template-card-background-color", templateCardBg);
                        document.documentElement.style.setProperty("--template-card-border-color", templateCardBorderColor);
                        document.documentElement.style.setProperty("--product-border-color", ProductBorderColor);
                        document.documentElement.style.setProperty("--template-review-color", templateReview);
                        document.documentElement.style.setProperty("--logo-font-size", logotextSize);
                        document.documentElement.style.setProperty("--disabled-button-color", disabledButtonColor);
                        document.documentElement.style.setProperty("--shadow-background-color", shadowBgColor);
                        document.documentElement.style.setProperty("--custom-loading-background-color", customLoadingBackgroundColor);
                        document.documentElement.style.setProperty("--arrow-background-color", arrowBackgroundColor);
                        document.documentElement.style.setProperty("--text-grey-color", textGrey);
                        document.documentElement.style.setProperty("--text-white-black", textWhiteBlack);
                        document.documentElement.style.setProperty("--text-black-white", textBlackWhite);
                        document.documentElement.style.setProperty("--text-more-dark", textMoreDark);
                        document.documentElement.style.setProperty("--text-more-grey", textMoreGrey);
                        document.documentElement.style.setProperty("--model-backdrop-opacity", modalBackdropOpacity);
                        document.documentElement.style.setProperty("--sidebar-shadow", sidebarShadow);
                        document.documentElement.style.setProperty("--date-time-shadow", dateTimeShadow);
                        document.documentElement.style.setProperty("--custom-button-shadow", CustomButtonShadow);
                        document.documentElement.style.setProperty("--promotion-overlay-shadow", promotionOverlayShadow);
                        document.documentElement.style.setProperty("--input-shadow", inputShadow);
                        document.documentElement.style.setProperty("--booking-card-shadow", bookingCardShadow);
                        document.documentElement.style.setProperty("--header-shadow", headerShadow);
                        document.documentElement.style.setProperty("--toast-text-color", toastTextColor);
                        document.documentElement.style.setProperty("--toast-border-color", toastBorderColor);
                        document.documentElement.style.setProperty("--toast-background-color", toastBackgroundColor);
                    }
                }
            })
            .addCase(getAllShopSettings.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getShop.pending, (state) => {
                state.loading = true;
            })
            .addCase(getShop.fulfilled, (state, { payload }: any) => {
                state.shop = payload;
                state.loading = false;
                if (payload && payload.admin_logo_image_url) {
                    const favicon: any = document.getElementById("favicon");
                    const link: any = document.getElementById("appleIcon");
                    if (favicon) {
                        favicon.href = payload.admin_logo_image_url;
                    }
                    if (link) {
                        link.href = payload.admin_logo_image_url;
                    }
                }
            })
            .addCase(getShop.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopLocations.pending, (state, { payload }: any) => {
                state.loading = true;
            })
            .addCase(getAllShopLocations.fulfilled, (state, { payload }: any) => {
                state.locations = payload;
                state.loading = false;
            })
            .addCase(getAllShopLocations.rejected, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(getAllShopProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopProducts.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.products = payload.data;
            })
            .addCase(getAllShopProducts.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export default commonSlice.reducer;

export const { setGlobalLanguage, setUser, setIsCrossClickTime } = commonSlice.actions;

export const currentUser = (state: RootState) => state.Common.user || null;
export const currentTheme = (state: RootState) => state.Common.theme;
export const currentShop = (state: RootState) => state.Common.shop || null;
export const currentSettings = (state: RootState) => state.Common.settings || [];
export const allShopProducts = (state: RootState) => state.Common.products || [];
export const allShopLocations = (state: RootState) => state.Common.locations || [];
export const allLanguages = (state: RootState) => {
    let languages = ["en"];
    if (state.Common.settings) {
        const appearance = state.Common.settings.find((setting: any) => setting.type === "appearance");
        if (appearance) {
            languages = appearance.value.languages;
        }
    }
    return languages;
};
export const globalLanguage = (state: RootState) => state.Common.globalLanguage || LANGUAGE;
export const userIsCrossClickTime = (state: RootState) => state.Common.isCrossClickTime || null;
