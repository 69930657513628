import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import CustomButton from "src/components/CustomButton";
import { CardElement } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import FloatingInputbox from "src/components/FloatingInputbox";

const MSidebarPaymentElement = ({ isCreateCardLoading, setCardError, cardError, successBtnText, handleClose, stripe, elements }: any) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <>
            <div className="flex-1 flex flex-col gap-4">
                <Controller
                    name="holdername"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full flex flex-col">
                            <FloatingInputbox label={t("Cardholder Name")} handleChange={(e) => onChange(e.target.value)} value={value} name="holdername" inputClass={`${error && "is-error"}`} />
                            {error && <p className="text-error">{error && error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="carddetails"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="flex flex-col w-full">
                            <div className={`border-none bg-transparent rounded-secondary CustomButtonShadow w-full relative card-number-wrapper`}>
                                <CardElement
                                    id="number-123"
                                    className={`card-number-input ${(error || cardError) && "is-invalid"}`}
                                    options={{
                                        style: {
                                            base: {
                                                color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                            },
                                        },
                                    }}
                                    onChange={(e) => {
                                        onChange(e);
                                        setCardError(e.complete ? "" : e.error?.message);
                                    }}
                                />
                            </div>
                            {(error || cardError) && <p className="text-error">{(error || cardError) && (error?.message || cardError)}</p>}
                        </div>
                    )}
                />
            </div>
            <div className="lg:border-t lg:border-borderColorPrimary flex gap-3 mt-5 bg-containerBgColor lg:pt-4 pb-4 -mx-[16px] px-[16px] lg:-mx-[20px] lg:px-[20px]">
                <CustomButton outlineSecondary disabled={isCreateCardLoading} className={`w-full max-lg:h-[48px]`} type="button" onClick={handleClose}>
                    {t("Back")}
                </CustomButton>
                <CustomButton primary isLoading={isCreateCardLoading} disabled={isCreateCardLoading} className={`w-full max-lg:h-[48px]`} type="submit">
                    {successBtnText}
                </CustomButton>
            </div>
        </>
    );
};

export default MSidebarPaymentElement;
