import React, { createRef, FC, useEffect, useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import { Cropper, ReactCropperElement } from "react-cropper";
import { ICropType, IPops } from "./CropperModal.interface";
import { useTranslation } from "react-i18next";
import Crop01 from "@untitled-ui/icons-react/build/cjs/Crop01";

import Sidebar from "../Sidebar";
import { useAppSelector } from "src/redux/hooks";
import { getBookingInfo } from "src/app/BookAppointment/Booking.slice";

const MCropperModal: FC<IPops> = ({
    imageUpload,
    imageUrl,
    setUpload,
    setCropData,
    isCropType = true,
    defaultCropType = "Rounded",
    title = "Adjust image",
    description = "Adjust your image",
    btnTitle,
    types = ["Rounded", "Square", "Rectangular", "Free Size"],
    setAspectRatio = null,
}) => {
    const { t } = useTranslation();
    const cropperRef = createRef<ReactCropperElement>();
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const zoomSliderRef = useRef<HTMLInputElement>(null);

    const [cropType, setCropType] = useState<ICropType>(defaultCropType);
    const [zoomLevel, setZoomLevel] = useState<number>(0.3);
    const [minZoomLevel, setMinZoomLevel] = useState<number>(0.0);
    const [maxZoomLevel, setMaxZoomLevel] = useState<number>(3.0);

    // const [naturalAspectRatio, setNaturalAspectRatio] = useState<any>(null);
    const BookingInfo = useAppSelector(getBookingInfo);

    // useEffect(() => {
    //     if (cropperRef.current && typeof cropperRef.current?.cropper.zoomTo === "number") {
    //         cropperRef.current?.cropper.zoomTo(Number(zoomSliderRef.current!.value));
    //     }
    // }, [zoomLevel]);

    useEffect(() => {
        handleCropType(cropType);
    }, [cropType]);

    const getCropData = () => {
        if (cropperRef.current?.cropper) {
            const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas({
                rounded: true,
                imageSmoothingEnabled: true,
            });
            const imageData = cropType === "Rounded" ? getRoundedCanvas(croppedCanvas).toDataURL() : croppedCanvas.toDataURL();
            if (croppedCanvas) {
                let type = null;
                switch (cropType) {
                    case "Rounded":
                        type = "circle";
                        break;
                    case "Square":
                        type = "square";
                        break;
                    case "Rectangular":
                        type = "rectangle";
                        break;
                    case "Free Size":
                        type = "free_size";
                        break;
                }
                setCropData(imageData, type);
                setUpload(false);
                imageUpload && imageUpload(imageData as any);
            }
        }
    };

    const handleZoomChange = (e: any) => {
        const newZoomLevel = parseFloat(e.target.value);
        setZoomLevel(newZoomLevel);
        if (cropperRef.current) {
            cropperRef.current.cropper.zoomTo(newZoomLevel);
        }
    };

    const handleCropType = (type: ICropType) => {
        cropperRef.current?.cropper.reset();
        if (setAspectRatio) {
            cropperRef.current?.cropper.setAspectRatio(setAspectRatio);
            return;
        }
        if (type === "Free Size") {
            cropperRef.current?.cropper.setAspectRatio(NaN);
            return;
        }
        let aspectRatio = type === "Rectangular" ? 16 / 9 : 1 / 1;
        cropperRef.current?.cropper.setAspectRatio(aspectRatio);
    };

    const handleImageLoaded = (e: any) => {
        const naturalWidth = e.target.naturalWidth;
        const naturalHeight = e.target.naturalHeight;
        let tempZoomLevel = 0;
        let zoomValueTemp = 0;
        if (naturalHeight > naturalWidth) {
            zoomValueTemp = ((600 / naturalHeight) * 25) / 100;
            tempZoomLevel = 300 / naturalWidth;
        } else {
            zoomValueTemp = ((600 / naturalWidth) * 25) / 100;
            tempZoomLevel = 300 / naturalHeight;
        }
        setMinZoomLevel(zoomValueTemp);
        setMaxZoomLevel(maxZoomLevel + zoomValueTemp);
        if (cropperRef.current) {
            setZoomLevel(tempZoomLevel);
            cropperRef.current.cropper.zoomTo(tempZoomLevel);
        }
    };

    const getRoundedCanvas = (sourceCanvas: any) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        const canvasWidth = sourceCanvas.width;
        const canvasHeight = sourceCanvas.height;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        if (context) {
            context.imageSmoothingEnabled = true;
            context.drawImage(sourceCanvas, 0, 0, canvasWidth, canvasHeight);
            context.globalCompositeOperation = "destination-in";
            context.beginPath();
            context.arc(canvasWidth / 2, canvasHeight / 2, Math.min(canvasWidth, canvasHeight) / 2, 0, 2 * Math.PI, true);
            context.fill();
        }
        return canvas;
    };

    const handleClose = () => {
        setUpload(false);
    };

    return (
        <Sidebar
            isOpen={true}
            handleClose={handleClose}
            size={`h-auto rounded-t-[16px] font-primary outline-custom mobileCroperModal ${BookingInfo.isFullScreen ? "max-lg:!pb-[32px]" : ""}`}
            primaryButton={btnTitle ? btnTitle : t("Crop Image")}
            secondaryButton={t("Cancel")}
            acceptAction={getCropData}
            declineAction={() => setUpload(false)}
            position="bottom"
            // title={
            //     <div className="flex justify-between items-start">
            //         <div className="flex gap-4 items-start">
            //             <div className="w-12 h-12 rounded-[10px] p-3 border border-borderColorPrimary shadow-InputAndButton">
            //                 <Crop01 className="w-6 h-6 text-txtAppointmentColor" />
            //             </div>
            //             <div className="flex flex-col gap-1">
            //                 <h2 className="font-semibold text-lg text-txtAppointmentColor">{title}</h2>
            //                 <p className="text-sm font-normal text-textGrey">{description}</p>
            //             </div>
            //         </div>
            //     </div>
            // }
        >
            <div className={`relative overflow-auto mobile-model-inner flex flex-col w-full `}>
                <div className="flex flex-col mb-2 pt-[12px]">
                    <span className="flex tracking-[-0.01rem] leading-[22.4px] font-bold text-[16px] mb-1 text-txtAppointmentColor">{t("Crop Image")}</span>
                    <span className="flex text-textGrey tracking-[-0.01rem] leading-[15.6px] font-normal text-[12px]">{t("Upload a 400x400px image for the best results.")}</span>
                </div>
                {isCropType && (
                    <div className="fl-tab mb-5 rounded-[10px]">
                        {types.map((type: ICropType) => (
                            <button key={type} type="button" value={type} className={`fl-tab-link !rounded-md ${cropType === type && "active"}`} onClick={() => setCropType(type)}>
                                {type}
                            </button>
                        ))}
                    </div>
                )}

                <div className="flex flex-col pb-[20px]">
                    <div className="h-[312px] mb-5 overflow-hidden rounded-lg app-logo-img-block bg-baseBg ">
                        <Cropper
                            key={cropType} // Force re-render
                            ready={handleImageLoaded}
                            aspectRatio={1}
                            autoCrop={true}
                            autoCropArea={0}
                            preview=".img-preview"
                            src={imageUrl}
                            center={true}
                            ref={cropperRef}
                            viewMode={0}
                            guides={false}
                            minCropBoxHeight={300}
                            minCropBoxWidth={300}
                            background={false}
                            responsive={false}
                            checkOrientation={true}
                            cropBoxMovable={cropType === "Free Size"}
                            cropBoxResizable={cropType === "Free Size"}
                            dragMode="move"
                            zoomOnTouch={false}
                            zoomOnWheel={false}
                            disabled={true}
                            highlight={true}
                            className={`${cropType === "Rounded" ? "imageCropper" : ""}`}
                        />
                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: "1px solid black",
                                objectFit: "contain",
                                height: 0.5,
                            }}
                            className="hidden"
                        />
                    </div>
                    <div className="w-full">
                        <div className="flex justify-between items-center custom-range-slider">
                            <input ref={zoomSliderRef} id="zoomSlider" type="range" min={minZoomLevel} max={maxZoomLevel} step="0.001" value={zoomLevel} onChange={handleZoomChange} />
                        </div>
                        <div className="flex justify-between">
                            <h1 className="font-medium text-[14px] leading-[24px] text-txtAppointmentColor">0%</h1>
                            <h1 className="font-medium text-[14px] leading-[24px] text-txtAppointmentColor">{Math.round(((zoomLevel - minZoomLevel) / (maxZoomLevel - minZoomLevel)) * 100)}%</h1>
                        </div>
                    </div>
                </div>
            </div>
        </Sidebar>
    );
};

export default MCropperModal;
