import { format } from "date-fns";
import { useCallback, useState } from "react";
import {
    setBookingInitialState,
    setBookingTotal,
    setIsModifyingAppointment,
    setIsShowFinalizeBooking,
    setModifyingAppointmentId,
    setOldPaymentOption,
    setPaymentOption,
    setPaymentType,
    setPreview,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setSelectedProducts,
    setSelectedPromocode,
    setSelectedServices,
    setSelectedShopLocation,
    setSelectedSpecialist,
    setStep,
} from "src/app/BookAppointment/Booking.slice";
import { IAppointmentBooking, IAppointmentBookingServices } from "src/Interface/booked_appointment.interface";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { currentShop, getAllShopProducts } from "src/redux/reducers/common/Common.slice";
import { calculateBookingTotal } from "src/utils/global-functions";
import useManageSettings from "./useManageSettings";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import { EBookingTabState } from "src/app/BookAppointment/bookAppointment.interface";
import { useShopNavigation } from "./useShopNavigation";
import { errorCode, LOCAL_STORAGE_KEYS } from "src/constants/common";

const useModifyAppointment = () => {
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const [loading, setLoading] = useState(true);
    const { manageSpecialistSetting } = useManageSettings();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { navigateBasedOnShopLocations } = useShopNavigation();

    const handleModifyAppointment = useCallback(
        async (bookingInfo: IAppointmentBooking) => {
            setLoading(true);
            dispatch(setBookingInitialState());
            dispatch(setIsModifyingAppointment(true));
            dispatch(setModifyingAppointmentId(bookingInfo.id));
            dispatch(setStep(EBookingTabState.FINALIZE_BOOKING));

            dispatch(setIsShowFinalizeBooking(false));
            dispatch(setSelectedShopLocation(bookingInfo.location));

            dispatch(setSelectedSpecialist(bookingInfo.staff));
            manageSpecialistSetting(bookingInfo.staff);
            dispatch(setPreview({ key: "selectedSpecialist", value: bookingInfo.staff }));
            dispatch(setSelectedBookingDate(bookingInfo.booking_date));
            dispatch(setSelectedBookingTime(format(new Date(`${bookingInfo.booking_date} ${bookingInfo.booking_start_time}`), "HH:mm")));
            dispatch(setPreview({ key: "selectedBookingDateTime", value: `${format(new Date(bookingInfo.booking_date), "Y-MM-dd")} ${bookingInfo.booking_start_time}` }));
            dispatch(setPaymentType(bookingInfo?.payment_type));
            dispatch(setPaymentOption(bookingInfo?.payment_option));
            dispatch(setOldPaymentOption(bookingInfo?.payment_option));

            if (bookingInfo.shop_promotion) {
                dispatch(setSelectedPromocode(bookingInfo?.shop_promotion));
            }

            const selectedServices: any = [];
            bookingInfo.booking_services.forEach((booking_service: IAppointmentBookingServices) => {
                const { service, quantity } = booking_service;
                for (let index = 0; index < quantity; index++) {
                    selectedServices.push({ ...service, quantity: 1 });
                }
            });
            dispatch(setSelectedServices(selectedServices));
            dispatch(setPreview({ key: "selectedServices", value: selectedServices }));

            const selectedProducts: any = [];
            bookingInfo?.booking_products?.forEach((booking_product) => {
                const { product, quantity, product_variant: variant, product_inventory: inventory } = booking_product;
                for (let index = 0; index < quantity; index++) {
                    selectedProducts.push({ ...product, selectedVariant: { ...variant, inventory } });
                }
            });
            dispatch(setSelectedProducts(selectedProducts));

            const payload = {
                params: {
                    shop_id: shop.id,
                    location_id: bookingInfo.location.id,
                },
                data: {
                    booking_id: bookingInfo.id,
                },
            };
            await dispatch(getAllShopProducts(payload));
            const finalTotal = await calculateBookingTotal(selectedServices, selectedProducts, bookingInfo?.location?.sales_tax);
            dispatch(setBookingTotal(finalTotal));

            setLoading(false);
        },
        [dispatch, shop.id],
    );

    const getBookingUserData = async (bookingId: number) => {
        setLoading(true);
        const params = {
            id: bookingId,
            shop_id: shop.id,
            url: `/user/shop/${shop.id}/booking/${bookingId}/show`,
        };
        try {
            const response = await axiosGet(API.BOOKING.SHOW, {}, params);
            const data = response.data.data;
            if (data && data.user) {
                await localStorage.setItem(LOCAL_STORAGE_KEYS.expressUserEmail, data.user.email);
                return data;
            } else {
                navigateBasedOnShopLocations();
            }
        } catch (error) {
            return null;
        } finally {
            setLoading(false);
        }
    };

    const getBookingData = async (bookingId: number) => {
        setLoading(true);
        const params = {
            id: bookingId,
            shop_id: shop.id,
            url: `/user/shop/${shop.id}/booking/${bookingId}`,
        };
        return axiosGet(API.BOOKING.GET, {}, params)
            .then((response) => {
                if (response.data.status === 401) {
                    // getBookingUserData(bookingId);
                    navigateBasedOnShopLocations();
                    setIsAuthenticated(false);
                    localStorage.setItem(LOCAL_STORAGE_KEYS.expressBookingId, String(bookingId));
                    dispatch(setIsShowLoginModal(true));
                } else {
                    setIsAuthenticated(true);
                    handleModifyAppointment(response.data.data);
                }
                return response;
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (
                        localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId) ||
                        localStorage.getItem(LOCAL_STORAGE_KEYS.expressUserEmail) ||
                        localStorage.getItem(LOCAL_STORAGE_KEYS.hasReloaded)
                    ) {
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.expressBookingId);
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.expressUserEmail);
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.hasReloaded);
                    }
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return { getBookingData, loading, isAuthenticated, getBookingUserData, setIsAuthenticated };
};

export default useModifyAppointment;
