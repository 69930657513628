import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { useTranslation } from "react-i18next";
import { getBookingInfo } from "../../Booking.slice";

const ProductBasket = () => {
    const { t } = useTranslation();
    const booking = useAppSelector(getBookingInfo);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const selectedProducts = booking.selectedProducts.reduce((acc, product) => {
            const productId = product.id;
            const variantId = product.selectedVariant.id;
            const price = product.selectedVariant.inventory.price;

            const key = `${productId}-${variantId}-${price}`;

            if (!acc[key]) {
                acc[key] = { ...product, quantity: 0 };
            }
            acc[key].quantity += 1;

            return acc;
        }, {});

        setProducts(Object.values(selectedProducts));
    }, [booking]);

    return (
        <div className="flex flex-col gap-2 lg:px-0 mt-3">
            <h2 className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Products")}</h2>
            <div className="flex gap-3 flex-col">
                {products.map((product: any, index: number) => (
                    <div className="flex justify-between items-center" key={`${product.id}-${index}`}>
                        <div className="flex gap-2.5 items-center w-1/2">
                            <div className="bg-bgContent w-[25px] h-[25px] justify-center items-center rounded-primary text-sm text-txtAppointmentColor hidden lg:flex">
                                <div className="">{product.quantity}</div>
                            </div>
                            <p className="text-txtAppointmentColor text-[14px] leading-[18.2px] font-medium max-w-[100px] lg:max-w-[148px] -tracking-[0.01rem]">{product.name}</p>
                            <div className="max-w-max tracking-[0.03rem]">
                                <span className="w-max ml-[4px] rounded-[4px] text-xs font-normal border px-[6px] py-[2px] items-center justify-center flex text-txtAppointmentColor border-borderColorSecondary tracking-[-0.02rem]">
                                    {`${product.selectedVariant.size} ${product.unit_of_measure === "units" ? "" : product.unit_of_measure}`}
                                </span>
                            </div>
                        </div>
                        <div className="flex gap-4 items-center w-1/2 justify-end text-txtAppointmentColor tracking-[0.03rem]">
                            <span className="text-txtcolor text-sm leading-[18.2px] font-normal   w-1/3 text-right -tracking-[0.01rem]">
                                {product?.selectedVariant?.inventory.price && `$${product?.selectedVariant?.inventory.price}`}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductBasket;
