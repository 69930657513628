import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { InfoCircle, X } from "@untitled-ui/icons-react/build/cjs";
import { animated } from "react-spring";
import { onError } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";

const ProductCard = ({ product, onClick, handleAction, isSelected = false, style }: any) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((data) => data.UiStates);
    const [defaultPrice, setDefaultPrice] = useState<number | null>(null);

    useEffect(() => {
        if (product.variants && product.variants.length > 0) {
            setDefaultPrice(product.variants[0].inventory.price); // Set default price to the first variant's price
        }
    }, [product]);
    return (
        <animated.div style={style} className="w-full flex flex-col px-2 pb-4 relative z-[99] hover:z-[99]">
            <div
                className={`border p-3 rounded-secondary border-borderColorPrimary h-full  bg-productBackgroundColor relative md:hover:bg-primaryHover card-shadow-hover ${
                    product.selectedVariant ? "!bg-primaryHover card-shadow" : ""
                } hover:z-[9999]`}
            >
                {product.selectedVariant && (
                    <button
                        className="absolute -right-[6px] -top-[6px] z-10 flex justify-center items-center w-6 h-6 bg-dropdownBackgroundColor border-borderColorPrimary text-txtcolor rounded-full border CloseShadow"
                        onClick={onClick(product, product.selectedVariant)}
                    >
                        <X width="18" />
                    </button>
                )}
                <div
                    className="cursor-pointer relative"
                    onClick={!isSelected ? (product.variants && product.variants.length > 1 ? handleAction("product", product) : onClick(product, product.variants[0])) : undefined}
                >
                    <img
                        className="w-full rounded-modelRadius aspect-square  object-cover"
                        src={product?.images?.find((image: any) => image?.is_main)?.image_url}
                        alt=""
                        onError={onError("Product")}
                    />
                    {product.selectedVariant ? (
                        <>
                            <div className=" absolute right-[6px] bottom-[6px]">
                                <div className="bg-black text-white rounded-full px-3 py-1 text-xs font-medium -tracking-[0.01rem]">
                                    {`${product.selectedVariant.size} ${product.unit_of_measure === "units" ? "" : product.unit_of_measure}`} - ${product.selectedVariant.price}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className=" absolute right-[6px] bottom-[6px]">
                                <div className="bg-black text-white rounded-full px-3 py-1 text-xs font-medium -tracking-[0.01rem]">
                                    {product.variants && product.variants.length > 1 ? `${product.variants.length} ${t("Sizes")}` : `$${defaultPrice}`}
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="flex flex-col w-full mt-3 ">
                    <div className="flex gap-1 items-center ">
                        <h2 className="text-sm font-normal text-TextColor leading-[18.2px] -tracking-[0.02rem] flex-auto truncate">{product.name}</h2>
                        {uiState.isMobile ? (
                            <div className="cursor-pointer items-end" onClick={handleAction("product", product)}>
                                <InfoCircle className="text-TextColor w-4 h-4 hover:text-brandNavigationColorHover" />
                            </div>
                        ) : (
                            <InfoCircle
                                id="alert_circle_svg"
                                width={16}
                                className="text-TextColor cursor-pointer opacity-80 hover:opacity-100 hover:text-primary"
                                onClick={handleAction("product", product)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </animated.div>
    );
};

export default ProductCard;
