import React, { ReactNode } from "react";
import { Button } from "primereact/button";
import { currentTheme } from "src/redux/reducers/common/Common.slice";
import { useAppSelector } from "src/redux/hooks";

interface IProps {
    onClick?: (event: any) => void;
    children?: ReactNode;
    className?: string;
    type?: "submit" | "reset" | "button" | undefined;
    disabled?: boolean;
    isLoading?: boolean;
    primary?: boolean;
    secondary?: boolean;
    outlinePrimary?: boolean;
    outlineSecondary?: boolean;
    icon?: ReactNode | undefined;
    rightIcon?: ReactNode | undefined;
    size?: string;
    borderClass?: string;
    iconOnlyClass?: string;
    shadow?: boolean;
}

const CustomButton = ({
    onClick,
    children,
    className = "",
    disabled = false,
    isLoading = false,
    type = "button",
    primary = false,
    secondary = false,
    outlinePrimary = false,
    outlineSecondary = false,
    icon = undefined,
    rightIcon = undefined,
    size = "",
    borderClass = "",
    iconOnlyClass = "",
    shadow = true,
}: IProps) => {
    const theme = useAppSelector(currentTheme);
    return (
        <Button
            type={type}
            loading={isLoading}
            disabled={disabled}
            onClick={onClick}
            className={`flex justify-center gap-[4px] font-bold text-[16px] leading-[24px] px-3 py-[9px] h-[40px] rounded-secondary !transition-all -tracking-[0.01rem] ${className} ${size} ${borderClass} ${iconOnlyClass} ${
                primary ? "md:hover:bg-brandColorLightDark text-primaryBrandText CustomButtonShadow bg-primary" : ""
            } ${disabled && primary ? "border border-borderColorPrimary !bg-disabledButtonColor text-textGrey !transition-all font-bold text-[16px] leading-[24px] CustomButtonShadow " : ""} ${
                outlinePrimary ? "text-primary bg-containerBgColor border border-primary md:hover:bg-primaryHover CustomButtonShadow" : ""
            } ${
                secondary
                    ? `bg-transparent border border-borderColorPrimary ${
                          theme ? "text-textWhiteBlack md:hover:bg-primaryHover md:hover:border-primaryBorderHover" : "text-textWhiteBlack md:hover:bg-textWhiteBlack md:hover:!text-textBlackWhite"
                      }  CustomButtonShadow`
                    : ""
            } ${outlineSecondary ? "text-txtAppointmentColor  border border-borderColorSecondary md:hover:bg-primaryHover md:hover:border-primaryBorderHover CustomButtonShadow" : ""} ${
                disabled && secondary ? "!bg-arrowBackgroundColor border border-borderColorSecondary text-black CustomButtonShadow" : ""
            } ${shadow && "shadow-InputAndButton"} `}
        >
            {icon}
            {children}
            {rightIcon}
        </Button>
    );
};

export default CustomButton;
