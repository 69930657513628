export interface ILoginProps {
    emailPhone: any;
}

export interface IInitAction {
    login: boolean;
    otp: boolean;
    phoneEmailVerification: boolean;
    emailVerification: boolean;
    passwordVerification: boolean;
    forgotPassword: boolean;
    forgotPasswordOtp: boolean;
    newPassword: boolean;
    regularProfile: boolean;
    socialProfile: boolean;
}
export const initAction: IInitAction = {
    login: true,
    otp: false,
    phoneEmailVerification: false,
    emailVerification: false,
    passwordVerification: false,
    forgotPassword: false,
    forgotPasswordOtp: false,
    newPassword: false,
    regularProfile: false,
    socialProfile: false,
};

export interface IAuthData {
    isEmail: boolean;
    email: any;
    phone: any;
}
export const DefaultAuthDataInit: IAuthData = {
    isEmail: true,
    email: null,
    phone: null,
};

export const initData = {
    phone: null,
    phone_country_code: null,
};

export interface IGettingStarted {
    setAuthData: any;
    authData: any;
    handleAuthAction: (type: keyof IInitAction) => void;
    handleClose: () => void;
}

export interface IVerifyOtpProps {
    authData?: any;
    handleVerifyOtpRedirect: () => void;
    handleAuthAction: (type: keyof IInitAction) => void;
    setAuthData?: any;
    resetAuthAction: () => void;
}

export interface IProfileProps {
    authData?: any;
    setAuthData?: any;
    handleAction: (type: keyof IInitAction) => () => void;
    upload: boolean;
    setUpload: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IProfileVerifyProps {
    authData?: any;
    setAuthData?: any;
    handleAction: (type: keyof IInitAction) => () => void;
    handleClose: () => void;
}

export interface IOtp {
    otp?: string;
}

export interface IRegularProfile {
    first_name: string;
    last_name: string;
    password: string;
    password_confirmation: string;
    profile_image_name: string | null;
    referral_code: string | undefined;
}

export interface ISocialProfile {
    first_name: string;
    last_name: string;
    phone_country_code: string;
    phone: string;
    profile_image_name: string | null;
    referral_code: string | undefined;
}
