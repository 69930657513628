import React, { useEffect, useState } from "react";
import { BookingTABS, EBookingTabState } from "./bookAppointment.interface";
import SelectSpecialists from "./SelectSpecialists";
import SelectDateAndTime from "./SelectDateAndTime";
import SelectServices from "./SelectServices";
import BookAppointmentTabMenu from "./BookAppointmentTabMenu";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import FinalizeBooking from "./FinalizeBooking";
import ProductBooking from "./ProductBooking";
import { getBookingInfo, setBookingInitialState, setNoShowPolicyVisible, setSelectedShopLocation, setSettings, setStep } from "src/app/BookAppointment/Booking.slice";
import { useNavigate, useParams } from "react-router-dom";
import { allShopLocations, allShopProducts, currentSettings, currentShop, currentUser, getAllShopProducts, me, setUser } from "src/redux/reducers/common/Common.slice";
import { ISpecialistService } from "src/Interface";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { PATH } from "src/constants/path";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import useModifyAppointment from "src/hooks/useModifyAppointment";
import { LOCAL_STORAGE_KEYS } from "src/constants/common";
import Loader from "src/components/Loader/Loader";
import AppointmentControlPanel from "./AppointmentControlPanel";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
export interface ILoading {
    specialist: boolean;
    service: boolean;
}
const BookAppointment = () => {
    const user = useAppSelector(currentUser);
    const currentSettingsList = useAppSelector(currentSettings);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const productList = useAppSelector(allShopProducts);
    const booking = useAppSelector(getBookingInfo);
    const shop = useAppSelector(currentShop);
    const [activeTab, setActiveTab] = useState<any>();
    const [isLoading, setIsLoading] = useState<ILoading>({
        specialist: false,
        service: false,
    });
    const [count, setCount] = useState<any>({ specialist: 0, service: 0 });
    const shopLocations: any[] = useAppSelector(allShopLocations);
    const { tab: currentTab, id: bookingId } = useParams();
    const { getBookingData, isAuthenticated, loading, getBookingUserData, setIsAuthenticated } = useModifyAppointment();
    const [isLogoutLoading, setIsLogoutLoading] = useState(false);

    useEffect(() => {
        if (booking.isCompleted) {
            dispatch(setBookingInitialState());
            dispatch(me());
            if (shopLocations && shopLocations.length === 1) {
                dispatch(setSelectedShopLocation(shopLocations[0]));
            }
        }
    }, [booking.isCompleted]);

    useEffect(() => {
        if (!booking.selectedShopLocation && !bookingId) {
            navigateBasedOnShopLocations();
        }
        handleStep(EBookingTabState.TAB_MENU);
        if (booking.selectedShopLocation) {
            // getSpecialists();
            // getServices();
            listProducts();
        }
    }, []);

    useEffect(() => {
        if (booking.step !== EBookingTabState.FINALIZE_BOOKING) {
            if (booking.selectedShopLocation) {
                getSpecialists();
                getServices();
            }
        }
    }, [booking.selectedSpecialist, booking.selectedBookingDate, booking.selectedBookingTime, booking.selectedServices]);

    useEffect(() => {
        if (!!localStorage.getItem(LOCAL_STORAGE_KEYS.isLoginPopup)) {
            localStorage.removeItem(LOCAL_STORAGE_KEYS.isLoginPopup);
            // window.location.reload();
        }
    }, []);
    useEffect(() => {
        if (!booking.selectedSpecialist) {
            const cancellationSettings = currentSettingsList?.find((setting: any) => setting.type === "cancellation");
            const noShowcurrentSettings = currentSettingsList?.find((setting: any) => setting.type === "no_show");
            const loyaltySettings = currentSettingsList?.find((setting: any) => setting.type === "loyalty");
            const referSettings = currentSettingsList?.find((setting: any) => setting.type === "refer");
            const calendarSettings = currentSettingsList?.find((setting: any) => setting.type === "calendar");
            const paymentSettings = currentSettingsList?.find((setting: any) => setting.type === "payment");

            if (paymentSettings) {
                dispatch(
                    setSettings({
                        payment: {
                            isBookWithCard: paymentSettings?.value.is_book_with_card,
                        },
                    }),
                );
            }
            if (cancellationSettings || noShowcurrentSettings) {
                dispatch(setNoShowPolicyVisible(cancellationSettings?.status || noShowcurrentSettings?.status));
            } else {
                dispatch(setNoShowPolicyVisible(false));
            }
            if (cancellationSettings) {
                dispatch(
                    setSettings({
                        cancellation: {
                            status: cancellationSettings?.status,
                            cancellation_charge: cancellationSettings?.value.cancellation_charge,
                            cancellation_type: cancellationSettings?.value.cancellation_type,
                            cancellation_window: cancellationSettings?.value.cancellation_window,
                        },
                    }),
                );
            } else {
                dispatch(
                    setSettings({
                        cancellation: {
                            status: false,
                        },
                    }),
                );
            }
            if (noShowcurrentSettings) {
                dispatch(
                    setSettings({
                        noShow: {
                            status: noShowcurrentSettings?.status,
                            no_show_charge: noShowcurrentSettings.value.no_show_charge,
                            no_show_type: noShowcurrentSettings.value.no_show_type,
                        },
                    }),
                );
            } else {
                dispatch(
                    setSettings({
                        noShow: {
                            status: false,
                        },
                    }),
                );
            }
            if (loyaltySettings) {
                dispatch(
                    setSettings({
                        loyalty: {
                            status: loyaltySettings?.status,
                            receive: loyaltySettings?.value.receive,
                            spend: loyaltySettings?.value.spend,
                            totalAmount: user ? user.total_loyalty_amount : 0,
                        },
                    }),
                );
            } else {
                dispatch(
                    setSettings({
                        loyalty: {
                            status: false,
                            receive: 0,
                            spend: 0,
                            totalAmount: 0,
                        },
                    }),
                );
            }
            if (referSettings) {
                dispatch(
                    setSettings({
                        refer: {
                            referee: referSettings?.value.referee,
                            refferer: referSettings?.value.refferer,
                            referee_type: referSettings?.value.referee_type,
                            refferer_type: referSettings?.value.refferer_type,
                            status: referSettings.status,
                        },
                    }),
                );
            } else {
                dispatch(
                    setSettings({
                        refer: {
                            referee: 0,
                            refferer: 0,
                            referee_type: "value",
                            refferer_type: "value",
                            status: false,
                        },
                    }),
                );
            }
            if (shop) {
                dispatch(
                    setSettings({
                        payment: {
                            enable: shop.admin.is_verified_stripe_account,
                        },
                    }),
                );
            }
            if (calendarSettings) {
                dispatch(
                    setSettings({
                        calendar: {
                            advance_weeks: calendarSettings.value.advance_weeks,
                        },
                    }),
                );
            } else {
                dispatch(
                    setSettings({
                        calendar: { advance_weeks: 0 },
                    }),
                );
            }
        }
    }, [currentSettingsList, booking.selectedSpecialist, shop, user]);

    useEffect(() => {
        if (isAuthenticated && bookingId) {
            dispatch(setStep(EBookingTabState.FINALIZE_BOOKING));
            setActiveTab(<FinalizeBooking />);
        }
    }, [currentTab, isAuthenticated, bookingId]);

    useEffect(() => {
        const hasReloaded = localStorage.getItem(LOCAL_STORAGE_KEYS.hasReloaded);
        const fetchUserData = async () => {
            if (bookingId && !hasReloaded) {
                try {
                    await getBookingUserData(+bookingId).then((res) => {
                        if (res) {
                            if (user && res?.user) {
                                if (user.id === res.user.id) {
                                    getBookingData(+bookingId);
                                } else {
                                    setIsLogoutLoading(true);
                                    localStorage.removeItem("persist:root");
                                    localStorage.removeItem(LOCAL_STORAGE_KEYS.authToken);
                                    dispatch(setUser(null));
                                    setIsAuthenticated(false);
                                    localStorage.setItem(LOCAL_STORAGE_KEYS.expressBookingId, String(bookingId));
                                    dispatch(setIsShowLoginModal(true));
                                    setIsLogoutLoading(false);
                                    if (!hasReloaded) {
                                        localStorage.setItem(LOCAL_STORAGE_KEYS.hasReloaded, "true");
                                        localStorage.setItem(LOCAL_STORAGE_KEYS.isLoginPopup, "true");
                                        navigateBasedOnShopLocations();
                                        // window.location.reload();
                                        // window.location.href = `${window.location.origin}/location`;
                                    }
                                }
                            } else {
                                navigateBasedOnShopLocations();
                                setIsAuthenticated(false);
                                localStorage.setItem(LOCAL_STORAGE_KEYS.expressBookingId, String(bookingId));
                                dispatch(setIsShowLoginModal(true));
                            }
                        }
                    });
                } catch (error) {
                    console.error("Failed to fetch booking user data:", error);
                }
            }
        };

        if (bookingId) {
            if (!user) {
                localStorage.setItem(LOCAL_STORAGE_KEYS.expressBookingId, bookingId);
            }
            fetchUserData();
        }
    }, [currentTab]);

    useEffect(() => {
        if (booking.selectedShopLocation) {
            switch (currentTab) {
                case BookingTABS.SELECT_SPECIALIST:
                    if (booking.isModifyingAppointment) {
                        navigate(PATH.APPOINTEMENT);
                    } else {
                        setActiveTab(<SelectSpecialists />);
                    }
                    break;
                case BookingTABS.SELECT_SERVICE:
                    if (booking.isModifyingAppointment && booking?.oldPaymentOption === "pay_now") {
                        navigate(PATH.APPOINTEMENT);
                    } else {
                        setActiveTab(<SelectServices />);
                    }
                    break;
                case BookingTABS.SELECT_DATE_TIME:
                    setActiveTab(<SelectDateAndTime />);
                    break;
                case BookingTABS.PRODUCT_BOOKING:
                    if (booking.isModifyingAppointment && booking?.oldPaymentOption === "pay_now") {
                        navigate(PATH.APPOINTEMENT);
                    } else if (booking.selectedShopLocation && booking.selectedBookingDate && booking.selectedBookingTime && booking.selectedServices.length && booking.selectedSpecialist) {
                        setActiveTab(<ProductBooking />);
                    } else {
                        navigate(PATH.APPOINTEMENT);
                    }
                    break;
                case BookingTABS.FINALIZE_BOOKING:
                    if (booking.isModifyingAppointment && booking?.oldPaymentOption === "pay_now") {
                        navigate(PATH.APPOINTEMENT);
                    } else if (booking.selectedShopLocation && booking.selectedBookingDate && booking.selectedBookingTime && booking.selectedServices.length && booking.selectedSpecialist) {
                        setActiveTab(<FinalizeBooking />);
                    } else {
                        navigateBasedOnShopLocations();
                    }
                    break;
                default:
                    setActiveTab(<BookAppointmentTabMenu isLoading={isLoading} count={count} />);
                    break;
            }
        } else {
            if (!bookingId) {
                navigateBasedOnShopLocations();
            }
        }
    }, [currentTab, count]);

    const listProducts = () => {
        const payload = {
            params: {
                shop_id: shop.id,
                location_id: booking.selectedShopLocation?.id,
            },
            data: {
                booking_id: booking.modifyingAppointmentId || null,
            },
        };
        dispatch(getAllShopProducts(payload));
    };

    const getSpecialists = async () => {
        setIsLoading((prev) => ({ ...prev, specialist: true }));
        const payloadServiceIds = booking.selectedServices.length ? booking.selectedServices?.map((service: ISpecialistService) => service.id) : undefined;
        const payloadData = {
            service_id: payloadServiceIds,
            booking_date: booking.selectedBookingDate,
            booking_time: booking.selectedBookingTime ? `${booking.selectedBookingTime}:00` : undefined,
        };
        const payloadObj = {
            shop_id: shop.id,
            location_id: booking.selectedShopLocation?.id,
        };
        axiosPost(API.STAFF.LIST, payloadData, payloadObj)
            .then(async (response) => {
                setCount((prev: any) => ({
                    ...prev,
                    specialist: response.data.data.length,
                }));
                return;
            })
            .catch((error) => {})
            .finally(() => {
                setIsLoading((prev) => ({ ...prev, specialist: false }));
            });
    };

    const getServices = async () => {
        setIsLoading((prev) => ({ ...prev, service: true }));
        const payloadData = {
            staff_id: booking.selectedSpecialist?.id,
            booking_date: booking.selectedBookingTime ? booking.selectedBookingDate : undefined,
            booking_time: booking.selectedBookingTime ? `${booking.selectedBookingTime}:00` : undefined,
            booking_id: booking.isModifyingAppointment ? booking.modifyingAppointmentId : null,
        };
        const urlParamObj = {
            shop_id: shop.id,
            location_id: booking.selectedShopLocation?.id,
        };
        axiosPost(API.SERVICE.LIST, payloadData, urlParamObj)
            .then(async (response) => {
                setCount((prev: any) => ({
                    ...prev,
                    service: response.data.data.length,
                }));
                return;
            })
            .catch((error) => {})
            .finally(() => {
                setIsLoading((prev) => ({ ...prev, service: false }));
            });
    };

    const handleStep = (step: EBookingTabState) => {
        dispatch(setStep(step));
    };

    return <>{bookingId ? loading || isLogoutLoading ? <Loader /> : <AppointmentControlPanel activeTab={activeTab} /> : <AppointmentControlPanel activeTab={activeTab} />}</>;
};

export default BookAppointment;
