import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Facebook1SVG, GoogleSVG, Instagram1SVG, UnionSVG } from "src/theme/Images";
import { useTranslation } from "react-i18next";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import CompleteProfile from "src/app/Auth/CompleteProfile/CompleteProfile";
import Auth from "../Auth";
import { currentSettings, currentShop, currentUser, globalLanguage } from "src/redux/reducers/common/Common.slice";
import { Menu02, User01, XClose } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";
import SelectBox from "src/components/SelectBox";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import ConditionalNoShowView from "src/app/BookAppointment/ConditionalNoShowView";
import { ROUTES } from "src/constants/routes";
import { useLanguage } from "src/hooks/useCommon";
import { getShortName } from "src/utils/global-functions";
import RadioSwitch from "src/components/RadioSwitch";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { isGetApp } from "src/redux/reducers/Landing.slice";
import Profile from "../Profile";
import { IInitAction, initAction } from "./CommonHeader.interface";
import { getBookingInfo } from "../BookAppointment/Booking.slice";
import { INSTAGRAM_URL } from "src/constants/common";

const CommonHeader: React.FC<any> = () => {
    const currentLanguage = useAppSelector(globalLanguage);
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const location = useLocation();
    const settings = useAppSelector(currentSettings);
    const [action, setAction] = useState<IInitAction>(initAction);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [nav, setNav] = useState(false);
    const [appearance, setAppearance] = useState<any>(null);
    const { languageOptions, languages, handleLanguage, handleRadioChange } = useLanguage();
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const isGetAppVisible = useAppSelector(isGetApp);
    const [isImageError, setIsImageError] = useState<boolean>(false);
    const bookingInfo = useAppSelector(getBookingInfo);

    useEffect(() => {
        if (user) {
            const isAccountComplete = user.is_account_complete;
            if (!isAccountComplete) {
                handleAction("completeProfile")();
            } else if (isAccountComplete && user.is_password_set === false) {
                // this is for when client created from admin.
                handleAction("completeProfile")();
            }
        }
    }, [settings]);

    useEffect(() => {
        if (settings.length) {
            const appearances = settings.find((setting: any) => setting.type === "appearance");
            if (appearances) {
                setAppearance(appearances.value);
            }
        }
    }, [settings]);

    const handleAction = (type: keyof IInitAction) => () => {
        if (nav) {
            handleNav();
        }
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleLogin = () => {
        if (nav) {
            handleNav();
        }
        dispatch(setIsShowLoginModal(true));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    const handleNav = () => {
        setNav(!nav);
    };

    const getLogo = () => {
        let response;
        let logo;
        let text;

        if (shop) {
            const logoHeight = appearance ? `${appearance?.logo_height}px` : "50px";
            const logotextSize = appearance ? `${appearance?.font_size}px` : "18px";
            const logoFontFamily = appearance ? `${appearance?.logo_font_family}` : "inter";
            const logoText = appearance ? `${appearance?.name}` : "";
            logo = (
                <img
                    src={shop.logo_image_url ?? UnionSVG}
                    className="object-contain max-lg:!h-[40px] max-lg:!max-h-[40px] max-lg:!min-h-[40px]"
                    alt=""
                    style={{
                        height: logoHeight,
                        minHeight: logoHeight,
                        maxHeight: logoHeight,
                    }}
                />
            );
            text = (
                <span
                    className={`ml-2 cursor-pointer flex max-lg:!text-[16px] max-lg:!leading-[18px] w-max lg:text-brandNavigationColorHover items-center font-logo font-logoWeight`}
                    style={{ fontSize: logotextSize, fontFamily: logoFontFamily }}
                >
                    {logoText}
                </span>
            );

            if (appearance) {
                switch (appearance.logo_type) {
                    case "logo":
                        response = logo;
                        break;
                    case "logo_text":
                        response = (
                            <>
                                {logo} {text}
                            </>
                        );
                        break;
                    case "text":
                        response = text;
                        break;
                    default:
                        response = logo;
                        break;
                }
            }
        }

        return response;
    };

    const handleBookNow = () => {
        if (nav) {
            handleNav();
        }
        navigateBasedOnShopLocations();
    };

    const handleImageError = () => {
        setIsImageError(true);
    };

    return (
        <div
            className={`flex justify-between ${isGetAppVisible ? "" : "getAppHeader"} ${bookingInfo.isFullScreen ? "fullScreenHeader" : ""} ${
                location.pathname === ROUTES.HOME
                    ? "bg-brandNavigationColor px-[24px] lg:pr-[34px] lg:pl-[34px] min-h-[60px] lg:min-h-[90px]"
                    : "min-h-[60px] border-textGray lg:pl-[64px] shadow-[0px_4px_4px_0px_var(--header-shadow)] bg-brandNavigationColor lg:pr-[32px]"
            } items-center px-[24px] sticky  w-full z-[9999] top-0 shrink-0 transition-all duration-300 lg:py-2`}
        >
            <div className="items-center cursor-pointer justify-between  w-full lg:w-auto flex">
                <div onClick={() => navigate("/")} className="flex items-center">
                    {getLogo()}
                </div>
                <div className="flex flex-row justify-end pl-2.5 ml-3  lg:hidden items-center">
                    {user ? (
                        <div onClick={handleAction("profile")} className="flex cursor-pointer items-center rounded-secondary w-[30px] h-[30px]">
                            {user?.profile_image_url && !isImageError ? (
                                <img src={user.profile_image_url} className="w-full h-full rounded-minimal" alt="" onError={handleImageError} />
                            ) : user?.full_name ? (
                                <div
                                    className={`h-[30px] max-h-[30px] min-h-[30px] min-w-[30px] w-[30px] bg-mainBackgroundColor flex justify-center items-center shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] font-medium rounded-minimal hover:bg-brandNavigationColorHover text-primary hover:!text-brandNavigationColor hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)]`}
                                >
                                    <span className="text-[12px] font-semibold leading-[30px]">{getShortName(user?.full_name as string)}</span>
                                </div>
                            ) : (
                                <span className="bg-uploadProfileBg flex justify-center items-center  text-primaryAppointment font-medium h-[30px] w-[30px] rounded-secondary">
                                    <User01 className="text-textMoreGrey w-[18px] h-[18px]" />
                                </span>
                            )}
                        </div>
                    ) : (
                        <CustomButton
                            secondary
                            size="w-full"
                            className="w-[72px] !px-3 !py-[0px] !h-[30px] !text-[12px] whitespace-nowrap cursor-pointer !text-brandNavigationColorHover !bg-brandNavigationColor lg:hover:bg-primaryHover border-brandNavigationColorHover lg:hover:border-primaryBorderHover"
                            onClick={handleLogin}
                        >
                            {t("Log in")}
                        </CustomButton>
                    )}
                    <div className="w-[35px] justify-end pl-2.5 ml-3 border-l items-center lg:hidden border-borderColorPrimary" onClick={handleNav}>
                        {nav ? <XClose className="w-[24px] !text-brandNavigationColorHover" /> : <Menu02 className="w-[24px] !text-brandNavigationColorHover" />}
                    </div>
                </div>
            </div>
            <ul
                className={
                    nav
                        ? `ease-in-out right-0 left-0 z-[99999] absolute  top-[60px] flex flex-col lg:flex-row lg:justify-between lg:items-center lg:h-[44px] 2xl:h-[44px] lg:top-0 xl:h-[45px] lg:space-y-0 lg:space-x-3.5 xls:space-x-3.5 xl:space-x-3.5  lg:sticky  lg:pr-0 pt-[24px] lg:pl-[0px] lg:pt-[0px] justify-start navbar-duration duration-1000 items-start  lg:border-l-0 border-borderColorPrimary *:w-full menu_wrapper `
                        : "right-0 z-[99999] absolute menu_wrapper top-[45px] flex-col lg:flex-row lg:justify-between lg:items-center lg:h-[44px] 2xl:h-[44px] lg:top-0 xl:h-[45px] lg:sticky  lg:pl-[0px] lg:pt-[0px] min-w-[240px] justify-start navbar-duration space-x-3 lg:space-x-3.5 xls:space-x-3.5 xl:space-x-3.5 navbar-duration ease-in-out duration-500 hidden lg:flex items-center"
                }
            >
                {shop && (
                    <li className="gap-3.5 flex flex-row items-center justify-center">
                        {/* {shop.google_review && (
                            <Link
                                to={`https://g.page/r/${shop.google_review}/review`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`h-[32px] w-[32px] min-w-[32px] flex justify-center items-center  hover:bg-primaryHover`}
                            >
                                <GoogleSVG className="w-[18px] h-[18px] text-textGrey" />
                            </Link>
                        )} */}
                        {shop.facebook_page && (
                            <Link
                                to={`https://www.facebook.com/${shop.facebook_page}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`flex items-center w-[30px] min-w-[30px] justify-center lg:!h-[30px] !leading-[30px] rounded-secondary   
                        max-lg:w-full max-lg:!h-[30px] max-lg:rounded-minimal !py-[2px] !h-[30px] !text-[12px] whitespace-nowrap cursor-pointer bookNowBorder md:hover:border-primaryBorderHover lg:text-brandNavigationColorHover navbarLoginHover`}
                            >
                                <Facebook1SVG className="w-[18px] h-[18px]" />
                            </Link>
                        )}
                        {shop.instagram_page && (
                            <Link
                                to={`${INSTAGRAM_URL}${shop.instagram_page}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`flex items-center w-[30px] min-w-[30px] justify-center lg:!h-[30px] !leading-[30px] rounded-secondary   
                        max-lg:w-full max-lg:!h-[30px] max-lg:rounded-minimal !py-[2px] !h-[30px] !text-[12px] whitespace-nowrap cursor-pointer bookNowBorder md:hover:border-primaryBorderHover lg:text-brandNavigationColorHover navbarLoginHover`}
                            >
                                <img src={Instagram1SVG} className="w-[18px] h-[18px]" alt="" />
                            </Link>
                        )}
                    </li>
                )}
                {languages.length > 1 && <RadioSwitch options={languageOptions} name="about_language" value={currentLanguage} onChange={handleRadioChange} />}
                <li className="flex flex-row gap-3.5 items-center max-lg:mt-auto">
                    {languages.length > 1 && (
                        <div className="max-lg:hidden">
                            <SelectBox
                                options={languageOptions}
                                value={languageOptions.find((lan: any) => lan.value === currentLanguage)}
                                onChange={handleLanguage}
                                inputClass="outline-select-box rounded-secondary navigation-select" // Change this to inputClass
                                allowClear={false}
                                searchable={false}
                                disabled={false}
                                name={"language"}
                            />
                        </div>
                    )}
                    <div className="max-lg:mt-2 max-lg:border-t max-lg:p-4 max-lg:pb-2 max-lg:border-mainBackgroundColor max-lg:shadow-[0px_-4px_10px_0px_var(--header-shadow)] max-lg:w-full">
                        <CustomButton
                            secondary
                            size="w-full"
                            className="flex items-center !px-[18px] lg:!h-[30px] !leading-[30px] rounded-secondary   
                        max-lg:w-full max-lg:!h-[36px] max-lg:rounded-minimal !py-[2px] !h-[30px] !text-[12px] whitespace-nowrap cursor-pointer bookNowBorder md:hover:border-primaryBorderHover lg:text-brandNavigationColorHover navbarLoginHover"
                            onClick={handleBookNow}
                        >
                            <span className="mt-[-1px]">{t("Book Now")}</span>
                        </CustomButton>
                    </div>
                    {user ? (
                        <div className="hidden lg:flex">
                            <div className="flex gap-2">
                                <div onClick={handleAction("profile")} className="flex cursor-pointer items-center rounded-secondary w-[30px] h-[30px]">
                                    {user?.profile_image_url && !isImageError ? (
                                        <img src={user.profile_image_url} className="w-full h-full rounded-minimal" alt="" onError={handleImageError} />
                                    ) : user?.full_name ? (
                                        <div
                                            className={`h-[30px] max-h-[30px] min-h-[30px] min-w-[30px] w-[30px] bg-mainBackgroundColor flex justify-center items-center shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] font-medium rounded-minimal hover:bg-brandNavigationColorHover text-primary hover:!text-brandNavigationColor hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)]`}
                                        >
                                            <span className="text-[12px] font-semibold leading-[30px]">{getShortName(user?.full_name as string)}</span>
                                        </div>
                                    ) : (
                                        <span className="bg-uploadProfileBg flex justify-center items-center text-primaryAppointment font-medium h-[30px] w-[30px] rounded-secondary">
                                            <User01 className="text-textMoreGrey w-[18px] h-[18px]" />
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="hidden lg:flex">
                            <CustomButton
                                secondary
                                size="w-full"
                                className="min-w-[72px] !px-3 !py-[2px] !h-[30px] !text-[12px] whitespace-nowrap cursor-pointer bookNowBorder md:hover:border-primaryBorderHover lg:text-brandNavigationColorHover navbarLoginHover"
                                onClick={handleLogin}
                            >
                                <span className="mt-[-1px]">{t("Log in")}</span>
                            </CustomButton>
                        </div>
                    )}
                    {AuthUserState.isShowLoginModal && <Auth />}
                    {user && <ConditionalNoShowView />}
                    <Profile handleClose={handleClose} isOpen={action.profile} />
                    {action.completeProfile && <CompleteProfile handleClose={handleClose} />}
                </li>
            </ul>
        </div>
    );
};

export default CommonHeader;
