import { getClientDetails, setNoShowPolicyVisible, setSettings } from "src/app/BookAppointment/Booking.slice";
import { SPECIALIST_PAY_STRUCTURE_TYPE } from "src/constants/common";
import { ISpecialist } from "src/Interface";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { currentSettings, currentShop, currentUser } from "src/redux/reducers/common/Common.slice";

export const useManageSettings = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const currentSettingsList = useAppSelector(currentSettings);
    const shop = useAppSelector(currentShop);

    const manageShopSetting = () => {
        const settings = applySettings(currentSettingsList);
        dispatch(setSettings({ payment: { enable: settings.paymentVerified } }));
    };

    const manageSpecialistSetting = (specialistData: ISpecialist | undefined) => {
        if (specialistData) {
            if (specialistData.shop_staff_settings && specialistData.shop_staff_settings.length) {
                const staffSettings = specialistData.shop_staff_settings;
                const calendarSettings = staffSettings?.find((setting: any) => setting.type === "calendar");
                if (calendarSettings) {
                    dispatch(
                        setSettings({
                            calendar: {
                                advance_weeks: calendarSettings.value.advance_weeks,
                            },
                        }),
                    );
                } else {
                    dispatch(
                        setSettings({
                            calendar: { advance_weeks: 0 },
                        }),
                    );
                }
            } else {
                dispatch(
                    setSettings({
                        calendar: { advance_weeks: 0 },
                    }),
                );
            }
            if (specialistData.pay_structure && specialistData.pay_structure.pay_structure_type && SPECIALIST_PAY_STRUCTURE_TYPE.includes(specialistData.pay_structure.pay_structure_type)) {
                if (shop && user) {
                    const payload = {
                        params: {
                            shop_id: shop.id,
                        },
                        data: {
                            shop_admin_id: specialistData.id,
                        },
                    };
                    dispatch(getClientDetails(payload));
                }
                if (specialistData.shop_staff_settings && specialistData.shop_staff_settings.length) {
                    const settings = applySettings(specialistData.shop_staff_settings, specialistData.is_verified_stripe_account);
                    dispatch(
                        setSettings({
                            payment: { enable: settings.paymentVerified },
                        }),
                    );
                } else {
                    manageShopSetting();
                }
            } else {
                if (shop && user) {
                    const payload = {
                        params: {
                            shop_id: shop.id,
                        },
                    };
                    dispatch(getClientDetails(payload));
                }
                manageShopSetting();
            }
        } else {
            const calendarSettings = currentSettingsList?.find((setting: any) => setting.type === "calendar");
            if (calendarSettings) {
                dispatch(
                    setSettings({
                        calendar: {
                            advance_weeks: calendarSettings.value.advance_weeks,
                        },
                    }),
                );
            } else {
                dispatch(
                    setSettings({
                        calendar: { advance_weeks: 0 },
                    }),
                );
            }
        }
    };

    const applySettings = (settingsList: any[], paymentVerified = shop.admin.is_verified_stripe_account) => {
        const cancellationSettings = settingsList?.find((setting: any) => setting.type === "cancellation");
        const noShowSettings = settingsList?.find((setting: any) => setting.type === "no_show");
        const loyaltySettings = settingsList?.find((setting: any) => setting.type === "loyalty");
        const referSettings = settingsList?.find((setting: any) => setting.type === "refer");
        const paymentSettings = settingsList?.find((setting: any) => setting.type === "payment");

        if (loyaltySettings) {
            dispatch(
                setSettings({
                    loyalty: {
                        status: loyaltySettings?.status,
                        receive: loyaltySettings?.value.receive,
                        spend: loyaltySettings?.value.spend,
                        totalAmount: user ? user.total_loyalty_amount : 0,
                    },
                }),
            );
        } else {
            dispatch(
                setSettings({
                    loyalty: {
                        status: false,
                        receive: 0,
                        spend: 0,
                        totalAmount: 0,
                    },
                }),
            );
        }

        if (referSettings) {
            dispatch(
                setSettings({
                    refer: {
                        referee: referSettings?.value.referee,
                        refferer: referSettings?.value.refferer,
                        referee_type: referSettings?.value.referee_type,
                        refferer_type: referSettings?.value.refferer_type,
                        status: referSettings.status,
                    },
                }),
            );
        } else {
            dispatch(
                setSettings({
                    refer: {
                        referee: 0,
                        refferer: 0,
                        referee_type: "value",
                        refferer_type: "value",
                        status: false,
                    },
                }),
            );
        }

        if (cancellationSettings || noShowSettings) {
            dispatch(setNoShowPolicyVisible(cancellationSettings?.status || noShowSettings?.status));

            dispatch(
                setSettings({
                    cancellation: {
                        status: cancellationSettings?.status,
                        cancellation_charge: cancellationSettings?.value.cancellation_charge,
                        cancellation_type: cancellationSettings?.value.cancellation_type,
                        cancellation_window: cancellationSettings?.value.cancellation_window,
                    },
                    noShow: {
                        status: noShowSettings?.status,
                        no_show_charge: noShowSettings?.value.no_show_charge,
                        no_show_type: noShowSettings?.value.no_show_type,
                    },
                }),
            );
        } else {
            dispatch(setNoShowPolicyVisible(false));
        }

        if (paymentSettings) {
            dispatch(
                setSettings({
                    payment: {
                        isBookWithCard: paymentSettings?.value.is_book_with_card,
                    },
                }),
            );
        } else {
            dispatch(
                setSettings({
                    payment: { isBookWithCard: false },
                }),
            );
        }

        return {
            paymentVerified,
        };
    };

    return { manageShopSetting, manageSpecialistSetting };
};

export default useManageSettings;
