import React, { useEffect, useState } from "react";
import { FormProvider, Resolver, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { GLOBALVARIABLE, LOCAL_STORAGE_KEYS, errorCode } from "../../../constants/common";
import { axiosPatch } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { useTranslation } from "react-i18next";
import { checkFileTypeValidation, convertBase64ToFile, logout } from "src/utils/global-functions";
import { s3Upload } from "src/utils/s3Operations";
import { s3Path } from "src/utils/s3Path";
import { IRegularProfile } from "../Auth.interface";
import { currentShop, currentUser, me } from "src/redux/reducers/common/Common.slice";
import CropperModal from "src/components/CropperModal/CropperModal";
import WRegularProfile from "./Web/WRegularProfile";
import MRegularProfile from "./Mobile/MRegularProfile";
import MCropperModal from "src/components/CropperModal/MCropperModal";
import useModifyAppointment from "src/hooks/useModifyAppointment";
import { PATH } from "src/constants/path";
import { BookingTABS } from "src/app/BookAppointment/bookAppointment.interface";
import { useNavigate } from "react-router-dom";

interface IProps {
    handleClose: () => void;
    upload: boolean;
    setUpload: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegularProfile = ({ handleClose, upload, setUpload }: IProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isLoading, setIsLoading] = useState(false);
    const [referralConfig, setReferralConfig] = useState({ status: false, referee: 0, refferer: 0, referee_type: "percentage", refferer_type: "percentage" });
    const [errorImage, setErrorImage] = useState({
        profile: false,
    });
    const [cropData, setCropData] = useState<any>("");
    const [image, setImage] = useState<any>("");
    const [file, setFile] = useState<any>({ convertedFile: "", filename: "" });
    const { getBookingData } = useModifyAppointment();
    const navigate = useNavigate();

    const schema = Yup.object({
        first_name: Yup.string().required(t("This field is required")),
        last_name: Yup.string().required(t("This field is required")),
        password: Yup.string()
            .required(t("This field is required"))
            .trim()
            .min(8, t("Password must be at least 8 characters long."))
            .matches(/(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])/, t("Password must contain at least one special character."))
            .matches(/(?=.*\d)/, t("Password must contain at least one number."))
            .matches(/(?=.*[A-Z])/, t("Password must contain at least one uppercase letter."))
            .matches(/(?=.*[a-z])/, t("Password must contain at least one lowercase letter.")),
        // .matches(COMPLETEPROFILE_PASSWORD_VALIDATION_REGEX, t("Include at least one uppercase letter, one lowercase letter, and a digit or one of these special characters: !@#$%^&*_|")),
        password_confirmation: Yup.string()
            .required(t("This field is required"))
            .oneOf([Yup.ref("password"), null], t("Passwords do not match")),
        profile_image_name: Yup.string().nullable(),
        referral_code: Yup.string().notRequired(),
    }).required();

    const methods = useForm<IRegularProfile>({
        resolver: yupResolver(schema) as Resolver<IRegularProfile>,
        defaultValues: {
            first_name: user?.first_name || "",
            last_name: user?.last_name || "",
            password: "",
            password_confirmation: "",
            profile_image_name: null,
            referral_code: "",
        },
    });

    const { handleSubmit, setError, setValue, clearErrors } = methods;

    useEffect(() => {
        const shopSetting = shop.shop_settings.find((shop_setting: { type: string }) => shop_setting.type === "refer");
        if (shopSetting) {
            setReferralConfig({
                status: shopSetting.status || false,
                referee: shopSetting.value.referee || 0,
                referee_type: shopSetting.value.referee_type || "percentage",
                refferer: shopSetting.value.refferer || 0,
                refferer_type: shopSetting.value.refferer_type || "percentage",
            });
        }
    }, []);

    const handleChange = (data: IRegularProfile) => {
        axiosPatch(API.USER.ACCOUNT, data, { shop_id: shop.id })
            .then(async () => {
                await dispatch(me());
                if (localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId)) {
                    const resultData = await getBookingData(Number(localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId)));
                    if (resultData) {
                        const storedId = localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId);
                        handleClose();
                        setTimeout(() => {
                            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}/${storedId}`);
                        }, 500);
                        if (
                            localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId) ||
                            localStorage.getItem(LOCAL_STORAGE_KEYS.expressUserEmail) ||
                            localStorage.getItem(LOCAL_STORAGE_KEYS.hasReloaded)
                        ) {
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.expressBookingId);
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.expressUserEmail);
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.hasReloaded);
                        }
                    } else {
                        handleClose();
                        if (
                            localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId) ||
                            localStorage.getItem(LOCAL_STORAGE_KEYS.expressUserEmail) ||
                            localStorage.getItem(LOCAL_STORAGE_KEYS.hasReloaded)
                        ) {
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.expressBookingId);
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.expressUserEmail);
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.hasReloaded);
                        }
                    }
                } else {
                    handleClose();
                    if (
                        localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId) ||
                        localStorage.getItem(LOCAL_STORAGE_KEYS.expressUserEmail) ||
                        localStorage.getItem(LOCAL_STORAGE_KEYS.hasReloaded)
                    ) {
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.expressBookingId);
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.expressUserEmail);
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.hasReloaded);
                    }
                }
                return;
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IRegularProfile, {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        return;
                    }
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleSave = async (payloadData: IRegularProfile) => {
        setIsLoading(true);
        if (file.convertedFile && file?.filename) {
            const logoImageResponse: any = await s3Upload(file.convertedFile, `${s3Path.USER_PROFILE}${file?.filename}`);
            const imageStatus = logoImageResponse?.status;
            if (imageStatus === errorCode.updateSuccess) {
                const updatedData = {
                    ...payloadData,
                    profile_image_name: file?.filename,
                };
                handleChange(updatedData);
            } else {
                setIsLoading(false);
            }
        } else {
            const updatedData = {
                ...payloadData,
                profile_image_name: payloadData.profile_image_name,
            };
            handleChange(updatedData);
        }
    };

    const onChangeCrop = (e: any) => {
        e.preventDefault();
        setUpload(false);
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result, message } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);

        if (result) {
            clearErrors("profile_image_name");
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage("");
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
            // setUploadProgress(0);
        } else {
            setError("profile_image_name" as "profile_image_name", {
                type: "manual",
                message: message,
            });
        }
    };

    const imageUploadFun = (data: any) => {
        const fileInfo = convertBase64ToFile(data);
        setFile(fileInfo);
        if (fileInfo && fileInfo.filename) {
            setValue("profile_image_name", fileInfo.filename);
        }
    };

    const onErrorImage = (name: string) => () => {
        setErrorImage((old) => ({ ...old, [name]: true }));
    };

    const handleLogout = async () => {
        logout();
    };

    return (
        <div className="content font-primary md:m-[50px] md:mt-[50px]">
            <div className={`flex flex-col ${uiState.isTablet ? "" : "mobile-model-inner pt-[12px]"}`}>
                <h1 className={`${uiState.isTablet ? "login-model-title" : "leading-[22.4px] font-bold text-[16px] tracking-[-0.01rem] mb-1 text-txtAppointmentColor"} `}>
                    {t("Complete your profile")}
                </h1>
                <p className={`${uiState.isTablet ? "login-model-subtitle" : "text-textGrey leading-[18px] text-[12px] font-normal"} `}>{t("Finalize your account setup in under 30 seconds.")}</p>
            </div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSave)} className={`${uiState.isTablet ? "w-full mt-[30px] flex flex-col" : "mt-[16px]"}`}>
                    {uiState.isTablet ? (
                        <WRegularProfile
                            isLoading={isLoading}
                            handleLogout={handleLogout}
                            onErrorImage={onErrorImage}
                            cropData={cropData}
                            errorImage={errorImage}
                            onChangeCrop={onChangeCrop}
                            referralConfig={referralConfig}
                        />
                    ) : (
                        <MRegularProfile
                            isLoading={isLoading}
                            handleLogout={handleLogout}
                            onErrorImage={onErrorImage}
                            cropData={cropData}
                            errorImage={errorImage}
                            onChangeCrop={onChangeCrop}
                            referralConfig={referralConfig}
                        />
                    )}
                </form>
            </FormProvider>
            {upload &&
                (uiState.isTablet ? (
                    <CropperModal
                        imageUpload={imageUploadFun}
                        imageUrl={image}
                        setUpload={setUpload}
                        setCropData={setCropData}
                        defaultCropType="Square"
                        title={t("Crop Image")}
                        description={t("Upload a 400x400px image for the best results.")}
                        isCropType={false}
                    />
                ) : (
                    <MCropperModal
                        imageUpload={imageUploadFun}
                        imageUrl={image}
                        setUpload={setUpload}
                        setCropData={setCropData}
                        defaultCropType="Square"
                        title={t("Crop Image")}
                        description={t("Upload a 400x400px image for the best results.")}
                        isCropType={false}
                    />
                ))}
        </div>
    );
};

export default RegularProfile;
