import React from "react";
import { useTranslation } from "react-i18next";
import { Step3PNG } from "src/theme/Images";
const Step3 = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex flex-col">
                <span className="text-[14px] leading-[19.6px] font-normal text-TextColor tracking-[-0.01em] h-[44px]">
                    <span>{t("Scroll down and select")}</span> <span className="text-primary text-[14px] leading-[19.6px] font-normal">{t("“Add to Home Screen”")}</span>
                </span>
                <div className="phone-body-bg">
                    <img src={Step3PNG} className="w-full rounded-secondary h-[305px]" alt="" />
                </div>
            </div>
        </>
    );
};

export default Step3;
