import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import WPhoneOrEmail from "src/components/PhoneOrEmail/WPhoneOrEmail";
import { ChevronLeft } from "@untitled-ui/icons-react/build/cjs";
import { IForgotPwd } from "../Password.interface";

const WForgotPassword: FC<IForgotPwd> = ({ handleAuthAction, isLoading, inputType }: any) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="content font-primary model-inner-wrapper">
            <h5 className="login-model-title">{t("Forgot your password?")}</h5>
            <p className="login-model-description ">{t("forgot_password_description")}</p>
            <div className="mt-[42px]">
                <label htmlFor="phone" className="text-sm text-txtAppointmentColor flex leading-[18.2px] font-normal text-[14px] -tracking-[0.02rem] mb-1.5">
                    {t("Phone or Email")}
                    <span className="asterisk">*</span>
                </label>
                <Controller
                    name="emailPhone"
                    control={control}
                    render={({ field: { onChange }, fieldState: { error } }: any) => (
                        <div className="flex flex-col ">
                            <WPhoneOrEmail onNumberChange={onChange} errors={error} inputType={inputType} />
                        </div>
                    )}
                />
                <div className="w-full flex justify-center"></div>
                <div className="mt-[52px] w-full bottom-0 flex flex-col">
                    <CustomButton
                        type="button"
                        icon={<ChevronLeft width={20} />}
                        className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px] hover:!bg-arrowBackgroundColor !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                        shadow={false}
                        onClick={() => handleAuthAction("login")}
                    ></CustomButton>
                    <CustomButton primary type="submit" className="w-full !h-[48px]" isLoading={isLoading} disabled={isLoading}>
                        <span>{t("Continue")}</span>
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

export default WForgotPassword;
