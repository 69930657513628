import React from "react";
import BarberDetailCard from "./BarberDetailCard";
import InputWithLabel from "../../../components/InputWithLabel";
import { ILocation, IShopLocation } from "../../../Interface";
import BarLoader from "../../../components/BarLoader";
import { useTranslation } from "react-i18next";
import { XClose, MarkerPin01, SearchLg } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";
import { useTrail, animated } from "react-spring";
import { isGetApp } from "src/redux/reducers/Landing.slice";
import { useAppSelector } from "src/redux/hooks";
import SBarberDetailCard from "./SBarberDetailCard";
import { currentTheme } from "src/redux/reducers/common/Common.slice";

interface IProps {
    shopLocationData: IShopLocation[];
    searchParam: string | undefined;
    handlerSearchChange: () => void;
    handlerNearbyBtnClick: () => void;
    isShopsDataLoading: boolean;
    selectedShopData: IShopLocation | undefined;
    setSelectedShopData: React.Dispatch<React.SetStateAction<IShopLocation | undefined>>;
    locationCoordinate: ILocation | undefined;
    bookingCount: number;
    setSearchParam: React.Dispatch<React.SetStateAction<string | undefined>>;
    nearByClicked: boolean;
}

const BarberSearchDetailView = ({
    shopLocationData,
    searchParam,
    handlerSearchChange,
    handlerNearbyBtnClick,
    isShopsDataLoading,
    selectedShopData,
    setSelectedShopData,
    locationCoordinate,
    bookingCount,
    setSearchParam,
    nearByClicked,
}: IProps) => {
    const { t } = useTranslation();
    const isGetAppVisible = useAppSelector(isGetApp);
    const uiState = useAppSelector((data) => data.UiStates);
    const locationAnimation = useTrail(shopLocationData.length, {
        from: { opacity: 0, transform: "translateY(20px) scale(0.95)" },
        to: { opacity: 1, transform: "translateY(0px) scale(1)" },
        config: { tension: 220, friction: 20 },
    });
    const theme = useAppSelector(currentTheme);

    return (
        <div className="w-full lg:w-[430px] xl:w-[480px] 2xl:w-[525px] 3xl:w-[600px]  font-primary max-lg:p-4 max-lg:flex max-lg:flex-col max-lg:flex-1 overflow-hidden h-full max-md:overflow-y-auto scrollbar-hide flex flex-col max-md:pb-5">
            <div className="sm:px-1 lg:px-8 pt-4 md:pt-0 md:mt-[20px] md:shrink-0">
                <div className="flex flex-col mb-4 lg:mb-5">
                    <h3 className="location-title-text 2xl:text-[24px]">{t("Select a location")}</h3>
                    <span className="location-sub-text">{uiState.isMobile ? t("Choose a location to get started.") : t("Select from any of our locations below to begin your booking.")} </span>
                </div>
                <div className="flex justify-between gap-2 h-[40px] search-box">
                    <div className="relative w-full">
                        <InputWithLabel
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchParam(e.target.value)}
                            name="search_location"
                            placeholder={t("Search")}
                            borderClass="!max-h-[40px] "
                            inputClass=" pl-[38px] w-full"
                            value={searchParam ?? ""}
                            buttonIcon={<SearchLg className="text-textGrey w-4" />}
                            handlerIconClick={() => setSearchParam("")}
                            buttonIconRight={searchParam ? <XClose className="text-textGrey w-5 absolute top-1/2 -translate-y-1/2 right-3" /> : <span></span>}
                        />
                    </div>
                    {/* <button
                        className={`flex justify-center gap-1.5 items-center min-w-[44px] border border-primaryAppointment px-3  transition-all ease-in-out delay-100 font-semibold text-base bg-transparent text-primaryAppointment  rounded-secondary ${
                            locationCoordinate ? "bg-transparent" : "bg-transparent"
                        }`}
                        onClick={handlerNearbyBtnClick}
                    >
                        <MarkerPin01 className="text-lg text-primaryAppointment" />
                        <span className="font-semibold text-base bg-transparent text-primaryAppointment -tracking-[0.03rem]"> {t("Nearby")}</span>
                    </button> */}
                    <CustomButton
                        // secondary
                        className={`h-[40px] w-[40px] min-w-[40px] !px-[10.5px] !border-borderCounter bg-transparent border ${
                            theme
                                ? "text-textMoreGrey md:hover:bg-primaryHover md:hover:border-primaryBorderHover hover:text-textWhiteBlack "
                                : "text-textWhiteBlack md:hover:bg-textWhiteBlack md:hover:!text-textBlackWhite"
                        }  CustomButtonShadow  
                        ${
                            locationCoordinate
                                ? theme
                                    ? "!text-textWhiteBlack !bg-primaryHover border-primaryBorderHover"
                                    : "!bg-textWhiteBlack !text-textBlackWhite md:hover:text-textBlackWhite"
                                : ""
                        }`}
                        onClick={handlerNearbyBtnClick}
                        icon={<MarkerPin01 width="20" />}
                    ></CustomButton>
                </div>
            </div>
            <div className="w-[90%] mx-auto mt-2 md:shrink-0">
                <BarLoader isLoading={isShopsDataLoading} />
            </div>
            {isShopsDataLoading ? (
                <div
                    className={`lg:px-8 px-0 sm:px-5 xl:px-8 mt-[16px] lg:mt-8 left_sidebar_content gap-4 md:overflow-y-auto flex flex-col h-full max-lg:-mx-4 max-lg:px-4 flex-grow lg:pb-5 scrollbar-hide`}
                >
                    {[...Array(5)].map((_$, index) => (
                        <SBarberDetailCard key={index} />
                    ))}
                </div>
            ) : shopLocationData.length ? (
                <div className={`lg:px-8 px-0 sm:px-5 xl:px-8 mt-[16px] lg:mt-8 left_sidebar_content gap-4 md:overflow-y-auto flex flex-col h-full max-lg:-mx-4 max-lg:px-4 flex-grow lg:pb-5`}>
                    {/* {shopLocationData.map((location: IShopLocation, index: number) => (
                        <BarberDetailCard location={location} key={index} selectedShopData={selectedShopData} setSelectedShopData={setSelectedShopData} />
                    ))} */}
                    {locationAnimation.map((style, index) => {
                        const locationData = shopLocationData[index];
                        return (
                            <animated.div key={`${locationData.id}-${index}`} style={style}>
                                <BarberDetailCard location={locationData} key={index} selectedShopData={selectedShopData} setSelectedShopData={setSelectedShopData} />
                            </animated.div>
                        );
                    })}
                </div>
            ) : (
                !isShopsDataLoading && (
                    <div className="left_sidebar_content flex items-center lg:mt-8 justify-center flex-1 h-full">
                        <div className="flex flex-col px-5 lg:mb-12">
                            <p className="text-center text-textGrey text-base font-normal mb-3 -tracking-[0.03rem]">
                                {searchParam ? t("No available locations match your search.") : nearByClicked ? t("No nearby locations found from the available list.") : t("No locations available.")}
                            </p>
                            {searchParam && (
                                <div className="flex justify-center">
                                    <CustomButton
                                        outlinePrimary
                                        className="flex items-center !px-[18px] !py-[2px] lg:!h-[30px] !text-[14px] !leading-[30px] rounded-secondary whitespace-nowrap 
                         max-lg:!h-[30px] max-lg:rounded-minimal CustomButtonShadowMax max-lg:!text-[14px] w-auto"
                                        onClick={() => handlerSearchChange()}
                                    >
                                        {t("Clear Search")}
                                    </CustomButton>
                                </div>
                            )}
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default BarberSearchDetailView;
