import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastBodyContainer = () => (
    <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className={"custom-toast"}
        style={{ whiteSpace: "pre-line", width: "auto", maxWidth: "320px" }}
    />
);

export default ToastBodyContainer;
