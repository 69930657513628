import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { API } from "src/constants/api";
import { axiosGet } from "src/utils/requestClient";
import { Category } from "./Profile.interface";
import { pickBy, some } from "lodash";
import { professionalDefaultImg } from "src/theme/Images";
import { GoDotFill } from "react-icons/go";
import CustomButton from "src/components/CustomButton";
import { Paginator } from "primereact/paginator";
import { useTranslation } from "react-i18next";
import { getShortName } from "src/utils/global-functions";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/constants/routes";
import BookingView from "./Booking/BookingView";
import Sidebar from "src/components/Sidebar";
import { useAppSelector } from "src/redux/hooks";
import { getBookingInfo } from "../BookAppointment/Booking.slice";

const Notification = ({ handleSidebarClose, totalRecords, setTotalRecords }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const uiState = useAppSelector((data) => data.UiStates);
    const [notifications, setNotifications] = useState([]);
    const [isNotification, setIsNotification] = useState(false);
    const [filteredNotifications, setFilteredNotifications] = useState({
        [Category.TODAY]: [],
        [Category.YESTERDAY]: [],
        [Category.THIS_WEEK]: [],
        [Category.OLDER]: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [loadingState, setLoadingState] = useState({
        next: false,
        prev: false,
    });
    const [isBookingDetailsShow, setIsBookingDetailsShow] = useState(false);
    const [booking, setBooking] = useState(null);
    const bookingInfo = useAppSelector(getBookingInfo);

    useEffect(() => {
        listNotification();
    }, []);

    useEffect(() => {
        const data: any = {
            [Category.TODAY]: [],
            [Category.YESTERDAY]: [],
            [Category.THIS_WEEK]: [],
            [Category.OLDER]: [],
        };

        const today = moment().startOf("day");
        const yesterday = moment().subtract(1, "days").startOf("day");
        const startOfWeek = moment().startOf("week");

        notifications.forEach((item: any) => {
            const date = moment(item.created_at);
            if (date.isSame(today, "d")) {
                data[Category.TODAY].push(item);
            } else if (date.isSame(yesterday, "d")) {
                data[Category.YESTERDAY].push(item);
            } else if (date.isAfter(startOfWeek)) {
                data[Category.THIS_WEEK].push(item);
            } else {
                data[Category.OLDER].push(item);
            }
        });

        const filteredData: any = pickBy(data, (item) => item.length > 0);

        setFilteredNotifications(filteredData);
        setIsNotification(some(filteredData, (arr) => Array.isArray(arr) && arr.length > 0));
    }, [notifications]);

    const listNotification = () => {
        setIsLoading(true);
        axiosGet(API.NOTIFICATION.LIST, lazyState)
            .then((response) => {
                setTotalRecords({ totalRecords: response.data.data.totalRecords, unread_count: response.data.data.unread_count });
                setNotifications(response.data.data.data);
            })
            .finally(() => {
                setLoadingState({ next: false, prev: false });
                setIsLoading(false);
            });
    };

    const handleAction = (isBookingNotify: boolean, data?: any) => () => {
        if (isBookingNotify) {
            setIsBookingDetailsShow(true);
            setBooking(data);
        } else {
            handleSidebarClose();
            navigate(ROUTES.LOCATION);
        }
    };

    const handleClose = () => {
        setIsBookingDetailsShow(false);
    };

    const handleOnModify = () => {
        handleSidebarClose(true);
        setIsBookingDetailsShow(false);
    };

    const NotificationCard = ({ notification }: any) => {
        const [isImageError, setIsImageError] = useState<boolean>(false);

        let imageUrl = professionalDefaultImg;
        let shortName = "";
        if (notification?.status === "user_referee") {
            shortName = notification?.user?.referrer.full_name;
            imageUrl = notification?.user?.referrer?.profile_image_url;
        } else if (notification?.status === "user_refferer") {
            shortName = notification?.referee.full_name;
            imageUrl = notification?.referee?.profile_image_url;
        } else if (notification?.booking?.staff?.profile_image_url) {
            shortName = notification?.booking?.staff?.full_name;
            imageUrl = notification?.booking?.staff?.profile_image_url;
        }
        const handleImageError = () => {
            setIsImageError(true);
        };

        return (
            <div
                className="flex items-start border rounded-minimal border-borderColorPrimary p-4 relative cursor-pointer hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)]"
                // onClick={checkNotificationStatus("single", notification)}
                onClick={notification?.status === "user_referee" || notification?.status === "user_refferer" ? handleAction(false) : handleAction(true, notification.booking)}
            >
                {/* <div className="flex mr-4">{renderProfileImage(notification)}</div> */}
                <div className="flex mr-4">
                    {imageUrl && !isImageError ? (
                        <img src={imageUrl} alt="" className="h-[40px] min-w-[40px] object-cover relative rounded-minimal" onError={handleImageError} />
                    ) : shortName ? (
                        <span className="h-[30px] max-h-[40px] min-h-[40px] min-w-[40px] w-[40px] bg-mainBackgroundColor flex justify-center items-center shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] text-primaryAppointment font-medium rounded-minimal text-[16px] leading-[40px]">
                            {getShortName(shortName)}
                        </span>
                    ) : (
                        <img src={professionalDefaultImg} alt="" className="h-[40px] min-w-[40px] object-cover relative rounded-minimal" onError={handleImageError} />
                    )}
                </div>
                <div className="flex flex-col multiline-truncate-wrapper">
                    <p className="text-[14px] font-normal leading-[20px] text-TextColor mb-1 maltiline-truncate">{notification.description}</p>
                    <div className="flex items-center">
                        <p className="text-[12px] leading-[18px] font-medium text-TextColor">{notification.name}</p>
                        <span className="text-[12px] font-medium text-textGrey leading-[18px] flex flex-row items-center">
                            <span className="flex h-[4px] w-[4px] bg-textGrey rounded-full mr-1 ml-1 leading-[18px]"></span> {moment(notification.created_at).fromNow()}
                        </span>
                    </div>
                </div>
                {!notification.is_read && (
                    <div className="absolute right-[10px] top-[10px]">
                        <GoDotFill size={10} color="#17B26A" className="rounded-full flex" />
                    </div>
                )}
            </div>
        );
    };

    const [lazyState, setlazyState] = useState<any>({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
    });

    useEffect(() => {
        listNotification();
    }, [lazyState]);

    const onPageChange = (event: any) => {
        const newLoadingState = { ...loadingState };
        if (event.page > lazyState.page) {
            newLoadingState.next = true;
        }
        if (event.page < lazyState.page) {
            newLoadingState.prev = true;
        }
        setLoadingState(newLoadingState);
        setlazyState((prev: any) => ({
            ...prev,
            first: event.first,
            rows: event.rows,
            page: event.page,
        }));
    };

    const customPaginatorTemplate = {
        layout: "CurrentPageReport PrevPageLink NextPageLink",

        PrevPageLink: ({ onClick }: any) =>
            totalRecords.totalRecords > lazyState.rows && (
                <CustomButton secondary onClick={onClick} isLoading={loadingState.prev} disabled={isLoading} className="mr-3 !h-[36px] !text-xs">
                    {t("Previous")}
                </CustomButton>
            ),
        NextPageLink: ({ onClick }: any) =>
            totalRecords.totalRecords > lazyState.rows && (
                <CustomButton secondary onClick={onClick} isLoading={loadingState.next} disabled={isLoading} className="!h-[36px] !text-xs">
                    {t("Next")}
                </CustomButton>
            ),
        CurrentPageReport: ({ currentPage, totalPages }: { currentPage: number; totalPages: number }) => (
            <div className="text-TextColor flex items-center text-sm font-medium mr-auto gap-3">
                {totalRecords.totalRecords > lazyState.rows && (
                    <span>
                        {t("Page")} {currentPage} {t("of")} {totalPages}
                    </span>
                )}
            </div>
        ),
    };

    return (
        <div className="flex flex-col h-full flex-1 overflow-hidden">
            {/* <div className="flex justify-between items-center shrink-0 h-[52px] lg:h-[60px] px-4 lg:px-5 border-borderColorPrimary ">
                <CustomButton
                    isLoading={isMarkingAsRead}
                    disabled={totalRecords.unread_count === 0}
                    secondary
                    onClick={checkNotificationStatus("all")}
                    className="text-textMoreGrey !font-semibold cursor-pointer !text-[13px] !leading-[18px] border-none !bg-transparent"
                >
                    <DoubleCheck />
                    {t("Mark as all read")}
                </CustomButton>
            </div> */}
            <div className="flex flex-col mt-5 lg:mt-[20px] shrink-0 px-4 lg:px-5">
                <div className="text-[16px] lg:text-[20px] mb-1 leading-[22.4px] lg:leading-[28.4px] font-bold lg:font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">
                    {t("Notifications")}
                </div>
                <div className="font-normal text-[12px] lg:text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">{t("Browse Your Notification History")}</div>
            </div>
            <div className="flex flex-col gap-2.5 lg:3.5 mt-4 lg:mt-5 overflow-y-auto flex-grow px-4 lg:px-5 flex-1 pb-1">
                {isLoading ? (
                    <div className="custom_loading_wrapper">
                        <div className="custom_loading"></div>
                    </div>
                ) : isNotification ? (
                    notifications.map((notification: any, index) => <NotificationCard key={notification.id} notification={notification} />)
                ) : (
                    !isLoading && <div className="flex-1 flex items-center justify-center text-center">{t("Notifications not available")}</div>
                )}
            </div>
            <div className={`px-4 lg:px-5 pt-2 ${bookingInfo.isFullScreen ? "!pb-[24px]" : "pb-2"}`}>
                <Paginator
                    first={lazyState.first}
                    rows={lazyState.rows}
                    totalRecords={totalRecords.totalRecords}
                    template={customPaginatorTemplate}
                    rowsPerPageOptions={[10, 20, 30]}
                    onPageChange={onPageChange}
                />
            </div>
            <Sidebar
                isOpen={isBookingDetailsShow}
                handleClose={handleOnModify}
                size="w-[460px]"
                position={uiState.isMobile ? "bottom" : "right"}
                isBottomView={uiState.isMobile}
                customClass="profile-sidebar !h-full "
                dismissable
            >
                {isBookingDetailsShow && booking && <BookingView booking={booking} handleClose={handleClose} handleOnModify={handleOnModify} />}
            </Sidebar>
        </div>
    );
};

export default Notification;
