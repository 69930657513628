import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import InputWithLabel from "src/components/InputWithLabel";
import OTPField from "src/components/OTPField/OTPField";
import { IUpdateEmail } from "../Profile.interface";
import { CheckCircle } from "@untitled-ui/icons-react/build/cjs";
import { exceptValidation } from "src/utils/global-functions";

const WUpdateEmail: FC<IUpdateEmail> = ({ verifyStep, email, isLoading, handleModalClose, handlerSendCode, isOtpLoading }) => {
    const { t } = useTranslation();
    const { control, watch, clearErrors, setValue } = useFormContext();
    const otpWatch = watch("otp");
    const handleEmailChange = (e: { target: { value: any } }) => {
        setValue("email", e.target.value);
        clearErrors("email");
    };

    return (
        <div className={`model-inner-wrapper font-primary ${verifyStep.success && "!py-[16px]"}`}>
            {verifyStep.otp && (
                <div className="flex flex-col w-full">
                    <h2 className="login-model-title">{t("Verify your email address")}</h2>
                    <div className="flex flex-col">
                        <span className="login-model-subtitle"> {t("We’ve sent a 4-digit code to your email")}</span>
                        <span className="login-model-subtitle-dec">{email}</span>
                    </div>
                    <div className="w-full mt-[30px]">
                        <Controller
                            name="otp"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="flex flex-col">
                                    <OTPField
                                        otp={value}
                                        setOtp={(newOtp: any) => {
                                            if (error) {
                                                clearErrors("otp");
                                            }
                                            onChange(newOtp);
                                        }}
                                        errors={error}
                                    />
                                </div>
                            )}
                        />
                        <div className="text-textGrey text-center text-sm font-normal mt-1.5 -tracking-[0.02rem]">{t("Tip: Check your inbox and spam folders.")}</div>
                        <div className="flex items-center justify-center gap-1 mt-[24px]">
                            <p className="text-textGrey text-sm font-normal -tracking-[0.02rem] leading-[19.6px]">{t("Didn’t receive the code?")}</p>
                            <CustomButton
                                type="button"
                                isLoading={isOtpLoading}
                                disabled={isOtpLoading}
                                onClick={handlerSendCode(email)}
                                className="text-primary !border-0 !p-0 !h-auto leading-[19.6px] !font-semibold overflow-visible border-b-animation-hover !bg-transparent text-sm text-center flex flex-row ring-0"
                                shadow={false}
                            >
                                {t("Resend code")}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            )}
            {verifyStep.email && (
                <div className="flex flex-col w-full">
                    <h2 className="login-model-title">{t("Email Update")}</h2>
                    <div className="flex flex-col">
                        <span className="login-model-subtitle"> {t("Update the email linked to your account")}</span>
                    </div>
                    <div className="w-full mt-[30px]">
                        <Controller
                            name="email"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="w-full">
                                    <div className="w-full flex flex-col">
                                        <InputWithLabel
                                            name="email"
                                            borderClass="!h-[44px]"
                                            label={t("New Email")}
                                            placeholder={t("Enter your new email address")}
                                            value={value}
                                            handleChange={handleEmailChange}
                                            error={!!error}
                                            required
                                        />
                                        {error && exceptValidation(error) && <p className="text-error">{error.message}</p>}
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </div>
            )}
            {verifyStep.success && (
                <div className="flex flex-col w-full justify-center ">
                    <div className="flex flex-col w-full justify-center text-center max-w-[280px] mx-auto">
                        <span className="bg-[#DCFAE6] mb-4 text-[14px] mx-auto leading-[20px] h-[48px] min-w-[48px] w-[48px] rounded-full flex items-center justify-center">
                            <CheckCircle className="text-[#079455] w-5 h-5" />
                        </span>
                        <span className="text-textMoreDark font-semibold text-[18px] leading-[28px] mb-1">{t("Email Address updated")}</span>
                        <span className="text-textMoreGrey font-normal text-[14px] leading-[20px]">{t("Your email address has been successfully updated.")}</span>
                    </div>
                    <CustomButton primary onClick={() => handleModalClose()} className={`w-full mt-5 !h-[48px]`}>
                        <span>{t("Continue")}</span>
                    </CustomButton>
                </div>
            )}
            {!verifyStep.success && (
                <div className="mt-[52px] w-full flex flex-row gap-4">
                    <CustomButton
                        primary
                        isLoading={isLoading}
                        disabled={isLoading || (verifyStep.otp && (isLoading || !otpWatch || otpWatch.length !== 4))}
                        className={`w-full !h-[48px]`}
                        type="submit"
                    >
                        <span>{verifyStep.email ? t("Update") : t("Continue")}</span>
                    </CustomButton>
                </div>
            )}
        </div>
    );
};

export default WUpdateEmail;
