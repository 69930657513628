import React, { FC, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { usePhone } from "../../../hooks/usePhone";
import { LOCAL_STORAGE_KEYS, errorCode } from "../../../constants/common";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { IGettingStarted, ILoginProps } from "../Auth.interface";
import { userLogin, userPhoneEmailLogin } from "../../../redux/reducers/Login.slice";
import { trim } from "lodash";
import { getSlug } from "src/utils/global-functions";
import { me } from "src/redux/reducers/common/Common.slice";
import WLogin from "./Web/WLogin";
import MLogin from "./Mobile/MLogin";
let newWindow: any;

const Login: FC<IGettingStarted> = ({ authData, setAuthData, handleAuthAction, handleClose }) => {
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { setFormatPhone } = usePhone();

    const methods = useForm<ILoginProps>({
        defaultValues: {
            emailPhone: "",
        },
    });

    useEffect(() => {
        const expressBooking = localStorage.getItem(LOCAL_STORAGE_KEYS.expressUserEmail);
        if (expressBooking !== "null") {
            const payload = {
                isEmail: true,
                data: expressBooking,
                error: "",
            };
            setValue("emailPhone", payload);
            setIsButtonDisabled(false);
        }
    }, []);

    const { handleSubmit, setError, setValue } = methods;

    const onInputChange = (data: any) => {
        if (trim(data.data)) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
    };
    const handlePhoneEmail = async (payload: any) => {
        setIsLoading(true);
        const result: any = await dispatch(userPhoneEmailLogin(payload));
        if (result.type === userPhoneEmailLogin.fulfilled.toString()) {
            handleAuthAction("otp");
        }
        if (result.type === userPhoneEmailLogin.rejected.toString()) {
            const response = result.payload.data;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError("emailPhone", {
                            type: "backend",
                            message: response.data[field][0],
                        });
                    });
                } else if (response.message) {
                    setError("emailPhone", {
                        type: "backend",
                        message: response.message,
                    });
                }
                setIsLoading(false);
                return;
            }
        }
    };
    const handlePhoneOrEmail = async (payload: any, info: any) => {
        setIsLoading(true);

        const result: any = await dispatch(userLogin(payload));
        if (result.type === userLogin.fulfilled.toString()) {
            let updateAuthData = { ...authData, isEmail: info.isEmail };
            if (!info.isEmail) {
                const phoneInfo = info.data;
                const formatedPhoneNumber = setFormatPhone(phoneInfo.code, phoneInfo.number, phoneInfo.country);
                const fullFormatedPhoneNumber = `+${phoneInfo.code} ${formatedPhoneNumber}`;
                updateAuthData = {
                    ...updateAuthData,
                    phone: { ...info.data, fullFormatedPhoneNumber: fullFormatedPhoneNumber },
                };
            } else {
                updateAuthData = {
                    ...updateAuthData,
                    email: info.data,
                };
            }
            setAuthData(updateAuthData);

            handleAuthAction("otp");
        }
        if (result.type === userLogin.rejected.toString()) {
            const response = result.payload;
            if (response.status === errorCode.unprocessable) {
                if (response.data) {
                    Object.keys(response.data).forEach((field) => {
                        setError("emailPhone", {
                            type: "backend",
                            message: response.data[field][0],
                        });
                    });
                } else if (response.message) {
                    setError("emailPhone", {
                        type: "backend",
                        message: response.message,
                    });
                }
                setIsLoading(false);

                return;
            }
        }
    };

    const handleSave = async (data: any) => {
        setIsLoading(true);
        const info = data.emailPhone;
        if (info.error) {
            setError("emailPhone", {
                type: "manual",
                message: info.error,
            });
            setIsLoading(false);
            return;
        }
        let payload = {};

        if (authData.phone && authData.email) {
            const mainNumber = `+${info.data.code}${info.data.number}`;
            payload = {
                email: info.data,
                phone: mainNumber,
                phone_country_code: info.data.country,
            };
            handlePhoneEmail(payload);
        } else {
            if (info.isEmail) {
                payload = {
                    email: info.data,
                };
            } else {
                const mainNumber = `+${info.data.code}${info.data.number}`;
                payload = {
                    phone: mainNumber,
                    phone_country_code: info.data.country,
                };
            }
            handlePhoneOrEmail(payload, info);
        }
    };
    const handleWindowClose = async (message: any) => {
        newWindow.close();
        if (message) {
            localStorage.setItem(LOCAL_STORAGE_KEYS.authToken, message);
            await dispatch(me());
        }
        handleClose();
    };

    const handleLogin = (type: "google" | "facebook" | "apple") => () => {
        const url = `${process.env.REACT_APP_SOCIAL_URL}${getSlug().slug}/${type}`;
        const height = 600;
        const width = 500;
        const leftPosition = window.screen.width / 2 - (width / 2 + 10);
        const topPosition = window.screen.height / 2 - (height / 2 + 50);
        newWindow = window.open(
            url,
            "Window2",
            `status=no,height=${height},width=${width},resizable=yes,left=${leftPosition},top=${topPosition},screenX=${leftPosition},screenY=${topPosition},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`,
        );
    };
    useEffect(() => {
        window.addEventListener("message", (event) => {
            if (event.source === newWindow) {
                handleWindowClose(event.data);
            }
        });
        return () => window.removeEventListener("message", handleWindowClose);
    }, []);
    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSave)}>
                {uiState.isTablet ? (
                    <WLogin onInputChange={onInputChange} isLoading={isLoading} isButtonDisabled={isButtonDisabled} handleLogin={handleLogin} />
                ) : (
                    <MLogin onInputChange={onInputChange} isLoading={isLoading} isButtonDisabled={isButtonDisabled} handleLogin={handleLogin} />
                )}
            </form>
        </FormProvider>
    );
};

export default Login;
