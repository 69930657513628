import React from "react";
import { formatInstagramLink, onError } from "../../../../utils/global-functions";
import { InstaSVG } from "src/theme/Images";

type Props = {
    data: any;
};

const Info: React.FC<Props> = ({ data }) => (
    <div className="flex flex-col text-txtcolor">
        <div className="profile-images">
            <img src={data.image_url} alt="" onError={onError("Professional")} className="w-full min-h-[343px] max-h-[672px] aspect-[1/1] profile-img object-cover cursor-pointer " />
        </div>
        <div className="w-[-webkit-fill-available] relative border-t mt-[16px] pt-[12px] text-TextColor border-TextColor">
            <h3 className="text-[24px] leading-[36px] uppercase text-TextColor font-medium">{data?.name}</h3>
            <div className="flex justify-between mt-[14px]">
                <p className="text-[20px] leading-[24.2px] text-TextColor font-medium">{data?.role}</p>
                {data?.is_instgram_button && (
                    <div className="">
                        {/* {data?.is_instgram_button && data?.instagram_link && (
                        <a href={`${formatInstagramLink(data?.instagram_link)}`} target="_blank" className="text-xs flex items-center gap-2 border-txtcolor border p-1 px-1.5">
                            <FiInstagram size={20} className="cursor-pointer" color="text-txtcolor" />@{getInstagramUsernames(data?.instagram_link)}
                        </a>
                    )} */}
                        <a
                            href={`${formatInstagramLink(data?.instgram_button)}`}
                            target="_blank"
                            className="text-xs flex text-ProductBorderColor font-normal text-[12px] leading-[18px] items-center gap-2 border-[#D2D2D2] border py-[5px] pl-2 pr-2.5 mr-[1px] hover:bg-primaryHover"
                        >
                            <img src={InstaSVG} alt="" className="" />
                            {data?.instgram_button ? data?.instgram_button : ""}
                        </a>
                    </div>
                )}
            </div>
        </div>
    </div>
);

export default Info;
