import React, { useEffect, useState } from "react";
import { UnionSVG } from "src/theme/Images";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { ISelectedTab } from "../../../../Interface";
import { selectTab, setIsScrollable } from "../../../../redux/reducers/Landing.slice";
import { currentSettings, currentShop } from "src/redux/reducers/common/Common.slice";
import CustomButton from "src/components/CustomButton";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { t } from "i18next";

interface Props {
    data: any;
    activeSettings: {
        name: string;
        tabName: ISelectedTab;
    }[];
}
const Footer: React.FC<Props> = ({ data, activeSettings }) => {
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const settings = useAppSelector(currentSettings);
    const activeTab = useAppSelector((tab) => tab.LandingStates.initSelectedTab);
    const [appearance, setAppearance] = useState<any>(null);
    const { navigateBasedOnShopLocations } = useShopNavigation();

    useEffect(() => {
        if (settings.length) {
            const appearances = settings.find((setting: any) => setting.type === "appearance");
            if (appearances) {
                setAppearance(appearances.value);
            }
        }
    }, [settings]);

    const handleNavigate = (tabName: ISelectedTab) => () => {
        dispatch(setIsScrollable(true));
        dispatch(selectTab(tabName));
    };

    const getLogo = () => {
        let response;
        let logo;
        let text;

        if (shop) {
            logo = <img src={shop.logo_image_url ?? UnionSVG} className="me-2 object-contain max-h-[90px]" alt="" />;
            text = (
                <p className="text-txtcolor hover:text-primary cursor-pointer text-[14px] md:text-[14px] lg:text-[14px] 2xl:text-[16px] 3xl:text-[1vw] flex items-center font-logo font-logoWeight">
                    {shop.business_name}
                </p>
            );

            if (appearance) {
                switch (appearance.logo_type) {
                    case "logo":
                        response = logo;
                        break;
                    case "logo_text":
                        response = (
                            <>
                                {logo} {text}
                            </>
                        );
                        break;
                    case "text":
                        response = text;
                        break;
                    default:
                        break;
                }
            }
        }

        return response;
    };
    const handleBookNow = () => {
        navigateBasedOnShopLocations();
    };
    return (
        <footer className="bg-templateBg spacing-top">
            <div className="w-full flex flex-col">
                <div className="flex flex-wrap justify-between pb-[54px] px-5 md:px-[34px] items-center gap-5">
                    <div className="flex max-w-[225px] max-h-[150px]">
                        <div className="flex items-center">{getLogo()}</div>
                    </div>
                    <div className="flex flex-wrap gap-4 md:gap-6">
                        {activeSettings.map(({ name, tabName }) => (
                            <span
                                key={tabName}
                                className={`text-[14px] leading-[21px] font-normal cursor-pointer tracking-[-0.001em] relative border-b-animation-hover ${
                                    activeTab[tabName] ? "text-primary" : "text-TextColor"
                                } `}
                                onClick={handleNavigate(tabName)}
                            >
                                {t(name)}
                            </span>
                        ))}
                    </div>
                    <div className="flex flex-col max-w-[225px]">
                        <CustomButton
                            primary
                            size="w-full"
                            className="bg-primary min-w-[187px] flex items-center !font-medium !px-6 !py-[2px] rounded-secondary  whitespace-nowrap 
                        max-lg:w-full !h-[49px] max-lg:rounded-minimal CustomButtonShadowMax !text-[18px]"
                            onClick={handleBookNow}
                        >
                            {t("Book Now")}
                        </CustomButton>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between border-t items-center pt-6 pb-6 border-borderColorPrimary mx-5 md:mx-[34px]">
                    <div className="flex w-full justify-end">
                        <button
                            onClick={handleNavigate(activeSettings[0].tabName)}
                            className="text-[16px] leading-[24px] text-TextColor tracking-[-0.01em] font-normal uppercase border-b-animation-hover relative"
                        >
                            {t("Back to top")}
                        </button>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
