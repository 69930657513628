import React, { FC, useEffect, useState } from "react";
import { CancelationIcon, SuccessIcon, UserImagePNG } from "src/theme/Images";
import { FiChevronRight, FiX } from "react-icons/fi";
import { BookingTABS, EBookingTabState } from "../bookAppointment.interface";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
    setSelectedBookingDate,
    setSelectedServices,
    setSelectedSpecialist,
    setSelectedBookingTime,
    getBookingInfo,
    setStep,
    setPaymentType,
    setPaymentOption,
    setPreview,
    setNextAvailability,
    resetTempStore,
} from "../Booking.slice";
import { onError } from "src/utils/global-functions";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { IBookAppointmentTabMenu } from "./BookAppointment.interface";
import { PATH } from "src/constants/path";
import { useChain, useSpring, useSpringRef, animated } from "react-spring";
import { XClose } from "@untitled-ui/icons-react/build/cjs";
import DeletePopupModal from "src/components/DeletePopupModal/DeletePopupModal";
import { axiosPatch, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { useShopNavigation } from "src/hooks/useShopNavigation";

const BookAppointmentTabMenu: FC<IBookAppointmentTabMenu> = ({ isLoading, count }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const bookingState = useAppSelector(getBookingInfo);
    const uiState = useAppSelector((data) => data.UiStates);
    const shop = useAppSelector(currentShop);
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const [isShowCancelAppointmentConfirmationModal, setIsShowCancelAppointmentConfirmationModal] = useState(false);
    const [isCancelLoading, setIsCancelLoading] = useState(false);

    useEffect(() => {
        dispatch(resetTempStore());
    }, []);

    useEffect(() => {
        const selectedDate = moment(bookingState.selectedBookingDate).startOf("day");
        const currentDate = moment().startOf("day");
        if (selectedDate.isBefore(currentDate)) {
            dispatch(setSelectedBookingDate(undefined));
            dispatch(setSelectedBookingTime(undefined));
            dispatch(setPreview({ key: "selectedBookingDateTime", value: undefined }));
        }
        dispatch(setPaymentType("cash"));
        dispatch(setPaymentOption("pay_later"));
        dispatch(setStep(EBookingTabState.TAB_MENU));
    }, []);

    useEffect(() => {
        if (bookingState.selectedSpecialist) {
            dispatch(setNextAvailability(bookingState.selectedSpecialist.available_at));
        } else {
            if (bookingState.selectedShopLocation) {
                dispatch(setNextAvailability(bookingState.selectedShopLocation.available_at));
            }
        }
    }, [bookingState.selectedSpecialist]);

    const handleStaff = (event: any) => {
        event.stopPropagation();
        dispatch(setSelectedSpecialist(undefined));
        dispatch(setPreview({ key: "selectedSpecialist", value: undefined }));
        dispatch(setSelectedServices([]));
        dispatch(setPreview({ key: "selectedServices", value: [] }));
        dispatch(setSelectedBookingDate(undefined));
        dispatch(setSelectedBookingTime(undefined));
        dispatch(setPreview({ key: "selectedBookingDateTime", value: undefined }));
    };

    const handleService = (id: number) => (event: any) => {
        event.stopPropagation();
        const services = bookingState.selectedServices.filter((_, index) => id !== index);
        dispatch(setSelectedServices(services));
        dispatch(setPreview({ key: "selectedServices", value: services }));
    };

    const handleSelectDateAndTime = (event: any) => {
        event.stopPropagation();
        dispatch(setSelectedBookingDate(undefined));
        dispatch(setSelectedBookingTime(undefined));
        dispatch(setPreview({ key: "selectedBookingDateTime", value: undefined }));
    };

    const handleNavigate = (step: any) => () => {
        switch (step) {
            case BookingTABS.SELECT_SPECIALIST:
                if (bookingState.isModifyingAppointment) {
                    return;
                }
                break;
            case BookingTABS.SELECT_SERVICE:
                if (bookingState.isModifyingAppointment && bookingState?.oldPaymentOption === "pay_now") {
                    return;
                }
                break;
            default:
                break;
        }
        navigate(`${PATH.APPOINTEMENT}/${step}`);
    };

    const specialistRef = useSpringRef();
    const serviceRef = useSpringRef();
    const dateRef = useSpringRef();
    const [showSpecialist, setShowSpecialist] = useState(false);
    const [showService, setShowService] = useState(false);

    const specialisStyle = useSpring({
        ref: specialistRef,
        from: { opacity: 0, transform: "translateY(-20px)" },
        to: { opacity: 1, transform: "translateY(0)" },
        config: { tension: 170, friction: 20, duration: 175 },
        onRest: () => setShowSpecialist(true),
    });

    const serviceStyle = useSpring({
        ref: serviceRef,
        from: { opacity: 0, transform: "translateY(-20px)" },
        to: { opacity: showSpecialist ? 1 : 0, transform: showSpecialist ? "translateY(0)" : "translateY(-20px)" },
        config: { tension: 170, friction: 20, duration: 175 },
        onRest: () => setShowService(true),
    });

    const dateStyle = useSpring({
        ref: dateRef,
        from: { opacity: 0, transform: "translateY(-20px)" },
        to: { opacity: showService ? 1 : 0, transform: showService ? "translateY(0)" : "translateY(-20px)" },
        config: { tension: 170, friction: 20, duration: 175 },
    });

    useChain([specialistRef, serviceRef, dateRef]);

    const handleCancelAppoitment = () => {
        setIsShowCancelAppointmentConfirmationModal(true);
    };

    const handleCancelAppointment = () => {
        setIsCancelLoading(true);
        const payload = {
            status: "request_canceled",
        };
        const params = {
            shop_id: shop.id,
            id: bookingState.modifyingAppointmentId,
        };
        axiosPatch(API.BOOKING.UPDATE_STATUS, payload, params)
            .then((response) => {
                listBookings();
                // cancel booking toast message
                toast.success(
                    <span className="text-toastTextColor font-medium text-[14px] leading-[20px] line-truncate pr-2 py-1 my-1 font-primary">{t("Your booking has been canceled successfully.")}</span>,
                    {
                        icon: <img src={SuccessIcon} className="h-[38px] w-[38px] min-w-[38px] ml-[2px]" alt="" />,
                    },
                );
            })
            .catch((error: any) => {
                const response = error?.response?.data;
                toast.error(<span className="text-toastTextColor font-medium text-[14px] leading-[20px] line-truncate pr-2 py-1 my-1 font-primary">{response?.message}</span>, {
                    icon: <img src={CancelationIcon} className="h-[38px] w-[38px] min-w-[38px] ml-[2px]" alt="" />,
                });
            })
            .finally(() => {
                setIsCancelLoading(false);
                setIsShowCancelAppointmentConfirmationModal(false);
            });
    };

    const listBookings = () => {
        setIsCancelLoading(true);
        const payload = {
            type: "upcoming",
        };
        const params = { shop_id: shop.id };
        axiosPost(API.BOOKING.LIST, payload, params)
            .then((response) => {
                navigateBasedOnShopLocations();
            })
            .finally(() => {
                setIsCancelLoading(false);
            });
    };

    const handleModalClose = (status: boolean) => () => {
        setIsShowCancelAppointmentConfirmationModal(status);
    };

    const availableDate = (inputDate: string) => {
        const date = moment(inputDate);
        const today = moment();

        if (date.isSame(today, "day")) {
            return t("Today");
        } else if (date.isSame(today.add(1, "days"), "day")) {
            return t("Tomorrow");
        } else {
            return date.format("DD-MM-YYYY");
        }
    };

    return (
        <>
            <div className="lg:pt-[36px] px-4 lg:px-2 pb-[64px] lg:pb-2 overflow-y-auto scrollbar-hide flex flex-col">
                <ul className="list-none p-0">
                    <animated.li style={specialisStyle} onClick={!isLoading.specialist ? handleNavigate(BookingTABS.SELECT_SPECIALIST) : undefined}>
                        <div className="bg-cardBackground border border-borderColorPrimary px-3 max-lg:py-5 lg:px-6 lg:py-[23px] flex gap-5 items-center justify-between rounded-secondary relative cursor-pointer shadow-none card-shadow-hover transition-all">
                            {bookingState.selectedSpecialist ? (
                                <>
                                    <div className="flex gap-4 items-center">
                                        <div className="img">
                                            <img
                                                src={bookingState?.selectedSpecialist?.profile_image_url ?? UserImagePNG}
                                                className="w-[45px] h-[45px] rounded-secondary"
                                                alt=""
                                                onError={onError("Professional")}
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <h2 className="text-base !leading-5 xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.02em] mb-[2px] mt-0.5">
                                                {bookingState.selectedSpecialist.full_name}
                                            </h2>
                                            <p className="text-sm font-medium text-textGrey -tracking-[0.02em] leading-[21px]">{bookingState.selectedSpecialist?.staff_role?.name}</p>
                                        </div>
                                    </div>
                                    <div onClick={bookingState.isModifyingAppointment ? handleCancelAppoitment : handleStaff} className="absolute -translate-y-1/2  top-1/2 right-3 lg:right-6">
                                        <XClose className="text-textGrey hover:text-primary w-[18px]" />
                                    </div>
                                </>
                            ) : (
                                <div className="flex gap-3 items-center">
                                    <div className="flex flex-row items-center gap-4">
                                        <div className="">
                                            <h2 className="text-base !leading-[22.4px]  xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem] mb-[2px]">
                                                {t("Select a specialist")}
                                            </h2>
                                            <p className={`text-sm font-medium text-textGrey -tracking-[0.02rem] leading-[21px] ${isLoading.specialist && "custom-loading"}`}>{`${
                                                count.specialist
                                            }  ${t("Professionals")}`}</p>
                                        </div>
                                    </div>

                                    <div className="absolute -translate-y-1/2  top-1/2 right-2 lg:right-6">
                                        {isLoading.specialist ? (
                                            <ProgressSpinner style={{ width: "25px", height: "25px" }} className="radio-loader" strokeWidth="8" animationDuration="1.5s" />
                                        ) : (
                                            <FiChevronRight className="text-textGrey text-2xl hover:text-primary" />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </animated.li>
                    <animated.li style={serviceStyle} className="mt-4 lg:mt-5" onClick={!isLoading.service ? handleNavigate(BookingTABS.SELECT_SERVICE) : undefined}>
                        {bookingState.selectedServices && bookingState.selectedServices.length ? (
                            <div className="bg-cardBackground border border-borderColorPrimary px-3 max-lg:py-5 lg:px-6 lg:py-[23px]  flex flex-col items-center justify-between rounded-secondary relative cursor-pointer shadow-none card-shadow-hover  transition-all ">
                                <div className="flex justify-between items-center w-full">
                                    <div className="flex flex-col flex-1 relative">
                                        <h2 className="text-base !leading-5 xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem] mb-[2px]">{t("Services")}</h2>
                                        <p className="text-sm font-medium text-textGrey -tracking-[0.02rem] leading-[21px]">
                                            {bookingState.selectedServices.length} {t("selected")}
                                        </p>
                                    </div>
                                    <div>
                                        <FiChevronRight className="text-textGrey hover:text-primary text-xl" />
                                    </div>
                                </div>

                                {bookingState.selectedServices.map((selectedService, index) => (
                                    <div className="flex justify-between border-t mt-4 pt-4 border-borderColorPrimary w-full items-center" key={`${index}_${selectedService.name}`}>
                                        <div className="flex gap-3 items-center">
                                            <p className="text-txtAppointmentColor text-sm leading-[18.2px] lg:text-[10px] lg:leading-3 xl:text-xs 2xl:text-sm 2xl:leading-[18.2px] font-medium flex items-center justify-center  h-[24px] w-[24px] bg-bgContent rounded-minimal text-[14px]">
                                                <span className="pt-[2px]">{selectedService.quantity}</span>
                                            </p>
                                            <p className="text-txtAppointmentColor text-sm leading-[18.2px] font-medium -tracking-[0.03rem]">{selectedService.name}</p>
                                        </div>
                                        {bookingState.isModifyingAppointment && bookingState?.oldPaymentOption === "pay_now" ? (
                                            <></>
                                        ) : (
                                            <div onClick={handleService(index)}>
                                                <FiX className="text-textGrey hover:text-primary text-lg" />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="bg-cardBackground border border-borderColorPrimary px-3 max-lg:py-5 lg:px-6 lg:py-[23px]  flex gap-4 items-center rounded-secondary relative cursor-pointer shadow-none card-shadow-hover transition-all ">
                                <div className="">
                                    <h2 className="text-base !leading-5 xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem] mb-[2px]">
                                        {t("Select a service")}
                                    </h2>
                                    <p className={`text-sm font-medium text-textGrey -tracking-[0.02rem] leading-[21px]  ${isLoading.service && "custom-loading"}`}>{`${count.service} ${t(
                                        "Options",
                                    )}`}</p>
                                </div>
                                <div className="absolute -translate-y-1/2  top-1/2 right-2 lg:right-6">
                                    {isLoading.service ? (
                                        <ProgressSpinner style={{ width: "25px", height: "25px" }} className="radio-loader" strokeWidth="8" animationDuration="1.5s" />
                                    ) : (
                                        <FiChevronRight className="text-textGrey text-2xl hover:text-primary" />
                                    )}
                                </div>
                            </div>
                        )}
                    </animated.li>
                    <animated.li style={dateStyle} className="mt-4 lg:mt-5" onClick={handleNavigate(BookingTABS.SELECT_DATE_TIME)}>
                        {bookingState.selectedBookingTime && bookingState.selectedBookingDate ? (
                            <div className="bg-cardBackground border border-borderColorPrimary px-3 max-lg:py-5 lg:px-6 lg:py-[23px]  rounded-secondary relative cursor-pointer lg:max-h-[220px] lg:overflow-hidden 2xl:max-h-full flex justify-between items-center shadow-none card-shadow-hover transition-all">
                                <div className="flex gap-4 items-center">
                                    <div className=" bg-transparent rounded-[6px] h-[46px] w-[46px] flex flex-col border border-borderColorSecondary justify-center items-center">
                                        <p className="text-base text-primary font-medium  align-middle -tracking-[0.02rem] leading-[22.4px]">{moment(bookingState.selectedBookingDate).format("DD")}</p>
                                        <p className="text-[10px] leading-[15px] font-medium text-primary">{moment(bookingState.selectedBookingDate).format("MMM").toUpperCase()}</p>
                                    </div>
                                    <div className="left">
                                        <h2 className="text-[14px] lg:text-base !leading-5 xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem] mb-1">
                                            {moment(bookingState.selectedBookingDate).format("dddd, MMMM Do, YYYY")}
                                        </h2>
                                        <p className="text-sm font-medium text-textGrey -tracking-[0.02rem] leading-[21px]">
                                            {format(new Date(`2000-01-01T${bookingState.selectedBookingTime}`), "hh:mm a")}
                                        </p>
                                    </div>
                                </div>
                                <div onClick={handleSelectDateAndTime}>
                                    <FiX className="text-textGrey text-lg hover:text-primary" />
                                </div>
                            </div>
                        ) : (
                            <div className="bg-cardBackground border border-borderColorPrimary px-3 max-lg:py-5 lg:px-6 lg:py-[23px]  flex gap-5 items-center justify-between rounded-secondary relative cursor-pointer shadow-none card-shadow-hover transition-all">
                                <div className="flex gap-3 items-center">
                                    <div className="">
                                        <h2 className="text-base !leading-5  xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem] mb-[2px]">
                                            {t("Select a date and time")}
                                        </h2>
                                        <p className="text-sm font-medium text-textGrey -tracking-[0.02rem] leading-[21px] flex items-center gap-1">
                                            <span className="text-sm">{`${t("Next availability")}: ${
                                                bookingState.nextAvailableDatetime ? availableDate(bookingState.nextAvailableDatetime) : ""
                                            }`}</span>
                                        </p>
                                    </div>
                                </div>
                                <div className="absolute -translate-y-1/2  top-1/2 right-2 lg:right-6">
                                    <FiChevronRight className="text-textGrey hover:text-primary text-2xl" />
                                </div>
                            </div>
                        )}
                    </animated.li>
                </ul>
                {isShowCancelAppointmentConfirmationModal && (
                    <DeletePopupModal
                        primary
                        cancelButtonText={t("Not Now")}
                        confirmButtonText={t("Yes, Confirm")}
                        onClose={handleModalClose}
                        size="w-[400px]"
                        title={t("Cancel Appointment")}
                        description={t("Do you want to cancel this appointment?")}
                        isLoading={isCancelLoading}
                        onConfirm={handleCancelAppointment}
                    />
                )}
            </div>
            {uiState.isMobile && <ManageSubmit />}
        </>
    );
};

export default BookAppointmentTabMenu;
