import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import OTPField from "src/components/OTPField/OTPField";
import { ChevronLeft } from "@untitled-ui/icons-react/build/cjs";
import { useAppSelector } from "src/redux/hooks";
import { IVerifyOtp } from "../Login.interface";

const MVerifyOtp: FC<IVerifyOtp> = ({ authData, loading, handleKeyPress, handlerLoginOtpAgainSend, handleChangePhoneEmail, handleAction }) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const { control, watch, clearErrors } = useFormContext();
    const otpWatch = watch("otp");
    return (
        <div className="mobile-model-inner-wrapper font-primary">
            <div className="mobile-model-inner flex flex-col" onKeyDown={handleKeyPress}>
                <span className="text-[14px] leading-[18.2px] text-txtAppointmentColor tracking-[-0.01rem] font-semibold">
                    {`${t("A code was sent to")} ${authData.isEmail ? authData.email : authData.phone.fullFormatedPhoneNumber}`}
                </span>
                <div className="mt-[12px]">
                    <Controller
                        name="otp"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col">
                                <OTPField
                                    otp={value}
                                    setOtp={(newOtp: any) => {
                                        if (error) {
                                            clearErrors("otp");
                                        }
                                        onChange(newOtp);
                                    }}
                                    errors={error}
                                />
                                {/* {error && <p className="text-error text-left pl-0">{error.message}</p>} */}
                            </div>
                        )}
                    />
                    <div className="mt-[6px] flex flex-col items-center">
                        <div className="flex items-center w-full justify-between gap-1">
                            <p className="text-textMoreGrey text-[12px] font-semibold -tracking-[0.02rem] leading-[20px] flex">
                                {authData.isEmail ? t("Enter the code sent to your email") : t("Enter the code sent to your phone")}{" "}
                            </p>
                            <CustomButton
                                type="button"
                                disabled={loading}
                                onClick={handlerLoginOtpAgainSend}
                                className="text-primary !border-0 !p-0 !h-auto leading-[20px] !font-semibold !bg-transparent !text-[12px] text-center flex flex-row ring-0"
                                shadow={false}
                            >
                                {t("Resend code")}
                            </CustomButton>
                        </div>
                        {authData.email && authData.phone && (
                            <CustomButton
                                type="button"
                                className="!border-0 !bg-transparent text-center ring-0 leading-[20px] mt-[12px] !p-0 !text-[12px] text-primary font-semibold"
                                shadow={false}
                                onClick={handleChangePhoneEmail}
                            >
                                {authData.isEmail ? t("Changed your email?") : t("Changed your phone number?")}
                            </CustomButton>
                        )}
                    </div>
                    <div className="mt-[20px] w-full mb-4 flex flex-col">
                        {uiState.isTablet && (
                            <CustomButton
                                type="button"
                                icon={<ChevronLeft width={20} />}
                                className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px] hover:!bg-arrowBackgroundColor !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                                shadow={false}
                                onClick={authData.email && authData.phone ? handleChangePhoneEmail : handleAction("login")}
                            ></CustomButton>
                        )}
                        <CustomButton type="submit" className="w-full !h-[48px]" primary disabled={loading || (otpWatch.length !== 4 ? true : false)} isLoading={loading}>
                            <span> {t("Continue")}</span>
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MVerifyOtp;
