import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Eye, EyeOff, User01, XClose } from "@untitled-ui/icons-react/build/cjs";
import InputWithLabel from "src/components/InputWithLabel";
import CustomButton from "src/components/CustomButton";
import { exceptRequiredValidation, getShortName } from "src/utils/global-functions";

const WRegularProfile = ({ isLoading, handleLogout, onErrorImage, cropData, errorImage, onChangeCrop, referralConfig }: any) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const firstName = watch("first_name");
    const lastName = watch("last_name");
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
    return (
        <>
            <div className={`flex flex-col justify-center `}>
                <div className="flex flex-col">
                    <Controller
                        name="profile_image_name"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="w-full flex flex-col">
                                <div className="flex flex-row gap-4 lg:gap-5 relative">
                                    {!errorImage.profile && cropData ? (
                                        <img src={cropData} alt="" onError={onErrorImage("profile")} className="w-16 h-16 border-[0.75px] border-uploadProfileBg rounded-lg object-cover" />
                                    ) : firstName && lastName ? (
                                        <div className="rounded-minimal bg-uploadProfileBg flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                            <span className="text-[24px] leading-[36px] font-medium max-w-[50px] overflow-hidden">{getShortName(`${firstName} ${lastName}`)}</span>
                                        </div>
                                    ) : (
                                        <div className="rounded-minimal bg-uploadProfileBg flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                            <span className="text-[24px] leading-[36px] font-medium">
                                                <User01 className="text-textGrey w-5 h-5" />
                                            </span>
                                        </div>
                                    )}

                                    <div
                                        className={`rounded-minimal border border-uploadProfileBorder bg-uploadInputBg hover:shadow-[0px_8px_14px_-4px_rgba(16,24,40,0.06)] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] cursor-pointer flex-auto items-center justify-center py-[11px] px-0 `}
                                    >
                                        <div className="relative overflow-hidden cursor-pointer">
                                            <input type="file" id="image" className=" absolute opacity-0 rounded-full h-[64px] w-[64px]" onChange={onChangeCrop} />
                                            <label htmlFor="image" className="cursor-pointer text-center ">
                                                <div className="text-textGrey text-[14px] leading-[20px] font-normal">
                                                    <p className="mb-1 flex items-center justify-center">
                                                        <span className="text-primary text-[14px] leading-[20px] font-semibold pr-1">{t("Click to upload")}</span>
                                                        <span className=" text-textGrey text-[14px] leading-[20px] font-normal">{t("or drag and drop")}</span>
                                                    </p>
                                                    <p className="text-[12px] text-textGrey font-normal leading-[18px]">{t("PNG or JPG file")}</p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {error && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                    <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4 mt-[24px]">
                        <Controller
                            name="first_name"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className="w-full">
                                    <InputWithLabel
                                        label={t("First Name")}
                                        type="text"
                                        borderClass={`!h-[44px] ${error ? "!is-invalid" : ""}`}
                                        required
                                        placeholder={t("Enter your first name")}
                                        handleChange={onChange}
                                        value={value}
                                        name="first_name"
                                        error={!!error}
                                    />
                                </div>
                            )}
                        />
                        <Controller
                            name="last_name"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className="w-full ">
                                    <InputWithLabel
                                        label={t("Last Name")}
                                        type="text"
                                        borderClass={`!h-[44px] !mb-0 ${error ? "!is-invalid" : ""}`}
                                        required
                                        placeholder={t("Enter your last name")}
                                        handleChange={onChange}
                                        value={value}
                                        name="last_name"
                                        error={!!error}
                                    />
                                </div>
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className="w-full">
                                    <InputWithLabel
                                        label={t("Password")}
                                        buttonIconRight={
                                            isShowPassword ? <Eye width={16} color="#8c9cbb" className="cursor-pointer mr-3" /> : <EyeOff width={16} color="#8c9cbb" className="cursor-pointer mr-3" />
                                        }
                                        handlerIconClick={() => setIsShowPassword(!isShowPassword)}
                                        type={`${isShowPassword ? "text" : "password"}`}
                                        placeholder="•••••••• "
                                        handleChange={onChange}
                                        borderClass="!h-[44px] !mb-0"
                                        required
                                        value={value}
                                        name="password"
                                        error={!!error}
                                        autoComplete="new-password"
                                    />
                                    {error && exceptRequiredValidation(error) && <p className="text-error !ml-0">{error.message}</p>}
                                </div>
                            )}
                        />
                        <Controller
                            name="password_confirmation"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className="w-full">
                                    <InputWithLabel
                                        label={t("Confirm Password")}
                                        buttonIconRight={
                                            isShowConfirmPassword ? (
                                                <Eye width={16} color="#8c9cbb" className="cursor-pointer mr-3" />
                                            ) : (
                                                <EyeOff width={16} color="#8c9cbb" className="cursor-pointer mr-3" />
                                            )
                                        }
                                        handlerIconClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                                        type={`${isShowConfirmPassword ? "text" : "password"}`}
                                        placeholder="••••••••"
                                        handleChange={onChange}
                                        borderClass="!h-[44px]"
                                        required
                                        value={value}
                                        name="password_confirmation"
                                        error={!!error}
                                        autoComplete="new-password"
                                    />
                                    {error && exceptRequiredValidation(error) && <p className="text-error !ml-0">{error.message}</p>}
                                </div>
                            )}
                        />
                    </div>

                    {referralConfig.status && (
                        <Controller
                            name="referral_code"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <div className="md:w-full mt-4">
                                    <div className="flex flex-row  mb-1.5 items-center">
                                        <span className="text-txtAppointmentColor text-[14px] font-normal leading-[18.2px] -tracking-[0.03rem]">{t("Referral Code")}</span>
                                    </div>
                                    <InputWithLabel
                                        // label={t("Referral code (Optional)")}
                                        type="text"
                                        placeholder={t("Enter a referral code")}
                                        borderClass="!h-[44px]"
                                        handleChange={(e) => onChange(e.target.value.toUpperCase())}
                                        value={value ?? ""}
                                        name="referral_code"
                                        error={!!error}
                                    />
                                    {error && <p className="text-error">{error.message}</p>}
                                    <span className="text-textMoreGrey text-[12px] font-normal leading-[20px] -tracking-[0.01rem] mt-1.5">
                                        <span>
                                            {t("Enter a referral code to receive")}
                                            &nbsp;
                                        </span>
                                        <span className="font-semibold text-textMoreGrey">
                                            {referralConfig.referee_type === "percentage" ? `${referralConfig.referee}%` : `$${referralConfig.referee}`}
                                        </span>
                                        <span>
                                            &nbsp;
                                            {t("your first appointment")}
                                        </span>
                                    </span>
                                </div>
                            )}
                        />
                    )}
                </div>
            </div>
            <div className="pt-4">
                <span className="text-txtcolor text-[12px] leading-[20px] -tracking-[0.01rem] font-normal text-left flex-row items-center">
                    <span>{t("By signing up, you agree to the")}&nbsp;</span>
                    <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="m-0 p-0 leading-[20px] box-border text-primary font-semibold">
                        {t("Terms of Service")}
                    </a>
                    <span>&nbsp;{t("and")}&nbsp;</span>
                    <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="m-0 p-0 leading-[20px] box-border text-primary font-semibold">
                        {t("Privacy Policy, ")}
                    </a>
                    <span>
                        &nbsp;
                        {t("and consent to receive personalized offers and update.")}
                    </span>
                </span>
            </div>
            <div className="flex flex-col mt-[18px] max-md:mb-[20px]">
                <CustomButton primary type="submit" size="w-full !h-[48px]" isLoading={isLoading} disabled={isLoading}>
                    {t("Continue")}
                </CustomButton>
                <CustomButton
                    type="button"
                    icon={<XClose className="w-[16px] h-[16px]" />}
                    className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -right-[11px] lg:right-[12px] -top-[18px] lg:top-[12px] hover:!bg-arrowBackgroundColor !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                    shadow={false}
                    onClick={handleLogout}
                ></CustomButton>
            </div>
        </>
    );
};

export default WRegularProfile;
