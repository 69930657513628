import React, { useState, useEffect } from "react";
import ManageSubmit from "../AppointmentPreview/ManageSubmit";
import { API } from "../../../constants/api";
import { axiosGet, axiosPost } from "../../../utils/requestClient";
import { errorCode } from "../../../constants/common";
import { capitalizeFirstLetter, getCardTypeImage } from "../../../utils/global-functions";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
    setPaymentType,
    setPaymentOption,
    getBookingInfo,
    setIsValidPaymentType,
    setStep,
    setIsCardAdded,
    shopSettings,
    setCardFormVisible,
    setFinalizeCardLoading,
    setBookingIsUpadteCard,
} from "../Booking.slice";
import { useTranslation } from "react-i18next";
import { OptionType, PaymentMethod } from "./Payment.interface";
import { currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { CoinsStacked02, Plus } from "@untitled-ui/icons-react/build/cjs";
import { EBookingTabState } from "../bookAppointment.interface";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardTabComponent from "./CardTabComponent";
import { ProgressSpinner } from "primereact/progressspinner";
import { IIsLoading, initLoading } from "./FinalizeBooking.interface";
import CustomButton from "src/components/CustomButton";
import { useParams } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");
const FinalizeBooking = () => {
    const [isShowAddNewCardTab, setIsShowAddNewCardTab] = useState(false);
    const uiState = useAppSelector((data) => data.UiStates);
    const [isLoadCard, setIsLoadCard] = useState(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const booking = useAppSelector(getBookingInfo);
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const [isLoading, setIsLoading] = useState<IIsLoading>(initLoading);
    const [userCardList, setUserCardList] = useState([]);
    const [defaultCardId, setDefaultCardId] = useState<string | undefined>(undefined);
    const [loyaltyPoints, setLoyaltyPoints] = useState(0);
    const [selectedOption, setSelectedOption] = useState<OptionType>("pay_now"); //<OptionType>(userCardList.length > 0 ? "stripe" : "cash");
    const [selectPaymentMethod, setPaymentMethod] = useState<PaymentMethod>("stripe"); //<PaymentMethod>(userCardList.length > 0 ? "pay_online" : "pay_at_shop");
    const bookingShopSettings = useAppSelector(shopSettings);
    const { id: bookingId } = useParams();

    useEffect(() => {
        dispatch(setStep(EBookingTabState.FINALIZE_BOOKING));
        if (
            !bookingId &&
            !(bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) &&
            bookingShopSettings.loyalty.status &&
            bookingShopSettings.loyalty.totalAmount >= Number(booking.bookingTotal)
        ) {
            setPaymentMethod("loyalty");
            dispatch(setPaymentType("loyalty"));
        }
    }, []);

    useEffect(() => {
        if (bookingShopSettings.loyalty.status) {
            const loyaltySetting = bookingShopSettings.loyalty.spend;
            const requiredLoyaltyPoints = (booking.bookingTotal * loyaltySetting.point) / loyaltySetting.amount;
            setLoyaltyPoints(parseFloat(requiredLoyaltyPoints.toFixed(2)));
        }
    }, [shop, booking.bookingTotal, bookingShopSettings.loyalty]);

    const handleLoading = (type: keyof IIsLoading, id: string | number | null = null) => {
        setIsLoading((prevState: any) => ({
            ...prevState,
            [type]: true,
            id,
        }));
    };

    const handleLoadingFalse = () => {
        setIsLoading(initLoading);
    };

    useEffect(() => {
        if (bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) {
            loadStripeCardList();
        }
        return () => {
            dispatch(setIsValidPaymentType(false));
        };
    }, []);

    useEffect(() => {
        if (isLoadCard) {
            if (bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) {
                loadStripeCardList();
            }
            setIsLoadCard(false);
            dispatch(setIsValidPaymentType(false));
        }
    }, [isLoadCard]);

    useEffect(() => {
        if (booking.isUpadteCard) {
            loadStripeCardList();
        }
    }, [booking.isUpadteCard]);

    const loadStripeCardList = async () => {
        try {
            dispatch(setFinalizeCardLoading(true));
            handleLoading("cardList");
            const response = await axiosGet(API.STRIPE.CARD_LIST);
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setUserCardList(response.data.data.cards);
                setDefaultCardId(response.data.data.default_card_id);
                if (bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) {
                    if (response.data.data.cards.length > 0) {
                        dispatch(setIsCardAdded(true));
                    }
                    // setSelectedOption("pay_now");
                    setPaymentMethod("stripe");
                    dispatch(setPaymentType("stripe"));
                    // setPaymentMethod("stripe");
                    // dispatch(setPaymentType("stripe"));
                    dispatch(setPaymentOption("pay_later"));
                } else {
                    dispatch(setIsCardAdded(false));
                    if (!bookingId && bookingShopSettings.loyalty.status && bookingShopSettings.loyalty.totalAmount >= Number(booking.bookingTotal)) {
                        setPaymentMethod("loyalty");
                        dispatch(setPaymentType("loyalty"));
                    }
                    dispatch(setPaymentOption("pay_later"));
                }
                return;
            }

            throw response.data;
        } catch (err: any) {
        } finally {
            handleLoadingFalse();
            dispatch(setFinalizeCardLoading(false));
            dispatch(setBookingIsUpadteCard(false));
        }
    };

    const handleMakeCardDefault = async (paymentMethodId: string) => {
        if (defaultCardId === paymentMethodId) {
            return;
        }
        handleLoading("deafultCard", paymentMethodId);
        axiosPost(API.STRIPE.CARD_DEFAULT, { payment_method_id: paymentMethodId })
            .then(() => setDefaultCardId(paymentMethodId))
            .finally(() => handleLoadingFalse());
    };

    const handleOptionChange = async (option: OptionType) => {
        setSelectedOption(option);
        dispatch(setPaymentOption(option));
    };

    const handlePaymentMethodChange =
        (paymentMethod: PaymentMethod, id: any = null) =>
        (event: any) => {
            event.stopPropagation();
            setPaymentMethod(paymentMethod);
            dispatch(setPaymentType(paymentMethod));
            if (id !== null) {
                handleMakeCardDefault(id);
                setDefaultCardId(id);
            }
        };

    const handleAddCard = () => {
        setIsShowAddNewCardTab(true);
        dispatch(setCardFormVisible(true));
    };

    return (
        <>
            {isShowAddNewCardTab ? (
                <Elements stripe={stripePromise}>
                    <CardTabComponent loadStripeCardList={loadStripeCardList} isButtonShow={true} setIsShowAddNewCardTab={setIsShowAddNewCardTab} />
                </Elements>
            ) : (
                <div className="h-full relative flex flex-col overflow-hidden max-lg:pb-[48px]">
                    <div className="title mb-4 text-txtcolor font-medium justify-between text-2xl lg:mt-7 flex gap-2.5 items-center px-2 max-lg:flex-col shrink-0">
                        <div className="flex flex-col max-lg:px-2 multiline-truncate-wrapper max-lg:w-full flex-1">
                            <h5 className="text-txtcolor font-semibold text-[18px] leading-[21px] md:text-[22px] md:leading-[32px] mb-0.5">
                                {userCardList.length === 0 && user && user.total_loyalty_points === 0 ? t("Add Payment Method") : t("Payment Methods")}
                            </h5>
                            <span className="text-textGrey leading-[18px] font-normal text-[14px] maltiline-truncate line-clamp-1">
                                {t("Add a payment method on file to secure your booking. You won’t be charged until the service is complete.")}
                            </span>
                        </div>
                        {bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard && userCardList.length > 0 && (
                            <div className="flex justify-end max-lg:px-2 relative max-lg:w-[calc(100%)]">
                                <CustomButton
                                    secondary
                                    className="text-primary font-semibold cursor-pointer max-lg:w-full flex items-center text-sm leading-[18.2px] -tracking-[0.02rem]"
                                    onClick={handleAddCard}
                                >
                                    <Plus className="mr-1 mt-0 w-[16px] min-w-[16px]" />
                                    <div className="min-w-max"> {t("Add Payment Method")}</div>
                                </CustomButton>
                            </div>
                        )}
                    </div>

                    <div className="flex flex-col px-4 lg:px-2 flex-grow overflow-y-auto pb-[60px] lg:pb-2 gap-4">
                        {bookingShopSettings.payment.enable &&
                            bookingShopSettings.payment.isBookWithCard &&
                            bookingShopSettings.payment.userIsBookWithCard &&
                            (isLoading.cardList ? (
                                <div className="custom_loading_wrapper">
                                    <div className="custom_loading"></div>
                                </div>
                            ) : userCardList.length > 0 ? (
                                userCardList.map((userCard: any) => (
                                    <label
                                        key={userCard.id}
                                        htmlFor={`card-${userCard.id}`}
                                        className={`flex justify-between bg-contentBackground border p-3 md:p-4 xl:p-5 gap-5 items-center  rounded-secondary relative hover:border-primaryBorderHover cursor-pointer card-shadow-hover ${
                                            defaultCardId === userCard.id && selectPaymentMethod === "stripe" ? "border-primaryBorderHover bg-primaryHover" : "border-borderColorPrimary"
                                        }`}
                                    >
                                        <div className="flex gap-3 items-center w-full justify-between">
                                            <div className="flex flex-row items-center gap-4">
                                                <div
                                                    className={`w-[40px] h-[40px] lg:w-10 lg:h-10 xl:w-[46px] xl:h-[46px] flex justify-center items-center  ${
                                                        defaultCardId === userCard.id && selectPaymentMethod === "stripe" ? "border-primaryBorderHover " : ""
                                                    }`}
                                                >
                                                    <img className="w-full object-contain object-center" src={getCardTypeImage(userCard.card.brand)} alt="" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="leading-[25.2px] font-semibold text-[16px] mb-0.5 md:text-[18px] tracking-[-0.01em] text-TextColor">
                                                        {capitalizeFirstLetter(userCard.card.brand)} {t("ending in")} {userCard.card.last4}
                                                    </span>
                                                    <span className="text-textGrey text-[12px] leading-[18px] font-normal -tracking-[0.01em]">
                                                        {defaultCardId === userCard.id ? t("Default") : t("Set as default")}
                                                    </span>
                                                </div>
                                            </div>
                                            {isLoading.deafultCard && isLoading.id && isLoading.id === userCard.id ? (
                                                <ProgressSpinner style={{ width: "25px", height: "25px" }} className="radio-loader" strokeWidth="8" animationDuration="1.5s" />
                                            ) : (
                                                <div className="mx-0">
                                                    <label className="radio-container">
                                                        <input
                                                            type="radio"
                                                            id={`card-${userCard.id}`}
                                                            checked={
                                                                bookingShopSettings.payment.enable &&
                                                                bookingShopSettings.payment.isBookWithCard &&
                                                                bookingShopSettings.payment.userIsBookWithCard &&
                                                                defaultCardId === userCard.id &&
                                                                selectPaymentMethod === "stripe"
                                                            }
                                                            onChange={
                                                                bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard
                                                                    ? handlePaymentMethodChange("stripe", userCard.id)
                                                                    : undefined
                                                            }
                                                        />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                            )}
                                        </div>
                                    </label>
                                ))
                            ) : (
                                !booking.isCardAdd && (
                                    <Elements stripe={stripePromise}>
                                        <CardTabComponent loadStripeCardList={loadStripeCardList} isButtonShow={false} />
                                    </Elements>
                                )
                            ))}

                        {!isLoading.cardList &&
                            bookingShopSettings.payment.enable &&
                            bookingShopSettings.payment.isBookWithCard &&
                            bookingShopSettings.payment.userIsBookWithCard &&
                            userCardList.length > 0 &&
                            user &&
                            user.total_loyalty_points > 0 && (
                                <div className="flex items-center mt-[10px] mb-[10px] w-full max-w-full mx-auto">
                                    <hr className="border-spacing-2 border border-borderColorPrimary opacity-70 w-full my-0" />
                                    <span className="w-[30px] pr-0.5 flex justify-center items-center min-w-[30px] bg-disabledButtonColor mx-2 rounded-md font-normal text-[18px] -tracking-[0.03rem] leading-[24px] h-[25px] text-textGrey ">
                                        {t("or")}
                                    </span>
                                    <hr className="border-spacing-2 border border-borderColorPrimary opacity-70 w-full my-0" />
                                </div>
                            )}

                        {user &&
                            user.total_loyalty_points > 0 &&
                            ((bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard && userCardList.length > 0) ||
                                !(bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard)) && (
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="loyalty-points"
                                        className={`flex justify-between bg-contentBackground border p-3 md:p-4 xl:p-5 gap-5 items-center rounded-secondary relative md:hover:border-primaryBorderHover card-shadow-hover ${
                                            bookingShopSettings.loyalty.status && user.total_loyalty_points < loyaltyPoints
                                                ? " !shadow-none hover:!shadow-none border border-borderColorPrimary !bg-disabledButtonColor text-textGrey !transition-all opacity-75 md:hover:!border-borderColorPrimary"
                                                : ""
                                        } ${bookingShopSettings.loyalty.status && user.total_loyalty_points >= loyaltyPoints ? "cursor-pointer card-shadow-hover" : ""} ${
                                            selectPaymentMethod === "loyalty" ? "border-primaryBorderHover bg-primaryHover card-shadow-hover" : "border-borderColorPrimary card-shadow-hover"
                                        }`}
                                    >
                                        <div className="flex gap-3 items-center w-full justify-between">
                                            <div className={`flex flex-row items-center gap-4`}>
                                                <div className="p-[10px] lg:p-[8px] xl:p-[9px] 2xl:p-[10px] border borderColorPrimary rounded-minimal w-[40px] h-[40px] lg:w-10 lg:h-10 xl:w-[46px] xl:h-[46px] flex justify-center items-center bg-containerBgColor">
                                                    <CoinsStacked02 className="text-TextColor w-[22px] h-[22px]" />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span
                                                        className={`leading-[25.2px] font-semibold text-TextColor text-[16px] mb-0.5 md:text-[18px] tracking-[-0.01em] ${
                                                            bookingShopSettings.loyalty.status && user.total_loyalty_points >= loyaltyPoints ? "text-TextColor" : "text-txtAppointmentColor"
                                                        }`}
                                                    >
                                                        {t("Pay with Loyalty Points")}
                                                    </span>
                                                    <span className="text-textGrey text-[12px] leading-[18px] font-normal -tracking-[0.01em]">
                                                        {t("Available")}
                                                        {" : "}
                                                        {user.total_loyalty_points} {t("points")} {bookingShopSettings.loyalty.status && `| ${t("Required")} : ${loyaltyPoints}`}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="mx-0">
                                                <label className="radio-container">
                                                    <input
                                                        type="radio"
                                                        id="loyalty-points"
                                                        disabled={bookingShopSettings.loyalty.status ? user.total_loyalty_points < loyaltyPoints : true}
                                                        checked={selectPaymentMethod === "loyalty"}
                                                        onChange={handlePaymentMethodChange("loyalty")}
                                                    />
                                                    {bookingShopSettings.loyalty.status && user.total_loyalty_points < loyaltyPoints ? "" : <span className="checkmark"></span>}
                                                </label>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            )}

                        {/* <div
                    // bg-contentBackground border border-lightgray aa class add karjo condition ma
                    className={` border border-borderColorPrimary p-3 md:p-4 xl:p-6 flex gap-5 items-center justify-between rounded-secondary relative cursor-pointer card-shadow-hover ${
                        selectedOption === "pay_now" ? "border-primaryBorderHover bg-primaryHover " : ""
                    }`}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOptionChange("pay_now");
                    }}
                >
                    <div className="flex gap-3 items-center w-full justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <div
                                className={`p-[5px] lg:p-[5px] xl:p-[5px] 2xl:p-[5px] border borderColorPrimary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton ${
                                    selectedOption === "pay_now" ? "border-primaryBorderHover  " : ""
                                }`}
                            >
                                <CreditCard02 color="var(--brand-color)" width={20} />
                            </div>
                            <h2 className="text-base !leading-5 xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">{t("Pay Now")}</h2>
                        </div>
                        <div className="mr-0">
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    id="loyaltyPointsSelect"
                                    className="cursor-pointer bg-primary"
                                    disabled={isLoyaltyPoint ? false : true}
                                    checked={selectedOption === "pay_now"}
                                    onChange={() => handleOptionChange("pay_now")}
                                    readOnly={true}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <div
                    className={` border  p-3 md:p-4 xl:p-6 flex gap-5 items-center justify-between rounded-secondary relative cursor-pointer mt-3 card-shadow-hover ${
                        selectedOption === "pay_later" ? "border-primaryBorderHover bg-primaryHover" : "bg-contentBackground border-borderColorPrimary"
                    }`}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOptionChange("pay_later");
                    }}
                >
                    <div className="flex gap-3 items-center w-full justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <div
                                className={`p-[5px] lg:p-[5px] xl:p-[5px] 2xl:p-[5px] border border-borderColorPrimary  rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton ${
                                    selectedOption === "pay_later" ? "border-primaryBorderHover " : ""
                                }`}
                            >
                                <Building02 width={20} color="var(--brand-color)" />
                            </div>
                            <h2 className="text-base !leading-5 xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">{t("Pay Later")}</h2>
                        </div>
                        <div className="mr-0">
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    id="loyaltyPointsSelect"
                                    className="cursor-pointer bg-primary"
                                    disabled={isLoyaltyPoint ? false : true}
                                    checked={selectedOption === "pay_later"}
                                    onChange={() => handleOptionChange("pay_later")}
                                    readOnly={true}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div> */}

                        {/* <div className="bg-contentBackground border border-lightgray py-3 md:py-3 xl:py-3 pl-3 pr-6 flex gap-5 items-center justify-between rounded-xl relative cursor-pointer mt-3">
                    <div className="flex gap-3 items-center w-full justify-between">
                        <div className="flex flex-row items-center gap-5">
                            <div className="p-[5px] lg:p-[5px] xl:p-[5px] 2xl:p-[5px] border custom-icon-border rounded-lg w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton">
                                <BiWallet color="var(--icon-color)" size={20} height={20} />
                            </div>
                            <h2 className="mt-1 text-base !leading-[22.4px] lg:text-xs lg:leading-4  xl:text-sm xl:leading-5 2xl:text-base 2xl:leading-[22.4px]  text-txtcolor font-medium align-middle">
                                {t("Deposit")}
                            </h2>
                        </div>
                        <div className="mr-0">
                            <label className="radio-container">
                                <input
                                    type="radio"
                                    id="loyaltyPointsSelect"
                                    className="cursor-pointer bg-primary"
                                    disabled={isLoyaltyPoint ? false : true}
                                    checked={selectedOption === "loyalty"}
                                    onChange={() => handleOptionChange("loyalty", "loyalty_points")}
                                    readOnly={true}
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div> */}

                        {/* <div className="mt-5 pb-6 w-full px-2">
                    <div className=" text-txtcolor -tracking-[0.03rem] font-semibold text-base">{t("Payment Method")}</div>
                    <div className="location-sub-text !text-xs !leading-[18px]">{t("To protect their time FLAIR Studios requires booking with a payment method on file.")}</div>
                    {user && user.total_loyalty_points > 0 && (
                        <label
                            htmlFor="loyalty-points"
                            className={`flex justify-between bg-contentBackground border p-3 md:p-4 xl:p-6 gap-5 items-center rounded-secondary relative card-shadow-hover ${
                                isLoyaltyPoint ? "cursor-pointer" : ""
                            } ${selectPaymentMethod === "loyalty" ? "border-primary bg-primaryHover" : "border-borderColorPrimary"}`}
                        >
                            <div className="flex gap-3 items-center w-full justify-between">
                                <div className={`flex flex-row items-center gap-5`}>
                                    <div className="p-[10px] lg:p-[8px] xl:p-[9px] 2xl:p-[10px] border borderColorPrimary rounded-primary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton">
                                        <LoyaltyPoint fillColor="var(--brand-color)" />
                                    </div>
                                    <div>
                                        <p className={`font-medium text-sm text-${isLoyaltyPoint ? "txtcolor" : "txtAppointmentColor"}`}>{t("Loyalty points")}</p>
                                        <p className="text-textGrey text-xs font-medium">
                                            {t("Available")}
                                            {" : "}
                                            {user.total_loyalty_points} {t("points")}
                                        </p>
                                    </div>
                                </div>
                                <div className="mx-0">
                                    <label className="radio-container">
                                        <input
                                            type="radio"
                                            id="loyalty-points"
                                            disabled={isLoyaltyPoint ? false : true}
                                            checked={selectPaymentMethod === "loyalty"}
                                            onChange={handlePaymentMethodChange("loyalty")}
                                        />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </label>
                    )}
                    {userCardList.length > 0 ? (
                        userCardList.map((userCard: any) => (
                            <label
                                key={userCard.id}
                                htmlFor={`card-${userCard.id}`}
                                className={`flex justify-between bg-contentBackground border p-3 md:p-4 xl:p-6 gap-5 items-center  rounded-secondary relative hover:border-primaryBorderHover mt-3 cursor-pointer card-shadow-hover ${
                                    defaultCardId === userCard.id && selectPaymentMethod === "stripe" ? "border-primaryBorderHover bg-primaryHover" : "border-borderColorPrimary"
                                }`}
                            >
                                <div className="flex gap-3 items-center w-full justify-between">
                                    <div className="flex flex-row items-center gap-5">
                                        <div
                                            className={`p-[5px] lg:p-[8px] xl:p-[9px] 2xl:p-[5px] border borderColorPrimary rounded-secondary w-[46px] h-[46px] lg:w-9 lg:h-9 xl:w-10 xl:h-10 2xl:w-[40px] 2xl:h-[40px] flex justify-center items-center bg-disableLightGreyButton  ${
                                                defaultCardId === userCard.id && selectPaymentMethod === "stripe" ? "border-primaryBorderHover " : ""
                                            }`}
                                        >
                                            <img className=" w-[32px] h-[32px] object-contain object-center" src={getCardTypeImage(userCard.card.brand)} alt="" />
                                        </div>
                                        <div className="text-txtcolor leading-[140%] font-medium text-sm flex flex-col">
                                            <p className="text-base !leading-5 xl:text-lg xl:leading-[25.2px] text-txtcolor font-semibold  align-middle -tracking-[0.03rem]">
                                                {userCard.card.brand} card ending {userCard.card.last4}
                                            </p>
                                            {defaultCardId === userCard.id && <p className="text-textGrey text-xs font-medium -tracking-[0.00rem]">{t("Default")}</p>}
                                        </div>
                                    </div>
                                    <div className="mx-0">
                                        <label className="radio-container">
                                            <input
                                                type="radio"
                                                id={`card-${userCard.id}`}
                                                checked={defaultCardId === userCard.id && selectPaymentMethod === "stripe"}
                                                onChange={handlePaymentMethodChange("stripe", userCard.id)}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </label>
                        ))
                    ) : (
                        <div
                            className={`bg-bgContent border py-3 md:py-3 xl:py-3 tracking-[0.03rem] pl-3 pr-6 flex gap-5 items-center justify-between rounded-secondary relative mt-3 h-[66px] ${
                                booking.isValidPaymentType ? "border-danger" : "border-borderColorPrimary"
                            }`}
                        >
                            <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1 -tracking-[0.03rem]">
                                {isUserCardLoading ? t("Loading") : t("Card no available")}
                            </p>
                        </div>
                    )}
                    <div className="flex justify-end mt-3">
                        <label className="text-primary font-medium cursor-pointer flex items-center text-sm -tracking-[0.02rem]" onClick={() => setIsShowAddNewCardTab(true)}>
                            <Plus className="mr-2 mt-0 w-[16px]" />
                            <div className=""> {t("Add a Payment Method")}</div>
                        </label>
                    </div>
                </div> */}

                        {/* {userCardList.length > 0 ? (
                        <div className="flex w-full gap-3 bg-[#F6F8FE] p-2.5 border-0 rounded-xl  items-center">
                            <BiInfoCircle size={20} className="text-primary" />
                            <p className="text-sm leading-[140%] text-primary">
                                {t(
                                    "Your payment will be processed only after you accept the offer and select a date. The card added to the file will be used to secure your order. You will not be charged until you accept the order.",
                                )}{" "}
                                {booking.selectedSpecialist?.shop_staff?.[0]?.cancellation_window} {t("hours of the reservation.")}
                            </p>
                        </div>
                    ) : (
                        ""
                    )} */}
                    </div>
                </div>
            )}
            {uiState.isMobile && <ManageSubmit />}
        </>
    );
};

export default FinalizeBooking;
