import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ManageSubmit from "./AppointmentPreview/ManageSubmit";
import { useAppSelector } from "src/redux/hooks";
import moment from "moment";
import { getBookingInfo, shopSettings } from "./Booking.slice";

const NoShowPolicy: FC = () => {
    const { t } = useTranslation();
    const uiStates = useAppSelector((data) => data.UiStates);
    const bookingInfo = useAppSelector(getBookingInfo);
    const shopSettingsInfo = useAppSelector(shopSettings);
    const [dateTime, setDateTime] = useState({ date: "", time: "", isPast: false });
    const [settingsData, setSettingsData] = useState({
        cancellation: { status: false, cancellation_charge: 0, cancellation_type: "amount", cancellation_window: 0 },
        noShow: { status: false, no_show_charge: 0, no_show_type: "amount" },
    });
    const [text, setText] = useState({
        header: t("Cancelation & No Show Policy"),
        subtitle: t("Review our no show and cancelation policies."),
    });
    const [isPastSubTitle, setIsPastSubTitle] = useState("");

    useEffect(() => {
        const cancellationSettings = shopSettingsInfo.cancellation;
        const noShowSettings = shopSettingsInfo.noShow;
        if (cancellationSettings) {
            setSettingsData((prevState) => ({
                ...prevState,
                cancellation: {
                    status: cancellationSettings?.status,
                    cancellation_charge: cancellationSettings.cancellation_charge,
                    cancellation_type: cancellationSettings.cancellation_type,
                    cancellation_window: cancellationSettings.cancellation_window,
                },
            }));
        }
        if (noShowSettings) {
            setSettingsData((prevState) => ({
                ...prevState,
                noShow: {
                    status: noShowSettings?.status,
                    no_show_charge: noShowSettings.no_show_charge,
                    no_show_type: noShowSettings.no_show_type,
                },
            }));
        }

        if (cancellationSettings?.status && noShowSettings?.status) {
            setText({
                header: t("Cancelation & No Show Policy"),
                subtitle: t("Review our no show and cancelation policies."),
            });
        } else if (cancellationSettings?.status) {
            setText({
                header: t("Cancelation Policy"),
                subtitle: t("Review our cancelation policies."),
            });
        } else {
            setText({
                header: t("No Show Policy"),
                subtitle: t("Review our no show policies."),
            });
        }
    }, [shopSettingsInfo]);

    const calculateUpdatedDateTime = (selectedTime: string | undefined, selectedDate: string | undefined, hoursToSubtract: number) => {
        if (!selectedTime || !selectedDate) {
            return { time: "", date: "" };
        }

        const dateAndTime = moment(`${selectedDate} ${selectedTime}`, "YYYY-MM-DD HH:mm");

        const updatedDateTime = dateAndTime.subtract(hoursToSubtract, "hours");

        const updatedTime = updatedDateTime.format("hh:mm A");
        const updatedDate = updatedDateTime.format("MMMM Do - YYYY");
        const isToday = updatedDateTime.isSame(moment(), "day");
        const isPastTime = updatedDateTime.isBefore(moment(), "minute");

        return { time: updatedTime, date: updatedDate, isPast: isToday && isPastTime };
    };

    useEffect(() => {
        if (bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime && settingsData.cancellation.cancellation_window) {
            const dateTimeObj = calculateUpdatedDateTime(bookingInfo.selectedBookingTime, bookingInfo.selectedBookingDate, settingsData.cancellation.cancellation_window);
            if (dateTimeObj) {
                setDateTime({
                    ...dateTimeObj,
                    isPast: dateTimeObj.isPast !== undefined ? dateTimeObj.isPast : false,
                });
            }
        }
    }, [bookingInfo.selectedBookingTime, bookingInfo.selectedBookingDate, settingsData.cancellation.cancellation_window]);

    useEffect(() => {
        if (dateTime.isPast) {
            if (settingsData.cancellation.status && settingsData.noShow.status) {
                setIsPastSubTitle(t("A cancellation and no-show for this appointment is subject to charges based on the following policies."));
            } else if (settingsData.cancellation.status) {
                setIsPastSubTitle(t("A cancellation for this appointment is subject to charges based on the following policies."));
            } else {
                setIsPastSubTitle(t("A no-show for this appointment is subject to charges based on the following policies."));
            }
        }
    }, [settingsData, dateTime.isPast]);

    return (
        <div className={`w-full flex flex-col flex-1 ${bookingInfo.isFullScreen ? "pb-[24px]" : ""}`}>
            <div className="flex flex-col pt-[20px] lg:py-[50px] font-primary max-w-[440px] mx-auto relative flex-1">
                <div className="flex flex-col max-lg:h-full font-primary flex-1 max-lg:pb-[80px]">
                    <div className="flex flex-col">
                        <span className={uiStates.isMobile ? "login-model-title" : "login-model-title"}>{text.header}</span>
                        <span className={uiStates.isMobile ? "login-model-subtitle" : "login-model-subtitle"}>{text.subtitle}</span>
                    </div>
                    <div className="flex flex-col mt-[42px] gap-4 mb-5">
                        {dateTime.isPast && (
                            <div className={`leading-[24px] text-[16px] -tracking-[0.02rem] font-normal text-txtcolor`}>
                                <span className="font-normal leading-[24px] text-[16px]">{isPastSubTitle}</span>
                            </div>
                        )}
                        {settingsData.cancellation.status && (
                            <>
                                {!dateTime.isPast && (
                                    <div className={`leading-[24px] text-[16px] -tracking-[0.02rem] font-normal text-txtcolor`}>
                                        <span className="font-normal leading-[24px] text-[16px]">{t("You have")}</span>{" "}
                                        <span className="font-semibold leading-[24px] text-[16px]">{t(" until 12:00 AM", { value: dateTime.time })}</span>
                                        <span className="font-normal leading-[24px] text-[16px]">{t(" on")}</span>
                                        <span className="font-semibold leading-[24px] text-[16px]">{` ${dateTime.date}`}</span>
                                        <span className="font-normal leading-[24px] text-[16px]">{t(" to cancel this appointment without being charged.")}</span>
                                    </div>
                                )}
                                <div className="flex w-full border border-borderColorSecondary p-4 rounded-modelRadius CustomButtonShadow gap-4">
                                    <div className="flex flex-col flex-1">
                                        <span className="flex text-txtcolor font-semibold text-[14px] leading-[20px] mb-1">
                                            {t("Late Cancelation - ")}
                                            {t("up to 50% Charge", {
                                                value: `${settingsData.cancellation.cancellation_charge}${settingsData.cancellation.cancellation_type === "amount" ? "$" : "%"}`,
                                            })}
                                        </span>
                                        <span className="text-txtcolor font-normal text-[14px] leading-[20px]">
                                            {t("You may be charged a up to 50% fee if you cancel your appointment after this date.", {
                                                value: `${settingsData.cancellation.cancellation_charge}${settingsData.cancellation.cancellation_type === "amount" ? "$" : "%"}`,
                                            })}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}
                        {settingsData.noShow.status && (
                            <div className="flex w-full border border-borderColorSecondary p-4 rounded-modelRadius CustomButtonShadow gap-4">
                                <div className="flex flex-col flex-1">
                                    <span className="flex text-txtcolor font-semibold text-[14px] leading-[20px] mb-1">
                                        {t("No-Show - ")}
                                        {t("up to 50% Charge", { value: `${settingsData.noShow.no_show_charge}${settingsData.noShow.no_show_type === "amount" ? "$" : "%"}` })}
                                    </span>
                                    <span className="text-txtcolor font-normal text-[14px] leading-[20px]">
                                        {t("You may be charged up to 100% if you do not attend your appointment on the date you specified.", {
                                            value: `${settingsData.noShow.no_show_charge}${settingsData.noShow.no_show_type === "amount" ? "$" : "%"}`,
                                        })}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <ManageSubmit isModal={true} />
            </div>
        </div>
    );
};

export default NoShowPolicy;
