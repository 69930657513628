import React, { FC, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { usePhone } from "src/hooks/usePhone";
import * as Yup from "yup";
import WInquireForm from "./WInquireForm";
import { useAppSelector } from "src/redux/hooks";
import { IInquireForm, IInquireProps } from "./Course.interface";
import MInquireForm from "./MInquireForm";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { errorCode } from "src/constants/common";

const InquireForm: FC<IInquireForm> = ({ setIsSuccess, handleClose, title }) => {
    const { isPhoneValid } = usePhone();
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [isLoading, setIsLoading] = useState(false);

    const schema = Yup.object({
        phone: Yup.string()
            .required(t("This field is required"))
            .test({
                message: t("Invalid phone number"),
                name: "invalid",
                test: function (value) {
                    const { phone_country_code: phoneCountryCode } = this.parent;
                    // Ensure 'value' is a string before calling 'isPhoneValid'
                    if (value && typeof value === "string") {
                        const isValid = isPhoneValid(value, phoneCountryCode);
                        return isValid;
                    }
                    return false;
                },
            }),
        phone_country_code: Yup.string().required("This field is required"),
        name: Yup.string().required("This field is required"),
        description: Yup.string().required("This field is required"),
    }).required();
    const methods = useForm<IInquireProps>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            phone_country_code: "CA",
            phone: "",
            description: "",
        },
    });

    const { handleSubmit, setError } = methods;
    const handleSave = async (data: any) => {
        setIsLoading(true);
        axiosPost(API.COURSE.CREATE, data, {
            shop_id: shop.id,
        })
            .then(() => {
                handleClose();
                setIsSuccess(true);
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IInquireProps, {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        return;
                    }
                }
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSave)} className={`w-full ${uiState.isTablet ? "p-[50px] bg-containerBgColor rounded-secondary" : "mobile-model-inner-wrapper font-primary"} `}>
                    {uiState.isTablet ? <WInquireForm title={title} /> : <MInquireForm title={title} />}
                    <div className="mt-[20px] md:mr-[30px] w-full flex flex-row px-4 md:px-0">
                        <CustomButton
                            primary
                            isLoading={isLoading}
                            disabled={isLoading}
                            className={`${uiState.isTablet ? "w-full !h-[49px]" : "w-full mb-4 !h-[54px] max-w-[400px] mx-auto"}`}
                            type="submit"
                        >
                            <span>{t("SUBMIT")}</span>
                        </CustomButton>
                    </div>
                </form>
            </FormProvider>
        </>
    );
};

export default InquireForm;
