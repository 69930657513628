import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { axiosGet, axiosPatch, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { capitalizeFirstLetter, checkFileTypeValidation, convertBase64ToFile, getCardTypeImage, getShortName } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";
import { currentShop, currentUser, me } from "src/redux/reducers/common/Common.slice";
import { usePhone } from "src/hooks/usePhone";
import { CountryCode } from "libphonenumber-js";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import { initAction } from "./Profile.interface";
import Sidebar from "src/components/Sidebar";
import BookingView from "./Booking/BookingView";
import Tooltip from "src/components/Tooltip/Tooltip";
import { Copy06, InfoCircle, Pencil02, Plus } from "@untitled-ui/icons-react/build/cjs";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { GoDotFill } from "react-icons/go";
import { errorCode, GLOBALVARIABLE } from "src/constants/common";
import CropperModal from "src/components/CropperModal/CropperModal";
import { s3Upload } from "src/utils/s3Operations";
import { s3Path } from "src/utils/s3Path";
import { ProgressSpinner } from "primereact/progressspinner";
import MCropperModal from "src/components/CropperModal/MCropperModal";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import BookingDetails from "../Location/BookingDetails";
import SBookingDetails from "./SBookingDetails";

interface ILanding {
    handleAction: (type: string) => () => void;
    handleClose: () => void;
}

interface IisCardActionLoading {
    [key: string]: boolean;
}

const Landing = ({ handleAction, handleClose }: ILanding) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((data) => data.UiStates);
    const { getFormatPhone } = usePhone();
    const [isStripeCardLoading, setIsStripeCardLoading] = useState(false);
    const [isBookingLoading, setIsBookingLoading] = useState(false);
    const [stripeCard, setStripeCard] = useState<any>(null);
    const [booking, setBooking] = useState<IAppointmentBooking | null>(null);
    const [referralConfig, setReferralConfig] = useState({ refferer: 0, refferer_type: "percentage" });
    const [action, setAction] = useState(initAction);
    const [isRefresh, setIsRefresh] = useState(true);
    const [upload, setUpload] = useState(false);
    const [cropData, setCropData] = useState<any>("");
    const [image, setImage] = useState<any>("");
    const [imageError, setImageError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isImageError, setIsImageError] = useState<boolean>(false);
    const [stripeCards, setStripeCards] = useState([]);
    const [defaultCardId, setDefalutCardId] = useState<string | undefined>(undefined);
    const [isCardActionLoading, setIsCardActionLoading] = useState<IisCardActionLoading>({});

    useEffect(() => {
        listStripeCards();
    }, []);

    useEffect(() => {
        if (isRefresh) {
            listBookings();
        }
    }, [isRefresh]);

    useEffect(() => {
        const filterShopSetting = shop.shop_settings.find((setting: { type: string }) => setting.type === "refer");
        if (filterShopSetting) {
            setReferralConfig({
                refferer: filterShopSetting.value?.refferer || 0,
                refferer_type: filterShopSetting.value?.refferer_type || "",
            });
        }
    }, [shop]);

    const listStripeCards = () => {
        setIsStripeCardLoading(true);
        axiosGet(API.STRIPE.CARD_LIST)
            .then((response) => {
                const cards = response.data.data.cards;
                setStripeCards(cards);
                setDefalutCardId(response.data.data.default_card_id);
                const defaultCard = response.data.data.default_card_id;
                if (cards.length) {
                    setStripeCard(cards.find((card: any) => card.id === defaultCard));
                }
            })
            .finally(() => {
                setIsStripeCardLoading(false);
            });
    };

    const listBookings = () => {
        setIsBookingLoading(true);
        const payload = { skip: 0, type: "upcoming" };
        const params = { shop_id: shop.id };
        axiosPost(API.BOOKING.LIST, payload, params)
            .then((response) => {
                setBooking(response.data.data[0]);
            })
            .finally(() => {
                setIsBookingLoading(false);
                setIsRefresh(false);
            });
    };

    const navigateLocation = () => {
        handleClose();
        navigateBasedOnShopLocations();
    };

    const handleActions = (type: string, data: IAppointmentBooking) => (event: any) => {
        event.stopPropagation();
        setBooking(data);
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleActionClose = () => {
        setAction(initAction);
    };

    const handleProfileImage = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);
        if (result) {
            setImageError(false);
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage("");
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
        } else {
            setImageError(true);
        }
    };

    const imageUploadFun = async (data: any) => {
        const fileInfo = convertBase64ToFile(data);
        setIsLoading(true);
        if (fileInfo && fileInfo.convertedFile && fileInfo?.filename) {
            const logoImageResponse: any = await s3Upload(fileInfo.convertedFile, `${s3Path.USER_PROFILE}${fileInfo?.filename}`);
            const imageStatus = logoImageResponse?.status;
            if (imageStatus === errorCode.updateSuccess) {
                axiosPatch(API.USER.USER_PROFILE_IMAGE_UPDATE, { profile_image_name: fileInfo?.filename })
                    .then(async () => {
                        await dispatch(me());
                        // handleClose();
                        return;
                    })
                    .catch((error) => {
                        const response = error.response.data;
                        if (response.status === errorCode.unprocessable) {
                            if (response.data) {
                                setImageError(true);
                                return;
                            }
                        }
                    })
                    .finally(() => setIsLoading(false));
            } else {
                setIsLoading(false);
            }
        }
    };

    const handleImageError = () => {
        setIsImageError(true);
    };

    const copyDebounce = useCallback(
        debounce((copyQuote: string) => {
            navigator.clipboard.writeText(copyQuote);
            toast.info(t("Code Copied!"), {
                position: "top-center",
                hideProgressBar: true,
                className: "custom-toast-code",
                bodyClassName: "custom-toast-code-body",
                autoClose: 3000,
                icon: false,
                closeOnClick: true,
                toastId: 1,
            });
        }, 0),
        [],
    );

    const handleClipboardClick = async () => {
        try {
            const permission = await navigator.permissions.query({ name: "clipboard-write" as PermissionName });

            if (permission.state === "granted" || permission.state === "prompt") {
                await navigator.clipboard.writeText(user ? user.referral_code : "");
                copyDebounce(user ? user.referral_code : "");
            }
        } catch (error) {
            console.log("error", error);
            // let copyText = document.querySelector("#input") as HTMLInputElement | null;
            // if (copyText) {
            //     copyText.select();
            //     document.execCommand("copy");
            // }
        }
    };

    const handleDefault = (paymentMethodId: string) => () => {
        if (defaultCardId === paymentMethodId) {
            return;
        }
        setIsCardActionLoading((prev) => ({ ...prev, [paymentMethodId]: true }));
        axiosPost(API.STRIPE.CARD_DEFAULT, { payment_method_id: paymentMethodId })
            .then(() => {
                setDefalutCardId(paymentMethodId);
            })
            .catch((error: any) => {})
            .finally(() => {
                setIsCardActionLoading((prev) => ({ ...prev, [paymentMethodId]: false }));
            });
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-col w-full p-4 lg:p-5 bg-sidebarBgColor">
                <div className="flex justify-between mb-[30px] items-center">
                    <div className="flex-1 flex flex-row items-center gap-4">
                        <div className="flex relative">
                            {user?.profile_image_url && !isImageError ? (
                                <img
                                    src={user?.profile_image_url}
                                    alt=""
                                    className={`rounded-minimal text-primaryAppointment flex justify-center items-center object-cover w-[64px] h-[64px] lg:h-[64px] min-w-[64px] lg:w-[64px] shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] ${
                                        imageError ? "is-invalid" : ""
                                    }`}
                                    onError={handleImageError}
                                />
                            ) : (
                                <div
                                    className={`rounded-minimal bg-mainBackgroundColor flex justify-center items-center shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] text-primaryAppointment font-medium  w-[64px] h-[64px] ${
                                        imageError ? "is-invalid" : ""
                                    }`}
                                >
                                    <span className="text-[24px] leading-[36px] font-semibold">{getShortName(user?.full_name as string)}</span>
                                </div>
                            )}
                            <div
                                className={`absolute h-[26px] w-[26px] right-[-6px] bottom-[-3px] flex transition justify-center items-center hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] hover:bg-white text-white hover:text-[#000000] ${
                                    !isLoading && "bg-[#000000]"
                                } rounded-full cursor-pointer`}
                            >
                                {isLoading ? (
                                    <ProgressSpinner style={{ width: "26px", height: "26px" }} className="radio-loader" strokeWidth="8" animationDuration="1.5s" />
                                ) : (
                                    <>
                                        <input type="file" id="image" className=" absolute opacity-0 rounded-full h-[26px] w-[26px] " onChange={handleProfileImage} />
                                        <Pencil02 className="w-[14px] h-[14px]" />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <h1 className="text-[16px] font-bold text-txtAppointmentColor capitalize leading-[22.4px] mb-1 tracking-[-0.01rem] truncate-custom max-w-[160px] xs:max-w-[180px] sms:max-w-[254px]">
                                {user?.full_name}
                            </h1>
                            {user?.phone && (
                                <h3 className="text-sm text-txtAppointmentColor font-normal leading-[18.2px] tracking-[-0.01rem] decoration-none">
                                    {getFormatPhone(user?.phone as string, user?.phone_country_code as CountryCode)}
                                </h3>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center justify-center rounded-[6px] border border-borderCounter pr-1.5 pl-1 h-[24px] CustomButtonShadow">
                        <span className="flex items-center">
                            <GoDotFill size={12} className="text-primary w-3 h-3 mr-0.5" />{" "}
                            <span className="text-[12px] font-medium text-textCounterColor leading-[18px]">
                                {" "}
                                {t("My Points")}: {user?.total_loyalty_points}
                            </span>
                        </span>

                        {/* <Tooltip description="Refferal points">
                            <InfoCircle id="alert_circle_svg" className="text-textGrey w-[18px] cursor-pointer" />
                        </Tooltip> */}
                    </div>
                </div>
                <div className="mb-[30px] flex flex-col">
                    <div className="flex items-center mb-[7px] gap-2">
                        <span className="text-[14px] font-semibold text-txtAppointmentColor leading-[18px] tracking-[-0.01rem]">{t("My Referral Code")}</span>
                        <Tooltip
                            position={`${uiState.isMobile ? "bottom" : "right"}`}
                            description={t("Get 0% off any service when you refer a friend!", {
                                value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                            })}
                        >
                            <InfoCircle id="alert_circle_svg" className="text-textGrey w-[16px] h-[18.2px] cursor-pointer" />
                        </Tooltip>
                    </div>
                    <div className="flex relative h-[56px] w-full rounded-secondary border border-borderColorPrimary text-primary leading-[44px] text-sm lg:text-base font-semibold lg:font-medium items-center px-4 tracking-[-0.01rem] bg-containerBgColor hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)]">
                        {user?.referral_code}
                        <Copy06 onClick={handleClipboardClick} className="text-primary w-[18px] cursor-pointer absolute right-[16px]" />
                    </div>
                </div>
                <div className="mb-[30px] flex flex-col">
                    <div className="flex justify-between items-center mb-1.5">
                        <h2 className="text-[14px] font-semibold text-txtAppointmentColor leading-[18px] tracking-[-0.01rem]">{t("Upcoming Appointment")}</h2>
                        {isBookingLoading ? (
                            <span className="text-sm font-medium leading-[16px] tracking-[-0.01rem] flex text-primaryAppointment hover:text-brandColorLightDark items-center cursor-pointer custom-loading">
                                New Appointment
                            </span>
                        ) : (
                            booking && (
                                <span
                                    className="text-sm font-medium leading-[16px] tracking-[-0.01rem] flex text-primaryAppointment hover:text-brandColorLightDark items-center cursor-pointer"
                                    onClick={navigateLocation}
                                >
                                    <Plus className="w-[16px] h-[15px] mr-1" /> <span className="leading-[15px]">{t("New Appointment")}</span>
                                </span>
                            )
                        )}
                    </div>

                    {isBookingLoading ? (
                        <SBookingDetails />
                    ) : booking ? (
                        <div className="cursor-pointer gap-5 flex flex-col hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)] rounded-minimal" onClick={handleActions("booking", booking)}>
                            <BookingDetails bookingDetails={booking} handleAction={handleActions} isTipShow={false} />
                        </div>
                    ) : (
                        <div
                            className="w-full border border-borderColorPrimary px-[12px] py-[12px] flex flex-col items-center h-[72px] bg-contentBackground rounded-secondary justify-center bg-cardBackgroundColor cursor-pointer hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)]"
                            onClick={navigateLocation}
                        >
                            <>
                                <span className="flex items-center justify-center h-[26px] w-[26px] mb-1">
                                    <Plus className="w-[16px] h-[16px]" />
                                </span>
                                <p className="text-sm text-textGrey font-medium leading-[18px] tracking-[-0.01rem]">{t("Create a New Appointment")}</p>
                            </>
                        </div>
                    )}
                </div>
                <div className="mb-5">
                    <div className="flex justify-between items-center mb-1.5">
                        <h2 className="text-[14px] font-semibold text-txtAppointmentColor leading-[18px] tracking-[-0.01rem]">{t("Payment Methods")}</h2>
                        {isStripeCardLoading ? (
                            <span className="text-sm font-medium leading-[16px] tracking-[-0.01rem] flex text-primaryAppointment hover:text-brandColorLightDark items-center cursor-pointer custom-loading">
                                {t("Add Payment Method")}
                            </span>
                        ) : (
                            stripeCards.length !== 0 && (
                                <span
                                    className="text-sm font-medium leading-[16px] tracking-[-0.01rem] flex text-primaryAppointment hover:text-brandColorLightDark items-center cursor-pointer"
                                    onClick={handleAction("create_payment_method")}
                                >
                                    <Plus className="w-[16px] h-[15px] mr-1" /> <span className="leading-[15px]">{t("Add Payment Method")}</span>
                                </span>
                            )
                        )}
                    </div>

                    <div className="flex flex-col flex-1 gap-2.5 lg:gap-3.5">
                        {isStripeCardLoading ? (
                            <div className="p-3 rounded-secondary border gap-3 lg:gap-3 border-borderColorPrimary flex items-center">
                                <div className="rounded-lg min-w-[42px] w-[42px] h-[42px] object-contain flex justify-center items-center custom-loading">
                                    <div className="w-full" />
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-[16px] font-medium flex-1 text-TextColor tracking-[-0.01rem] leading-[22.4px] truncate custom-loading">dummytextdummytexttext</span>
                                    <span className="text-xs text-textGrey font-normal leading-[18px] tracking-[-0.01rem] custom-loading max-w-[100px] mt-0.5">{t("Default")}</span>
                                </div>
                            </div>
                        ) : stripeCards.length > 0 ? (
                            stripeCards.map((stripeCardDetail: any) => (
                                <div
                                    className="p-3 rounded-minimal border border-borderColorPrimary flex items-center cursor-pointer"
                                    // onClick={(e) => {
                                    //     e.stopPropagation();
                                    //     handlePaymentMethodChange("card", stripeCard.id);
                                    // }}
                                    key={stripeCardDetail.id}
                                >
                                    <div className="flex-1 gap-3 lg:gap-3.5 flex flex-row items-center">
                                        <div className="rounded-lg min-w-[42px] w-[42px] h-[42px] object-contain flex justify-center items-center">
                                            <img src={getCardTypeImage(stripeCardDetail.card.brand)} alt="card" className="w-full" />
                                        </div>
                                        <div className="flex flex-col">
                                            <span className="leading-[19.6px] font-semibold text-[14px] text-TextColor tracking-[-0.01em] mb-0.5">
                                                {capitalizeFirstLetter(stripeCardDetail.card.brand)} {t("ending in")} {stripeCardDetail.card.last4}
                                            </span>
                                            {stripeCardDetail.id === defaultCardId ? (
                                                <span className="text-[12px] text-textGrey leading-[18px] font-normal">{t("Default")}</span>
                                            ) : (
                                                <button
                                                    className="text-[12px] text-textGrey leading-[18px] font-normal cursor-pointer text-left hover:text-TextColor"
                                                    disabled={Object.values(isCardActionLoading).includes(true)}
                                                    onClick={handleDefault(stripeCardDetail.id)}
                                                >
                                                    {t("Set as default")}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    {isCardActionLoading[stripeCardDetail.id] ? (
                                        <ProgressSpinner style={{ width: "25px", height: "25px" }} className="radio-loader" strokeWidth="8" animationDuration="1.5s" />
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            ))
                        ) : (
                            <div
                                className="w-full border border-borderColorPrimary px-[12px] py-[12px] flex flex-col items-center h-[72px] bg-contentBackground rounded-secondary justify-center bg-cardBackgroundColor cursor-pointer hover:shadow-[0px_0px_8px_2px_var(--booking-card-shadow)]"
                                onClick={handleAction(stripeCard ? "payment_method" : "create_payment_method")}
                            >
                                <>
                                    <span className="flex items-center justify-center h-[26px] w-[26px] mb-1">
                                        <Plus className="w-[16px] h-[16px]" />
                                    </span>
                                    <p className="text-sm text-textGrey font-medium leading-[18px] tracking-[-0.01rem]">{t("Add Payment Method")}</p>
                                </>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Sidebar
                isBack
                isOpen={action.booking}
                isBottomView={uiState.isMobile}
                size="w-[460px]"
                customClass="sidebar-wrapper profile-sidebar !h-full"
                position={uiState.isMobile ? "bottom" : "right"}
                handleClose={handleActionClose}
                handleBack={handleActionClose}
                dismissable
            >
                {action.booking && booking && <BookingView booking={booking} handleClose={handleActionClose} handleOnModify={handleClose} setIsRefresh={setIsRefresh} />}
            </Sidebar>
            {upload &&
                (uiState.isTablet ? (
                    <CropperModal
                        imageUpload={imageUploadFun}
                        imageUrl={image}
                        setUpload={setUpload}
                        setCropData={setCropData}
                        defaultCropType="Square"
                        title={t("Crop Image")}
                        description={t("Upload a 400x400px image for the best results.")}
                        isCropType={false}
                    />
                ) : (
                    <MCropperModal
                        imageUpload={imageUploadFun}
                        imageUrl={image}
                        setUpload={setUpload}
                        setCropData={setCropData}
                        defaultCropType="Square"
                        title={t("Crop Image")}
                        description={t("Upload a 400x400px image for the best results.")}
                        isCropType={false}
                    />
                ))}
        </div>
    );
};

export default Landing;
