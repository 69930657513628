import { CountryCode } from "libphonenumber-js";
import { boolean } from "yup/lib/locale";

export interface INotification {
    referral: NotificationOptions;
    whats_new: NotificationOptions;
    promotions: NotificationOptions;
    booking_confirm: NotificationOptions;
    booking_canceled: NotificationOptions;
    booking_reschedule: NotificationOptions;
}

export type NotificationOptions = "none" | "all" | "email" | "sms";

export enum Category {
    TODAY = "today",
    YESTERDAY = "yesterday",
    THIS_WEEK = "this_week",
    OLDER = "older",
}

export const initAction = {
    landing: true,
    logout: false,
    notification: false,
    notification_setting: false,
    account_setting: false,
    payment_method: false,
    appointment_history: false,
    tip: false,
    booking: false,
    create_payment_method: false,
};

export interface IAction {
    landing: boolean;
    logout: boolean;
    notification: boolean;
    notification_setting: boolean;
    account_setting: boolean;
    payment_method: boolean;
    appointment_history: boolean;
    tip: boolean;
    booking: boolean;
    create_payment_method: boolean;
}

export interface IUpdateEmailField {
    email: string;
    otp?: string;
}

export interface IUpdateEmailPayload {
    email: string;
}

export type IUpdateEmailFieldKey = "email";

export interface IUpdateEmailVerifyPayload {
    email: string;
    otp: string;
}

export interface IinitAction {
    email: boolean;
    phone: boolean;
    password: boolean;
}

export type IAccountSetting = {
    handleAction: (type: keyof IinitAction) => () => void;
};

export interface IUpdateEmail {
    email: string;
    isLoading: boolean;
    isOtpLoading: boolean;
    handleModalClose: () => void;
    verifyStep: IEmailVerifyStep;
    handlerSendCode: (emailData: string) => () => Promise<void>;
}

export interface IEmailVerifyStep {
    email: boolean;
    otp: boolean;
    success: boolean;
}

export interface IPhoneVerifyStep {
    phone: boolean;
    otp: boolean;
    success: boolean;
}

export interface IUpdatePasswordField {
    old_password: string;
    password: string;
    password_confirmation: string;
}

export interface IUpdatePhoneField {
    phone: string;
    phone_country_code: string;
    otp?: string;
}

export interface IUpdatePhonePayload {
    phone_country_code: string;
    phone: string;
}

export type IUpdatePhoneFieldKey = "phone" | "phone_country_code";

export interface IPhoneDetail {
    formatedPhone?: string;
    phone: string;
    country_code: CountryCode;
}
export interface IUpdatePhone {
    isLoading: boolean;
    isOtpLoading: boolean;
    handleModalClose: () => void;
    verifyStep: IPhoneVerifyStep;
    phoneDetails: IPhoneDetail;
    handlerSendCode: (phoneData: IPhoneDetail) => () => Promise<void>;
}

export interface IUpdatePassword {
    passwordChange: boolean;
    handleValidation: (value: string) => void;
    isLoading: boolean;
    validationsPassed: boolean;
    handleModalClose: () => void;
}
