import React, { FC, useCallback, useState } from "react";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { useAppSelector } from "src/redux/hooks";
import { onError } from "src/utils/global-functions";
import { SwiperSlide } from "swiper/react";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import { Swiper as SwiperType } from "swiper/types";

interface GalleryCarouselProps {
    activeSlideIndex: number;
}

const GalleryCarousel: FC<GalleryCarouselProps> = ({ activeSlideIndex }) => {
    const uiState = useAppSelector((data) => data.UiStates);
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);
    return (
        <div className="relative w-full GalleryCarousel">
            <CustomSwiper spaceBetween={28} loop={true} className="mySwiper w-full" setSwiperRef={setSwiperRef} initialSlide={activeSlideIndex}>
                {uiState.showSpecialistProfileModalData?.galleries.map((item: any, index: React.Key | null | undefined) => (
                    <SwiperSlide key={index}>
                        <div className="relative w-full h-full cursor-pointer flex items-center justify-center">
                            <img
                                src={item.image_url}
                                alt=""
                                onError={onError()}
                                className="object-contain max-sms:max-w-[310px] max-md:max-w-[380px] max-md:max-h-[380px] max-md:aspect-square md:h-[493px] md:w-[660px] rounded-b-none"
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </CustomSwiper>
            {uiState.showSpecialistProfileModalData?.galleries && uiState.showSpecialistProfileModalData?.galleries.length > 1 && (
                <div className="flex relative py-3 gap-3 justify-center">
                    <button
                        className="rounded-primary flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] z-10 bg-transparent md:hover:bg-primaryHover md:hover:border-primaryBorderHover transition-all"
                        onClick={handlePrevious}
                    >
                        <ChevronLeft className="text-TextColor w-4 h-4 smd:w-[20px] smd:h-[20px]" />
                    </button>
                    <button
                        className="rounded-primary flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] bg-transparent z-10 md:hover:bg-primaryHover md:hover:border-primaryBorderHover transition-all"
                        onClick={handleNext}
                    >
                        <ChevronRight className="text-TextColor w-4 h-4 smd:w-[20px] smd:h-[20px]" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default GalleryCarousel;
