import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import {
    getBookingInfo,
    getClientDetails,
    setBookingComplete,
    setBookingTotal,
    setIsShowNoShowPolicy,
    setIsShowOrderSummary,
    setIsValidPaymentType,
    setNextAvailability,
    setNoShowPolicyVisible,
    setPreview,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setSelectedPromocode,
    setSelectedServices,
    setSelectedSpecialist,
    setSettings,
    setStep,
    setSubmitTriggered,
    setTempStore,
    shopSettings,
} from "../Booking.slice";
import { BookingTABS, EBookingTabState } from "../bookAppointment.interface";
import { axiosPatch, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { ISpecialistService } from "src/Interface";
import { toast } from "react-toastify";
import { setIsShowLoginModal } from "src/redux/reducers/Login.slice";
import { useNavigate } from "react-router-dom";
import { allShopProducts, currentSettings, currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { PATH } from "src/constants/path";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { calculateBookingTotal } from "src/utils/global-functions";
import { useBookingConditionsStatus } from "src/hooks/useBookingConditionsStatus";
import { LOCAL_STORAGE_KEYS, SPECIALIST_PAY_STRUCTURE_TYPE } from "src/constants/common";
import { CancelationIcon, SuccessIcon } from "src/theme/Images";

interface IManageSubmit {
    isModal?: boolean;
}

const ManageSubmit: FC<IManageSubmit> = ({ isModal = false }) => {
    const { t } = useTranslation();
    const currentSettingsList = useAppSelector(currentSettings);
    const bookingInfo = useAppSelector(getBookingInfo);
    const productList = useAppSelector(allShopProducts);
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { navigateBasedOnShopLocations, syncTempStoreOnClose } = useShopNavigation();
    const { validateConditions, hasSelectedRequiredFields } = useBookingConditionsStatus();
    const [isServiceBtnClick, setIsServiceBtnClick] = useState(false);
    const bookingShopSettings = useAppSelector(shopSettings);
    const [productLable, setproductLable] = useState("Continue");

    useEffect(() => {
        getProductButtonLabel();
    }, [bookingInfo.selectedProducts, user, bookingShopSettings.loyalty.totalAmount]);

    const manageShopSetting = () => {
        const cancellationSettings = currentSettingsList?.find((setting: any) => setting.type === "cancellation");
        const noShowSettings = currentSettingsList?.find((setting: any) => setting.type === "no_show");
        const loyaltySettings = currentSettingsList?.find((setting: any) => setting.type === "loyalty");
        const referSettings = currentSettingsList?.find((setting: any) => setting.type === "refer");
        const paymentSettings = currentSettingsList?.find((setting: any) => setting.type === "payment");

        dispatch(
            setSettings({
                payment: { enable: shop.admin.is_verified_stripe_account },
            }),
        );
        if (paymentSettings) {
            dispatch(
                setSettings({
                    payment: {
                        isBookWithCard: paymentSettings?.value.is_book_with_card,
                    },
                }),
            );
        }
        if (loyaltySettings) {
            dispatch(
                setSettings({
                    loyalty: {
                        status: loyaltySettings?.status,
                        receive: loyaltySettings?.value.receive,
                        spend: loyaltySettings?.value.spend,
                        totalAmount: user ? user.total_loyalty_amount : 0,
                    },
                }),
            );
        } else {
            dispatch(
                setSettings({
                    loyalty: {
                        status: false,
                        receive: 0,
                        spend: 0,
                        totalAmount: 0,
                    },
                }),
            );
        }
        if (referSettings) {
            dispatch(
                setSettings({
                    refer: {
                        referee: referSettings?.value.referee,
                        refferer: referSettings?.value.refferer,
                        referee_type: referSettings?.value.referee_type,
                        refferer_type: referSettings?.value.refferer_type,
                        status: referSettings.status,
                    },
                }),
            );
        } else {
            dispatch(
                setSettings({
                    refer: {
                        referee: 0,
                        refferer: 0,
                        referee_type: "value",
                        refferer_type: "value",
                        status: false,
                    },
                }),
            );
        }
        if (cancellationSettings || noShowSettings) {
            dispatch(setNoShowPolicyVisible(cancellationSettings?.status || noShowSettings?.status));

            dispatch(
                setSettings({
                    cancellation: {
                        status: cancellationSettings?.status,
                        cancellation_charge: cancellationSettings?.value.cancellation_charge,
                        cancellation_type: cancellationSettings?.value.cancellation_type,
                        cancellation_window: cancellationSettings?.value.cancellation_window,
                    },
                    noShow: {
                        status: noShowSettings?.status,
                        no_show_charge: noShowSettings?.value.no_show_charge,
                        no_show_type: noShowSettings?.value.no_show_type,
                    },
                }),
            );
        } else {
            dispatch(setNoShowPolicyVisible(false));
        }
    };

    useEffect(() => {
        if (bookingInfo.selectedSpecialist) {
            //  for calendar settting selected staff
            if (bookingInfo.selectedSpecialist.shop_staff_settings && bookingInfo.selectedSpecialist.shop_staff_settings.length) {
                const staffSettings = bookingInfo.selectedSpecialist.shop_staff_settings;
                const calendarSettings = staffSettings?.find((setting: any) => setting.type === "calendar");
                if (calendarSettings) {
                    dispatch(
                        setSettings({
                            calendar: {
                                advance_weeks: calendarSettings.value.advance_weeks,
                            },
                        }),
                    );
                } else {
                    dispatch(
                        setSettings({
                            calendar: { advance_weeks: 0 },
                        }),
                    );
                }
            } else {
                dispatch(
                    setSettings({
                        calendar: { advance_weeks: 0 },
                    }),
                );
            }
            if (
                bookingInfo.selectedSpecialist.pay_structure &&
                bookingInfo.selectedSpecialist.pay_structure.pay_structure_type &&
                SPECIALIST_PAY_STRUCTURE_TYPE.includes(bookingInfo.selectedSpecialist.pay_structure.pay_structure_type)
            ) {
                if (shop && user) {
                    const payload = {
                        params: {
                            shop_id: shop.id,
                        },
                        data: {
                            shop_admin_id: bookingInfo.selectedSpecialist.id,
                        },
                    };
                    dispatch(getClientDetails(payload));
                }
                dispatch(
                    setSettings({
                        payment: {
                            enable: bookingInfo.selectedSpecialist.is_verified_stripe_account,
                        },
                    }),
                );
                if (bookingInfo.selectedSpecialist.shop_staff_settings && bookingInfo.selectedSpecialist.shop_staff_settings.length) {
                    const staffSettings = bookingInfo.selectedSpecialist.shop_staff_settings;
                    const cancellationSettings = staffSettings?.find((setting: any) => setting.type === "cancellation");
                    const noShowcurrentSettings = staffSettings?.find((setting: any) => setting.type === "no_show");
                    const loyaltySettings = staffSettings?.find((setting: any) => setting.type === "loyalty");
                    const referSettings = staffSettings?.find((setting: any) => setting.type === "refer");
                    const paymentSettings = staffSettings?.find((setting: any) => setting.type === "payment");

                    if (paymentSettings) {
                        dispatch(
                            setSettings({
                                payment: {
                                    isBookWithCard: paymentSettings?.value.is_book_with_card,
                                },
                            }),
                        );
                    }
                    if (cancellationSettings || noShowcurrentSettings) {
                        dispatch(setNoShowPolicyVisible(cancellationSettings?.status || noShowcurrentSettings?.status));
                        dispatch(
                            setSettings({
                                cancellation: {
                                    status: cancellationSettings?.status,
                                    cancellation_charge: cancellationSettings?.value.cancellation_charge,
                                    cancellation_type: cancellationSettings?.value.cancellation_type,
                                    cancellation_window: cancellationSettings?.value.cancellation_window,
                                },
                                noShow: {
                                    status: noShowcurrentSettings?.status,
                                    no_show_charge: noShowcurrentSettings?.value.no_show_charge,
                                    no_show_type: noShowcurrentSettings?.value.no_show_type,
                                },
                            }),
                        );
                    } else {
                        dispatch(setNoShowPolicyVisible(false));
                    }

                    if (loyaltySettings) {
                        let spendAmount = 0;
                        if (user) {
                            const spendObj = loyaltySettings?.value.spend;
                            const amount = spendObj.amount;
                            const point = spendObj.point;
                            const points = user.total_loyalty_points;
                            spendAmount = (amount / point) * points;
                        }
                        dispatch(
                            setSettings({
                                loyalty: {
                                    status: loyaltySettings?.status,
                                    receive: loyaltySettings?.value.receive,
                                    spend: loyaltySettings?.value.spend,
                                    totalAmount: spendAmount,
                                },
                            }),
                        );
                    } else {
                        dispatch(
                            setSettings({
                                loyalty: {
                                    status: false,
                                    receive: 0,
                                    spend: 0,
                                    totalAmount: 0,
                                },
                            }),
                        );
                    }
                    if (referSettings) {
                        dispatch(
                            setSettings({
                                refer: {
                                    referee: referSettings?.value.referee,
                                    refferer: referSettings?.value.refferer,
                                    referee_type: referSettings?.value.referee_type,
                                    refferer_type: referSettings?.value.refferer_type,
                                    status: referSettings.status,
                                },
                            }),
                        );
                    } else {
                        dispatch(
                            setSettings({
                                refer: {
                                    referee: 0,
                                    refferer: 0,
                                    referee_type: "value",
                                    refferer_type: "value",
                                    status: false,
                                },
                            }),
                        );
                    }
                } else {
                    dispatch(setNoShowPolicyVisible(false));
                    dispatch(
                        setSettings({
                            loyalty: {
                                status: false,
                                receive: 0,
                                spend: 0,
                                totalAmount: 0,
                            },
                        }),
                    );
                    dispatch(
                        setSettings({
                            refer: {
                                referee: 0,
                                refferer: 0,
                                referee_type: "value",
                                refferer_type: "value",
                                status: false,
                            },
                        }),
                    );
                    dispatch(
                        setSettings({
                            payment: { isBookWithCard: false },
                        }),
                    );
                }
            } else {
                manageShopSetting();
            }
        }
    }, [bookingInfo.selectedSpecialist, user]);

    // isModal props only true when ShowOrderSummary and noshowpolicy modal
    // for specialist bookingTotal send in manageBookingProcess and for other set using useeffect
    // bookingTotal set -> when data in main then 1 otherwise default 0
    // checkForNavigateFurtherStep use for navigate finalize or product first check there is tempdata for particular step
    // isNoShowPolicyApplicable use before NoShowPolicy show check there is temp data
    // remove service and date time when specialist change
    // bookingShopSettings use for store every setting if rent then store rent barber setting otherwise default shop settings

    useEffect(() => {
        if (bookingInfo.step !== EBookingTabState.SELECT_SERVICE) {
            setIsServiceBtnClick(false);
        }
    }, [bookingInfo.step]);

    useEffect(() => {
        if (validateConditions(bookingInfo.step === EBookingTabState.SELECT_SERVICE, !!bookingInfo.selectedSpecialist, bookingInfo.tempStore.selectedServices.length > 0)) {
            const finalTotal = calculateBookingTotal(bookingInfo.tempStore.selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
            dispatch(setBookingTotal(finalTotal));
        } else if (bookingInfo.tempStore.selectedServices.length === 0 && isServiceBtnClick) {
            dispatch(setBookingTotal(0));
        }
    }, [bookingInfo.tempStore.selectedServices]);

    useEffect(() => {
        if (bookingInfo.selectedServices.length > 0) {
            const finalTotal = calculateBookingTotal(bookingInfo.selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
            dispatch(setBookingTotal(finalTotal));
        } else if (bookingInfo.selectedProducts.length > 0) {
            dispatch(setBookingTotal(0));
        }
    }, [bookingInfo.selectedServices]);

    useEffect(() => {
        const finalTotal = calculateBookingTotal(bookingInfo.selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
        dispatch(setBookingTotal(finalTotal));
    }, [bookingInfo.selectedProducts]);

    const isNoShowPolicyApplicable = () => {
        const baseCondition = bookingInfo.tempStore.SelectionCount > 0 && hasSelectedRequiredFields;
        // Dynamically check for either selectedServices or selectedSpecialist based on the step
        switch (bookingInfo.step) {
            case EBookingTabState.SELECT_SERVICE:
                return baseCondition && bookingInfo.tempStore.selectedServices.length > 0;
            case EBookingTabState.SELECT_SPECIALIST:
                return baseCondition && bookingInfo.tempStore.selectedSpecialist;
            case EBookingTabState.SELECT_DATE_TIME:
                return baseCondition && bookingInfo.tempStore.selectedBookingDate && bookingInfo.tempStore.selectedBookingTime;
            case EBookingTabState.TAB_MENU:
                return bookingInfo.selectedSpecialist && bookingInfo.selectedServices.length > 0 && bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime;
            case EBookingTabState.PRODUCT_BOOKING:
                return true;
            case EBookingTabState.FINALIZE_BOOKING:
                return true;
            default:
                return false;
        }
    };

    const checkForNavigateFurtherStep = (isTempData?: null | number) => {
        switch (bookingInfo.step) {
            case EBookingTabState.SELECT_SERVICE:
                return bookingInfo.tempStore.selectedServices.length > 0;
            case EBookingTabState.SELECT_SPECIALIST:
                return !!isTempData;
            case EBookingTabState.SELECT_DATE_TIME:
                return bookingInfo.tempStore.selectedBookingDate && bookingInfo.tempStore.selectedBookingTime;
            default:
                return false;
        }
    };

    const handlePromotionRemove = () => {
        if (!bookingInfo.isModifyingAppointment) {
            dispatch(setSelectedPromocode(undefined));
        }
    };

    const getButtonLabel = () => {
        if (uiState.isMobile && bookingInfo.isShowOrderSummary) {
            return t("Finish");
        }
        if (isModal) {
            return t("I agree");
        }

        const hasSelectedAllRequiredFields = [
            bookingInfo.selectedSpecialist,
            bookingInfo.selectedBookingDate,
            bookingInfo.selectedBookingTime,
            bookingInfo.selectedServices?.length,
            !(productList.length > 0),
            !(bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) &&
                !(bookingShopSettings.loyalty.totalAmount >= bookingInfo.bookingTotal && bookingShopSettings.loyalty.status),
        ].every(Boolean);

        if (bookingInfo.isModifyingAppointment) {
            if (hasSelectedAllRequiredFields && bookingInfo.isShowNoShowPolicy) {
                return t("Finish");
            } else {
                return t("Edit Appointment");
            }
        }

        return hasSelectedAllRequiredFields && bookingInfo.isShowNoShowPolicy ? t("Finish") : t("Continue");
    };

    const getProductButtonLabel = () => {
        if (uiState.isMobile && bookingInfo.isShowOrderSummary) {
            setproductLable(t("Finish"));
            return;
        }
        if (isModal) {
            setproductLable(t("I agree"));
            return;
        }
        if (
            !(bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) &&
            !(bookingShopSettings.loyalty.totalAmount >= bookingInfo.bookingTotal && bookingShopSettings.loyalty.status)
        ) {
            setproductLable(t("Finish"));
            return;
        } else {
            if (!bookingInfo.selectedProducts.length) {
                setproductLable(t("Skip"));
            } else {
                if (bookingInfo.isModifyingAppointment) {
                    setproductLable(t("Edit Appointment"));
                } else {
                    setproductLable(t("Continue"));
                }
            }
            return;
        }
        // if (bookingInfo.isModifyingAppointment) {
        //     if (
        //         !(bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) &&
        //         !(bookingShopSettings.loyalty.totalAmount >= bookingInfo.bookingTotal && bookingShopSettings.loyalty.status) &&
        //         bookingInfo.isShowNoShowPolicy
        //     ) {
        //         setproductLable(t("Finish"));
        //         return;
        //     } else {
        //         setproductLable(t("Edit Appointment"));
        //         return;
        //     }
        // }
        // if (
        //     !(bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) &&
        //     !(bookingShopSettings.loyalty.totalAmount >= bookingInfo.bookingTotal && bookingShopSettings.loyalty.status) &&
        //     bookingInfo.isShowNoShowPolicy
        // ) {
        //     setproductLable(t("Finish"));
        //     return;
        // }
    };

    const manageBookingProcess = (
        finalTotal?: null | number,
        isTempData?: null | number,
        selectedBookingDate?: null | string,
        selectedBookingTime?: null | string,
        selectedServices?: ISpecialistService[] | null,
    ) => {
        const checkValue = finalTotal
            ? bookingShopSettings.loyalty.totalAmount >= finalTotal && bookingShopSettings.loyalty.status
            : bookingShopSettings.loyalty.totalAmount >= bookingInfo.bookingTotal && bookingShopSettings.loyalty.status;
        if (bookingInfo.isModifyingAppointment && bookingInfo.oldPaymentOption === "pay_now") {
            updateBooking(selectedBookingDate, selectedBookingTime, selectedServices);
        } else if (productList.length > 0) {
            if (checkForNavigateFurtherStep(isTempData)) {
                navigate(`${PATH.APPOINTEMENT}/${BookingTABS.PRODUCT_BOOKING}`);
            }
        } else if (!user) {
            dispatch(setIsShowLoginModal(true));
        } else if ((bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) || checkValue) {
            if (checkForNavigateFurtherStep(isTempData)) {
                navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}`);
            }
        } else if (bookingInfo.isModifyingAppointment) {
            if (!bookingInfo.isShowNoShowPolicy && bookingInfo.isNoShowPolicyVisible) {
                if (bookingInfo.tempStore.SelectionCount === 0 || isNoShowPolicyApplicable() || isTempData) {
                    dispatch(setIsShowNoShowPolicy(true));
                }
            } else {
                updateBooking(selectedBookingDate, selectedBookingTime, selectedServices);
            }
        } else {
            handleCreateBooking(isTempData, selectedBookingDate, selectedBookingTime, selectedServices);
        }
    };

    const handleCreateBooking = async (isTempData?: null | number, selectedBookingDate?: null | string, selectedBookingTime?: null | string, selectedServices?: ISpecialistService[] | null) => {
        if (uiState.isMobile) {
            if (!bookingInfo.isShowNoShowPolicy && bookingInfo.isNoShowPolicyVisible && !bookingInfo.isShowOrderSummary) {
                if (bookingInfo.tempStore.SelectionCount === 0 || isNoShowPolicyApplicable() || isTempData) {
                    dispatch(setIsShowNoShowPolicy(true));
                }
            } else if (bookingInfo.isShowNoShowPolicy || !bookingInfo.isShowOrderSummary) {
                if (bookingInfo.tempStore.SelectionCount === 0 || isNoShowPolicyApplicable() || isTempData) {
                    dispatch(setIsShowOrderSummary(true));
                    dispatch(setIsShowNoShowPolicy(false));
                }
            } else if (bookingInfo.isShowOrderSummary) {
                createBooking(selectedBookingDate, selectedBookingTime, selectedServices);
            }
        } else {
            if (!bookingInfo.isShowNoShowPolicy && bookingInfo.isNoShowPolicyVisible) {
                if (bookingInfo.tempStore.SelectionCount === 0 || isNoShowPolicyApplicable() || isTempData) {
                    dispatch(setIsShowNoShowPolicy(true));
                }
            } else {
                createBooking(selectedBookingDate, selectedBookingTime, selectedServices);
            }
        }
    };

    const handleCardAdd = () => {
        dispatch(setSubmitTriggered(true));
    };

    const updateBooking = (selectedBookingDate?: string | null, selectedBookingTime?: string | null, selectedServices?: ISpecialistService[] | null) => {
        setIsLoading(true);
        let url = API.BOOKING.UPDATE_PAY_NOW;

        const params = {
            id: bookingInfo.modifyingAppointmentId,
            shop_id: bookingInfo.selectedShopLocation?.shop_id,
            location_id: bookingInfo.selectedShopLocation?.id,
        };
        const payload: any = {
            booking_date: selectedBookingDate ?? bookingInfo.selectedBookingDate,
            booking_time: selectedBookingTime ? `${selectedBookingTime}:00` : bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
        };

        if (bookingInfo.oldPaymentOption === "pay_later") {
            url = API.BOOKING.UPDATE_PAY_LATER;

            payload.payment_type = bookingInfo.paymentType;
            payload.payment_option = bookingInfo.paymentOption;

            const products = bookingInfo.selectedProducts.map((product: any) => ({
                id: product.selectedVariant.inventory.id,
                quantity: 1,
            }));
            payload.products = products;

            const services = selectedServices
                ? selectedServices.map((service: any) => ({
                      id: service.id,
                      quantity: service.quantity,
                  }))
                : bookingInfo.selectedServices.map((service: any) => ({
                      id: service.id,
                      quantity: service.quantity,
                  }));
            payload.services = services;
        }

        axiosPatch(url, payload, params)
            .then(async (response) => {
                dispatch(setBookingComplete(true));
                // update booking toast message
                toast.success(<span className="text-toastTextColor font-medium text-[14px] leading-[20px] line-truncate pr-2 py-1 my-1 font-primary">{response.data.message}</span>, {
                    icon: <img src={SuccessIcon} className="h-[38px] w-[38px] min-w-[38px] ml-[2px]" alt="" />,
                });
                if (localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId) || localStorage.getItem(LOCAL_STORAGE_KEYS.expressUserEmail) || localStorage.getItem(LOCAL_STORAGE_KEYS.hasReloaded)) {
                    localStorage.removeItem(LOCAL_STORAGE_KEYS.expressBookingId);
                    localStorage.removeItem(LOCAL_STORAGE_KEYS.expressUserEmail);
                    localStorage.removeItem(LOCAL_STORAGE_KEYS.hasReloaded);
                }
                navigateBasedOnShopLocations();
            })
            .catch((error) => {
                const response = error?.response?.data;
                toast.error(
                    <span className="text-toastTextColor font-medium text-[14px] leading-[20px] line-truncate pr-2 py-1 my-1 font-primary">
                        {response?.message ?? "Something went wrong, Please try again"}
                    </span>,
                    {
                        icon: <img src={CancelationIcon} className="h-[38px] w-[38px] min-w-[38px] ml-[2px]" alt="" />,
                    },
                );
            })
            .finally(() => setIsLoading(false));
    };

    const createBooking = (selectedBookingDate?: string | null, selectedBookingTime?: string | null, selectedServices?: ISpecialistService[] | null) => {
        setIsLoading(true);
        const products = bookingInfo.selectedProducts.map((product: any) => ({
            id: product.selectedVariant.inventory.id,
            quantity: 1,
        }));

        const services = selectedServices
            ? selectedServices.map((service: any) => ({
                  id: service.id,
                  quantity: service.quantity,
              }))
            : bookingInfo.selectedServices.map((service: any) => ({
                  id: service.id,
                  quantity: service.quantity,
              }));

        const params = {
            shop_id: bookingInfo.selectedShopLocation?.shop_id,
            location_id: bookingInfo.selectedShopLocation?.id,
        };

        const payload = {
            staff_id: bookingInfo.selectedSpecialist?.id,
            services,
            products,
            payment_type: bookingInfo.paymentType,
            payment_option: bookingInfo.paymentOption,
            booking_date: selectedBookingDate ?? bookingInfo.selectedBookingDate,
            booking_time: selectedBookingTime ? `${selectedBookingTime}:00` : bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
            shop_promotion_id: bookingInfo.selectedPromocode?.id ?? undefined,
        };
        axiosPost(API.BOOKING.CREATE, payload, params)
            .then(async (response) => {
                dispatch(setBookingComplete(true));
                toast.success(<span className="text-toastTextColor font-medium text-[14px] leading-[20px] line-truncate pr-2 py-1 my-1 font-primary">{response.data.message}</span>, {
                    icon: <img src={SuccessIcon} className="h-[38px] w-[38px] min-w-[38px] ml-[2px]" alt="" />,
                });
                navigateBasedOnShopLocations();
            })
            .catch((error) => {
                const response = error?.response?.data;
                toast.error(
                    <span className="text-toastTextColor font-medium text-[14px] leading-[20px] line-truncate pr-2 py-1 my-1 font-primary">
                        {response?.message ?? "Something went wrong, Please try again"}
                    </span>,
                    {
                        icon: <img src={CancelationIcon} className="h-[38px] w-[38px] min-w-[38px] ml-[2px]" alt="" />,
                    },
                );
            })
            .finally(() => setIsLoading(false));
    };

    const handleBack = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.TAB_MENU:
                handlePromotionRemove();
                navigateBasedOnShopLocations();
                break;
            case EBookingTabState.SELECT_SERVICE:
                handlePromotionRemove();
                dispatch(setTempStore({ selectedServices: [] }));
                dispatch(setStep(EBookingTabState.TAB_MENU));
                navigate(`${PATH.APPOINTEMENT}`);
                break;
            case EBookingTabState.SELECT_DATE_TIME:
                handlePromotionRemove();
                dispatch(
                    setTempStore({
                        selectedBookingDate: undefined,
                        selectedBookingTime: undefined,
                        selectedBookingDateTime: undefined,
                    }),
                );
                dispatch(setStep(EBookingTabState.TAB_MENU));
                navigate(`${PATH.APPOINTEMENT}`);
                break;
            case EBookingTabState.SELECT_SPECIALIST:
                handlePromotionRemove();
                if (bookingInfo.selectedSpecialist) {
                    if (bookingInfo.selectedSpecialist.shop_staff_settings && bookingInfo.selectedSpecialist.shop_staff_settings.length) {
                        const staffSettings = bookingInfo.selectedSpecialist.shop_staff_settings;
                        const calendarSettings = staffSettings?.find((setting: any) => setting.type === "calendar");
                        if (calendarSettings) {
                            dispatch(
                                setSettings({
                                    calendar: {
                                        advance_weeks: calendarSettings.value.advance_weeks,
                                    },
                                }),
                            );
                        } else {
                            dispatch(
                                setSettings({
                                    calendar: { advance_weeks: 0 },
                                }),
                            );
                        }
                    } else {
                        dispatch(
                            setSettings({
                                calendar: { advance_weeks: 0 },
                            }),
                        );
                    }
                } else {
                    const calendarSettings = currentSettingsList?.find((setting: any) => setting.type === "calendar");
                    if (calendarSettings) {
                        dispatch(
                            setSettings({
                                calendar: {
                                    advance_weeks: calendarSettings.value.advance_weeks,
                                },
                            }),
                        );
                    } else {
                        dispatch(
                            setSettings({
                                calendar: { advance_weeks: 0 },
                            }),
                        );
                    }
                }
                dispatch(setTempStore({ selectedSpecialist: undefined }));
                dispatch(setStep(EBookingTabState.TAB_MENU));
                navigate(`${PATH.APPOINTEMENT}`);
                break;
            case EBookingTabState.PRODUCT_BOOKING:
                handlePromotionRemove();
                navigate(PATH.APPOINTEMENT);
                break;
            case EBookingTabState.FINALIZE_BOOKING:
                handlePromotionRemove();
                if (bookingInfo.isShowOrderSummary) {
                    dispatch(setIsShowOrderSummary(false));
                } else {
                    let navigateStep = BookingTABS.TAB_MENU;
                    if (productList.length > 0) {
                        navigateStep = BookingTABS.PRODUCT_BOOKING;
                    }
                    navigate(`${PATH.APPOINTEMENT}/${navigateStep}`);
                }
                break;
            default:
                break;
        }
    };

    const handleChange = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.SELECT_DATE_TIME:
                if (isModal && uiState.isMobile && !bookingInfo.isShowOrderSummary) {
                    if (hasSelectedRequiredFields) {
                        syncTempStoreOnClose(bookingInfo);
                    }
                } else if (!bookingInfo.isShowOrderSummary) {
                    dispatch(setSelectedBookingDate(bookingInfo.tempStore?.selectedBookingDate));
                    dispatch(setSelectedBookingTime(bookingInfo.tempStore?.selectedBookingTime));
                    dispatch(
                        setPreview({
                            key: "selectedBookingDateTime",
                            value: bookingInfo.tempStore?.selectedBookingDateTime,
                        }),
                    );
                    if (user) {
                        dispatch(
                            setTempStore({
                                selectedBookingDate: undefined,
                                selectedBookingTime: undefined,
                                selectedBookingDateTime: undefined,
                            }),
                        );
                    }
                }
                if (!bookingInfo.selectedSpecialist) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SPECIALIST}`);
                } else if (!bookingInfo.selectedServices.length) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                } else {
                    manageBookingProcess(null, null, bookingInfo.tempStore?.selectedBookingDate, bookingInfo.tempStore?.selectedBookingTime);
                }
                break;
            case EBookingTabState.SELECT_SERVICE:
                setIsServiceBtnClick(true);
                if (isModal && uiState.isMobile && !bookingInfo.isShowOrderSummary) {
                    if (hasSelectedRequiredFields) {
                        syncTempStoreOnClose(bookingInfo);
                    }
                } else if (!bookingInfo.isShowOrderSummary) {
                    dispatch(setSelectedServices(bookingInfo.tempStore?.selectedServices));
                    dispatch(
                        setPreview({
                            key: "selectedServices",
                            value: bookingInfo.tempStore?.selectedServices,
                        }),
                    );
                    if (user) {
                        dispatch(setTempStore({ selectedServices: [] }));
                    }
                }
                if (!bookingInfo.selectedSpecialist) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SPECIALIST}`);
                } else if (!(bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime)) {
                    navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_DATE_TIME}`);
                } else {
                    manageBookingProcess(null, null, null, null, bookingInfo.tempStore?.selectedServices);
                }
                break;
            case EBookingTabState.SELECT_SPECIALIST:
                if (isModal && uiState.isMobile) {
                    // manage for mobile when i agree modal open sync temp data to main on i agree click
                    if (hasSelectedRequiredFields && !bookingInfo.isShowOrderSummary) {
                        syncTempStoreOnClose(bookingInfo);
                    }
                    // no need to manage update booking flow because we dont change specialist booking update time
                    if (bookingInfo.isShowNoShowPolicy) {
                        dispatch(setIsShowOrderSummary(true));
                        dispatch(setIsShowNoShowPolicy(false));
                    } else if (bookingInfo.isShowOrderSummary) {
                        createBooking();
                    }
                } else if (!bookingInfo.isShowOrderSummary) {
                    dispatch(setSelectedSpecialist(bookingInfo.tempStore?.selectedSpecialist));
                    if (bookingInfo.tempStore?.selectedSpecialist) {
                        dispatch(setNextAvailability(bookingInfo.tempStore?.selectedSpecialist.available_at));
                    }
                    dispatch(
                        setPreview({
                            key: "selectedSpecialist",
                            value: bookingInfo.tempStore?.selectedSpecialist,
                        }),
                    );
                    dispatch(setTempStore({ selectedSpecialist: undefined }));
                    if (bookingInfo.isShowNoShowPolicy) {
                        createBooking();
                    } else {
                        if (bookingInfo.selectedServices.length) {
                            setIsLoading(true);
                            const payload = {
                                staff_id: bookingInfo.tempStore?.selectedSpecialist?.id,
                                booking_date: bookingInfo.selectedBookingDate,
                                booking_time: bookingInfo.selectedBookingTime ? `${bookingInfo.selectedBookingTime}:00` : undefined,
                            };
                            const params = {
                                shop_id: bookingInfo.selectedShopLocation?.shop_id,
                                location_id: bookingInfo.selectedShopLocation?.id,
                            };
                            axiosPost(API.SERVICE.LIST, payload, params)
                                .then(async (response) => {
                                    const services = response.data.data;
                                    const selectedServices: ISpecialistService[] = [];
                                    bookingInfo.selectedServices.forEach((selectedService: ISpecialistService) => {
                                        const service = services.find((item: ISpecialistService) => item.id === selectedService.id);
                                        if (service) {
                                            selectedServices.push({
                                                ...selectedService,
                                                price: service.price,
                                            });
                                        }
                                    });
                                    const filteredServicesBookingTotal = await calculateBookingTotal(selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
                                    const selectedServicesBookingTotal = await calculateBookingTotal(
                                        bookingInfo.selectedServices,
                                        bookingInfo.selectedProducts,
                                        bookingInfo.selectedShopLocation?.sales_tax,
                                    );
                                    // manage if old service total and filtered service total is same then specialist not change and work as it is but if change
                                    // then clear service and time from main data so user want to reselect that
                                    if (!bookingInfo.tempStore?.selectedSpecialist) {
                                        navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SPECIALIST}`);
                                    } else if (filteredServicesBookingTotal !== selectedServicesBookingTotal && bookingInfo.selectedSpecialist) {
                                        dispatch(setSelectedServices([]));
                                        dispatch(
                                            setPreview({
                                                key: "selectedServices",
                                                value: [],
                                            }),
                                        );
                                        dispatch(setSelectedBookingDate(undefined));
                                        dispatch(setSelectedBookingTime(undefined));
                                        dispatch(
                                            setPreview({
                                                key: "selectedBookingDateTime",
                                                value: undefined,
                                            }),
                                        );
                                        navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                                    } else {
                                        dispatch(setSelectedServices(selectedServices));
                                        const finalTotal = await calculateBookingTotal(selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
                                        dispatch(setBookingTotal(finalTotal));
                                        if (!(bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime)) {
                                            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_DATE_TIME}`);
                                        } else {
                                            dispatch(
                                                setTempStore({
                                                    SelectionCount: bookingInfo.selectedSpecialist ? 1 : 0,
                                                }),
                                            );
                                            manageBookingProcess(finalTotal, bookingInfo.tempStore.selectedSpecialist ? 1 : 0);
                                        }
                                        dispatch(
                                            setPreview({
                                                key: "selectedServices",
                                                value: selectedServices,
                                            }),
                                        );
                                    }
                                    // dispatch(setSelectedServices(selectedServices));
                                    // const finalTotal = await calculateBookingTotal(selectedServices, bookingInfo.selectedProducts, bookingInfo.selectedShopLocation?.sales_tax);
                                    // dispatch(setBookingTotal(finalTotal));
                                    // if (!(selectedServices.length > 0)) {
                                    //     navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                                    // } else if (!(bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime)) {
                                    //     navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_DATE_TIME}`);
                                    // } else {
                                    //     dispatch(setTempStore({ SelectionCount: bookingInfo.selectedSpecialist ? 1 : 0 }));
                                    //     manageBookingProcess(finalTotal, bookingInfo.tempStore.selectedSpecialist ? 1 : 0);
                                    // }
                                    // dispatch(setPreview({ key: "selectedServices", value: selectedServices }));
                                })
                                .catch((error) =>
                                    toast.error(
                                        <span className="text-toastTextColor font-medium text-[14px] leading-[20px] line-truncate py-2 pr-2 font-primary w-full">
                                            {error?.message ?? "Something went wrong, Please try again"}
                                        </span>,
                                        {
                                            icon: <img src={CancelationIcon} className="h-[38px] w-[38px] min-w-[38px] ml-[2px]" alt="" />,
                                        },
                                    ),
                                )
                                .finally(() => setIsLoading(false));
                        } else if (!bookingInfo.selectedServices.length && bookingInfo.tempStore.selectedSpecialist) {
                            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.SELECT_SERVICE}`);
                        }
                    }
                }
                break;
            default:
                break;
        }
    };

    const handleContinue = (step: EBookingTabState) => () => {
        switch (step) {
            case EBookingTabState.TAB_MENU:
                if (bookingInfo.isModifyingAppointment && bookingInfo.oldPaymentOption === "pay_now") {
                    updateBooking();
                } else {
                    if (productList.length > 0) {
                        navigate(`${PATH.APPOINTEMENT}/${BookingTABS.PRODUCT_BOOKING}`);
                    } else if (!user) {
                        dispatch(setIsShowLoginModal(true));
                        return;
                    } else {
                        if (
                            (bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) ||
                            (bookingShopSettings.loyalty.totalAmount >= bookingInfo.bookingTotal && bookingShopSettings.loyalty.status)
                        ) {
                            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}`);
                        } else {
                            if (bookingInfo.isModifyingAppointment) {
                                if (!bookingInfo.isShowNoShowPolicy && bookingInfo.isNoShowPolicyVisible) {
                                    dispatch(setIsShowNoShowPolicy(true));
                                } else {
                                    updateBooking();
                                }
                            } else {
                                handleCreateBooking();
                            }
                        }
                    }
                }
                break;
            case EBookingTabState.PRODUCT_BOOKING:
                if (!user) {
                    dispatch(setIsShowLoginModal(true));
                    return;
                } else {
                    if (
                        (bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) ||
                        (bookingShopSettings.loyalty.totalAmount >= bookingInfo.bookingTotal && bookingShopSettings.loyalty.status)
                    ) {
                        navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}`);
                    } else {
                        if (bookingInfo.isModifyingAppointment) {
                            if (!bookingInfo.isShowNoShowPolicy && bookingInfo.isNoShowPolicyVisible) {
                                dispatch(setIsShowNoShowPolicy(true));
                            } else {
                                updateBooking();
                            }
                        } else {
                            handleCreateBooking();
                        }
                    }
                }
                break;
            case EBookingTabState.FINALIZE_BOOKING:
                if (!bookingInfo.paymentType) {
                    dispatch(setIsValidPaymentType(true));
                } else if (bookingInfo.isModifyingAppointment) {
                    if (!bookingInfo.isShowNoShowPolicy && bookingInfo.isNoShowPolicyVisible) {
                        dispatch(setIsShowNoShowPolicy(true));
                    } else {
                        updateBooking();
                    }
                } else {
                    handleCreateBooking();
                }
                break;
        }
    };

    // eslint-disable-next-line complexity
    const renderStepComponent = () => {
        const isTabMenuDisabled = !(
            bookingInfo.selectedShopLocation &&
            bookingInfo.selectedBookingDate &&
            bookingInfo.selectedBookingTime &&
            bookingInfo.selectedServices.length &&
            bookingInfo.selectedSpecialist
        );

        const isSpecialistDisabled =
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.tempStore?.selectedSpecialist) &&
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.selectedSpecialist);

        const isServiceDisabled =
            !validateConditions(!!bookingInfo.selectedShopLocation, bookingInfo.tempStore?.selectedServices?.length > 0) &&
            !validateConditions(!!bookingInfo.selectedShopLocation, bookingInfo.selectedServices?.length > 0);

        const isDateDisabled =
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.tempStore?.selectedBookingDate, !!bookingInfo.tempStore?.selectedBookingTime) &&
            !validateConditions(!!bookingInfo.selectedShopLocation, !!bookingInfo.selectedBookingDate, !!bookingInfo.selectedBookingTime);

        const isProductDisabled = !validateConditions(
            !!bookingInfo.selectedShopLocation,
            !!bookingInfo.selectedBookingDate,
            !!bookingInfo.selectedBookingTime,
            bookingInfo.selectedServices.length > 0,
            !!bookingInfo.selectedSpecialist,
        );

        switch (bookingInfo.step) {
            case EBookingTabState.TAB_MENU:
                return (
                    <div className="absolute max-lg:bottom-0 gap-2 mt-auto max-lg:w-[calc(100%+32px)] lg:w-full lg:relative lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-containerBgColor max-lg:mx-[-16px] max-lg:px-8 max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_var(--header-shadow)]">
                        {/*  mx-auto items-center  justify-center mt-2.5  lg:mb-0`} */}
                        {!isModal && (
                            <CustomButton secondary onClick={handleBack(EBookingTabState.TAB_MENU)} disabled={isLoading} className="md:hover:border-primary  px-5 h-[36px] !w-full lg:mb-0">
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            primary
                            disabled={isTabMenuDisabled || isLoading}
                            isLoading={isLoading}
                            className={`${bookingInfo.isModifyingAppointment ? "w-full !mx-0" : "w-full"}  mx-auto items-center  justify-center lg:mb-0`}
                            onClick={handleContinue(EBookingTabState.TAB_MENU)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );
            case EBookingTabState.SELECT_DATE_TIME:
                return (
                    <div
                        className={`absolute max-lg:bottom-0 gap-2 mt-auto max-lg:w-[calc(100%+32px)] lg:w-full lg:relative lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-containerBgColor max-lg:mx-[-16px] max-lg:px-8 max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_var(--header-shadow)] ${
                            isModal ? (bookingInfo.isShowOrderSummary ? "max-lg:!w-[calc(100%)] max-lg:!mx-[0px] max-lg:!bottom-[0] max-lg:!px-4" : "max-lg:!px-4 max-lg:bottom-[16px]") : ""
                        }`}
                    >
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.SELECT_DATE_TIME)}
                                disabled={isLoading}
                                className="border md:hover:border-primary px-5 lg:mt-2.5 h-[36px] w-full lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className={`${bookingInfo.isModifyingAppointment ? "w-full !mx-0" : "w-full"} mx-auto items-center justify-center lg:mt-2.5 lg:mb-0`}
                            primary
                            onClick={handleChange(EBookingTabState.SELECT_DATE_TIME)}
                            isLoading={isLoading}
                            disabled={isLoading || (bookingInfo.isShowNoShowPolicy || bookingInfo.isShowOrderSummary ? false : isDateDisabled)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );
            case EBookingTabState.SELECT_SERVICE:
                return (
                    <div
                        className={`absolute max-lg:bottom-[0px] gap-2 mt-auto max-lg:w-[calc(100%+32px)] lg:w-full lg:relative lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-contentBackground max-lg:mx-[-16px] max-lg:px-8 max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_var(--header-shadow)] ${
                            isModal ? (bookingInfo.isShowOrderSummary ? "max-lg:!w-[calc(100%)] max-lg:!mx-[0px] max-lg:!bottom-[0] max-lg:!px-4" : "max-lg:!px-4 max-lg:bottom-[16px]") : ""
                        }`}
                    >
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.SELECT_SERVICE)}
                                disabled={isLoading}
                                className=" md:hover:border-primary md:hover:text-primary px-5 lg:mt-2.5 h-[36px] w-full  lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className={`${bookingInfo.isModifyingAppointment ? "w-full " : "w-full"}   mx-auto items-center justify-center lg:mt-2.5 lg:mb-0`}
                            primary
                            isLoading={isLoading}
                            disabled={isLoading || (bookingInfo.isShowNoShowPolicy || bookingInfo.isShowOrderSummary ? false : isServiceDisabled)}
                            onClick={handleChange(EBookingTabState.SELECT_SERVICE)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );
            case EBookingTabState.SELECT_SPECIALIST:
                return (
                    <div
                        className={`absolute max-lg:bottom-0 gap-2 mt-auto max-lg:w-[calc(100%+32px)] lg:w-full lg:relative lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-containerBgColor max-lg:mx-[-16px] max-lg:px-8 max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_var(--header-shadow)] ${
                            isModal ? (bookingInfo.isShowOrderSummary ? "max-lg:!w-[calc(100%)] max-lg:!mx-[0px] max-lg:!bottom-[0] max-lg:!px-4" : "max-lg:!px-4 max-lg:bottom-[16px]") : ""
                        }`}
                    >
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.SELECT_SPECIALIST)}
                                disabled={isLoading}
                                className="md:hover:border-primary md:hover:text-primary px-5 lg:mt-2.5 h-[36px] w-full lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className="w-full lg:relative mx-auto  items-center justify-center lg:mt-2.5  lg:mb-0"
                            primary
                            onClick={handleChange(EBookingTabState.SELECT_SPECIALIST)}
                            isLoading={isLoading}
                            disabled={isLoading || (bookingInfo.isShowNoShowPolicy || bookingInfo.isShowOrderSummary ? false : isSpecialistDisabled)}
                        >
                            {getButtonLabel()}
                        </CustomButton>
                    </div>
                );

            case EBookingTabState.PRODUCT_BOOKING:
                return (
                    <div
                        className={`absolute max-lg:bottom-0 gap-2 mt-auto max-lg:w-[calc(100%)] lg:w-full lg:relative lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-containerBgColor  max-lg:px-4 max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_var(--header-shadow)] ${
                            isModal && !bookingInfo.isShowOrderSummary ? "max-lg:!bottom-[16px] max-lg:w-[calc(100%+32px)] max-lg:mx-[-16px] max-lg:px-[16px]" : ""
                        } `}
                    >
                        {!isModal && (
                            <CustomButton
                                secondary
                                onClick={handleBack(EBookingTabState.PRODUCT_BOOKING)}
                                disabled={isLoading}
                                className=" md:hover:border-primary  px-5 lg:mt-2.5 h-[36px] w-full  lg:mb-0"
                            >
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton
                            className={`${bookingInfo.isModifyingAppointment ? "w-full !mx-0" : "w-full "}  mx-auto items-center  justify-center lg:mt-2.5  lg:mb-0`}
                            primary
                            disabled={isProductDisabled || isLoading}
                            isLoading={isLoading}
                            onClick={handleContinue(EBookingTabState.PRODUCT_BOOKING)}
                        >
                            {productLable}
                        </CustomButton>
                    </div>
                );

            case EBookingTabState.FINALIZE_BOOKING:
                if (bookingInfo.selectedShopLocation && bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime && bookingInfo.selectedServices.length && bookingInfo.selectedSpecialist) {
                    return (
                        <div
                            className={`absolute max-lg:bottom-[36px] gap-2 mt-auto max-lg:w-[calc(100%)] max-lg:mx-[-16px] max-lg:px-8  lg:w-full  lg:relative -tracking-[0.01rem] lg:bg-transparent flex lg:gap-3 lg:items-center lg:!mx-auto bg-containerBgColor max-lg:pt-3 max-lg:shadow-[0px_-4px_4px_0px_var(--header-shadow)] ${
                                !isModal
                                    ? "max-lg:w-[calc(100%)] max-lg:!bottom-[0px] max-lg:mx-[0px] max-lg:px-[16px]"
                                    : bookingInfo.isShowOrderSummary
                                    ? "max-lg:w-[calc(100%)] max-lg:mx-[0px] max-lg:!bottom-[0] max-lg:!px-4"
                                    : "max-lg:w-[calc(100%+32px)] max-lg:!bottom-[16px] max-lg:!px-4"
                            }  ${bookingInfo.isShowNoShowPolicy ? "max-lg:!shadow-none" : ""}`}
                        >
                            {!isModal && (
                                <CustomButton
                                    secondary
                                    onClick={handleBack(EBookingTabState.FINALIZE_BOOKING)}
                                    disabled={isLoading || bookingInfo.isCardLoading}
                                    className=" md:hover:border-primary  px-5 lg:mt-2.5 h-[36px] w-full  lg:mb-0"
                                >
                                    {t("Back")}
                                </CustomButton>
                            )}
                            {bookingInfo.isFinalizeLoading ? (
                                <CustomButton
                                    className={`w-full mx-auto items-center justify-center lg:mt-2.5 lg:mb-0 ${bookingInfo.isShowNoShowPolicy ? "lg:!h-[48px]" : ""}`}
                                    primary
                                    disabled={true}
                                    isLoading={bookingInfo.isFinalizeLoading}
                                >
                                    {t("Finish")}
                                </CustomButton>
                            ) : bookingInfo.paymentType === "loyalty" || (bookingInfo.isCardAdd && bookingInfo.paymentType === "stripe") ? (
                                <CustomButton
                                    className={`w-full mx-auto items-center justify-center lg:mt-2.5 lg:mb-0 ${bookingInfo.isShowNoShowPolicy ? "" : ""}`}
                                    primary
                                    onClick={handleContinue(EBookingTabState.FINALIZE_BOOKING)}
                                    disabled={isLoading || bookingInfo.isCardFormVisible}
                                    isLoading={isLoading}
                                >
                                    {uiState.isMobile && bookingInfo.isShowOrderSummary ? t("Finish") : isModal ? t("I agree") : t("Finish")}
                                </CustomButton>
                            ) : (
                                <CustomButton
                                    className={`w-full mx-auto items-center justify-center lg:mt-2.5 lg:mb-0 ${bookingInfo.isShowNoShowPolicy ? "lg:!h-[48px]" : ""}`}
                                    primary
                                    onClick={handleCardAdd}
                                    disabled={bookingInfo.isCardLoading}
                                    isLoading={bookingInfo.isCardLoading}
                                >
                                    {t("Continue")}
                                </CustomButton>
                            )}
                        </div>
                    );
                } else {
                    return <></>;
                }

            default:
                return <></>;
        }
    };

    return renderStepComponent();
};

export default ManageSubmit;
