import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import NotGuardedRoute from "./NotGuardedRoute";
import { LOCAL_STORAGE_KEYS, errorCode } from "../constants/common";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setCurrentBreakpoint, setIsMobile, setIsTablet } from "../redux/reducers/Ui.slice";
import { updateMetaThemeColor } from "../utils/global-functions";
import AppLayout from "../app/AppLayout";
import { getShop, me } from "src/redux/reducers/common/Common.slice";
import { ROUTES } from "src/constants/routes";
import PageNotFound from "src/app/PageNotFound";
import Landing from "src/app/Landing";
import Location from "src/app/Location";
import MobileLanding from "src/app/MobileLanding";
import BookAppointment from "src/app/BookAppointment";
import Loader from "src/components/Loader/Loader";
import { setIsFullScreen } from "src/app/BookAppointment/Booking.slice";
// import Env from "src/app/Env";

const MainRoutes = (): JSX.Element => {
    // const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const uiState = useAppSelector((data) => data.UiStates);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const updateMobileState = () => {
        const isMobileState = window.innerWidth <= 1023;
        const isTabletState = window.innerWidth >= 768;
        if (window.innerWidth > 280 && window.innerWidth < 640) {
            dispatch(setCurrentBreakpoint("XS"));
        } else if (window.innerWidth >= 640 && window.innerWidth < 767) {
            dispatch(setCurrentBreakpoint("SM"));
        } else if (window.innerWidth >= 767 && window.innerWidth < 1024) {
            dispatch(setCurrentBreakpoint("MD"));
        } else if (window.innerWidth >= 1024 && window.innerWidth < 1279) {
            dispatch(setCurrentBreakpoint("LG"));
        } else if (window.innerWidth > 1279 && window.innerWidth < 1440) {
            dispatch(setCurrentBreakpoint("XL"));
        } else if (window.innerWidth >= 1440 && window.innerWidth < 1620) {
            dispatch(setCurrentBreakpoint("2XL"));
        } else if (window.innerWidth >= 1620 && window.innerWidth < 1760) {
            dispatch(setCurrentBreakpoint("3XL"));
        } else {
            dispatch(setCurrentBreakpoint("XXL"));
        }

        dispatch(setIsTablet(isTabletState));
        // if (isMobileState === uiState.isMobile) {
        //     return;
        // }
        dispatch(setIsMobile(isMobileState));
        // isMobile global state logic
        // if (!uiState.isMobilePageVisitedOnce) {
        //     navigate(ROUTES.MOBILE_LANDING);
        // }
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            requestAnimationFrame(updateMobileState);
        });

        resizeObserver.observe(document.documentElement);

        return () => {
            resizeObserver.disconnect();
        };
    }, [uiState.isMobile, uiState.isMobilePageVisitedOnce, uiState.currentBreakpoint]);

    useEffect(() => {
        const fetchInitialData = async () => {
            updateMetaThemeColor("white");
            const result: any = await dispatch(getShop());
            if (result.type === getShop.fulfilled.toString()) {
                setLoading(false);
            }
            if (result.type === getShop.rejected.toString()) {
                setLoading(false);
                if (result?.payload?.status === errorCode.badRequest) {
                    navigate(ROUTES.PAGE_NOT_FOUND);
                }
            }
        };
        fetchInitialData();
    }, []);
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.authToken);

    useEffect(() => {
        const fetchData = async () => {
            await dispatch(me());
        };
        token && fetchData();
    }, []);

    useEffect(() => {
        if (window.navigator.standalone) {
            dispatch(setIsFullScreen(true));
        } else {
            dispatch(setIsFullScreen(false));
        }
    }, []);

    return loading ? (
        <div className="h-screen w-full flex justify-center items-center">
            <Loader />
        </div>
    ) : (
        <Routes>
            <Route element={<AppLayout />}>
                <Route path={ROUTES.HOME} element={<NotGuardedRoute Component={Landing} title="Home" />} />
                <Route path={ROUTES.LOCATION} element={<NotGuardedRoute Component={Location} title="Location" />} />
                <Route path={ROUTES.MOBILE_LANDING} element={<NotGuardedRoute Component={MobileLanding} title="Mobile" />} />
                <Route path={ROUTES.BOOK_APPOINTMENT} element={<NotGuardedRoute Component={BookAppointment} title="Book Appointment" />} />
            </Route>
            {/* <Route path={ROUTES.ENV} element={<Env />} /> */}
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/page-not-found" />} />
        </Routes>
    );
};

export default MainRoutes;
