import React, { FC, useState } from "react";
import { useForm, Controller, Resolver } from "react-hook-form";
import { IOtp, IProfileVerifyProps } from "../Auth.interface";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { errorCode, LOCAL_STORAGE_KEYS } from "src/constants/common";
import { userLogin } from "src/redux/reducers/Login.slice";
import { useAppDispatch } from "src/redux/hooks";
import OTPField from "src/components/OTPField/OTPField";
import CustomButton from "src/components/CustomButton";
import { axiosPatch } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { me } from "src/redux/reducers/common/Common.slice";
import { usePhone } from "src/hooks/usePhone";
import { PATH } from "src/constants/path";
import useModifyAppointment from "src/hooks/useModifyAppointment";
import { BookingTABS } from "src/app/BookAppointment/bookAppointment.interface";
import { useNavigate } from "react-router-dom";

const VerifyOtp: FC<IProfileVerifyProps> = ({ authData, handleAction, setAuthData, handleClose }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { getCustomFormatPhone } = usePhone();
    const [loading, setLoading] = useState(false);
    const { getBookingData } = useModifyAppointment();
    const navigate = useNavigate();

    const schema = Yup.object({
        otp: Yup.string().length(4, t("OTP must be exactly 4 number")).required(t("This field is a required")),
    }).required();

    const { control, handleSubmit, setError, reset, watch, clearErrors } = useForm<IOtp>({
        resolver: yupResolver(schema) as Resolver<IOtp>,
        defaultValues: {
            otp: "",
        },
        mode: "onSubmit",
        reValidateMode: "onSubmit",
    });

    const otpWatch = watch("otp");

    const handleSave = (data: any) => {
        setLoading(true);
        const payload = {
            phone: authData.phone,
            phone_country_code: authData.phone_country_code,
            otp: data.otp,
        };
        axiosPatch(API.USER.SOCIAL_ACCOUNT_VERIFY, payload)
            .then(async () => {
                await dispatch(me());
                if (localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId)) {
                    const resultData = await getBookingData(Number(localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId)));
                    if (resultData) {
                        const storedId = localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId);
                        handleClose();
                        setTimeout(() => {
                            navigate(`${PATH.APPOINTEMENT}/${BookingTABS.FINALIZE_BOOKING}/${storedId}`);
                        }, 500);
                        if (
                            localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId) ||
                            localStorage.getItem(LOCAL_STORAGE_KEYS.expressUserEmail) ||
                            localStorage.getItem(LOCAL_STORAGE_KEYS.hasReloaded)
                        ) {
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.expressBookingId);
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.expressUserEmail);
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.hasReloaded);
                        }
                    } else {
                        handleClose();
                        if (
                            localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId) ||
                            localStorage.getItem(LOCAL_STORAGE_KEYS.expressUserEmail) ||
                            localStorage.getItem(LOCAL_STORAGE_KEYS.hasReloaded)
                        ) {
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.expressBookingId);
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.expressUserEmail);
                            localStorage.removeItem(LOCAL_STORAGE_KEYS.hasReloaded);
                        }
                    }
                } else {
                    handleClose();
                    if (
                        localStorage.getItem(LOCAL_STORAGE_KEYS.expressBookingId) ||
                        localStorage.getItem(LOCAL_STORAGE_KEYS.expressUserEmail) ||
                        localStorage.getItem(LOCAL_STORAGE_KEYS.hasReloaded)
                    ) {
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.expressBookingId);
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.expressUserEmail);
                        localStorage.removeItem(LOCAL_STORAGE_KEYS.hasReloaded);
                    }
                }
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    Object.keys(response.data).forEach((field) => {
                        setError("otp", {
                            type: "manual",
                            message: response.data[field][0],
                        });
                    });
                    return;
                }
            })
            .finally(() => setLoading(false));
    };

    const handleResend = async () => {
        setLoading(true);
        reset();
        const payload = {
            phone: authData.phone,
            phone_country_code: authData.phone_country_code,
        };
        await dispatch(userLogin(payload));
        setLoading(false);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!e.repeat) {
            if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit(handleSave)();
            }
        }
    };

    return (
        <div className="font-primary model-inner-wrapper" onKeyDown={handleKeyPress}>
            <h2 className="login-model-title">{t("Enter the verification code")}</h2>
            <div className="flex flex-col">
                <span className="login-model-subtitle">{t("We’ve sent a 4-digit code to your phone number")}</span>
                <span className="login-model-subtitle-dec">{getCustomFormatPhone(authData.phone, authData.phone_country_code)}</span>
            </div>

            <div className="mx-auto mt-[30px]">
                <form onSubmit={handleSubmit(handleSave)}>
                    <Controller
                        name="otp"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col mt-8">
                                <OTPField
                                    otp={value}
                                    setOtp={(newOtp: any) => {
                                        if (error) {
                                            clearErrors("otp");
                                        }
                                        onChange(newOtp);
                                    }}
                                    errors={error}
                                />
                                {/* {error && <p className="text-error text-center">{error.message}</p>} */}
                            </div>
                        )}
                    />
                    <div className="mt-[24px] flex flex-col items-center">
                        <div className="flex items-center justify-center gap-1">
                            <p className="text-textGrey text-sm font-normal -tracking-[0.02rem] leading-[19.6px]">{t("Didn’t receive the code?")}</p>
                            <CustomButton
                                type="button"
                                disabled={loading}
                                onClick={handleResend}
                                className="text-primary !border-0 !p-0 !h-auto leading-[19.6px] !font-semibold !bg-transparent text-sm text-center flex flex-col ring-0"
                                shadow={false}
                            >
                                {t("Resend")}
                            </CustomButton>
                        </div>
                        {authData.phone && (
                            <CustomButton
                                type="button"
                                className="!border-0 !bg-transparent text-center ring-0 leading-[19.6px] mt-[24px] !p-0 text-sm  text-primary font-bold"
                                shadow={false}
                                onClick={handleAction("socialProfile")}
                            >
                                {t("Changed your phone number?")}
                            </CustomButton>
                        )}
                    </div>
                    <div className="mt-[52px] w-full bottom-0 flex flex-col">
                        <CustomButton type="submit" className="w-full !h-[48px]" primary disabled={loading || (otpWatch && otpWatch.length !== 4 ? true : false)} isLoading={loading}>
                            <span> {t("Continue")}</span>
                        </CustomButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default VerifyOtp;
