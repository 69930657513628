import React, { useEffect, useState } from "react";
import { addDays, addWeeks, format, isAfter, isBefore, isToday, subDays, subWeeks } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import moment from "moment";
import { ArrowLeft, ArrowRight, ChevronLeft, ChevronRight, Calendar } from "@untitled-ui/icons-react/build/cjs";
import DateSelectButton from "src/components/DateSelectButton";
import { setSelectedBookingDate, setTempStore } from "src/app/BookAppointment/Booking.slice";

type Props = {
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
    workingDays: string[];
    maxDate: Date | null;
};

const RangeCalnedarComponent: React.FC<Props> = ({ selectedDate, setSelectedDate, workingDays, maxDate }) => {
    const uiState = useAppSelector((data) => data.UiStates);
    const dispatch = useAppDispatch();
    const [dateRangeArray, setDateRangeArray] = useState<any>([]);
    const [formattedDate, setFormattedDate] = useState("");
    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
    const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (dateRangeArray.length) {
            const firstDate = dateRangeArray[0];
            const lastDate = dateRangeArray[dateRangeArray.length - 1];

            if (maxDate) {
                const isNextDisabledBtn = maxDate && isAfter(lastDate, maxDate);
                setIsNextDisabled(isNextDisabledBtn);
            }

            const isPrevDisabledBtn = isToday(firstDate);
            setIsPrevDisabled(isPrevDisabledBtn);
        }
    }, [dateRangeArray, maxDate]);

    const updateDateRange = (date: Date) => {
        const totalDates = uiState.isMobile ? 5 : 7;
        const newDates = Array.from({ length: totalDates }, (_, index) => addDays(date, index));
        setDateRangeArray(newDates);
    };

    useEffect(() => {
        const date = new Date(selectedDate);
        const formatted = moment(date).format("MMMM YYYY");
        setFormattedDate(formatted);
        updateDateRange(selectedDate);
    }, [selectedDate, uiState.currentBreakpoint]);

    const handlePrevClick = () => {
        if (!isPrevDisabled) {
            const newDate = uiState.isMobile ? subDays(selectedDate, 4) : subWeeks(selectedDate, 1);
            setSelectedDate(newDate);
        }
    };

    const handleNextClick = () => {
        if (!isNextDisabled) {
            const newDate = uiState.isMobile ? addDays(selectedDate, 4) : addWeeks(selectedDate, 1);
            setSelectedDate(newDate);
        }
    };

    // UI classes
    const getSelectedDateClasses = (dateRange: number) => {
        if (selectedDate && format(selectedDate, "d") === format(dateRange, "d")) {
            //   point mate
            return "!text-primary  border border-borderColorPrimary lg:border-none xl:border xl:border-borderColorPrimary !bg-cardBackground shadow-[0px_2px_12px_0px_#00000014] lg:shadow-none xl:shadow-[0px_2px_12px_0px_#00000014] before:content-[''] before:absolute before:w-[5px] !font-semibold before:h-[5px] before:bg-primary before:rounded-full before:bottom-[7px] lg:before:bottom-[6px] ";
            // if (workingDays.includes(format(dateRange, "EEEE").toLocaleLowerCase())) {
        }
        const isFutureDate = isAfter(dateRange, new Date()) || isToday(dateRange);
        if (!isFutureDate) {
            return "";
        }
        if (workingDays.includes(format(dateRange, "EEEE").toLocaleLowerCase())) {
            return " text-primaryAppointment border-borderColorPrimary";
        }
        if (isFutureDate) {
            return "border-borderColorSecondary border disableDateAndTime lg:border-none xl:border xl:border-borderColorSecondary";
        }
    };

    return (
        <div className="bg-background overflow-visible lg:overflow-hidden scrollbar-hide relative w-full lg:max-w-full">
            {uiState.isMobile && (
                <div className="flex justify-between w-full items-center lg:px-2 mb-[15px]">
                    <button disabled={isPrevDisabled} onClick={handlePrevClick} className={`flex justify-center items-center disabled:opacity-50 ${isPrevDisabled ? "opacity-50" : ""}`}>
                        <ChevronLeft className="text-txtcolor w-[20px] h-[20px]" />
                    </button>

                    <div className="flex items-center flex-row gap-2 mobile_datepiker">
                        <span className="text-base leading-[22.4px] tracking-[-0.02rem] text-txtcolor font-medium">{formattedDate}</span>
                        {uiState.isMobile && (
                            <DateSelectButton
                                className={` ${
                                    uiState.isMobile
                                        ? " "
                                        : " w-[68px] h-[68px] lg:h-[42px] lg:w-[42px] hover:border-borderColorPrimary hover:bg-primaryHover xl:h-[68px] xl:w-[68px] border cursor-pointer border-borderColorPrimary hover: rounded-primary"
                                } flex justify-center items-center relative`}
                                dateFormat="dd/MM/yyyy"
                                selectedDate={selectedDate}
                                onChangeFunc={(changedDate: Date) => {
                                    setSelectedDate(new Date(changedDate));
                                    dispatch(setTempStore({ selectedBookingDate: format(new Date(changedDate), "Y-MM-dd") }));
                                    dispatch(setSelectedBookingDate(format(new Date(changedDate), "Y-MM-dd")));
                                }}
                                minDate={new Date()}
                                closeOnOutsideClick={true}
                            >
                                <Calendar className="w-[16px] h-[16px] !text-primaryAppointment" />
                            </DateSelectButton>
                        )}
                    </div>

                    <button
                        disabled={isNextDisabled}
                        onClick={handleNextClick}
                        className={`flex justify-center items-center ${isNextDisabled ? "!bg-arrowBackgroundColor border border-borderColorSecondary text-black CustomButtonShadow" : ""}`}
                    >
                        <ChevronRight className="text-txtcolor w-[20px] h-[20px]" />
                    </button>
                </div>
            )}
            <div className="flex justify-between w-full items-center lg:px-2">
                {!uiState.isMobile && (
                    <button
                        onClick={handlePrevClick}
                        className={`bg-containerBgColor rounded-primary shadow-[0px_4px_4px_0px_var(--header-shadow)]  flex justify-center items-center border h-[36px] min-w-[36px] w-[36px] border-borderColorPrimary disabled:opacity-50 z-10 hover:bg-primaryHover ${
                            isPrevDisabled ? "opacity-50 hover:bg-transparent cursor-not-allowed" : ""
                        }`}
                    >
                        <ArrowLeft className="text-txtcolor w-[18px] h-[18px]" />
                    </button>
                )}
                <div className="flex gap-2 lg:gap-2.5 justify-between w-full lg:pr-0 lg:mx-[4px] pt-1 pb-1.5">
                    {dateRangeArray.map((dateRange: number, index: number) => (
                        <div
                            key={index}
                            onClick={() => {
                                if (maxDate) {
                                    if (isToday(dateRange) || (isAfter(dateRange, new Date()) && maxDate && isBefore(dateRange, maxDate))) {
                                        setSelectedDate(new Date(dateRange));
                                    }
                                } else {
                                    setSelectedDate(new Date(dateRange));
                                }
                            }}
                            className={`w-full h-full flex justify-center flex-col items-center ${
                                maxDate
                                    ? isToday(dateRange) || (isAfter(dateRange, new Date()) && maxDate && isBefore(dateRange, maxDate))
                                        ? "cursor-pointer"
                                        : "cursor-not-allowed border-borderColorSecondary border opacity-50 lg:border-none xl:border xl:border-borderColorSecondary dateAndTimeOpacity"
                                    : "cursor-pointer"
                            }`}
                        >
                            <div
                                className={`md:w-w-full md:h-[68px] w-full h-[68px] dateTimeShadow lg:hover:!bg-cardBackground relative flex justify-center items-center rounded-secondary text-txtcolor uppercase text-[12px] font-medium flex-col bg-mainBackgroundColor lg:bg-transparent xl:bg-mainBackgroundColor
                            ${getSelectedDateClasses(dateRange)}
                            `}
                            >
                                <div className="uppercase text-[12px] 2xl:text-[14px] font-medium leading-[16.8px] tracking-[-0.01rem] flex mb-1 mt-0.5 sm:mt-1">{format(dateRange, "EE")}</div>
                                <span className="uppercase text-[14px] 2xl:text-[16px] font-medium leading-[18.6px] tracking-[-0.01rem] flex">{format(dateRange, "d")}</span>
                            </div>
                        </div>
                    ))}
                </div>
                {!uiState.isMobile && (
                    <button
                        onClick={handleNextClick}
                        className={`bg-containerBgColor shadow-[0px_4px_4px_0px_var(--header-shadow)]  flex justify-center items-center rounded-primary border h-[36px] min-w-[36px] w-[36px] border-borderColorPrimary disabled:opacity-50 z-10 hover:bg-primaryHover ${
                            isNextDisabled ? "opacity-50 hover:bg-transparent cursor-not-allowed" : ""
                        }`}
                    >
                        <ArrowRight className="text-txtcolor w-[18px] h-[18px]" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default RangeCalnedarComponent;
