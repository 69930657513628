import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CardElement } from "@stripe/react-stripe-js";
import FloatingInputbox from "src/components/FloatingInputbox";
import { useAppSelector } from "src/redux/hooks";
import { currentUser } from "src/redux/reducers/common/Common.slice";

const MCardTabComponent = ({ setCardError, cardError, isButtonShow }: any) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    const user = useAppSelector(currentUser);

    return (
        <div className={`flex-1 lg:px-4 ${user && user.total_loyalty_points > 0 ? "mt-5" : ""} ${isButtonShow ? "px-4" : ""}`}>
            <Controller
                name="holdername"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className="w-full mb-5">
                        <FloatingInputbox label={t("Cardholder Name")} handleChange={(e) => onChange(e.target.value)} value={value} name="holdername" inputClass={`${error && "is-error"}`} />
                        {error && <p className="text-error">{error && error.message}</p>}
                    </div>
                )}
            />

            <div className="flex flex-col w-full tracking-[0.03rem]">
                <Controller
                    name="carddetails"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full mb-5">
                            <div className={`border-none bg-transparent rounded-secondary CustomButtonShadow w-full relative card-number-wrapper `}>
                                <CardElement
                                    id="number-123"
                                    className={`card-number-input  ${(error || cardError) && "is-invalid"}`}
                                    options={{
                                        style: {
                                            base: {
                                                color: document.documentElement.style.getPropertyValue("--main-text-color"),
                                            },
                                        },
                                    }}
                                    onChange={(e) => {
                                        onChange(e);
                                        setCardError(e.complete ? "" : e.error?.message);
                                    }}
                                />
                            </div>
                            {(error || cardError) && <p className="text-error">{(error || cardError) && (error?.message || cardError)}</p>}
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default MCardTabComponent;
