import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { PrimeReactProvider } from "primereact/api";
import MainRoutes from "./routes/MainRoutes";
import ToastBodyContainer from "./components/ToastContainer";
import { onMessageListener } from "./services/API/firebase";
import { isSupported } from "firebase/messaging";
const App = () => {
    useEffect(() => {
        isSupported().then((supported) => {
            if (!supported) {
                return;
            }
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    onMessageListener();
                }
            });
        });
    }, []);

    return (
        <Provider store={store}>
            <BrowserRouter>
                <PrimeReactProvider>
                    <MainRoutes />
                </PrimeReactProvider>
            </BrowserRouter>
            <ToastBodyContainer />
        </Provider>
    );
};

export default App;
