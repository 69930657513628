import React, { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CustomButton from "src/components/CustomButton";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InputWithLabel from "src/components/InputWithLabel";
import { axiosPatch } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { currentUser, me } from "src/redux/reducers/common/Common.slice";
import { Edit02 } from "@untitled-ui/icons-react/build/cjs";
import MAccountSetting from "./Mobile/MAccountSetting";
import WAccountSetting from "./Web/WAccountSetting";
import PopupModal from "src/components/PopupModal";
import UpdateEmail from "./UpdateEmail";
import Sidebar from "src/components/Sidebar";
import UpdatePassword from "./UpdatePassword";
import UpdatePhone from "./UpdatePhone";
import Number from "src/components/Number";
import { IinitAction } from "./Profile.interface";
import FloatingInputbox from "src/components/FloatingInputbox";
import { getBookingInfo } from "../BookAppointment/Booking.slice";
import { InputMask } from "primereact/inputmask";
import { errorCode } from "src/constants/common";
import moment from "moment";
interface ILanding {
    handleClose: () => void;
}
interface IFormInput {
    first_name: string;
    last_name: string;
    profile_image_name?: any;
    birthdate: string;
    // gender: string;
}

const initAction = {
    email: false,
    phone: false,
    password: false,
};
const AccountSetting = ({ handleClose }: ILanding) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const bookingInfo = useAppSelector(getBookingInfo);

    const schema = Yup.object({
        first_name: Yup.string().required(t("This field is required")),
        last_name: Yup.string().required(t("This field is required")),
        profile_image_name: Yup.object().nullable(),
        birthdate: Yup.string()
            .required(t("This field is required"))
            .test("valid-day-month", t("Please enter a valid date."), (value) => {
                if (!value) return false;
                const [day, month, year] = value.split("/").map((num) => parseInt(num, 10));
                if (isNaN(year) || isNaN(month) || isNaN(day)) return false;
                if (month < 1 || month > 12) return false;
                const lastDayOfMonth = new Date(year, month, 0).getDate();
                return day > 0 && day <= lastDayOfMonth;
            })
            .test("not-future-date", t("Birthdate cannot be in the future."), (value) => {
                if (!value) return false;
                const [day, month, year] = value.split("/").map((num) => parseInt(num, 10));
                const enteredDate = new Date(year, month - 1, day);
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                return enteredDate <= today;
            }),
        // gender: Yup.string().required(t("Please select a gender")),
    }).required();

    const methods = useForm<IFormInput>({
        resolver: yupResolver(schema) as unknown as Resolver<IFormInput>,
        defaultValues: {
            first_name: user?.first_name || "",
            last_name: user?.last_name || "",
            profile_image_name: user?.profile_image_url ? user?.profile_image_name : null,
            birthdate: user?.birthdate ? moment(user?.birthdate, "YYYY-MM-DD").format("DD/MM/YYYY") : "",
            // gender: user?.gender,
        },
    });
    const { control, handleSubmit, setError } = methods;
    // useEffect(() => {
    //     const userGender = user?.gender ?? "";
    //     const foundOption = GENDER_OPTIONS.find((go) => go.value === userGender);
    //     if (!foundOption && userGender) {
    //         const customOption = {
    //             label: userGender,
    //             value: userGender,
    //         };
    //         setCustomGenderOption([...GENDER_OPTIONS, customOption]);
    //     } else {
    //         setCustomGenderOption(GENDER_OPTIONS);
    //     }
    // }, [user]);

    const handleChange = async (data: IFormInput) => {
        axiosPatch(API.USER.PROFILE, data)
            .then(async () => {
                await dispatch(me());
                // handleClose();
                return;
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    if (response.data) {
                        Object.keys(response.data).forEach((field) => {
                            setError(field as keyof IFormInput, {
                                type: "manual",
                                message: response.data[field][0],
                            });
                        });
                        return;
                    }
                }
            })
            .finally(() => setIsLoading(false));
    };

    const handleSave = async (data: IFormInput) => {
        setIsLoading(true);
        const { profile_image_name: profileImageName, ...dataWithoutProfileImage } = data;
        const updatedData = {
            ...dataWithoutProfileImage,
            birthdate: moment(data.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        };
        handleChange(updatedData);
    };

    const [action, setAction] = useState<IinitAction>(initAction);

    const handleAction = (type: keyof IinitAction) => () => {
        setAction((old) => {
            const newState: IinitAction = {
                ...old,
                email: false,
                phone: false,
                password: false,
            };

            newState[type] = true;
            return newState;
        });
    };

    const handleModalClose = useCallback(() => {
        setIsSuccess(false);
        setAction(initAction);
    }, []);

    return (
        <div className="relative h-full w-full flex flex-col font-primary">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(handleSave)} className={`flex flex-col flex-1 px-4 lg:px-5 ${bookingInfo.isFullScreen ? "!pb-[24px]" : ""}`}>
                    <div className="flex flex-col mt-1 lg:mt-[20px] ">
                        <div className="text-[16px] lg:text-[20px] mb-1 leading-[22.4px] lg:leading-[28.4px] font-bold lg:font-semibold text-txtAppointmentColor -tracking-[0.01rem] capitalize">
                            {t("Account Settings")}
                        </div>
                        <div className="font-normal text-[12px] lg:text-sm leading-[18.2px] text-textGrey -tracking-[0.01rem]">{t("Manage your profile")}</div>
                    </div>
                    {uiState.isTablet ? <WAccountSetting handleAction={handleAction} /> : <MAccountSetting handleAction={handleAction} />}
                    <div className="w-full mt-4 flex flex-col">
                        <div className="flex flex-col">
                            {uiState.isTablet ? <label className="text-txtAppointmentColor text-sm font-normal leading-[18.2px] mb-1.5 -tracking-[0.01rem] block">{t("Phone Number")}</label> : ""}
                            <Number
                                getNumber={() => {}}
                                onNumberChange={() => {}}
                                disabled
                                parentClassName="max-md:!h-[54px]"
                                defaultValues={{
                                    phone: user?.phone,
                                    phone_country_code: user?.phone_country_code,
                                }}
                                buttonIconRight={<Edit02 className="w-4 h-4 text-primary" onClick={handleAction("phone")} />}
                            />
                        </div>
                    </div>
                    <div className="mt-[16px] relative">
                        <Controller
                            name="birthdate"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                                <div className="w-full relative date_of_birth flex flex-col">
                                    {uiState.isTablet && <label className="text-txtAppointmentColor text-sm font-normal leading-[18.2px] mb-1.5 -tracking-[0.01rem] block">{t("Date of Birth")}</label>}
                                    <InputMask
                                        value={value}
                                        onChange={onChange}
                                        mask="99/99/9999"
                                        className={`fl-input-field fl-phone-field !h-[44px] rounded-secondary focus:shadow-[0px_8px_14px_-4px_var(--input-shadow)] focus-within:shadow-[0px_8px_14px_-4px_var(--input-shadow)] focus-visible:shadow-[0px_8px_14px_-4px_var(--input-shadow)] relative !outline-0 outline-none focus:ring-0 focus:ring-transparent form_input_control placeholder-gray-900 placeholder:text-[16px] placeholder:uppercase uppercase placeholder:text-textGrey text-txtAppointmentColor text-[16px] form_input_control !px-3.5 ${
                                            error
                                                ? "!is-invalid !border-danger focus:!border-danger focus-within:!border-danger focus-visible:!border-danger"
                                                : "border border-borderCounter hover:border-primaryBorderHover focus:border-primaryBorderHover focus-within:border-primaryBorderHover focus-visible:border-primaryBorderHover"
                                        } ${uiState.isTablet ? "" : "!h-[54px]"}`}
                                        placeholder="dd/mm/yyyy"
                                        slotChar="dd/mm/yyyy"
                                    />
                                    {error && <span className="text-error">{error.message}</span>}
                                </div>
                            )}
                        />
                    </div>
                    <div className="w-full mt-4 flex flex-col mb-5">
                        <div className="flex flex-col">
                            {uiState.isTablet ? (
                                <>
                                    <label className="text-txtAppointmentColor text-sm font-normal leading-[18.2px] mb-1.5 -tracking-[0.01rem] block">{t("Password")}</label>
                                    <InputWithLabel
                                        disabled
                                        name="password"
                                        handleChange={() => {}}
                                        placeholder="* * * * * * * *"
                                        value={""}
                                        inputClass="pl-[14px] pt-[5px]"
                                        buttonIconRight={<Edit02 className="w-[16px] h-[16px] text-primary absolute top-1/2 -translate-y-1/2 right-4" onClick={handleAction("password")} />}
                                    />
                                </>
                            ) : (
                                <div className="w-full flex flex-col">
                                    <FloatingInputbox
                                        label={t("Password")}
                                        disabled
                                        buttonIconRight={<Edit02 className="text-primary w-4 h-4" onClick={handleAction("password")} />}
                                        type="text"
                                        handleChange={() => {}}
                                        value="* * * * * * * *"
                                        name="password"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="mt-auto md:border-t border-borderColorPrimary flex gap-3 bg-containerBgColor md:pt-4 mb-4 -mx-[16px] px-[16px] lg:-mx-[20px] lg:px-[20px]">
                        {uiState.isTablet && (
                            <CustomButton outlineSecondary className="w-full" disabled={isLoading} onClick={handleClose}>
                                {t("Back")}
                            </CustomButton>
                        )}
                        <CustomButton primary className="w-full" type="submit" isLoading={isLoading} disabled={isLoading}>
                            {t("Save changes")}
                        </CustomButton>
                    </div>
                </form>
            </FormProvider>
            {action.email &&
                (uiState.isTablet ? (
                    <PopupModal
                        onClose={handleModalClose}
                        size={`max-w-[95%] min-w-[360px] mt-[76px] mx-auto md:w-[520px] font-primary outline-custom  ${isSuccess ? "mt-[0px] md:!w-[400px] " : ""}`}
                        className="!pt-0 !pb-0"
                        position={`${isSuccess ? "center" : ""}`}
                        dismissible
                        isCrossSign={false}
                    >
                        <UpdateEmail handleModalClose={handleModalClose} setIsSuccess={setIsSuccess} />
                    </PopupModal>
                ) : (
                    <Sidebar
                        dismissable
                        isOpen={uiState.isMobile && action.email}
                        position="bottom"
                        handleClose={() => handleModalClose()}
                        customClass={`w-full rounded-t-[16px] h-auto mobile-login-modal ${isSuccess ? "SuccessModel" : ""}`}
                        size={""}
                    >
                        <UpdateEmail handleModalClose={handleModalClose} />
                    </Sidebar>
                ))}
            {action.phone &&
                (uiState.isTablet ? (
                    <PopupModal
                        onClose={handleModalClose}
                        size={`max-w-[95%] min-w-[360px] mt-[76px] mx-auto md:w-[520px] font-primary outline-custom ${isSuccess ? "mt-[0px] md:!w-[400px]" : ""}`}
                        className="!pt-0 !pb-0"
                        dismissible
                        isCrossSign={false}
                        position={`${isSuccess ? "center" : ""}`}
                    >
                        <UpdatePhone handleModalClose={handleModalClose} setIsSuccess={setIsSuccess} />
                    </PopupModal>
                ) : (
                    <Sidebar
                        dismissable
                        isOpen={uiState.isMobile && action.phone}
                        position="bottom"
                        handleClose={() => handleModalClose()}
                        customClass={`w-full rounded-t-[16px] h-auto mobile-login-modal ${isSuccess ? "SuccessModel" : ""}`}
                        size={""}
                    >
                        <UpdatePhone handleModalClose={handleModalClose} />
                    </Sidebar>
                ))}
            {action.password &&
                (uiState.isTablet ? (
                    <PopupModal
                        onClose={handleModalClose}
                        size={`max-w-[95%] min-w-[360px] mt-[76px] mx-auto md:w-[520px] font-primary outline-custom ${isSuccess ? "mt-[0px] md:!w-[400px] " : ""}`}
                        className="!pt-0 !pb-0"
                        dismissible
                        position={`${isSuccess ? "center" : ""}`}
                    >
                        <UpdatePassword handleModalClose={handleModalClose} setIsSuccess={setIsSuccess} />
                    </PopupModal>
                ) : (
                    <Sidebar
                        dismissable
                        isOpen={uiState.isMobile && action.password}
                        position="bottom"
                        handleClose={() => handleModalClose()}
                        customClass={`w-full rounded-t-[16px] h-auto mobile-login-modal`}
                        size={""}
                    >
                        <UpdatePassword handleModalClose={handleModalClose} />
                    </Sidebar>
                ))}
        </div>
    );
};

export default AccountSetting;
