import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setNotificationToken } from "../../redux/reducers/Login.slice";
import { errorCode } from "../../constants/common";
import { toast } from "react-toastify";
import { firebaseEmitter, requestForFirebaseToken } from "../../services/API/firebase";
import { axiosPost } from "../../utils/requestClient";
import { API } from "../../constants/api";
import Template1Header from "../Landing/Template1/Header";
import Template2Header from "../Landing/Template2/Header";
import CommonHeader from "./CommonHeader";
import { currentShop, currentUser, setIsCrossClickTime, userIsCrossClickTime } from "src/redux/reducers/common/Common.slice";
import { XClose } from "@untitled-ui/icons-react/build/cjs";
import { FlairLogo, SuccessIcon } from "src/theme/Images";
import CustomButton from "src/components/CustomButton";
import { setIsGetApp } from "src/redux/reducers/Landing.slice";
import DownloadApp from "../Location/DownloadApp";
import Sidebar from "src/components/Sidebar";
import { getSlug } from "src/utils/global-functions";
import { getBookingInfo } from "../BookAppointment/Booking.slice";
import moment from "moment";

export type IActionType = {
    downloadAppInfo: boolean;
};
const initAction: IActionType = {
    downloadAppInfo: false,
};

const Header = () => {
    const dispatch = useAppDispatch();
    const AuthUserState = useAppSelector((data) => data.AuthUser);
    const user = useAppSelector(currentUser);
    const userCrossClickTime = useAppSelector(userIsCrossClickTime);
    const booking = useAppSelector(getBookingInfo);
    const settings: any = useAppSelector((data) => data.Common.settings)?.find((setting: any) => setting.type === (getSlug().isPreview ? "template_preview" : "template"))?.value;
    const template = useAppSelector((data) => data.Common.template);
    const [isVisible, setIsVisible] = useState<boolean>(true);
    const [action, setAction] = useState(initAction);
    const shop = useAppSelector(currentShop);
    const [isMoreThan24Hours, setIsMoreThan24Hours] = useState(true);

    const handleHideClick = (): void => {
        const currentTime = moment();
        dispatch(setIsCrossClickTime(currentTime));
        dispatch(setIsGetApp(false));
        setIsVisible(false);
        setIsMoreThan24Hours(false);
    };

    useEffect(() => {
        if (userCrossClickTime) {
            const currentTime = moment();
            const timeDifferenceInSeconds = currentTime.diff(userCrossClickTime, "seconds");

            if (timeDifferenceInSeconds > 24 * 60 * 60) {
                dispatch(setIsCrossClickTime(null));
                setIsVisible(true);
                dispatch(setIsGetApp(true));
                setIsMoreThan24Hours(true);
            } else {
                setIsVisible(false);
                dispatch(setIsGetApp(false));
                setIsMoreThan24Hours(false);
            }
        } else {
            dispatch(setIsCrossClickTime(null));
            setIsVisible(true);
            dispatch(setIsGetApp(true));
            setIsMoreThan24Hours(true);
        }
    }, [userCrossClickTime]);

    useEffect(() => {
        const getOrUpdateFirebaseNotificationToken = async () => {
            let FCM_TOKEN = await requestForFirebaseToken();
            if (!FCM_TOKEN) {
                FCM_TOKEN = await requestForFirebaseToken();
            }

            if (FCM_TOKEN && FCM_TOKEN !== AuthUserState.notificationToken && user) {
                dispatch(setNotificationToken(FCM_TOKEN));

                try {
                    const response = await axiosPost(API.NOTIFICATION.CREATE_UPDATE, {
                        token: FCM_TOKEN,
                    });
                    if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                        return;
                    }

                    throw response.data;
                } catch (err: any) {
                    toast.error(err?.message);
                }
            }
        };

        getOrUpdateFirebaseNotificationToken();

        if (user) {
            firebaseEmitter.on("firebaseNotification", handleNewNotification);
            return () => {
                firebaseEmitter.removeListener("firebaseNotification", handleNewNotification);
            };
        }
    }, [user, AuthUserState.notificationToken, dispatch]);
    const handleNewNotification = (payload: any) => {
        // create booking toast message also for reffer
        toast.success(<span className="text-toastTextColor font-medium text-[14px] leading-[20px] line-truncate pr-2 py-1 my-1 font-primary">{payload?.notification?.body}</span>, {
            icon: <img src={SuccessIcon} className="h-[38px] w-[38px] min-w-[38px] ml-[2px]" alt="" />,
        });
    };

    const renderHeader = () => {
        if (settings && settings.is_active_template && template) {
            if (template === "template_1") {
                return <Template1Header />;
            } else if (template === "template_2") {
                return <Template2Header />;
            }
        } else {
            return <CommonHeader />;
        }
    };

    const handleAction =
        (actionType: keyof IActionType | null = null) =>
        () => {
            if (actionType) {
                setAction({
                    ...initAction,
                    [actionType]: true,
                });
            } else {
                setAction(initAction);
            }
        };

    return (
        <>
            {booking.isFullScreen === false ? (
                isVisible &&
                isMoreThan24Hours && (
                    <div className="relative top-0 flex items-center py-[12px] px-4 bg-navbarBgColor transition-transform duration-300 border-b-[2px] border-borderColorPrimary lg:hidden shrink-0">
                        <div className="mr-[6px]">
                            <XClose className="w-5 h-5 text-textGrey" onClick={handleHideClick} />
                        </div>
                        <div className="flex-1 flex items-center ">
                            <img src={shop && shop.admin_logo_image_url ? shop.admin_logo_image_url : FlairLogo} alt="" className="rounded-[6px] max-h-[36px]" />
                            <div className="flex flex-col ml-2 flex-1 min-w-0">
                                <span className="text-txtAppointmentColor font-bold text-[14px] leading-[21px] tracking-[-0.01rem] truncate-custom mb-[1px]">Get the app</span>
                                <span className="leading-[15px] text-[10px] font-normal text-txtAppointmentColor truncate-custom">For the best user experience</span>
                            </div>
                        </div>

                        <CustomButton primary className="btn-appointment-primary !px-2 !py-[2px] !h-[24px]  font-bold !text-[12px] whitespace-nowrap " onClick={handleAction("downloadAppInfo")}>
                            <span className="truncate-custom">USE APP</span>
                        </CustomButton>

                        {action.downloadAppInfo && (
                            <Sidebar
                                dismissable
                                isOpen={action.downloadAppInfo}
                                position="bottom"
                                handleClose={handleAction()}
                                customClass={`w-full rounded-t-[16px] h-auto max-h-[90%] mobile-login-modal`}
                            >
                                <DownloadApp handleClose={handleAction()} />
                            </Sidebar>
                        )}
                    </div>
                )
            ) : (
                <></>
            )}

            {renderHeader()}
        </>
    );
};

export default Header;
