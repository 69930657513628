import React, { useEffect, useLayoutEffect, useState } from "react";
import en from "react-phone-number-input/locale/en.json";
import { initSelectedNumber, INumberList, ISelectedNumber } from "./Number.interface";
import { Check, ChevronDown, Plus } from "@untitled-ui/icons-react/build/cjs";
import { useTranslation } from "react-i18next";
import { usePhone } from "src/hooks/usePhone";
import { CountryCode, formatIncompletePhoneNumber, parseIncompletePhoneNumber } from "libphonenumber-js";
import countryData from "src/utils/countryData.json";

const Number = ({
    index,
    getNumber,
    onNumberChange,
    parentClassName = "min-h-[38px]",
    disabled = false,
    buttonIconRight,
    handlerIconClick,
    errors,
    name,
    defaultValues,
    isFocus = false,
    isButtonClicked,
    id,
    onFocus,
    onBlur,
}: any) => {
    const { setFormatPhone, isPhoneValid, phoneInfo } = usePhone();
    const { t } = useTranslation();
    const [Switch, setSwitch] = useState(false);
    const [selectedNumber, setSelectedNumber] = useState<ISelectedNumber>(initSelectedNumber);
    const [filterList, setFilterList] = useState<INumberList[]>([]);

    useEffect(() => {
        const handler = () => setSwitch(false);
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler);
        };
    }, []);

    useEffect(() => {
        let existSelectedNumber: ISelectedNumber = { ...initSelectedNumber };
        if (defaultValues?.phone && defaultValues?.phone_country_code) {
            existSelectedNumber = { ...selectedNumber };
            const getphoneinfo = phoneInfo(defaultValues.phone, defaultValues.phone_country_code);
            if (getphoneinfo) {
                if (getphoneinfo.countryCallingCode) {
                    existSelectedNumber.code = getphoneinfo.countryCallingCode;
                }
                if (getphoneinfo.nationalNumber) {
                    existSelectedNumber.number = getphoneinfo.nationalNumber;
                }
            }
            existSelectedNumber.image = countryData.find((country) => country.code === defaultValues.phone_country_code)?.flag_emoji;
            existSelectedNumber.name = en[defaultValues.phone_country_code as CountryCode];
            existSelectedNumber.country = defaultValues.phone_country_code;
        }
        setSelectedNumber((old) => ({ ...old, ...existSelectedNumber }));
    }, [defaultValues]);

    const handlerInputClik = (e: any) => {
        e.stopPropagation();
        if (!disabled) {
            setSwitch(!Switch);
        }
    };

    const handleCountryCode = (data: ISelectedNumber) => () => {
        setSelectedNumber((old) => ({ ...old, ...data }));
        const isValid = isPhoneValid(selectedNumber.number, data.country);
        onNumberChange(data.country, selectedNumber.number, data.code, isValid);
    };

    const handleInputNumberChange = (e: any) => {
        let number = parseIncompletePhoneNumber(e.target.value);
        if (number === selectedNumber.number) {
            const newValueFormatted = formatIncompletePhoneNumber(number, selectedNumber.country);
            if (newValueFormatted.indexOf(e.target.value) === 0) {
                // Trim the last digit (or plus sign).
                number = number.slice(0, -1);
            }
        }
        setSelectedNumber((old) => ({ ...old, number }));
        const isValid = isPhoneValid(number, selectedNumber.country);
        onNumberChange(selectedNumber.country, number, selectedNumber.code, isValid);
    };

    useLayoutEffect(() => {
        const fetchData = async () => {
            let list = countryData.map((country) => ({
                image: country.flag_emoji,
                code: country.dial_code,
                name: country.name,
                country: country.code as CountryCode,
            }));
            const shortedList = list.sort((a, b) => a.name.localeCompare(b.name));
            setFilterList(shortedList);
        };

        fetchData();
    }, []);

    const defaultCountry = () => {
        const matchingItem = filterList.find((item) => item.name === selectedNumber.name);
        if (matchingItem) {
            const cIndex = filterList.indexOf(matchingItem);
            const listElement = document.getElementById(`country-item-${cIndex}`);
            listElement?.scrollIntoView({ block: "center" });
        }
    };

    useEffect(() => {
        if (Switch) {
            defaultCountry();
        }
    }, [Switch]);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const key = e.key.toLowerCase();
        const matchingItem = filterList.find((item) => item.name.toLowerCase().startsWith(key));
        if (matchingItem) {
            const cIndex = filterList.indexOf(matchingItem);
            const listElement = document.getElementById(`country-item-${cIndex}`);
            listElement?.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    const handleSearchTermClick = (e: { stopPropagation(): unknown; preventDefault: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div className="relative">
            <div
                className={`fl-input-field w-full rounded-secondary flex items-center !h-[54px] md:!h-[44px] fl-phone-field form_input_control px-3.5 ${parentClassName} ${Switch && ""} ${
                    (typeof errors === "object" && Object.keys(errors).length > 0) ||
                    errors?.phonenumber ||
                    errors?.phone ||
                    (!isPhoneValid(selectedNumber.number, selectedNumber.country) && isButtonClicked)
                        ? "is-invalid"
                        : "border"
                }`}
            >
                <span className={`flex items-center ${disabled ? "!cursor-not-allowed" : ""}`} onClick={handlerInputClik}>
                    <div className="text-[22px] mr-2">{selectedNumber.image}</div>
                    {/* <span className="min-w-5 placeholder:text-textGrey placeholder:text-[16px] bg-transparent text-[16px] font-normal text-txtAppointmentColor placeholder:font-normal">
                        {selectedNumber.country}
                    </span> */}
                    <ChevronDown className="text-[#98A2B3] h-[20px] w-[19px] mr-2" />
                </span>
                <span className="ml-1 mr-1 placeholder:text-textGrey placeholder:text-[16px] bg-transparent text-[16px] font-normal text-txtAppointmentColor placeholder:font-normal">
                    +{selectedNumber.code}
                </span>
                <input
                    className={`placeholder:text-textGrey placeholder:text-[16px] pl-1 bg-transparent text-[16px] font-normal text-txtAppointmentColor placeholder:font-normal focus:ring-0 focus:ring-offset-0 focus-visible:outline-none w-full border-none focus:border-headerBorderColor CustomButtonShadow ${
                        disabled ? "cursor-not-allowed" : ""
                    }`}
                    type="text"
                    name={name}
                    id={id}
                    disabled={disabled}
                    value={setFormatPhone(selectedNumber.code, selectedNumber.number, selectedNumber.country)}
                    onChange={handleInputNumberChange}
                    placeholder={setFormatPhone(selectedNumber.code, "8708708866", selectedNumber.country)}
                    autoFocus={isFocus}
                    maxLength={20}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                {buttonIconRight && (
                    <span onClick={handlerIconClick ?? undefined} className="text-textGrey cursor-pointer absolute top-1/2 -translate-y-1/2 right-4">
                        {buttonIconRight}
                    </span>
                )}
            </div>
            {/* {errors && errors.phone ? <p className="text-error">{errors && errors.phone ? errors.phone.message : 'Invalid Phone number'}</p> : ''} */}
            {Switch && (
                <div
                    id="dropdownDelay"
                    className="z-[999] absolute top-15 rounded-lg w-full focus:outline-none bg-dropdownBackgroundColor px-2 pt-2 pb-2 shadow-[0px_5.33px_16px_0px_var(--promotion-overlay-shadow)] mt-1"
                >
                    <div className="relative opacity-0 w-0 h-0">
                        <input
                            type="text"
                            placeholder={t("Search for a country")}
                            onClick={handleSearchTermClick}
                            autoFocus
                            value={""}
                            onKeyDown={handleKeyPress}
                            onChange={() => {}}
                            className="opacity-0 w-0 h-0"
                        />
                    </div>
                    <ul className="country-code-dropdown-list" aria-labelledby="dropdownDelayButton">
                        {filterList.map((country: any, indexNumberCode: number) => (
                            <li
                                onClick={handleCountryCode(country)}
                                className={`country-code-dropdown-li ${selectedNumber.name === country.name && "active"}`}
                                key={indexNumberCode}
                                value={country.code}
                                id={`country-item-${indexNumberCode}`}
                                tabIndex={indexNumberCode}
                            >
                                <div className="flex items-center">
                                    {/* <span className="w-6 h-auto max-h-6 font-normal">{country.country}</span> */}
                                    <span className="text-[20px] h-auto"> {country.image}</span>
                                    <span className="text-sm min-w-[42px] ml-3 mr-2 flex items-center">
                                        <Plus className="w-[12px] min-w-[12px] h-[15px] mr-0.5" /> <span className="leading-[15px]">{country.code}</span>
                                    </span>
                                    <span className="">{country.name}</span>
                                </div>
                                {selectedNumber.country === country.country && <Check className="h-[17px] w-[17px] text-primary" />}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Number;
