import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StepProgressBar from "src/components/Stepper";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { EBookingTabState } from "./bookAppointment.interface";
import { getBookingInfo, setProductCategory, shopSettings } from "./Booking.slice";
import { currentShop, currentUser, globalLanguage } from "src/redux/reducers/common/Common.slice";
import { FilterLines, Check } from "@untitled-ui/icons-react/build/cjs";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { errorCode } from "src/constants/common";
import { Dropdown } from "flowbite-react";

const Stepper: FC = () => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const currentLanguage = useAppSelector(globalLanguage);
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const bookingInfo = useAppSelector(getBookingInfo);
    const [productCategoryLists, setProductCategoryLists] = useState<any[]>([]);
    const bookingShopSettings = useAppSelector(shopSettings);

    const getProductCategories = async () => {
        try {
            const payloadObj = {
                shop_id: shop.id,
                shop_location_id: bookingInfo.selectedShopLocation?.id,
            };
            const response = await axiosGet(API.PRODUCT.CATEGORY, { shop_location_id: payloadObj.shop_location_id }, { shop_id: payloadObj.shop_id });
            if (response.data?.status === errorCode.success || response.data?.status === errorCode.updateSuccess) {
                setProductCategoryLists(response.data.data);
                return;
            }

            throw response.data;
        } catch (err: any) {}
    };

    useEffect(() => {
        const initialLoad = async () => {
            await getProductCategories();
        };
        if (bookingInfo.selectedShopLocation) {
            initialLoad();
        }
    }, [currentLanguage]);

    const getSteps = () =>
        (bookingShopSettings.payment.enable && bookingShopSettings.payment.isBookWithCard && bookingShopSettings.payment.userIsBookWithCard) ||
        (bookingShopSettings.loyalty.totalAmount >= bookingInfo.bookingTotal && bookingShopSettings.loyalty.status)
            ? 4
            : 3;

    const getCompletedStepsCount = () =>
        [bookingInfo.selectedSpecialist, bookingInfo.selectedServices?.length, bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime ? true : false].filter(Boolean).length;

    const stepContent: Record<EBookingTabState, { title?: string; subtitle?: React.ReactNode; step: number } | undefined> = {
        [EBookingTabState.TAB_MENU]: {
            step: getCompletedStepsCount(),
            title: bookingInfo.isModifyingAppointment ? t("Edit your appointment") : t("Welcome back with name", { name: user && user.first_name ? `${user.first_name}` : "" }),
            subtitle: (
                <div className="block">
                    <span className="mr-1.5">{bookingInfo.isModifyingAppointment ? <span>{t("Edit your appointment at")}</span> : t("Book your next appointment at")}</span>
                    <span className="text-primaryAppointment font-medium uppercase">{`${bookingInfo.selectedShopLocation?.name}`}</span>
                </div>
            ),
        },
        [EBookingTabState.PRODUCT_BOOKING]: {
            step: 4,
            title: bookingInfo.isModifyingAppointment
                ? uiState.isMobile
                    ? t("Edit an item to your order")
                    : t("Would you like to edit an item to your order?")
                : uiState.isMobile
                ? t("Add an item to your order")
                : t("Would you like to add an item to your order?"),
            subtitle: bookingInfo.isModifyingAppointment
                ? uiState.isMobile
                    ? t("Edit products and collect at your appointment.")
                    : t("Edit a product to your order and pick it up in-store during your appointment.")
                : uiState.isMobile
                ? t("Add products and collect at your appointment.")
                : t("Add a product to your order and pick it up in-store during your appointment."),
        },
        [EBookingTabState.FINALIZE_BOOKING]: {
            step: 5,
            title: t("Finalize Your Booking"),
            subtitle: t("Add payment method to complete your booking."),
        },
        [EBookingTabState.SELECT_SPECIALIST]: undefined,
        [EBookingTabState.SELECT_DATE_TIME]: undefined,
        [EBookingTabState.SELECT_SERVICE]: undefined,
    };
    const renderStepComponent = () => {
        const content = stepContent[bookingInfo.step];
        return (
            <>
                {content && (
                    <div className="mb-4">
                        {bookingInfo.step === EBookingTabState.PRODUCT_BOOKING ? (
                            <>
                                <div className="flex items-center justify-between">
                                    <div className="flex flex-col justify-start">
                                        <h3 className="location-title-text">{content.title}</h3>
                                        <p className="location-sub-text ">{content.subtitle}</p>
                                    </div>
                                    <div className="ml-4 relative z-[999] lg:z-[99999]">
                                        {/* Add margin to separate the dropdown from the text */}
                                        <Dropdown
                                            className="z-[999] lg:z-[99999] bg-dropdownBackgroundColor border border-borderColorPrimary rounded-minimal flex items-center justify-center min-w-[180px]"
                                            theme={{
                                                content: "py-1.5 px-1 focus:outline-none min-w-[180px] w-full space-y-[3px]",
                                            }}
                                            label=""
                                            placement="bottom-start"
                                            dismissOnClick={true}
                                            inline
                                            renderTrigger={() => (
                                                <button
                                                    type="button"
                                                    className="min-w-[36px] h-[36px] sm:min-w-[44px] sm:h-[44px] border border-borderColorSecondary lg:border-borderColorSecondary rounded-minimal flex items-center justify-center lg:mt-0 relative hover:border-primaryBorderHover hover:bg-primaryHover gap-1"
                                                >
                                                    <FilterLines className="w-[20px] text-textMoreGrey" />
                                                </button>
                                            )}
                                        >
                                            {productCategoryLists.map((categoryList) => (
                                                <Dropdown.Item
                                                    theme={{
                                                        container: "right-0",
                                                        base: `${
                                                            bookingInfo?.productCategoryFilterValue?.value === categoryList.id &&
                                                            "bg-mainBackgroundColor rounded-minimal !text-TextColor focus:outline-none"
                                                        } flex items-center text-[12px] text-textMoreGrey hover:text-TextColor font-medium justify-start py-2 pl-2 pr-2 cursor-pointer w-full hover:bg-mainBackgroundColor hover:rounded-minimal focus:bg-mainBackgroundColor focus:outline-none focus:ring-0 min-w-max w-full`,
                                                    }}
                                                    key={categoryList.id}
                                                    onClick={() => {
                                                        bookingInfo?.productCategoryFilterValue?.value === categoryList.id
                                                            ? dispatch(setProductCategory(null))
                                                            : dispatch(setProductCategory({ label: categoryList.name, value: categoryList.id }));
                                                    }}
                                                >
                                                    <div className="flex items-center justify-between gap-2 w-full">
                                                        {categoryList.name}
                                                        {bookingInfo?.productCategoryFilterValue?.value === categoryList.id && <Check className="text-primary h-4 w-4 mr-1.5" />}
                                                    </div>
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                        {bookingInfo?.productCategoryFilterValue && (
                                            <div className="w-[13px] h-[13px] bg-primary rounded-full absolute -top-1 -right-1 border-[3px]  border-contentBackground "></div>
                                        )}
                                    </div>
                                </div>
                            </>
                        ) : (
                            content.title && (
                                <>
                                    <h3 className="location-title-text">{content.title}</h3>
                                    <p className="location-sub-text">{content.subtitle}</p>
                                </>
                            )
                        )}
                    </div>
                )}
                <div className="w-[100%] self-center">
                    <StepProgressBar steps={getSteps()} currentStep={content && bookingInfo.step !== EBookingTabState.PRODUCT_BOOKING ? content.step : getCompletedStepsCount()} />
                </div>
            </>
        );
    };

    return <>{renderStepComponent()}</>;
};

export default Stepper;
