import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { ISpecialistService } from "../../../../Interface";
import { getBookingInfo } from "../../Booking.slice";
import { formatAmount } from "../../../../utils/global-functions";
import { useTranslation } from "react-i18next";
import { EBookingTabState } from "../../bookAppointment.interface";
import moment from "moment";

const ServicesBasket = ({ value }: any) => {
    const { t } = useTranslation();
    const bookingState = useAppSelector(getBookingInfo);
    const [bookingServices, setBookingServices] = useState<ISpecialistService[]>([]);

    useEffect(() => {
        let totalPrices = 0;
        if (bookingState.step === EBookingTabState.SELECT_SERVICE) {
            totalPrices = bookingState.tempStore.selectedServices.reduce((prevPrice, serviceRecord) => {
                if (serviceRecord.price && serviceRecord.quantity) {
                    return prevPrice + Number(serviceRecord.price?.price) * serviceRecord.quantity;
                }
                return prevPrice;
            }, 0);
            setBookingServices(value);
        } else {
            totalPrices = bookingState.selectedServices.reduce((prevPrice, serviceRecord) => {
                if (serviceRecord.price && serviceRecord.quantity) {
                    return prevPrice + Number(serviceRecord.price?.price) * serviceRecord.quantity;
                }
                return prevPrice;
            }, 0);
            setBookingServices(value);
        }
    }, [bookingState]);

    const getHours = (time: string) => {
        const duration = moment.duration(time);
        const hours = duration.hours();
        const minutes = duration.minutes();
        return `${hours > 0 ? `${hours} ${t("hour")}${hours > 1 ? "s" : ""}` : ""}${hours > 0 && minutes > 0 ? " " : ""}${minutes > 0 ? `${minutes} minute${minutes > 1 ? "s" : ""}` : ""}`;
    };

    const getPriceRange = (data: any) => {
        if (data?.staff_service?.length) {
            const prices = data.staff_service.map((service: any) => service.price);
            const minPrice = Math.min(...prices);
            const maxPrice = Math.max(...prices);
            return minPrice === maxPrice ? `${formatAmount(minPrice)}` : `${formatAmount(minPrice)} - ${formatAmount(maxPrice)}`;
        }
    };

    return (
        <>
            {bookingServices.length > 0 && (
                <div className="flex flex-col gap-2 mt-3">
                    <h2 className="text-[14px] leading-[19.6px] font-medium text-textGrey  -tracking-[0.01rem]">{t("Service")}</h2>
                    <div className="flex flex-col gap-3">
                        {bookingServices.map((selectedService: ISpecialistService, index: number) => (
                            <div className="flex justify-between items-center gap-1" key={index}>
                                <div className="flex items-center w-auto">
                                    <div className="bg-bgContent w-[25px] min-w-[25px] h-[25px] lg:flex justify-center items-center rounded-primary text-sm text-txtAppointmentColor hidden mr-2.5">
                                        <div className="">{selectedService.quantity}</div>
                                    </div>
                                    <p className="text-txtAppointmentColor text-sm leading-[18.2px] font-medium -tracking-[0.01rem] truncate max-w-[140px] md:max-w-[150px]">
                                        <span>{selectedService.name}</span>
                                    </p>
                                    {selectedService.price && (
                                        <span className="text-textGrey ml-1 text-sm leading-[18.2px] font-medium -tracking-[0.01rem] w-max">({getHours(selectedService.price.duration)})</span>
                                    )}
                                </div>
                                <div className="flex gap-4 items-center justify-end text-txtAppointmentColor -tracking-[0.01rem]">
                                    <span className="text-txtcolor text-sm leading-[18.2px] font-normal text-right -tracking-[0.01rem] flex flex-row items-center">
                                        {selectedService.price ? (
                                            <span className="text-[14px] leading-[23.2px] font-normal">
                                                {selectedService.price.starting_price && (
                                                    <span className="text-[14px] leading-[23.2px] font-normal text-textGrey mr-0.5 max-md:-tracking-[0.02rem]"> {t("Starting Price")} </span>
                                                )}{" "}
                                                <span className="">{formatAmount(selectedService.price?.price)}</span>
                                            </span>
                                        ) : (
                                            <>{getPriceRange(selectedService)}</>
                                        )}
                                    </span>
                                    {/* </p> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default ServicesBasket;
