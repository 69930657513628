import React from "react";
import SpecialistDetail from "../../../components/SpecialistDetail";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import ServicesBasket from "./ServicesBasket";
import { setIsShowSpecialistProfileModal, setShowSpecialistProfileModalData } from "../../../redux/reducers/Ui.slice";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ProductBasket from "./ProductBasket";
import BasketCalculation from "./BasketCalculation";
import { BookingPreviewItem, getBookingInfo, setTempStore } from "../Booking.slice";
import ManageSubmit from "./ManageSubmit";
import Sidebar from "src/components/Sidebar";
import { ArrowRight, Calendar } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";
import { onError } from "src/utils/global-functions";

const AppointmentPreview = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bookingInfo = useAppSelector(getBookingInfo);
    const uiStates = useAppSelector((data) => data.UiStates);
    const booking = useAppSelector(getBookingInfo);

    const handleRedirect = () => {
        if (bookingInfo && bookingInfo.selectedShopLocation) {
            const latitude = bookingInfo.selectedShopLocation.latitude;
            const longitude = bookingInfo.selectedShopLocation.longitude;
            const googleMapUrl = `https://www.google.com/maps?q=${latitude},${longitude}&z=15&output=embed`;
            window.open(googleMapUrl, "_blank");
        }
    };

    const handleSpecialistSelect = () => {
        dispatch(setTempStore({ selectedSpecialist: uiStates.showSpecialistProfileModalData }));
        dispatch(setIsShowSpecialistProfileModal(false));
    };

    const handleClose = () => {
        dispatch(setIsShowSpecialistProfileModal(false));
        dispatch(setShowSpecialistProfileModalData(undefined));
    };

    const updateDataArray = (data: any, key: string, value: any) => {
        const index = data.findIndex((item: any) => item.key === key);
        if (index !== -1) {
            return data.map((item: any, i: any) => (i === index ? { key, value } : item));
        } else {
            return [...data, { key, value }];
        }
    };

    const bookingPreviewJsx = () => {
        let data = bookingInfo.bookingPreview && bookingInfo.bookingPreview.length ? [...bookingInfo.bookingPreview] : [];

        if (bookingInfo.tempStore.selectedSpecialist) {
            data = updateDataArray(data, "selectedSpecialist", bookingInfo.tempStore.selectedSpecialist);
        }

        if (bookingInfo.tempStore.selectedBookingDateTime) {
            data = updateDataArray(data, "selectedBookingDateTime", bookingInfo.tempStore.selectedBookingDateTime);
        }

        if (bookingInfo.tempStore.selectedServices && bookingInfo.tempStore.selectedServices.length) {
            data = updateDataArray(data, "selectedServices", bookingInfo.tempStore.selectedServices);
        }
        return (
            <>
                {data.map((item: BookingPreviewItem, index: number) => {
                    const { key, value } = item;

                    if (key === "selectedSpecialist") {
                        return value?.profile_image_url || value?.full_name ? (
                            <div key={key} className="flex flex-col gap-2 mt-3">
                                <h1 className="text-[14px] leading-[19.6px] font-medium text-textGrey  -tracking-[0.01rem]">{t("Specialist")}</h1>
                                <div className="flex gap-2.5 items-center">
                                    <div className="img">
                                        <img
                                            src={value.profile_image_url}
                                            className="rounded-minimal w-[40px] min-w-[40px] h-[40px] lg:min-w-[25px] lg:w-[25px] lg:h-[25px] object-cover"
                                            alt=""
                                            onError={onError("Professional")}
                                        />
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-txtAppointmentColor text-[14px] leading-[18.2px] font-medium -tracking-[0.01rem] capitalize-first">{value.full_name}</p>
                                    </div>
                                </div>
                            </div>
                        ) : null;
                    }

                    if (key === "selectedBookingDateTime") {
                        return (
                            <div key={`datetime-${index}`} className="flex flex-col gap-2 lg:px-0 mt-3 ">
                                <h2 className="text-[14px] leading-[19.6px] font-medium text-textGrey  -tracking-[0.01rem]">{t("Date and time")}</h2>
                                <div className="flex gap-2.5 items-center">
                                    <div className="bg-bgContent w-[25px] h-[25px] justify-center items-center rounded-primary text-[14px] text-textGrey hidden lg:flex">
                                        <Calendar className="w-[18px] h-[18px]" />
                                    </div>
                                    <p className="text-txtAppointmentColor text-sm leading-[18.2px] font-medium -tracking-[0.01rem]">{moment(value).format("dddd, MMMM Do - YYYY [at] h:mm A")}</p>
                                </div>
                            </div>
                        );
                    }

                    if (key === "selectedServices") {
                        if (value.length) {
                            return <ServicesBasket value={value} key={key} />;
                        }
                    }

                    return null;
                })}
            </>
        );
    };
    return (
        <div className={`lg:rounded-secondary relative h-full scrollbar-hide lg:bg-cardBackground overflow-hidden flex flex-col ${bookingInfo.isFullScreen ? "!pb-[28px]" : ""}`}>
            <div className="relative w-full flex-col hidden lg:flex shrink-0">
                <img
                    src={bookingInfo.selectedShopLocation?.location_image_url}
                    className="rounded-secondary rounded-b-none w-full h-[120px] lg:h-[160px] object-cover"
                    alt=""
                    onError={onError("Location")}
                />{" "}
                <div className="lg:absolute w-full lg:w-[94%] lg:top-[71px] pt-4 p-2.5 flex flex-col lg:mx-4 bg-[#00000066] rounded-secondary mt-2 lg:mt-0">
                    <div className="flex flex-col flex-1">
                        <h1 className="leading-[28px] text-lg lg:text-[12.8px] lg:leading-4 xl:text-base xl:leading-[22.4px] font-medium  text-white -tracking-[0.01rem] mb-1">
                            {bookingInfo.selectedShopLocation?.name}
                        </h1>
                    </div>
                    <div className="flex flex-row items-center">
                        <p className="flex-1 text-textGrey text-[11px] font-normal lg:text-xs lg:leading-5 truncate  md:text-sm md:leading-[18.2px] text-white -tracking-[0.01rem] mr-3">
                            {`${bookingInfo.selectedShopLocation?.street}, ${bookingInfo.selectedShopLocation?.city}, ${bookingInfo.selectedShopLocation?.country} ${bookingInfo.selectedShopLocation?.postal_code}`}
                        </p>
                        <span
                            className="flex items-center justify-bottom rounded-none text-white cursor-pointer text-sm whitespace-nowrap font-normal leading-[18.2px] relative get-direction"
                            onClick={handleRedirect}
                        >
                            <span className="border-b-animation border-white flex flex-row">
                                {t("Directions")} <ArrowRight className="w-[18px] h-[18px] ml-2" />
                            </span>
                        </span>
                    </div>
                </div>
            </div>
            <div className={`lg:px-5 lg:pb-[5px] flex flex-col h-full overflow-y-auto scrollbar-hide mb-[68px]`}>
                <p className={`flex lg:mt-5 text-base font-semibold text-txtcolor -tracking-[0.02rem] border-b border-borderColorPrimary  ${uiStates.isMobile ? "px-4 pb-4" : "pb-3"} `}>
                    {t("Order Summary")}
                </p>
                <div className="relative w-full flex-col gap-2 flex mt-3 lg:hidden px-4">
                    <span className="text-[14px] leading-[19.6px] font-medium text-textGrey -tracking-[0.01rem]">{t("Location")}</span>
                    <div className="flex flex-row items-center gap-2.5">
                        <img src={bookingInfo.selectedShopLocation?.location_image_url} className="rounded-minimal w-[40px] h-[40px] object-cover" alt="" onError={onError()} />{" "}
                        <div className=" flex flex-col">
                            <h1 className="text-txtAppointmentColor font-medium text-[14px] leading-[19.6px] -tracking-[0.01rem] capitalize-first">{bookingInfo.selectedShopLocation?.name}</h1>

                            <p className="text-textGrey text-[12px] leading-[18px] font-normal -tracking-[0.01rem] ">
                                {`${bookingInfo.selectedShopLocation?.street}, ${bookingInfo.selectedShopLocation?.city}, ${bookingInfo.selectedShopLocation?.country} ${bookingInfo.selectedShopLocation?.postal_code}`}
                            </p>
                        </div>
                    </div>
                </div>

                <div className={`flex flex-col flex-1 ${uiStates.isMobile ? "px-4 " : ""}`}>
                    {bookingPreviewJsx()}
                    {bookingInfo.selectedProducts && bookingInfo.selectedProducts.length > 0 && <ProductBasket />}
                    {bookingInfo.selectedSpecialist && bookingInfo.selectedBookingDate && bookingInfo.selectedServices && bookingInfo.selectedServices.length > 0 && <BasketCalculation />}
                </div>

                {uiStates.isShowSpecialistProfileModal &&
                    (uiStates.isMobile ? (
                        <Sidebar
                            isOpen={uiStates.isShowSpecialistProfileModal}
                            position="bottom"
                            handleClose={handleClose}
                            isBottomView
                            customClass=""
                            size="w-[460px] remove-scroll-sidebar h-auto max-h-[90%] RegularProfileModel"
                            dismissable
                        >
                            <div className="w-full flex flex-col flex-1 relative bg-contentBackground overflow-hidden h-full">
                                <SpecialistDetail />
                            </div>
                            <div className={`flex w-full gap-3 mt-auto bg-contentBackground ${booking.isFullScreen ? "pb-8" : "mb-5"}`}>
                                <CustomButton className="w-full" secondary onClick={handleClose}>
                                    {t("Close")}
                                </CustomButton>
                                <CustomButton primary className="w-full" onClick={handleSpecialistSelect}>
                                    {t("Select")}
                                </CustomButton>
                            </div>
                        </Sidebar>
                    ) : null)}
            </div>
            <div className="absolute lg:left-[20px] left-0 right-0 lg:right-[20px] bg-cardBackground bottom-[12px] lg:bottom-[20px]">
                <ManageSubmit isModal={uiStates.isMobile} />
            </div>
        </div>
    );
};

export default AppointmentPreview;
