import React, { useEffect } from "react";
import { ISpecialist } from "../../../../Interface";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setIsShowSpecialistProfileModal, setShowSpecialistProfileModalData } from "../../../../redux/reducers/Ui.slice";
import { getBookingInfo, getClientDetails, setNoShowPolicyVisible, setSettings, setTempStore } from "../../Booking.slice";
import { InfoCircle } from "@untitled-ui/icons-react/build/cjs";
import { onError } from "src/utils/global-functions";
import { currentSettings, currentShop, currentUser } from "src/redux/reducers/common/Common.slice";
import { SPECIALIST_PAY_STRUCTURE_TYPE } from "src/constants/common";

interface IProps {
    specialistData: ISpecialist;
}

const SpecialistCardComponent = ({ specialistData }: IProps) => {
    const dispatch = useAppDispatch();
    const uiStates = useAppSelector((data) => data.UiStates);
    const bookingInfo = useAppSelector(getBookingInfo);
    const currentSettingsList = useAppSelector(currentSettings);
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);

    useEffect(
        () => () => {
            dispatch(setIsShowSpecialistProfileModal(false));
            dispatch(setShowSpecialistProfileModalData(undefined));
        },
        [],
    );

    const manageShopSetting = () => {
        const cancellationSettings = currentSettingsList?.find((setting: any) => setting.type === "cancellation");
        const noShowSettings = currentSettingsList?.find((setting: any) => setting.type === "no_show");
        const loyaltySettings = currentSettingsList?.find((setting: any) => setting.type === "loyalty");
        const referSettings = currentSettingsList?.find((setting: any) => setting.type === "refer");
        const paymentSettings = currentSettingsList?.find((setting: any) => setting.type === "payment");

        dispatch(
            setSettings({
                payment: { enable: shop.admin.is_verified_stripe_account },
            }),
        );
        if (paymentSettings) {
            dispatch(
                setSettings({
                    payment: {
                        isBookWithCard: paymentSettings?.value.is_book_with_card,
                    },
                }),
            );
        }
        if (loyaltySettings) {
            dispatch(
                setSettings({
                    loyalty: {
                        status: loyaltySettings?.status,
                        receive: loyaltySettings?.value.receive,
                        spend: loyaltySettings?.value.spend,
                        totalAmount: user ? user.total_loyalty_amount : 0,
                    },
                }),
            );
        } else {
            dispatch(
                setSettings({
                    loyalty: {
                        status: false,
                        receive: 0,
                        spend: 0,
                        totalAmount: 0,
                    },
                }),
            );
        }
        if (referSettings) {
            dispatch(
                setSettings({
                    refer: {
                        referee: referSettings?.value.referee,
                        refferer: referSettings?.value.refferer,
                        referee_type: referSettings?.value.referee_type,
                        refferer_type: referSettings?.value.refferer_type,
                        status: referSettings.status,
                    },
                }),
            );
        } else {
            dispatch(
                setSettings({
                    refer: {
                        referee: 0,
                        refferer: 0,
                        referee_type: "value",
                        refferer_type: "value",
                        status: false,
                    },
                }),
            );
        }
        if (cancellationSettings || noShowSettings) {
            dispatch(setNoShowPolicyVisible(cancellationSettings?.status || noShowSettings?.status));

            dispatch(
                setSettings({
                    cancellation: {
                        status: cancellationSettings?.status,
                        cancellation_charge: cancellationSettings?.value.cancellation_charge,
                        cancellation_type: cancellationSettings?.value.cancellation_type,
                        cancellation_window: cancellationSettings?.value.cancellation_window,
                    },
                    noShow: {
                        status: noShowSettings?.status,
                        no_show_charge: noShowSettings?.value.no_show_charge,
                        no_show_type: noShowSettings?.value.no_show_type,
                    },
                }),
            );
        } else {
            dispatch(setNoShowPolicyVisible(false));
        }
    };

    const handleClick = () => {
        const isSelected = bookingInfo?.tempStore?.selectedSpecialist?.id === specialistData.id;
        dispatch(
            setTempStore({
                selectedSpecialist: isSelected ? undefined : specialistData,
            }),
        );

        if (!isSelected) {
            //  for calendar settting selected staff
            if (specialistData.shop_staff_settings && specialistData.shop_staff_settings.length) {
                const staffSettings = specialistData.shop_staff_settings;
                const calendarSettings = staffSettings?.find((setting: any) => setting.type === "calendar");
                if (calendarSettings) {
                    dispatch(
                        setSettings({
                            calendar: {
                                advance_weeks: calendarSettings.value.advance_weeks,
                            },
                        }),
                    );
                } else {
                    dispatch(
                        setSettings({
                            calendar: { advance_weeks: 0 },
                        }),
                    );
                }
            } else {
                dispatch(
                    setSettings({
                        calendar: { advance_weeks: 0 },
                    }),
                );
            }

            if (specialistData.pay_structure && specialistData.pay_structure.pay_structure_type && SPECIALIST_PAY_STRUCTURE_TYPE.includes(specialistData.pay_structure.pay_structure_type)) {
                if (shop && user) {
                    const payload = {
                        params: {
                            shop_id: shop.id,
                        },
                        data: {
                            shop_admin_id: specialistData.id,
                        },
                    };
                    dispatch(getClientDetails(payload));
                }
                dispatch(
                    setSettings({
                        payment: {
                            enable: specialistData.is_verified_stripe_account,
                        },
                    }),
                );
                if (specialistData.shop_staff_settings && specialistData.shop_staff_settings.length) {
                    const staffSettings = specialistData.shop_staff_settings;
                    const cancellationSettings = staffSettings?.find((setting: any) => setting.type === "cancellation");
                    const noShowcurrentSettings = staffSettings?.find((setting: any) => setting.type === "no_show");
                    const loyaltySettings = staffSettings?.find((setting: any) => setting.type === "loyalty");
                    const referSettings = staffSettings?.find((setting: any) => setting.type === "refer");
                    const paymentSettings = staffSettings?.find((setting: any) => setting.type === "payment");

                    if (paymentSettings) {
                        dispatch(
                            setSettings({
                                payment: {
                                    isBookWithCard: paymentSettings?.value.is_book_with_card,
                                },
                            }),
                        );
                    }
                    if (cancellationSettings || noShowcurrentSettings) {
                        dispatch(setNoShowPolicyVisible(cancellationSettings?.status || noShowcurrentSettings?.status));
                        dispatch(
                            setSettings({
                                cancellation: {
                                    status: cancellationSettings?.status,
                                    cancellation_charge: cancellationSettings?.value.cancellation_charge,
                                    cancellation_type: cancellationSettings?.value.cancellation_type,
                                    cancellation_window: cancellationSettings?.value.cancellation_window,
                                },
                                noShow: {
                                    status: noShowcurrentSettings?.status,
                                    no_show_charge: noShowcurrentSettings?.value.no_show_charge,
                                    no_show_type: noShowcurrentSettings?.value.no_show_type,
                                },
                            }),
                        );
                    } else {
                        dispatch(setNoShowPolicyVisible(false));
                    }

                    if (loyaltySettings) {
                        let spendAmount = 0;
                        if (user) {
                            const spendObj = loyaltySettings?.value.spend;
                            const amount = spendObj.amount;
                            const point = spendObj.point;
                            const points = user.total_loyalty_points;
                            spendAmount = (amount / point) * points;
                        }
                        dispatch(
                            setSettings({
                                loyalty: {
                                    status: loyaltySettings?.status,
                                    receive: loyaltySettings?.value.receive,
                                    spend: loyaltySettings?.value.spend,
                                    totalAmount: spendAmount,
                                },
                            }),
                        );
                    } else {
                        dispatch(
                            setSettings({
                                loyalty: {
                                    status: false,
                                    receive: 0,
                                    spend: 0,
                                    totalAmount: 0,
                                },
                            }),
                        );
                    }

                    if (referSettings) {
                        dispatch(
                            setSettings({
                                refer: {
                                    referee: referSettings?.value.referee,
                                    refferer: referSettings?.value.refferer,
                                    referee_type: referSettings?.value.referee_type,
                                    refferer_type: referSettings?.value.refferer_type,
                                    status: referSettings.status,
                                },
                            }),
                        );
                    } else {
                        dispatch(
                            setSettings({
                                refer: {
                                    referee: 0,
                                    refferer: 0,
                                    referee_type: "value",
                                    refferer_type: "value",
                                    status: false,
                                },
                            }),
                        );
                    }
                } else {
                    dispatch(setNoShowPolicyVisible(false));
                    dispatch(
                        setSettings({
                            loyalty: {
                                status: false,
                                receive: 0,
                                spend: 0,
                                totalAmount: 0,
                            },
                        }),
                    );
                    dispatch(
                        setSettings({
                            refer: {
                                referee: 0,
                                refferer: 0,
                                referee_type: "value",
                                refferer_type: "value",
                                status: false,
                            },
                        }),
                    );
                    dispatch(
                        setSettings({
                            payment: { isBookWithCard: false },
                        }),
                    );
                }
            } else {
                manageShopSetting();
            }
        } else {
            manageShopSetting();
            const calendarSettings = currentSettingsList?.find((setting: any) => setting.type === "calendar");
            if (calendarSettings) {
                dispatch(
                    setSettings({
                        calendar: {
                            advance_weeks: calendarSettings.value.advance_weeks,
                        },
                    }),
                );
            } else {
                dispatch(
                    setSettings({
                        calendar: { advance_weeks: 0 },
                    }),
                );
            }
        }

        if (uiStates.isShowSpecialistProfileModal) {
            dispatch(setShowSpecialistProfileModalData(specialistData));
            dispatch(setIsShowSpecialistProfileModal(true));
        }
    };

    return (
        <div className="w-full px-2 pb-4" onClick={handleClick} key={specialistData.id}>
            <div
                className={`cursor-pointer border border-borderColorPrimary p-3 rounded-secondary h-full   ${
                    bookingInfo?.tempStore?.selectedSpecialist?.id === specialistData.id ? "card-shadow bg-primaryHover" : ""
                }  h-auto border-borderColorPrimary md:hover:bg-primaryHover`}
            >
                <div className="">
                    <img className="rounded-secondary aspect-square w-full  object-cover" src={specialistData.profile_image_url} alt="" onError={onError("Professional")} />
                </div>
                <div className="flex items-end gap-1 pb-1 pt-4">
                    <div className="flex flex-col flex-1 min-w-0">
                        <h2 className="mb-1 text-sm font-semibold text-txtAppointmentColor -tracking-[0.02rem] leading-[19.6px] truncate-custom capitalize-first">
                            {specialistData.first_name} {specialistData.last_name}
                        </h2>
                        <p className="text-textGrey text-xs font-normal -tracking-[0.01rem] leading-[18px] truncate-custom">{specialistData?.staff_role?.name}</p>
                    </div>
                    <div
                        className="cursor-pointer flex w-auto"
                        onClick={(e) => {
                            e.stopPropagation();
                            dispatch(setShowSpecialistProfileModalData(specialistData));
                            dispatch(setIsShowSpecialistProfileModal(true));
                        }}
                    >
                        <InfoCircle className="text-textGrey hover:text-primary" width={16} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpecialistCardComponent;
