import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import WInputPasswordWithLabel from "src/components/InputPasswordWithLabel/WInputPasswordWithLabel";
import { INewPassword } from "../Password.interface";
import { exceptRequiredValidation } from "src/utils/global-functions";

const WNewPassword: FC<INewPassword> = ({ loading, validationsPassed, forgotPasswordStep, handleValidation }) => {
    const { t } = useTranslation();
    const { control } = useFormContext();
    return (
        <div className="content font-primary model-inner-wrapper">
            <h5 className="login-model-title">{t("Change Password")}</h5>
            <p className="login-model-subtitle flex flex-col">{t("password_description")}</p>
            <div className="flex flex-wrap mt-[42px]">
                {!forgotPasswordStep && (
                    <Controller
                        name="old_password"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                            <div className="flex flex-col w-full mb-4">
                                <WInputPasswordWithLabel
                                    required
                                    name="old_password"
                                    label={t("Current Password")}
                                    placeholder={t("Enter your current password")}
                                    value={value}
                                    onChange={onChange}
                                    inputPasswordClass="!h-[44px]"
                                    error={!!error}
                                />
                                {error && exceptRequiredValidation(error) && <p className="text-error">{error.message}</p>}
                            </div>
                        )}
                    />
                )}
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-full mb-4">
                            <WInputPasswordWithLabel
                                name="password"
                                required
                                label={t("New Password")}
                                placeholder={forgotPasswordStep ? t("Password") : t("New Password")}
                                value={value}
                                onChange={(e: { target: { value: string } }) => {
                                    onChange(e);
                                    handleValidation(e.target.value);
                                }}
                                inputPasswordClass="!h-[44px]"
                                error={!!error}
                            />
                            {error && exceptRequiredValidation(error) && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }: any) => (
                        <div className="flex flex-col w-full">
                            <WInputPasswordWithLabel
                                name="password_confirmation"
                                label={t("Confirm Password")}
                                required
                                placeholder={t("Confirm Password")}
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                inputPasswordClass="!h-[44px]"
                            />
                            {error && exceptRequiredValidation(error) && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
            </div>
            <div className="mt-[52px]">
                <CustomButton className={`w-full !h-[48px]`} primary isLoading={loading} disabled={loading || validationsPassed === true ? false : true} type="submit">
                    {t("Save changes")}
                </CustomButton>
            </div>
        </div>
    );
};

export default WNewPassword;
