import React from "react";
import { useTranslation } from "react-i18next";
import { DefaultImageJPG } from "src/theme/Images";
import { GoDotFill } from "react-icons/go";

const SBookingDetails = ({ index }: any) => {
    const { t } = useTranslation();
    return (
        <div
            key={index}
            className={`bg-cardBackground rounded-minimal flex flex-wrap items-center justify-between cursor-pointer font-primary transition border border-borderColorPrimary hover:shadow-[0px_4px_18px_0px_var(--header-shadow)] z-10 bg-[#FEFEFE] w-full `}
        >
            <div className="flex item-center gap-3 p-3 w-full">
                <div className="w-[42px] min-w-[42px] relative">
                    <div className="h-[42px] min-w-[42px] w-[42px] object-cover relative rounded-minimal custom-loading" />
                </div>
                <div className="flex-1 flex flex-col justify-center">
                    <span className="text-[16px] font-medium flex-1 text-TextColor tracking-[-0.01rem] leading-[22.4px] truncate custom-loading max-w-[210px]">{t("Appointment with")}</span>
                    <p className="text-xs text-textGrey font-normal leading-[18px] tracking-[-0.01rem] custom-loading mt-0.5">dummytext</p>
                </div>
            </div>
            <div className="flex gap-2 p-3 flex-row border-t border-borderColorPrimary w-full justify-between">
                <span className="text-[12px] text-TextColor font-normal flex items-center leading-[18px] flex-1 truncate custom-loading">dummytextdummytext</span>

                <div className="flex items-center justify-center rounded-[6px] border border-borderCounter pr-1.5 pl-1 h-[24px] CustomButtonShadow">
                    <span className="flex items-center">
                        <GoDotFill size={12} className={`w-3 h-3 mr-0.5 `} />
                        <span className="text-[12px] font-medium text-textCounterColor leading-[18px] capitalize custom-loading">dummytext</span>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default SBookingDetails;
