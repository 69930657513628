import React, { FC, useCallback, useLayoutEffect, useState } from "react";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { IMDProfessionalSwiper, init } from "./Professional.interface";
import { SwiperSlide } from "swiper/react";
import Info from "./Info";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import { useEnableSliderButtons } from "src/hooks/useEnableSliderButtons";
import { Swiper as SwiperType } from "swiper/types";
import IndividualStaffDetail from "./IndividualStaffDetail";
import { useAppSelector } from "src/redux/hooks";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";

const MDProfessionalSwiper: FC<IMDProfessionalSwiper> = ({ data }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [swiperKey, setSwiperKey] = useState(0);
    const [breakPoint, setBreakPoint] = useState(init);
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const [currentBreakpoint, setCurrentBreakpoint] = useState("1280");
    const conditions = [
        { breakpoint: "1280", minLength: 4 },
        { breakpoint: "1024", minLength: 3 },
        { breakpoint: "768", minLength: 2 },
    ];
    const isEnableSliderButtons = useEnableSliderButtons(currentBreakpoint, data?.staff.length, conditions, true);

    useLayoutEffect(() => {
        if (data?.staff.length === 2 || data?.staff.length === 3) {
            setBreakPoint((prev: any) => ({
                ...prev,
                1024: {
                    slidesPerView: data?.staff.length,
                    spaceBetween: 20,
                },
                1280: {
                    slidesPerView: data?.staff.length,
                    spaceBetween: 20,
                },
            }));
        } else {
            setBreakPoint(init);
        }
        setSwiperKey((prevKey) => prevKey + 1);
    }, [data?.staff]);

    const handleSlideChange = (event: any) => {
        setCurrentBreakpoint(event.currentBreakpoint);
    };

    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const title = getLocalizedSetting(data, "title");

    return (
        <>
            <div className="relative mb-6 md:mb-[54px] justify-end w-full hidden md:flex items-center ">
                {data?.is_title && <h2 className="custom-heading uppercase flex truncate overflow-hidden whitespace-nowrap w-full min-w-0">{title}</h2>}
                <div className="flex flex-row">
                    <button
                        onClick={handlePrevious}
                        disabled={!isEnableSliderButtons}
                        className={`rounded-primary flex justify-center items-center border border-TextColor  h-[52px] min-w-[52px] w-[52px] mr-2.5 bg-templateBg ${
                            !isEnableSliderButtons ? "opacity-50 cursor-not-allowed" : "cursor-pointer lg:hover:bg-primaryHover"
                        }`}
                    >
                        <ChevronLeft className="w-[24px] h-[24px] text-TextColor" />
                    </button>
                    <button
                        onClick={handleNext}
                        disabled={!isEnableSliderButtons}
                        className={`rounded-primary flex justify-center items-center border border-TextColor bg-templateBg h-[52px] min-w-[52px] w-[52px] ${
                            !isEnableSliderButtons ? "opacity-50 cursor-not-allowed" : "cursor-pointer lg:hover:bg-primaryHover"
                        }`}
                    >
                        <ChevronRight className="w-[24px] h-[24px] text-TextColor" />
                    </button>
                </div>
            </div>
            {data?.staff.length === 1 && uiState.isTablet ? (
                <IndividualStaffDetail staffList={data?.staff} />
            ) : (
                <CustomSwiper key={swiperKey} setSwiperRef={setSwiperRef} className="mySwiper w-full" loop={isEnableSliderButtons} onInit={handleSlideChange} breakpoints={breakPoint}>
                    {data?.staff.map((staff: any, index: React.Key | null | undefined) => (
                        <SwiperSlide key={index}>
                            <Info key={index} data={staff} />
                        </SwiperSlide>
                    ))}
                </CustomSwiper>
            )}
            {data?.staff && data?.staff.length > 1 && (
                <div className="flex justify-center w-full relative mt-4 md:hidden">
                    <button
                        className="rounded-primary flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] mr-3.5 bg-transparent"
                        onClick={handlePrevious}
                    >
                        <ChevronLeft className="text-TextColor w-4 h-4 smd:w-[20px] smd:h-[20px]" />
                    </button>
                    <button
                        className="rounded-primary flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] bg-transparent"
                        onClick={handleNext}
                    >
                        <ChevronRight className="text-TextColor w-4 h-4 smd:w-[20px] smd:h-[20px]" />
                    </button>
                </div>
            )}
        </>
    );
};

export default MDProfessionalSwiper;
