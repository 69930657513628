import React, { FC } from "react";
import { IProps } from "./RadioSwitch.interface";
import { useTranslation } from "react-i18next";

const RadioSwitch: FC<IProps> = ({ options, name, value, onChange }) => {
    const { t } = useTranslation();
    return (
        <li className="flex lg:hidden px-3 mt-4">
            <div className="w-full bg-mainBackgroundColor rounded-[8px] px-4 py-2.5 flex items-center justify-between">
                <span className="flex-1 text-textMoreGrey font-medium text-[14px] leading-[20px]">{t("Language")}</span>
                <div className="flex gap-2 flex-row">
                    {options.map((option, index) => (
                        <React.Fragment key={option.value}>
                            <input type="radio" className="hidden" id={`${option.value}-${name}`} name={name} value={option.value} checked={value === option.value} onChange={onChange} />
                            <label
                                className={`border-none flex h-[40px] w-[40px] rounded-[8px] items-center justify-center text-[16px] font-medium leading-[22.4px] tracking-[-0.03rem] ${
                                    value === option.value ? "bg-containerBgColor text-txtcolor shadow-[1px_2px_14px_0px_var(--header-shadow)]" : "text-textGrey"
                                }`}
                                htmlFor={`${option.value}-${name}`}
                            >
                                {option.label}
                            </label>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </li>
    );
};

export default RadioSwitch;
