import { CountryCode } from "libphonenumber-js";

export const initSelectedNumber = {
    image: "🇨🇦",
    name: "CA",
    country: "CA" as CountryCode,
    code: "1",
    number: "",
};

export type ISelectedNumber = {
    code: string;
    number: string;
    image: any; // TODO
    name: string;
    country: CountryCode;
};

export type INumberList = {
    code: string;
    // image: any; // TODO
    name: string;
    country: CountryCode;
};
