import { FloatingLabel } from "flowbite-react";
import React, { ReactElement, useEffect, useState } from "react";

export interface IPops {
    disabled?: boolean;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>, index?: number) => void;
    value: string;
    name: string;
    label: string;
    type?: string;
    index?: number;
    handlerIconClick?: () => void;
    buttonIcon?: ReactElement | null;
    buttonIconRight?: ReactElement | null;
    inputClass?: string;
    min?: number;
    inputWrapperClass?: string;
    required?: boolean;
    maxLength?: number;
    phoneNumber?: ReactElement | null;
    autoFocus?: boolean;
    autoComplete?: string;
    isPhone?: boolean;
    error?: any;
}

const FloatingInputbox: React.FC<IPops> = ({
    disabled = false,
    handleChange,
    value,
    name,
    label,
    type,
    index,
    handlerIconClick,
    buttonIcon,
    inputWrapperClass,
    inputClass,
    buttonIconRight,
    min,
    required,
    maxLength,
    phoneNumber,
    autoFocus = false,
    autoComplete,
    isPhone,
    error,
}) => {
    const [isFocus, setIsFocus] = useState(false);
    const [isLabel, setIsLabel] = useState(value ? false : true);

    useEffect(() => {
        if (isPhone) {
            if (!value) {
                setIsLabel(false);
            }
        }
    }, [isPhone]);

    const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isPhone) {
            setIsLabel(false);
        } else if (!e.target.value) {
            setIsLabel(true);
        } else {
            setIsLabel(false);
        }
        setIsFocus(false);
    };

    const onFocus = () => {
        setIsFocus(true);
        setIsLabel(true);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(e);
    };

    return (
        <div className={`flowbite_input border-none bg-transparent rounded-secondary w-full relative CustomButtonShadow ${inputWrapperClass} `}>
            <FloatingLabel
                className={`${isFocus ? "flowbite_input_focus" : ""} ${inputClass} ${error ? "is-error" : ""}`}
                type={type || "text"}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                min={min}
                variant="filled"
                label={isLabel ? label : ""}
                onBlur={onBlur}
                onFocus={onFocus}
                maxLength={maxLength}
                autoFocus={autoFocus}
                autoComplete={autoComplete}
            />
            {buttonIcon && (
                <span onClick={handlerIconClick ?? undefined} className="cursor-pointer absolute top-1/2 -translate-y-1/2 left-4">
                    {buttonIcon}
                </span>
            )}
            {buttonIconRight && (
                <span onClick={handlerIconClick ?? undefined} className="text-textGrey cursor-pointer absolute top-1/2 -translate-y-1/2 right-4 z-[99]">
                    {buttonIconRight}
                </span>
            )}
            {phoneNumber && <div className={`flex items-center phoneNumberInner ${isFocus ? "flowbite_input_focus" : ""}`}>{phoneNumber}</div>}
        </div>
    );
};

export default FloatingInputbox;
