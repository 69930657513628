/* eslint-disable complexity */
import React, { memo, useEffect, useLayoutEffect, useState } from "react";
import en from "react-phone-number-input/locale/en.json";
import { usePhone } from "src/hooks/usePhone";
import { INumberList, ISelectedNumber } from "./PhoneOrEmail.interface";
import { CountryCode, formatIncompletePhoneNumber, parseIncompletePhoneNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import FloatingInputbox from "../FloatingInputbox";
import { Check, ChevronDown, Plus } from "@untitled-ui/icons-react/build/cjs";
import countryData from "src/utils/countryData.json";
import { exceptValidation } from "src/utils/global-functions";

const MPhoneOrEmail = ({ onNumberChange, disabled, errors, name, defaultValues, isFocus = false, singleType, isButtonClicked, inputType, defaultEmailValues }: any) => {
    const { setFormatPhone, isPhoneValid, phoneInfo } = usePhone();
    const { t } = useTranslation();

    const getphoneinfo = phoneInfo(defaultValues?.phone, defaultValues?.phone_country_code);
    const [isPhone, setIsPhone] = useState(singleType && singleType === "phone" ? true : false);
    const [label, setLabel] = useState(inputType === "phone" ? t("Phone") : inputType === "email" ? t("Email") : t("Phone or Email"));

    const initialSelectedNumber: ISelectedNumber = {
        code: getphoneinfo && getphoneinfo.countryCallingCode ? getphoneinfo.countryCallingCode : "1",
        number: getphoneinfo && getphoneinfo.nationalNumber ? getphoneinfo.nationalNumber : "",
        image: defaultValues && defaultValues.phone_country_code ? countryData.find((country) => country.code === defaultValues.phone_country_code)?.flag_emoji : "🇨🇦",
        name: en[defaultValues && defaultValues.phone_country_code ? (defaultValues.phone_country_code as CountryCode) : "CA"],
        country: defaultValues && defaultValues.phone_country_code ? defaultValues.phone_country_code : "CA",
    };

    const [Switch, setSwitch] = React.useState(false);
    const [selectedNumber, setSelectedNumber] = useState<ISelectedNumber>(initialSelectedNumber);
    const [numberList, setNumberList] = React.useState<INumberList[]>([]);
    const [isInit, setIsInit] = React.useState(true);

    useEffect(() => {
        const handler = () => setSwitch(false);
        window.addEventListener("click", handler);
        return () => {
            window.removeEventListener("click", handler);
        };
    }, []);

    useEffect(() => {
        if (!isPhone) {
            setSelectedNumber(initialSelectedNumber);
        }
    }, [isPhone]);

    const handlerInputClik = (e: any) => {
        e.stopPropagation();
        setSwitch(!Switch);
    };

    const handleCountryCode = (option: ISelectedNumber) => {
        setSelectedNumber({ ...selectedNumber, ...option });
    };

    const handleInputNumberChange = (e: any) => {
        let value = e.target.value;
        const numberPattern = /^\d+$/;
        const characterPattern = /^[A-Za-z]+$/;
        if (!singleType) {
            if (value && value.length === 2) {
                if (numberPattern.test(value)) {
                    setLabel(t("Phone"));
                    setIsPhone(true);
                } else if (characterPattern.test(value)) {
                    setLabel(t("Email"));
                }
            } else if (!value) {
                setLabel(t("Phone or Email"));
                setIsPhone(false);
            }
        }

        let payload = {};
        setSelectedNumber({ ...selectedNumber, number: value });
        if (isPhone) {
            let newValue = parseIncompletePhoneNumber(value);
            const newValueFormatted = formatIncompletePhoneNumber(newValue, selectedNumber.country);
            if (newValue === selectedNumber.number) {
                if (newValueFormatted.indexOf(e.target.value) === 0) {
                    newValue = newValue.slice(0, -1);
                }
            }
            setSelectedNumber({ ...selectedNumber, number: newValue });
        } else {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            payload = {
                isEmail: true,
                data: value,
                error: !emailPattern.test(value) ? "Please enter a valid email" : "",
            };
            onNumberChange(payload);
        }
    };
    useEffect(() => {
        if (isPhone) {
            const isValid = isPhoneValid(selectedNumber.number, selectedNumber.country);
            const payload = {
                isEmail: false,
                data: { country: selectedNumber.country, number: selectedNumber.number, isValid: isValid, code: selectedNumber.code },
                error: !isValid ? "Please enter a valid number" : "",
            };
            onNumberChange(payload);
        }
    }, [selectedNumber.code, selectedNumber.number, selectedNumber.country]); // TODO
    const [filterList, setFilterList] = React.useState<INumberList[]>([]);

    useLayoutEffect(() => {
        const fetchData = async () => {
            let list = countryData.map((country) => ({
                image: country.flag_emoji,
                code: country.dial_code,
                name: country.name,
                country: country.code as CountryCode,
            }));
            const shortedList = list.sort((a, b) => a.name.localeCompare(b.name));
            setNumberList(shortedList);
            setFilterList(shortedList);
        };

        fetchData();
    }, []);

    const [searchTerm, setSearchTerm] = React.useState("");

    const handleSearchTermClick = (e: { stopPropagation(): unknown; preventDefault: () => void }) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const term = e.target.value.toLowerCase();
        setSearchTerm(term);
    };

    const clearSearchTerm = (event: { stopPropagation(): unknown; preventDefault: () => void }) => {
        event.preventDefault();
        event.stopPropagation();
        setSearchTerm("");
    };

    useEffect(() => {
        if (isInit) {
            setIsInit(false);
            return;
        }
        const filteredList = numberList.filter((country) => country.name.toLowerCase().includes(searchTerm));
        if (searchTerm === "") {
            setFilterList(numberList);
        } else {
            setFilterList(filteredList);
        }
    }, [searchTerm]);

    const defaultCountry = () => {
        const matchingItem = filterList.find((item) => item.name === selectedNumber.name);
        if (matchingItem) {
            const index = filterList.indexOf(matchingItem);
            const listElement = document.getElementById(`country-item-${index}`);
            listElement?.scrollIntoView({ block: "center" });
        }
    };

    useEffect(() => {
        if (Switch) {
            defaultCountry();
        } else {
            setSearchTerm("");
        }
    }, [Switch]);

    return (
        <div className="relative">
            <div
                className={`relative w-full ${isPhone && "phoneNumberWrapper"} ${
                    selectedNumber.code.length === 1
                        ? "phoneNumberOne"
                        : selectedNumber.code.length === 2
                        ? "phoneNumberTwo"
                        : selectedNumber.code.length === 3
                        ? "phoneNumberThree"
                        : selectedNumber.code.length === 4
                        ? "phoneNumberFour"
                        : ""
                }`}
            >
                <FloatingInputbox
                    type="text"
                    name={name}
                    inputClass={` ${isPhone && "isPhoneNumber"}`}
                    disabled={disabled}
                    error={errors}
                    value={
                        isPhone ? setFormatPhone(selectedNumber.code, selectedNumber.number, selectedNumber.country) : defaultEmailValues?.data ?? defaultValues // Assuming `defaultValues.email` holds the email value.
                    }
                    handleChange={handleInputNumberChange}
                    label={label}
                    maxLength={isPhone ? 20 : 255}
                    phoneNumber={
                        isPhone ? (
                            <span
                                className={`focus:ring-0 ring-0 focus:outline-none !focus:border-0 text-txtAppointmentColor flex items-center ${disabled ? "!cursor-default" : ""}`}
                                onClick={handlerInputClik}
                            >
                                <div className="text-[18px] mr-1.5 rounded">{selectedNumber.image}</div>
                                {/* <span className="text-txtAppointmentColor text-[14px] !font-normal leading-[24px]">{selectedNumber.country}</span> */}
                                <ChevronDown className="ml-1 text-[#98A2B3] w-[18px] mr-2" />
                                <span className="text-txtAppointmentColor text-[14px] font-normal leading-[24px] flex items-center">
                                    <Plus className="w-[12px] min-w-[12px] h-[15px] mr-0.5" /> <span className="leading-[15px]">{selectedNumber.code}</span>
                                </span>
                            </span>
                        ) : null
                    }
                    isPhone={isPhone}
                />
            </div>
            {errors && exceptValidation(errors) && <p className="text-error">{errors.message}</p>}
            {isPhone && (
                <>
                    {!disabled && Switch && (
                        <div
                            id="dropdownDelay"
                            className="z-[999] absolute top-15 rounded-lg w-full focus:outline-none bg-dropdownBackgroundColor  px-2 pt-2 pb-2 shadow-[0px_5.33px_16px_0px_var(--promotion-overlay-shadow)] mt-1"
                        >
                            <div className="relative h-0 p-0 m-0 opacity-0 w-0">
                                <input
                                    type="text"
                                    placeholder={t("Search for a country")}
                                    value={searchTerm}
                                    onChange={handleSearchTermChange}
                                    onClick={handleSearchTermClick}
                                    autoFocus
                                    className="placeholder:text-textGrey font-normal text-txtAppointmentColor text-[14px] -tracking-[0.01rem] placeholder:-tracking-[0.01rem] border-none w-0 h-0 focus:ring-0 ring-0 focus:outline-none !focus:border-0"
                                />
                                {/* <button className="absolute right-3 top-[14px]" onClick={clearSearchTerm}>
                                    <XClose className="w-[18px] h-[18px] text-textCounterColor" />
                                </button> */}
                            </div>
                            <ul className="country-code-dropdown-list" aria-labelledby="dropdownDelayButton">
                                {filterList.map((country: any, indexNumberCode: number) => (
                                    <li
                                        onClick={() => handleCountryCode(country)}
                                        className={`country-code-dropdown-li ${selectedNumber.name === country.name && "active"}`}
                                        key={indexNumberCode}
                                        value={country.code}
                                        id={`country-item-${indexNumberCode}`}
                                    >
                                        <div className="flex">
                                            <span className="text-[18px]"> {country.image}</span>
                                            {/* <span className="w-6 h-auto max-h-6 font-normal"> {country.country}</span> */}
                                            <span className="w-10 text-sm min-w-[40px] font-normal mx-3 flex items-center">
                                                <Plus className="w-[12px] min-w-[12px] h-[15px] mr-0.5" /> <span className="leading-[15px]">{country.code}</span>
                                            </span>
                                            <span className="text-[14px] font-normal max-w-max truncate">{country.name}</span>
                                        </div>
                                        {selectedNumber.country === country.country && <Check className="h-[17px] w-[17px] text-primary" />}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default memo(MPhoneOrEmail);
