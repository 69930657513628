import React, { FC, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { IOtp, IVerifyOtpProps } from "./Password.interface";
import { errorCode } from "src/constants/common";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import WVerifyOtp from "./Web/WVerifyOtp";
import { useAppSelector } from "src/redux/hooks";
import MVerifyOtp from "./Mobile/MVerifyOtp";
type FieldKey = "otp";

const VerifyOtp: FC<IVerifyOtpProps> = ({ authData, handleAuthAction, setAuthData }) => {
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const [loading, setLoading] = useState(false);

    const methods = useForm<IOtp>({
        defaultValues: {
            otp: "",
        },
        mode: "onSubmit",
        reValidateMode: "onSubmit",
    });
    const { handleSubmit, setError, reset } = methods;

    const handlerOtpAgainSend = async () => {
        reset();
        let payload;
        if (authData.isEmail) {
            payload = {
                email: authData.email,
            };
        } else {
            payload = {
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
            };
        }
        axiosPost(API.USER.FORGOT_PASSWORD, payload);
    };

    const handleSave = (data: any) => {
        setLoading(true);
        let payload;
        if (authData.isEmail) {
            payload = {
                email: authData.email,
                otp: data.otp,
            };
        } else {
            payload = {
                phone: `+${authData.phone.code}${authData.phone.number}`,
                phone_country_code: authData.phone.country,
                otp: data.otp,
            };
        }
        axiosPost(API.USER.FORGET_PASSWORD_VERIFY_OTP, payload)
            .then((response: any) => {
                const enhancedAuthData = {
                    ...authData,
                    token: response.data.data.token,
                };
                setAuthData(enhancedAuthData);
                handleAuthAction("newPassword");
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable) {
                    Object.keys(response.data).forEach((field) => {
                        setError(field as FieldKey, {
                            type: "manual",
                            message: response.data[field][0],
                        });
                    });
                    // setValue('number', new Array(4).fill(''));
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSave)}>
                {uiState.isTablet ? (
                    <WVerifyOtp loading={loading} handlerOtpAgainSend={handlerOtpAgainSend} authData={authData} />
                ) : (
                    <MVerifyOtp authData={authData} loading={loading} handlerOtpAgainSend={handlerOtpAgainSend} />
                )}
            </form>
        </FormProvider>
    );
};

export default VerifyOtp;
