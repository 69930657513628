import React, { useEffect } from "react";
import Template2 from "./Template2";
import Template1 from "./Template1";
import { useAppSelector } from "../../redux/hooks";
import { getSlug } from "src/utils/global-functions";
import Location from "src/app/Location";
import { allShopLocations } from "src/redux/reducers/common/Common.slice";
import BookAppointment from "../BookAppointment";
import { LOCAL_STORAGE_KEYS } from "src/constants/common";

const Landing = () => {
    const template = useAppSelector((data) => data.Common.template);
    const settings: any = useAppSelector((data) => data.Common.settings)?.find((setting: any) => setting.type === (getSlug().isPreview ? "template_preview" : "template"))?.value;
    const shopLocations = useAppSelector(allShopLocations);

    useEffect(() => {
        if (!!localStorage.getItem(LOCAL_STORAGE_KEYS.isLoginPopup)) {
            localStorage.removeItem(LOCAL_STORAGE_KEYS.isLoginPopup);
            // window.location.reload();
        }
    }, []);

    const getTemplate = () => {
        if (template === "template_1") {
            return <Template1 />;
        } else if (template === "template_2") {
            return <Template2 />;
        }
        return <></>;
    };

    return settings && settings.is_active_template ? getTemplate() : shopLocations && shopLocations.length > 1 ? <Location /> : <BookAppointment />;
};

export default Landing;
