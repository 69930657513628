import React, { memo, FC } from "react";
import { IProps } from "./DeletePopupModal.interface";
import { Modal } from "flowbite-react";
import CustomButton from "../CustomButton";
import { useTranslation } from "react-i18next";
import { BiTrash } from "react-icons/bi";

const DeletePopupModal: FC<IProps> = ({
    size = "md",
    dismissible = false,
    position = "center",
    onClose,
    onConfirm,
    title,
    description,
    className = "",
    buttonColor = "bg-primary text-txtAppointmentColor",
    confirmButtonText = "",
    cancelButtonText = "",
    headerIcon,
    primary = false,
    isLoading = false,
}) => {
    const { t } = useTranslation();

    return (
        <Modal
            show
            popup
            dismissible={dismissible}
            size={size}
            position={position}
            className="modal_backdrop font-primary"
            theme={{ content: { inner: `bg-contentBackground rounded-secondary shadow  max-lg:max-w-[90%] mx-auto`, base: `${size}` } }}
            onClose={onClose(false)}
        >
            <Modal.Header className="px-5 pt-4 pb-0">{headerIcon && <div className="border rounded-secondary p-3 border-borderCounter">{headerIcon}</div>}</Modal.Header>

            <Modal.Body className="px-5 py-0 text-center mb-3">
                <h3 className="text-[22px] font-semibold mb-1 text-txtAppointmentColor">{title}</h3>
                <p className="text-sm font-normal text-textGrey">{description}</p>
            </Modal.Body>
            <Modal.Footer className="px-5 pt-5 pb-4">
                <div className="flex gap-4 w-full">
                    <CustomButton className="w-full" secondary onClick={onClose(false)}>
                        {cancelButtonText || t("Cancel")}
                    </CustomButton>
                    <CustomButton isLoading={isLoading} className={`w-full !text-primaryBrandText ${className} ${primary ? buttonColor : "!bg-dangerErrorText"}`} primary={primary} onClick={onConfirm}>
                        {confirmButtonText || t("Delete")}
                    </CustomButton>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default memo(DeletePopupModal);
